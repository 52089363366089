import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { shadow } from 'theme/palette';

type CategoryCardProps = {
  title: string;
  description: string;
  url: string;
};

const CategoryCard: React.FC<CategoryCardProps> = (props: CategoryCardProps) => {
  const { title, description, url } = props;
  const navigate = useNavigate();

  const goTo = () => navigate(url);

  return (
    <Box
      height='250px'
      maxHeight='280px'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      component='div'
      boxShadow={shadow[200]}
      borderRadius='6px'
      p={4}
    >
      <Box
        height='100%'
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        alignItems='flex-start'
      >
        <Box component='div' height='20%'>
          <Typography
            component='h1'
            color='primary'
            variant='h6'
            sx={{
              textTransform: 'capitalize',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box component='div' overflow='hidden' height='60%'>
          <Typography
            className='ellipsis-card'
            my={1}
            component='p'
            color='customGrey'
            variant='body2'
          >
            {description}
          </Typography>
        </Box>
        <Button sx={{ mt: 1 }} variant='primarybtn' onClick={goTo} size='small'>
          Examine
        </Button>
      </Box>
    </Box>
  );
};

export default CategoryCard;
