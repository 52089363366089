import CommentsFormContainer from 'modules/global-academics/agencies/help-desk-tickets-admin/form/comments/container';
import { useParams } from 'react-router-dom';
import TicketFormContainer from './ticket/container';

const TicketsAndCommentsWrapper = () => {
  const { ticketId } = useParams();
  return (
    <>
      <TicketFormContainer />
      {ticketId && <CommentsFormContainer />}
    </>
  );
};

export default TicketsAndCommentsWrapper;
