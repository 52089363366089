import { Box, Typography } from '@mui/material';
import { CancelAndSave, Checkbox, DatePicker, DropDown, Input } from 'components';
import MultifileUpload from 'components/fields/MultiFileUpload';
import { useFileStore } from 'components/fields/MultiFileUpload/fileStore';
import { API_ROUTES } from 'constants/apiRoutes';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { catalogs } from 'services/catalogs/help-desk-tickets';
import { ticketFormFields } from 'services/help-desk/form';
import { dark } from 'theme/palette';
import { optionType } from 'utils/data-to-options';
import { AcceptedFileTypes } from 'utils/MIMETypes';

type Props = {
  handleSubmit: () => void;
  catalogs: catalogs;
  associateDirector: optionType[];
  staffMembers: optionType[];
  serviceList: optionType[];
  staffMembersByAdminRole: optionType[];
};

const TicketDetail: React.FC<Props> = (props) => {
  const { ticketId } = useParams();
  const isReadOnlyView = Boolean(ticketId);

  const {
    handleSubmit,
    catalogs,
    associateDirector,
    staffMembers,
    serviceList,
    staffMembersByAdminRole,
  } = props;
  const {
    centerSupportTypeOptions,
    programTypeOptions,
    ticketPriorityOptions,
    attendanceTypeOptions,
    ticketStatusOptions,
  } = catalogs;

  const centerRoute = '/help-desk';

  const navigate = useNavigate();
  const goBack = () => navigate(centerRoute);
  const { isUploading } = useFileStore();

  return (
    <Box component='form' id='ticket-form' onSubmit={handleSubmit}>
      <Box
        component='div'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        mt={3}
        mb={3}
      >
        <Typography component='h1' color={dark[200]} variant='h5'>
          Help Desk Ticket
        </Typography>
      </Box>
      <Box mb={10}>
        <Box
          mt={1}
          mb={4}
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' },
            columnGap: 2,
            rowGap: 2,
          }}
        >
          <DropDown
            caption='Center Support Type'
            fieldName={ticketFormFields.supportTypeId}
            options={centerSupportTypeOptions}
            placeholder='Select Center Support Type...'
            numberType
            isDisabled={isReadOnlyView}
          />
          <DropDown
            caption='Program Type'
            fieldName={ticketFormFields.programTypeId}
            options={programTypeOptions}
            placeholder='Select Program Type...'
            numberType
            isDisabled={isReadOnlyView}
          />
          <Checkbox
            caption='Outside of Current Month Request'
            fieldName={ticketFormFields.outsideOfCurrentMonthRequest}
            placement='end'
            toggle={false}
            isDisabled={isReadOnlyView}
          />
          <DropDown
            caption='Priority'
            fieldName={ticketFormFields.priorityId}
            options={ticketPriorityOptions}
            placeholder='Select Priority...'
            numberType
            isDisabled={isReadOnlyView}
          />
          <DatePicker
            caption='Date Needed By'
            fieldName={ticketFormFields.dateNeededBy}
            placeholder='MM/DD/YYYY'
            isDisabled={isReadOnlyView}
          />
          <Checkbox
            caption='Census Related'
            fieldName={ticketFormFields.isCensusRelated}
            placement='end'
            toggle={false}
            isDisabled={isReadOnlyView}
          />
        </Box>
        <Typography
          mb={3}
          mt={3}
          component='h1'
          color={dark[200]}
          variant='h6'
          sx={{ fontWeight: 'bold' }}
        >
          Service Change Request
        </Typography>
        <Box
          mt={1}
          mb={4}
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' },
            columnGap: 2,
            rowGap: 2,
          }}
        >
          <DropDown
            caption='Service Type'
            fieldName={ticketFormFields.providedServiceTypeId}
            options={serviceList}
            placeholder='Select Service Type...'
            numberType
            isDisabled={isReadOnlyView}
            searchEnable
          />
          <Input
            caption='Service ID'
            fieldName={ticketFormFields.serviceId}
            placeholder='Enter Service ID...'
            isDisabled={isReadOnlyView}
          />
          <DropDown
            caption='Attendance Type'
            fieldName={ticketFormFields.attendanceTypeId}
            options={attendanceTypeOptions}
            placeholder='Select Attendance Type...'
            numberType
            isDisabled={isReadOnlyView}
          />
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' },
            columnGap: 2,
            rowGap: 2,
          }}
        >
          <Input
            caption='Description of Issue- and Change Needed'
            fieldName={ticketFormFields.descriptionOfIssueAndChangeNeeded}
            textarea
            multiline
            rows={4}
            placeholder='Enter Description of Issuer and Change Needed...'
            customArrayErrors
            isDisabled={isReadOnlyView}
          />
        </Box>

        <Typography
          mb={3}
          mt={3}
          component='h1'
          color={dark[200]}
          variant='h6'
          sx={{ fontWeight: 'bold' }}
        >
          Upload Supporting Documentation
        </Typography>
        <Box
          mt={1}
          mb={4}
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' },
            columnGap: 2,
            rowGap: 2,
          }}
        >
          <MultifileUpload
            fieldName={ticketFormFields.supportingDocumentationFiles}
            azureUrl={`${API_ROUTES.AZURE_UPLOAD_FILE_HELP_DESK}?fileName=FILENAME`}
            deleteUrl={API_ROUTES.AZURE_DELETE_FILE_HELP_DESK}
            fileNameField='FILENAME'
            accept={[
              AcceptedFileTypes.PNG,
              AcceptedFileTypes.JPEG,
              AcceptedFileTypes.PDF,
              AcceptedFileTypes.DOC,
              AcceptedFileTypes.DOCX,
            ]}
            isDisabled={isReadOnlyView}
          />
        </Box>

        <Typography
          mb={3}
          mt={3}
          component='h1'
          color={dark[200]}
          variant='h6'
          sx={{ fontWeight: 'bold' }}
        >
          Notifications
        </Typography>
        <Box
          mt={1}
          mb={4}
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' },
            columnGap: 2,
            rowGap: 2,
          }}
        >
          <Input
            caption='Associated User'
            fieldName={ticketFormFields.associatedUser}
            placeholder='Autogerenated'
            isDisabled
          />
          <Input
            caption='Manager Involved'
            fieldName={ticketFormFields.managerInvolved}
            placeholder='Autogenerated'
            isDisabled
          />
          <DropDown
            caption='Assigned Associate Director'
            fieldName={ticketFormFields.assignedAssociateDirectorId}
            options={associateDirector}
            placeholder='Select Assigned Associate Director...'
            numberType
            isDisabled={isReadOnlyView}
          />
          <DropDown
            numberType
            caption='Additional Notifications '
            options={staffMembers}
            fieldName={ticketFormFields.additionalNotificationId}
            placeholder='Search...'
            searchEnable
            isDisabled={isReadOnlyView}
          />
        </Box>
        <Typography
          mb={3}
          mt={3}
          component='h1'
          color={dark[200]}
          variant='h6'
          sx={{ fontWeight: 'bold' }}
        >
          Admin User Information
        </Typography>
        <Box
          mt={1}
          mb={4}
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' },
            columnGap: 2,
            rowGap: 2,
          }}
        >
          <DropDown
            caption='Status'
            fieldName={ticketFormFields.statusId}
            options={ticketStatusOptions}
            placeholder='Select Status...'
            numberType
            isDisabled
          />
          <DropDown
            caption='Help Desk Owner'
            fieldName={ticketFormFields.helpDeskOwnerId}
            options={staffMembersByAdminRole}
            placeholder='Select Help Desk Owner...'
            numberType
            isDisabled
          />
          <DropDown
            caption='Help Desk Record Type'
            fieldName={ticketFormFields.helpDeskRecordTypeId}
            options={[]}
            placeholder='Select Help Desk Record Type...'
            numberType
            isDisabled
            isWip
          />
        </Box>
      </Box>
      <CancelAndSave
        saveText={'Save'}
        onCancel={goBack}
        isDisabled={isReadOnlyView}
        isSaving={isUploading}
      />
    </Box>
  );
};

export default TicketDetail;
