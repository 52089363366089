import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { axiosConfig } from './axiosConfig';

export interface TaskApiRequest {
  sortField?: string;
  sortDirection?: string;
  page: number;
  pageSize: number;
  taskId?: number;
  nextToken?: string;
  previousToken?: string;
}

export interface TaskApiResponse {
  nextToken: string;
  previousToken: string;
  data: Task[];
}

export interface Task {
  id: number;
  title: string;
  description: string;
  assignedUserId: string;
  taskType: string;
  dueDate: Date;
  status?: string;
  referenceId: string;
  referenceName: string;
  completedDate?: Date;
  isActive: boolean;
  createdDate?: Date;
  updatedDate?: Date;
}

export default function useTaskApi() {
  const getAll = createAsyncThunk('get-tasks', async (request: TaskApiRequest) => {
    try {
      let endpointUrl = '/api/v1/Tasks/?UserId=1';
      if (request.sortField) {
        endpointUrl = endpointUrl.concat(
          `&SortField=${request.sortField}&SortDirection=${request.sortDirection}`,
        );
      }

      if (request.nextToken && request.nextToken.trim().length > 0) {
        endpointUrl = endpointUrl.concat(`&NextToken=${request.nextToken}`);
      }

      if (request.previousToken && request.previousToken.trim().length > 0) {
        endpointUrl = endpointUrl.concat(`&PreviousToken=${request.previousToken}`);
      }

      if (request.page > 0) {
        endpointUrl = endpointUrl.concat(`&Page=${request.page}&PageSize=${request.pageSize}`);
      } else {
        endpointUrl = endpointUrl.concat('&Page=1&PageSize=10');
      }

      const { data } = await axiosConfig.get<TaskApiResponse>(endpointUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access-token') || null}`,
        },
      });

      if (data) return data as TaskApiResponse;
      else return [];
    } catch (error) {
      return error;
    }
  });

  const getById = createAsyncThunk('get-task', async (request: TaskApiRequest) => {
    try {
      let endpointUrl = `/api/v1/Tasks/${request.taskId}`;
      if (request.sortField) {
        endpointUrl = endpointUrl.concat(
          `&SortField=${request.sortField}&SortDirection=${request.sortDirection}`,
        );
      }

      if (request.page > 0) {
        endpointUrl = endpointUrl.concat(`&Page=${request.page}&PageSize=${request.pageSize}`);
      } else {
        endpointUrl = endpointUrl.concat('&Page=1&PageSize=10');
      }

      const { data } = await axiosConfig.get<Task>(endpointUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access-token') || null}`,
        },
      });

      return data as Task;
    } catch (error) {
      console.log(error);
      return null;
    }
  });

  const saveTask = createAsyncThunk('save-task', async (payload: Task, { dispatch }) => {
    const endpointUrl = '/api/v1/Tasks';
    try {
      const { data } = await axiosConfig.post(endpointUrl, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access-token') || null}`,
        },
      });

      toast.success('Task Created');
      dispatch(getAll({ page: 1, pageSize: 5 }));
      return data;
    } catch (error) {
      toast.error('error');
    }
  });

  const completeTask = createAsyncThunk('complete-task', async (taskId: number, { dispatch }) => {
    const endpointUrl = `/api/v1/Tasks/Complete/${taskId}`;

    try {
      const { data } = await axiosConfig.post<Task>(endpointUrl, taskId, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access-token') || null}`,
        },
      });
      toast.success('Task Completed');
      dispatch(getAll({ page: 1, pageSize: 5 }));
      return data;
    } catch (error) {
      toast.error('Error');
    }
  });

  return {
    getAll,
    getById,
    saveTask,
    completeTask,
  };
}
