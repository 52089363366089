import { Dialog } from '@mui/material';
import { TransitionModal } from 'modules/client/incident-reporting/dialogs/dialogMessage';
import { useEffect, useState } from 'react';

type ModalFormProps = {
  children: string | JSX.Element | JSX.Element[];
  open: boolean;
  disableBackClick?: boolean;
  onClose: () => void;
  style?: React.CSSProperties;
};
const ModalForm: React.FC<ModalFormProps> = (props: ModalFormProps) => {
  const { children, open, disableBackClick, onClose, style = {} } = props;
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (open && !isMounted) {
      setIsMounted(true);
    } else if (!open && isMounted) {
      setTimeout(() => {
        setIsMounted(false);
      }, 300);
    }
  }, [open]);

  if (!isMounted) return null;

  const handleClose = (_, reason: 'backdropClick' | 'escapeKeyDown') => {
    if ((reason === 'backdropClick' || reason === 'escapeKeyDown') && disableBackClick) {
      return;
    }

    onClose();
  };
  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: '10px',
          paddingLeft: '36px',
          paddingRight: '36px',
          paddingTop: '42px',
          minWidth: '730px',
          ...style,
        },
      }}
      fullWidth
      open={open}
      onClose={handleClose}
      TransitionComponent={TransitionModal}
    >
      {children}
    </Dialog>
  );
};

export default ModalForm;
