import { API_ROUTES } from 'constants/apiRoutes';
import { useBaseCatalog } from 'utils/base-catalog';
import { useFetch, UseServiceProps } from 'utils/react-query';
import { pathToUrl } from 'utils/routes';
import { z } from 'zod';
import { CATALOGS_NAMES } from '../catalogsNames';

export enum providedServicesTypesFields {
  id = 'id',
  fundingId = 'fundingId',
  funding = 'funding',
  displayName = 'displayName',
  internalName = 'internalName',
}

export const GenericDataSchema = z.object({
  id: z.number(),
  attributeName: z.string().nullable(),
  attributeValue: z.string().nullable(),
});

export const ProvidedServicesTypesSchema = z.object({
  [providedServicesTypesFields.id]: z.number(),
  [providedServicesTypesFields.fundingId]: z.number().nullable(),
  [providedServicesTypesFields.funding]: GenericDataSchema.nullable(),
  [providedServicesTypesFields.displayName]: z.string(),
  [providedServicesTypesFields.internalName]: z.string(),
});

export type ProvidedServicesType = z.infer<typeof ProvidedServicesTypesSchema>;

export const useServicesProvided = ({
  routeParams,
  params,
  config,
}: UseServiceProps<ProvidedServicesType>) =>
  useFetch<ProvidedServicesType[]>(
    pathToUrl(API_ROUTES.SERVICES_TYPE, routeParams),
    params,
    config,
  );

export const useServiceTypeCatalog = useBaseCatalog(CATALOGS_NAMES.ServiceType);
