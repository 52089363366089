import { Button, SxProps } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

type LinkButtonProps = {
  children: string;
  to: string;
  variant?: 'secondarybtn' | 'primarybtn' | 'tertiarybtn' | 'tertiarybtnSmall' | 'PACE_Secondary';
  sx?: SxProps;
  style?: React.CSSProperties;
  startIcon?: React.ReactNode;
};
const LinkButton: FC<LinkButtonProps> = (props: LinkButtonProps) => {
  const { children, to, variant = 'secondarybtn', sx, style = {}, startIcon } = props;
  return (
    <Link to={to} style={{ display: 'block', ...style }}>
      <Button type='submit' variant={variant} sx={sx} startIcon={startIcon}>
        {children}
      </Button>
    </Link>
  );
};

export default LinkButton;
