import { axiosConfiguration } from 'axios/config';
import { API_ROUTES } from 'constants/apiRoutes';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { ServerResponse } from 'services/client/attendance/attendanceService';
import { phoneValidations } from 'services/client/demographics/household-details/householdDetailsServices';
import Alert from 'UI/molecules/global/alerts';
import {
  useFetch,
  usePost,
  usePut,
  UseServiceProps,
  UseServicePropsSingle,
} from 'utils/react-query';
import { pathToUrl } from 'utils/routes';
import { z } from 'zod';
import { Center } from '../centers';

const SERVICE_NAME = 'Agency';

export interface AgencyResponse {
  agencyId: string;
  avatarProfilePhotoURL: string | null;
  createdDate: string;
  centers: Center[];
  modifiedDate: string;
  id: number;
  agencyName: string;
  state: string | null;
  city: string | null;
  contactPerson: string;
  contactPhone: string;
  avatarProfilePhoto: string | null;
}

export const getAgencies = async () => {
  const { data: response } = await axiosConfiguration.get('agency');
  return response;
};

export const getAgencyInformation = async (agencyId: number) => {
  const { data: response } = await axiosConfiguration.get<AgencyResponse>(`agency/${agencyId}`);
  return response;
};

interface Params {
  id: number;
  agencyName: string;
  agencyId: string | number;
  state: string;
  city: string;
  contactPerson: string;
  contactPhone: string | number;
  avatarProfilePhoto: string | null;
}

export const createAgency = async (formBody: Params) => {
  const { data: response } = await axiosConfiguration.post('agency', formBody);
  return response.key;
};

export const updateAgency = async (formBody: Params) => {
  const { data: response } = await axiosConfiguration.put('agency', formBody);
  return response.key;
};

interface UploadPhotoParams {
  id: number;
  formBody: FormData;
}
export const uploadAvatarAgency = async (params: UploadPhotoParams) => {
  const { data: response } = await axiosConfiguration.post(
    `agency/${params.id}/avatar`,
    params.formBody,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response;
};

export const deleteAgencyAvatar = async (id: number) => {
  const { data: response } = await axiosConfiguration.delete(`agency/${id}/avatar`);
  return response;
};

interface Props {
  agencyId: number;
}

export const useAgency = ({ agencyId }: Props) => {
  const agencyQuery = useQuery(['get-events', agencyId], () => getAgencyInformation(agencyId), {
    staleTime: 120000,
    onError: () => {
      toast.error(<Alert title='Error' subtitle='Error loading agency information!' />);
    },
  });

  return {
    agencyQuery,
  };
};

export enum agencyFieldName {
  id = 'id',
  agencyName = 'agencyName',
  state = 'state',
  city = 'city',
  contactPerson = 'contactPerson',
  contactPhone = 'contactPhone',
}

export const agencyFields = z.object({
  [agencyFieldName.id]: z.number().nullable().optional().default(0),
  [agencyFieldName.agencyName]: z.string().trim().min(1, { message: 'Required' }),
  [agencyFieldName.state]: z.string().trim().min(1, { message: 'Required' }),
  [agencyFieldName.city]: z.string().trim().min(1, { message: 'Required' }),
  [agencyFieldName.contactPerson]: z.string().trim().min(1, { message: 'Required' }),
  [agencyFieldName.contactPhone]: phoneValidations,
});

export type AgencyFieldTypes = z.infer<typeof agencyFields>;

export const useGetAgency = ({
  routeParams,
  params,
  config,
}: UseServicePropsSingle<AgencyFieldTypes>) =>
  useFetch<AgencyFieldTypes>(pathToUrl(API_ROUTES.AGENCY_BY_ID, routeParams), params, config);

export const useAddAgency = ({ routeParams }: UseServiceProps<AgencyFieldTypes>) =>
  usePost<ServerResponse, AgencyFieldTypes>(
    pathToUrl(API_ROUTES.AGENCY),
    undefined,
    SERVICE_NAME,
    pathToUrl(API_ROUTES.AGENCY_BY_ID, routeParams),
  );

export const useUpdateAgency = ({ routeParams }: UseServiceProps<AgencyFieldTypes>) =>
  usePut<ServerResponse, AgencyFieldTypes>(
    pathToUrl(API_ROUTES.AGENCY),
    undefined,
    SERVICE_NAME,
    pathToUrl(API_ROUTES.AGENCY_BY_ID, routeParams),
  );
