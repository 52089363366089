import { Box, Button, Dialog, Divider, Typography } from '@mui/material';
/* Icons */
import notificationIcon from 'assets/images/svg/notificationdark.svg';
import { getAlertLevelColor } from 'modules/client/alerts';
import React from 'react';
import { useParams } from 'react-router-dom';
import { alertCategory } from 'services/catalogs/alerts/alertsCatalogServices';
import { useHero } from 'services/client/client-hero';
import { blue, purple, shadow } from 'theme/palette';

type Props = {
  open: boolean /* State (open or close) */;
  onClose: () => void;
};

const RecordModal: React.FC<Props> = ({ open, onClose }) => {
  const { id: clientID } = useParams();
  const clientId = Number(clientID);

  const { heroQuery } = useHero({ clientId });
  const heroInfo = heroQuery.data;

  const medical = heroInfo?.alerts?.filter(
    (element) => element.category.toLowerCase() === alertCategory.medical,
  );
  const general = heroInfo?.alerts?.filter(
    (element) => element.category.toLowerCase().replace(/\s+/g, '') === alertCategory.general,
  );
  const safeAndSecurity = heroInfo?.alerts?.filter(
    (element) =>
      element.category.toLowerCase().replace(/\s+/g, '') === alertCategory.safetyandsecurity,
  );
  const mentalHealth = heroInfo?.alerts?.filter(
    (element) => element.category.toLowerCase().replace(/\s+/g, '') === alertCategory.mentalhealth,
  );

  return (
    <Dialog
      open={open}
      aria-labelledby='responsive-dialog-title'
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: '6px',
          boxShadow: shadow[200],
          minWidth: '333px',
        },
      }}
    >
      <Box component='div'>
        <Box
          component='div'
          bgcolor={purple[600]}
          display='flex'
          justifyContent='center'
          alignItems='center'
          p={2}
        >
          <Box component='img' mr={2} src={notificationIcon} alt='alert icon' />
          <Typography component='h1' variant='h6' fontWeight={700} color={blue[100]}>
            Alerts
          </Typography>
        </Box>
        {medical && medical.length > 0 && (
          <Box component='div' px={2} my={1}>
            <Typography component='p' color={blue[100]} variant='body1' fontWeight={600}>
              Medicals:
            </Typography>
            {medical.map((element, index) => (
              <Box
                component='div'
                key={index}
                display='flex'
                alignItems='center'
                justifyContent='flex-start'
              >
                <Typography component='p' color={blue[100]} variant='body1'>
                  {element.alertType}
                </Typography>
                <Typography
                  color={getAlertLevelColor(element.level.toLowerCase())}
                  variant='body2'
                  fontWeight={500}
                  ml={1}
                >
                  ({element.level})
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        {general && general.length > 0 && (
          <Box component='div' px={2} my={1}>
            <Typography component='p' color={blue[100]} variant='body1' fontWeight={600}>
              General:
            </Typography>
            {general.map((element, index) => (
              <Box
                component='div'
                key={index}
                display='flex'
                alignItems='center'
                justifyContent='flex-start'
              >
                <Typography component='p' color={blue[100]} variant='body1'>
                  {element.alertType}
                </Typography>
                <Typography
                  color={getAlertLevelColor(element.level.toLowerCase())}
                  variant='body2'
                  fontWeight={500}
                  ml={1}
                >
                  ({element.level})
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        {mentalHealth && mentalHealth.length > 0 && (
          <Box component='div' px={2}>
            <Typography component='p' color={blue[100]} variant='body1' fontWeight={600}>
              Mental Health:{' '}
            </Typography>
            {mentalHealth.map((element, index) => (
              <Box
                component='div'
                key={index}
                display='flex'
                alignItems='center'
                justifyContent='flex-start'
              >
                <Typography component='p' color={blue[100]} variant='body1'>
                  {element.alertType}
                </Typography>
                {element.level && (
                  <Typography
                    color={getAlertLevelColor(element.level.toLowerCase())}
                    variant='body2'
                    fontWeight={500}
                    ml={1}
                  >
                    ({element.level})
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        )}
        {safeAndSecurity && safeAndSecurity.length > 0 && (
          <Box component='div' px={2} my={1}>
            <Typography component='p' color={blue[100]} variant='body1' fontWeight={600}>
              Safety & Security:{' '}
            </Typography>
            {safeAndSecurity.map((element, index) => (
              <Box
                component='div'
                key={index}
                display='flex'
                alignItems='center'
                justifyContent='flex-start'
              >
                <Typography component='p' color={blue[100]} variant='body1'>
                  {element.alertType}
                </Typography>
                {element.level && (
                  <Typography
                    color={getAlertLevelColor(element.level.toLowerCase())}
                    variant='body2'
                    fontWeight={500}
                    ml={1}
                  >
                    ({element.level})
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        )}
        <Divider />
        <Box component='div' py={1} px={2}>
          <Box component='div' display='flex' justifyContent='flex-end' alignItems='center'>
            <Button onClick={onClose} variant='primarybtn'>
              Accept
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RecordModal;
