import { API_ROUTES } from 'constants/apiRoutes';
import { dateZodType } from 'constants/zodTypes';
import { getDefaults } from 'utils/get-defaults';
import {
  useFetch,
  usePost,
  usePut,
  UseServiceProps,
  UseServicePropsSingle,
} from 'utils/react-query';
import { pathToUrl } from 'utils/routes';
import { z } from 'zod';

export enum incidentDetailsFields {
  id = 'id',
  incidentNumber = 'incidentNumber',
  centerId = 'centerId',
  dateIncidentOccured = 'dateIncidentOccured',
  timeIncidentOccured = 'timeIncidentOccured',
  dateCenterBecameAware = 'dateCenterBecameAware',
  timeCenterBecameAware = 'timeCenterBecameAware',
  incidentCategories = 'incidentCategories',
  incidentSubCategories = 'incidentSubCategories',
  incidentReportDetail = 'incidentReportDetail',
  incidentAffectsId = 'incidentAffectsId',
  clients = 'clients',
  incidentReportFiles = 'incidentReportFiles',
  clientsInvolved = 'clientsInvolved',
  externalAgencyNotifications = 'externalAgencyNotifications',

  employeesInvolved = 'employeesInvolved',
  reportPropertyDamages = 'reportPropertyDamages',
  reportVehicleDamages = 'reportVehicleDamages',

  pageNumberAffects = 'pageNumberAffects',
  pageSizeAffects = 'pageSizeAffects',

  pageNumberEmployees = 'pageNumberEmployees',
  pageSizeEmployees = 'pageSizeEmployees',

  pageNumberAgencies = 'pageNumberAgencies',
  pageSizeAgencies = 'pageSizeAgencies',
  file = 'file',
}

export enum incidentDetailsInfoFields {
  incidentLocation = 'incidentLocation',
  staffToYouthRatio = 'staffToYouthRatio',
  incidentVideoTaped = 'incidentVideoTaped',
  videotapeSecured = 'videotapeSecured',
  incidentDescription = 'incidentDescription',
  immediateActionByAgency = 'immediateActionByAgency',
}

export enum externalAgencyNotificationList {
  id = 'id',
  agencyName = 'agencyName',
  contactName = 'contactName',
  dateNotified = 'dateNotified',
  timeNotified = 'timeNotified',
  notificationTypeId = 'notificationTypeId',
  notificationType = 'notificationType',
  cccIncidentNumber = 'cccIncidentNumber',
}

export enum vehicleDamageFields {
  id = 'id',
  descriptionOfDamage = 'descriptionOfDamage',
  licensePlate = 'licensePlate',
  model = 'model',
  make = 'make',
}

export enum reportFile {
  fileName = 'fileName',
  displayName = 'displayName',
  fileUrl = 'fileUrl',
  containerName = 'containerName',
  size = 'size',
  type = 'type',
}

export enum propertyDamageFields {
  id = 'id',
  hasDamageBeenRepaired = 'hasDamageBeenRepaired',
  dollarAmount = 'dollarAmount',
  descriptionOfDamage = 'descriptionOfDamage',
}

const SERVICE_NAME = 'Incident Report Details';

export const GenericDataSchema = z.object({
  id: z.number(),
  attributeName: z.string().nullable(),
  attributeValue: z.string().nullable(),
});

export const IncidentDetailInfoSchema = z.object({
  [incidentDetailsInfoFields.incidentLocation]: z.string().nonempty(),
  [incidentDetailsInfoFields.staffToYouthRatio]: z.preprocess((a) => Number(a), z.number()),
  [incidentDetailsInfoFields.incidentVideoTaped]: z.boolean(),
  [incidentDetailsInfoFields.videotapeSecured]: z.boolean(),
  [incidentDetailsInfoFields.incidentDescription]: z.string().nonempty(),
  [incidentDetailsInfoFields.immediateActionByAgency]: z.string().nonempty(),
});

export const ExternalAgencyNotificationsSchema = z.object({
  [externalAgencyNotificationList.id]: z.number().optional(),
  [externalAgencyNotificationList.agencyName]: z.string().nonempty(),
  [externalAgencyNotificationList.contactName]: z.string().nonempty(),
  [externalAgencyNotificationList.dateNotified]: dateZodType,
  [externalAgencyNotificationList.timeNotified]: dateZodType,
  [externalAgencyNotificationList.notificationTypeId]: z.number().min(1, { message: 'Required' }),
  [externalAgencyNotificationList.notificationType]: GenericDataSchema.optional(),
  [externalAgencyNotificationList.cccIncidentNumber]: z
    .string()
    .trim()
    .min(1, { message: 'Required' }),
});

export const PropertyDamageSchema = z.object({
  [propertyDamageFields.id]: z.number().optional(),
  [propertyDamageFields.hasDamageBeenRepaired]: z.boolean(),
  [propertyDamageFields.dollarAmount]: z.preprocess((a) => Number(a), z.number()),
  [propertyDamageFields.descriptionOfDamage]: z.string(),
});

export const VehicleDamageSchema = z.object({
  [vehicleDamageFields.id]: z.number().optional(),
  [vehicleDamageFields.descriptionOfDamage]: z.string(),
  [vehicleDamageFields.licensePlate]: z.string(),
  [vehicleDamageFields.model]: z.string(),
  [vehicleDamageFields.make]: z.string(),
});

export const IncidentReportDetailsSchema = z.object({
  [incidentDetailsFields.id]: z.number().optional(),
  [incidentDetailsFields.incidentNumber]: z.string(),
  [incidentDetailsFields.dateIncidentOccured]: dateZodType,
  [incidentDetailsFields.timeIncidentOccured]: dateZodType,
  [incidentDetailsFields.centerId]: z.number(),
  [incidentDetailsFields.dateCenterBecameAware]: dateZodType,
  [incidentDetailsFields.timeCenterBecameAware]: dateZodType,
  [incidentDetailsFields.incidentCategories]: z.array(z.any()).nonempty(),
  [incidentDetailsFields.incidentSubCategories]: z.array(z.any()).default([]),
  [incidentDetailsFields.incidentAffectsId]: z.number().min(1, { message: 'Required' }),
  [incidentDetailsFields.clients]: z.array(z.any()),
  [incidentDetailsFields.clientsInvolved]: z.array(z.any()).default([]),
  [incidentDetailsFields.incidentReportDetail]: IncidentDetailInfoSchema,
  [incidentDetailsFields.externalAgencyNotifications]: z.array(ExternalAgencyNotificationsSchema),

  [incidentDetailsFields.employeesInvolved]: z.array(z.any()),

  [incidentDetailsFields.reportVehicleDamages]: z.array(VehicleDamageSchema).optional(),
  [incidentDetailsFields.reportPropertyDamages]: z.array(PropertyDamageSchema).optional(),

  [incidentDetailsFields.pageSizeAffects]: z.number().optional(),
  [incidentDetailsFields.pageSizeEmployees]: z.number().optional(),
  [incidentDetailsFields.pageSizeAgencies]: z.number().optional(),
  [incidentDetailsFields.file]: z.any(),
  [incidentDetailsFields.incidentReportFiles]: z.array(z.any()).optional(),
});

export const IncidentReportDetailsBooleanDefaults = getDefaults<typeof IncidentReportDetailsSchema>(
  IncidentReportDetailsSchema,
);

export type IncidentReportDetailsType = z.infer<typeof IncidentReportDetailsSchema>;

export type ExternalNotificationsType = z.infer<typeof ExternalAgencyNotificationsSchema>;

export const useAddIncidentReportDetails = ({
  routeParams,
}: UseServiceProps<IncidentReportDetailsType>) =>
  usePost<IncidentReportDetailsType[], IncidentReportDetailsType>(
    pathToUrl(API_ROUTES.INCIDENT_REPORT_DETAIL, routeParams),
    undefined,
    SERVICE_NAME,
  );

export const useIncidentReportById = ({
  routeParams,
  params,
  config,
}: UseServicePropsSingle<IncidentReportDetailsType>) =>
  useFetch<IncidentReportDetailsType>(
    pathToUrl(API_ROUTES.INCIDENT_BY_ID, routeParams),
    params,
    config,
  );

export const useUpdateIncidentReportDetails = ({
  routeParams,
}: UseServiceProps<IncidentReportDetailsType>) =>
  usePut<IncidentReportDetailsType[], IncidentReportDetailsType>(
    pathToUrl(API_ROUTES.INCIDENT_REPORT_DETAIL, routeParams),
    undefined,
    SERVICE_NAME,
    pathToUrl(API_ROUTES.INCIDENT_BY_ID, routeParams),
  );
