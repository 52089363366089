import EastIcon from '@mui/icons-material/East';
import { Box, Button } from '@mui/material';
import { DropDown, ModalForm, ModalHeading } from 'components';
import { Paragraph } from 'components/Text';
import { FontWeight } from 'components/Text/BaseText';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { withFormProvider } from 'utils/withFormProvider/indes';
import { create } from 'zustand';

type CenterPickerStoreType = {
  isOpen: boolean;
  setIsOpen: (boolean) => void;
};
export const useCenterPickerStore = create<CenterPickerStoreType>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set(() => ({ isOpen })),
}));

const USER_CENTER_ID = 'USER_CENTER_ID';
const CenterPickerModal: React.FC = () => {
  const { isOpen, setIsOpen } = useCenterPickerStore();
  const navigate = useNavigate();
  const userCenterId = localStorage.getItem(USER_CENTER_ID);
  const _isDisabled = !userCenterId;

  const onClose = () => {
    setIsOpen(false);
    navigate('/');
  };

  const onOpen = () => setIsOpen(true);

  const setCenter = () => {
    onClose();
    localStorage.setItem(USER_CENTER_ID, '123');
  };

  useEffect(() => {
    if (!userCenterId) onOpen();
  }, []);

  return (
    <ModalForm open={isOpen} onClose={onClose} style={{ width: 550, minWidth: 550 }}>
      <ModalHeading title='Please select a Center' onClose={onClose} tittlePadding={3} />

      <Box mt={-2} mb={3}>
        <Paragraph fontWeight={FontWeight.regular}>
          You are associated with multiple centers. Please select a center from the list to view its
          information.
        </Paragraph>
      </Box>

      <DropDown caption='Center' fieldName='' options={[]} placeholder='Select a Center' />

      <Box display='flex' justifyContent='center' gap={2} my={3}>
        <Button variant='secondarybtn' sx={{ width: 120 }} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant='primarybtn'
          endIcon={<EastIcon />}
          // disabled={isDisabled}
          onClick={setCenter}
        >
          Go to center
        </Button>
      </Box>
    </ModalForm>
  );
};

export default withFormProvider(CenterPickerModal);
