import { createSlice } from '@reduxjs/toolkit';
import useTaskApi from '../../../services/tasksApi';

interface TaskState {
  taskData: {
    data: object[];
    nextToken?: null;
    previousToken?: null;
  };
  loading: boolean;
  error: string;
  details: {
    title: string;
    description: string;
    assigneeName: string;
    dueDate: string;
    referenceName: string;
    status: string;
    taskType: string;
    id: number;
  };
  nextToken: string | undefined;
  previousToken: string | undefined;
}

const initialState = {
  taskData: {
    data: [],
    nextToken: null,
    previousToken: null,
  },
  loading: false,
  error: '',
  details: {
    title: '',
    description: '',
    assigneeName: '',
    dueDate: '',
    referenceName: '',
    status: '',
    taskType: '',
    id: 0,
  },
  nextToken: '',
  previousToken: '',
} as TaskState;

const { getAll } = useTaskApi();

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    getTaskDetails(state, { payload }) {
      state.details = payload;
    },
    getNextToken(state, { payload }) {
      state.nextToken = payload;
    },
    getPreviousToken(state, { payload }) {
      state.previousToken = payload;
    },
  },
  extraReducers: {
    [getAll.pending.type]: (state) => {
      state.loading = true;
    },
    [getAll.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.taskData = payload;
    },
  },
});

export const { getTaskDetails, getNextToken, getPreviousToken } = taskSlice.actions;
export default taskSlice.reducer;
