import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { TransitionModal } from 'modules/client/incident-reporting/dialogs/dialogMessage';
import { dark, purple } from 'theme/palette';

type ModalFormProps = {
  open: boolean;
  onClose: () => void;
  onLeave: () => void;
};
const CancelModal: React.FC<ModalFormProps> = (props: ModalFormProps) => {
  const { open, onClose, onLeave } = props;

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: '10px',
          paddingLeft: '36px',
          paddingRight: '36px',
          paddingTop: '42px',
          minWidth: '730px',
        },
      }}
      fullWidth
      open={open}
      onClose={onClose}
      TransitionComponent={TransitionModal}
    >
      <Box display='flex' justifyContent='space-between' alignItems='center' mb={3}>
        <Box width={24} />
        <Typography fontSize={20} fontWeight={600} color={purple[500]} textAlign='center'>
          Are you sure you want to leave this page?
        </Typography>
        <CloseRoundedIcon onClick={onClose} sx={{ ':hover': { cursor: 'pointer' } }} />
      </Box>
      <Typography
        fontSize={14}
        fontWeight={400}
        color={dark[200]}
        textAlign='center'
        width={580}
        mx='auto'
        mb={5}
      >
        You haven’t completed this form. If you leave now, you will lose all the data you already
        entered. Do you still wish to leave?
      </Typography>

      <Box mb={4} display='flex' flexDirection='row' justifyContent='center'>
        <Button sx={{ width: 166 }} variant='secondarybtn' onClick={onLeave}>
          Yes, leave
        </Button>

        <Button
          sx={{ width: 166 }}
          style={{ marginLeft: 12 }}
          variant='primarybtn'
          onClick={onClose}
        >
          No, continue
        </Button>
      </Box>
    </Dialog>
  );
};

export default CancelModal;
