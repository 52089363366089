import { API_ROUTES } from 'constants/apiRoutes';
import { dateZodType } from 'constants/zodTypes';
import {
  PaginatedResult,
  useFetchInfinite,
  usePost,
  UseServiceProps,
  UseServicePropsSingle,
} from 'utils/react-query';
import { pathToUrl } from 'utils/routes';
import { z } from 'zod';

export enum commentsFields {
  id = 'id',
  helpDeskTicketId = 'helpDeskTicketId',
  text = 'text',
  date = 'date',
  staffMemberInfo = 'staffMemberInfo',
}

export const GenericPersonalDataSchema = z.object({
  id: z.number(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  profilePhotoURL: z.string().nullable(),
});

const SERVICE_NAME = 'Comment';

export const CommentSchema = z.object({
  [commentsFields.id]: z.number(),
  [commentsFields.helpDeskTicketId]: z.number(),
  [commentsFields.text]: z.string().max(500).trim().nonempty(),
  [commentsFields.date]: dateZodType.optional(),
  [commentsFields.staffMemberInfo]: GenericPersonalDataSchema.optional(),
});

export type StaffMemberInfoByComment = z.infer<typeof GenericPersonalDataSchema>;
export type CommentsType = z.infer<typeof CommentSchema>;
export type PaginatedCommentsType = PaginatedResult<CommentsType>;

export const useGetCommentsInfinite = ({
  routeParams,
  params,
  config,
}: UseServicePropsSingle<PaginatedCommentsType>) => {
  const { data, ...res } = useFetchInfinite(
    pathToUrl(API_ROUTES.COMMENTS_BY_TICKET, routeParams),
    params,
    config,
  );

  return {
    data,
    ...res,
  };
};

export const useAddComment = ({ routeParams }: UseServiceProps<CommentsType>) =>
  usePost<CommentsType[], CommentsType>(
    pathToUrl(API_ROUTES.ADD_COMMENT, routeParams),
    undefined,
    SERVICE_NAME,
    pathToUrl(API_ROUTES.COMMENTS_BY_TICKET, routeParams),
  );
