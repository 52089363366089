import React from 'react';
import { Toaster } from 'react-hot-toast';
import { blue, green, light, red } from 'theme/palette';

const ToastNotifications: React.FC = () => {
  return (
    <Toaster
      toastOptions={{
        style: {
          padding: 0,
          margin: 0,
          borderRadius: '6px',
        },
        success: {
          icon: null,
          style: {
            margin: 8,
            borderLeft: `8px solid ${green[100]}`,
            backgroundColor: light[100],
          },
        },
        loading: {
          icon: null,
          style: {
            margin: 8,
            borderLeft: `8px solid ${blue[100]}`,
            backgroundColor: light[100],
          },
        },
        error: {
          icon: null,
          style: {
            margin: 8,
            borderLeft: `8px solid ${red[100]}`,
            backgroundColor: light[100],
          },
        },
      }}
      position='bottom-right'
      reverseOrder={false}
    />
  );
};

export default ToastNotifications;
