import CheckIcon from '@mui/icons-material/Check';
import { Box, SxProps, Typography } from '@mui/material';
import { Step as ST, Stepper as BaseStepper } from '@progress/kendo-react-layout';
import { FC } from 'react';
import { dark, grey, purple, white } from 'theme/palette';

const activeOuterCircle: SxProps = {
  borderRadius: '40px',
  height: '40px',
  width: '40px',
  border: `2px solid ${purple[200]}`,
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
};

const activeInnerCircle: SxProps = {
  borderRadius: '36px',
  height: '36px',
  width: '36px',
  backgroundColor: purple[200],
  border: `2px solid ${white[100]}`,
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
};

const outerCircle: SxProps = {
  ...activeOuterCircle,
  border: `2px solid ${grey[300]}`,
};

const innerCircle: SxProps = {
  ...activeInnerCircle,
  backgroundColor: white[100],
};

const setCustomStepper = (val) => {
  const CustomStep = (props: any) => {
    const { index, label } = props;

    const isActive = index <= val;
    const color = isActive ? white[100] : dark[200];
    const outer = isActive ? activeOuterCircle : outerCircle;
    const inner = isActive ? activeInnerCircle : innerCircle;

    const isArrayOfLabels = Array.isArray(label);

    const icon = isActive ? (
      <CheckIcon sx={{ color: white[100] }} />
    ) : (
      <Typography color={color} fontWeight={500}>
        {index + 1}
      </Typography>
    );

    const labelComp = (isArrayOfLabels ? label : [label]).map((l, idx) => (
      <Typography
        fontSize={14}
        color={dark[200]}
        fontWeight={isActive ? 600 : 400}
        width={140}
        sx={{ wordBreak: 'break-word' }}
        key={idx}
      >
        {l}
      </Typography>
    ));

    return (
      <ST {...props}>
        <Box sx={outer} className='custom-step'>
          <Box sx={inner}>{icon}</Box>
        </Box>

        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          width='100%'
          height={40}
          mt={1}
        >
          {labelComp}
        </Box>
      </ST>
    );
  };

  return CustomStep;
};

type Props = {
  items: { label: string }[];
  onPageChange: (number) => void;
  value: number;
  direction?: 'horizontal' | 'vertical';
};

const Stepper: FC<Props> = (props: Props) => {
  const { items, onPageChange, value, direction } = props;
  const handleChange = (e) => {
    onPageChange(e.value);
  };

  return (
    <BaseStepper
      className='custom-stepper'
      value={value}
      onChange={handleChange}
      items={items}
      item={setCustomStepper(value)}
      orientation={direction}
    />
  );
};

export default Stepper;
