import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Divider, Typography } from '@mui/material';
import { H5 } from 'components/Text';
import { FontWeight } from 'components/Text/BaseText';
import { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { BLACK, BLUE, GREY } from 'theme/palette/new';
import { AcceptedFileTypes, createAcceptObject, getAcceptedFileTypesString } from 'utils/MIMETypes';
import { fromMbToBytes } from 'utils/toKb';
import { FileCard } from './FileCard';
import { useFileStore } from './fileStore';

export type DragNDropProps = {
  isDisabled?: boolean;
  accept?: AcceptedFileTypes[];
  max?: number;
  maxSize?: number;
};

export const DragDrop: FC<DragNDropProps> = (props) => {
  const {
    isDisabled,
    accept = [AcceptedFileTypes.PNG, AcceptedFileTypes.JPEG],
    max,
    maxSize = 20,
  } = props;
  const { files = [], setFiles, uploadingFiles } = useFileStore();
  const onChange = (acceptedFiles) => {
    const uploadedNames = uploadingFiles.map((file) => file.name);
    const currentName = files.map((file) => file.name);

    const filteredFiles = acceptedFiles.filter((file) => {
      if ([...uploadedNames, ...currentName].includes(file.name)) return false;
      return true;
    });

    setFiles([...filteredFiles, ...files]);
  };
  const onDrop = (acceptedFiles) => {
    onChange([...acceptedFiles, ...files]);
  };
  const handleDelete = (idx) => () => {
    const newFiles = files.filter((_, index) => idx !== index);
    setFiles(newFiles);
  };
  const handleRename = (idx, filename, format) => {
    const newFiles = files.map((file, index) => {
      if (index === idx) return new File([file], `${filename}.${format}`, { type: file.type });
      return file;
    });

    setFiles(newFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: createAcceptObject(accept),
    disabled: isDisabled,
    maxFiles: max,
    multiple: true,
    maxSize: fromMbToBytes(maxSize),
  });

  const cursor = isDisabled ? 'auto' : 'pointer';

  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' flexDirection='column' sx={{ cursor }} {...getRootProps()}>
        <input {...getInputProps()} />
        <Box
          sx={{
            borderColor: GREY[400],
            borderWidth: 1,
            borderStyle: 'dashed',
            borderRadius: '10px',
            bgcolor: GREY[100],
            height: 104,
          }}
          p={1}
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='center'
          mt={1}
        >
          <FileUploadOutlinedIcon sx={{ color: BLUE[600], mr: 0.5, ml: 2 }} />
          <H5 fontWeight={FontWeight.medium} color={GREY[600]}>
            Drag and drop files here, or&nbsp;
          </H5>
          <H5 fontWeight={FontWeight.medium} underlined color={BLUE[600]}>
            click to browse
          </H5>
        </Box>
      </Box>

      <Box display='flex' flexDirection='row' alignItems='center' mt={1}>
        <Typography color={GREY[400]}>Up to {maxSize}MB</Typography>
        <FiberManualRecordIcon sx={{ fontSize: 12, mx: 0.5, color: GREY[400] }} />
        <Typography color={GREY[400]}>
          Accepted file types: {getAcceptedFileTypesString(accept)}
        </Typography>
      </Box>

      <Divider sx={{ my: 3 }} />

      {files.length > 0 && (
        <Box display='flex' flexDirection='row' mb={2}>
          <H5 fontWeight={FontWeight.regular} color={BLACK[100]}>
            {files.length}&nbsp;
          </H5>
          <H5 fontWeight={FontWeight.regular} color={GREY[600]}>
            File(s) attached
          </H5>
        </Box>
      )}
      {files.map((file, idx) => (
        <FileCard
          key={`${file.name}`}
          file={file}
          idx={idx}
          handleDelete={handleDelete(idx)}
          handleRename={handleRename}
        />
      ))}
    </Box>
  );
};
