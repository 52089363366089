import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import { Box, IconButton } from '@mui/material';
import { Calendar } from '@progress/kendo-react-dateinputs';
import { CustomNavigationItem } from 'components/fields/DatePicker';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { dark } from 'theme/palette';
import { dpCalendarContainerStyle, dpStyle } from 'theme/styles/inputs';
import { dateStrgToDate, formatDate } from 'utils/format-date';

function parseNum(str): string {
  return str.replace(/[^\d.-]/g, '');
}

function between(x, min, max) {
  return x >= min && x <= max;
}

function removeLeadingZeros(inputStr) {
  return inputStr.replace(/^0+(?=[1-9])/, '');
}

const maxSizeGen = (maxSize) => (val) => {
  if (val === 'YYYY') return 40;
  if (Number(val) && Number(val) < 100) return 16;

  const len = val.length;
  const width = len * 12;

  return width > maxSize ? maxSize : width;
};

const returnDefaultDateSection = (section) => {
  const isMonthOrDay = section.length === 2;
  const isNan = Number.isNaN(section);

  if (isNan && isMonthOrDay) return '01';
  else if (isNan && !isMonthOrDay) return '1995';

  return section;
};

const monthMaxWidth = maxSizeGen(42);
const dayMaxWidth = maxSizeGen(26);
const yearMaxWidth = maxSizeGen(30);

const dateSectionControllerGen = (gt, between1, between2, setter, goTo, ph, focus) => {
  return (ev) => {
    const value = parseNum(ev.target.value);
    if (value.length === 0) {
      setter(ph);
      focus(ev);
      return;
    }
    const len1 = value.length === 1;
    const len2 = value.length === 2;
    const is00 = value === '00';
    const greaterThan = value > gt;

    if (len1 && between(value, between1, between2)) {
      setter(`0${value}`);

      goTo();
      return;
    } else if (len2) {
      if (is00) {
        setter('01');
        goTo();
        return;
      }
      goTo();
    }

    if (greaterThan) {
      setter(`${gt}`);
      return;
    }

    setter(value);
  };
};

type Props = {
  onChange: (Date) => void;
  value: Date;
  disabled?: boolean;
  focused?: boolean;
  hasErrors?: boolean;
};

const CustomDatePicker: React.FC<Props> = (props: Props) => {
  const { onChange, value: v, disabled, focused, hasErrors } = props;

  const value =
    v instanceof Date && v.toString() === 'Invalid Date'
      ? new Date()
      : (v as any) === ''
      ? undefined
      : v;

  const [month, setMonth] = useState('MM');
  const [day, setDay] = useState('DD');
  const [year, setYear] = useState('YYYY');

  const [isActive, setIsActive] = useState(false);
  const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  const compRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const dayRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const goToMonth = () => {
    if (monthRef.current !== null) {
      monthRef.current.focus();
      monthRef.current.select();
    }
  };
  const goToDay = () => {
    if (dayRef.current !== null) {
      dayRef.current.focus();
      dayRef.current.select();
    }
  };
  const goToYear = () => {
    if (yearRef.current !== null) {
      yearRef.current.focus();
      yearRef.current.select();
    }
  };

  const onClick = (event) => {
    event.stopPropagation();
    setTimeout(() => {
      event.target.select();
    }, 0);
  };

  const handleMonth = dateSectionControllerGen(12, 2, 9, setMonth, goToDay, 'MM', onClick);
  const handleDay = dateSectionControllerGen(31, 4, 9, setDay, goToYear, 'DD', onClick);

  const handleYear = (ev) => {
    const value = parseNum(ev.target.value);
    if (value.length === 0) {
      setYear('YYYY');
      onClick(ev);
      return;
    }
    const trimmed = removeLeadingZeros(value);

    if (trimmed.length === 1) {
      setYear(`000${trimmed}`);
      return;
    } else if (trimmed.length === 2) {
      setYear(`00${trimmed}`);
      return;
    } else if (trimmed.length === 3) {
      setYear(`0${trimmed}`);
      return;
    } else if (trimmed.length === 4) {
      setYear(`${trimmed}`);
      return;
    } else if (Number(trimmed) > 9999) {
      setYear(`${trimmed.slice(0, 4)}`);
      return;
    }

    setYear(value);
  };

  // TODO:
  // const onFocus = () => {
  //   const canSet = canChange(month, day, year);
  //   if (canSet) {
  //     setMonth('month')
  //     setDay('day')
  //     setYear('year')
  //   }
  // }

  // const onBlur = () => {
  //   const canSet = canChange(month, day, year);
  //   if (canSet) {
  //     setMonth('MM')
  //     setDay('DD')
  //     setYear('YYYY')
  //   }
  // }

  const onCalendarChange = (event) => {
    if (!event) return;
    const { value } = event.target;

    const [year, month, day] = formatDate(value).split('-');
    setYear(year);
    setMonth(month);
    setDay(day);
    onChange(value);

    removeEventListener();
    setIsActive(false);
  };

  const handleEvent = () => {
    setIsActive(false);
    removeEventListener();
  };

  const addEventListener = () => {
    setTimeout(() => {
      document.addEventListener('click', handleEvent);
    }, 10);
  };

  const removeEventListener = () => {
    document.removeEventListener('click', handleEvent);
  };

  const toggle = () => {
    setTimeout(() => {
      setIsActive(!isActive);
    }, 50);

    if (!isActive) addEventListener();
    else removeEventListener();

    if (compRef) {
      const rect = compRef.current?.getBoundingClientRect();

      const x = rect?.x as number;
      const y = window.scrollY + (rect?.bottom as number);

      setPosition({
        x,
        y,
      });
    }
  };

  useEffect(() => {
    const m = returnDefaultDateSection(month);
    const d = returnDefaultDateSection(day);
    const y = returnDefaultDateSection(year);

    if (Number(y) > 1000 && moment(`${m}/${d}/${y}`).isValid() && !!month && !!day) {
      if (process.env.NODE_ENV === 'development') {
        console.log('date:', dateStrgToDate(`${m}/${d}/${y}`));
      }

      onChange(dateStrgToDate(`${m}/${d}/${y}`));
    }

    return () => {
      removeEventListener();
    };
  }, [month, day, year]);

  useEffect(() => {
    // if (!value || isMounted) return;
    if (!value) return;

    const [year, month, day] = formatDate(value).split('-');
    setMonth(month);
    setDay(day);
    setYear(year);
    // setIsMounted(true);
  }, [value]);

  const holdEvent = (event) => {
    event.stopPropagation();
  };

  return (
    <Box display='flex' flexDirection='column' position='relative'>
      {isActive &&
        createPortal(
          <Box
            top={position.y}
            left={position.x}
            sx={dpCalendarContainerStyle}
            onClick={holdEvent}
            height={isActive ? '328px' : '0'}
          >
            <Calendar
              value={value}
              onChange={onCalendarChange}
              navigationItem={CustomNavigationItem}
            />
          </Box>,
          document.body,
        )}

      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        sx={dpStyle(disabled)}
        className={`${hasErrors && 'field-error'} ${focused && 'focused'}`}
        onClick={goToMonth}
        ref={compRef}
      >
        <input
          ref={monthRef}
          type='text'
          placeholder='MM'
          maxLength={2}
          value={month}
          onChange={handleMonth}
          onClick={onClick}
          // onFocus={onFocus}
          // onBlur={onBlur}
          style={{ width: `${monthMaxWidth(month)}px` }}
          disabled={disabled}
        />
        /
        <input
          ref={dayRef}
          type='text'
          placeholder='DD'
          maxLength={2}
          value={day}
          onChange={handleDay}
          onClick={onClick}
          // onFocus={onFocus}
          // onBlur={onBlur}
          style={{ width: `${dayMaxWidth(day)}px` }}
          disabled={disabled}
        />
        /
        <input
          ref={yearRef}
          type='text'
          placeholder='YYYY'
          maxLength={5}
          value={year}
          onChange={handleYear}
          onClick={onClick}
          // onFocus={onFocus}
          // onBlur={onBlur}
          style={{ width: `${yearMaxWidth(year)}px` }}
          disabled={disabled}
        />
        <IconButton
          sx={{ fontSize: 16, color: dark[200], marginLeft: 'auto' }}
          onClick={toggle}
          disabled={disabled}
        >
          <InsertInvitationOutlinedIcon fontSize='inherit' />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CustomDatePicker;
