import { compile } from 'path-to-regexp';
import { hasUndefinedValue } from 'utils/object';

export const pathToUrl = (path: string, params: object = {}) => {
  const hasUndefinedValues = hasUndefinedValue(params);

  if (hasUndefinedValues) return '';

  return compile(path)(params);
};

export const removeLastSlash = (path: string) => {
  if (path.endsWith('/')) return path.slice(0, -1);
  return path;
};
