type returnValue = { isValidBoolean: boolean; value?: boolean };

export const stringToBoolean = (stringValue): returnValue => {
  switch (stringValue?.toLowerCase()?.trim()) {
    case 'true':
      return { isValidBoolean: true, value: true };

    case 'false':
      return { isValidBoolean: true, value: false };

    default:
      return { isValidBoolean: false };
  }
};
