import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Aria = { id: string };

const initialState: Aria[] = [];

const AriasSlice = createSlice({
  name: 'arias',
  initialState,
  reducers: {
    addAria(state, action: PayloadAction<string>) {
      const newState = [...state, { id: action.payload }];
      return newState;
    },
    removeAria(state, action: PayloadAction<string>) {
      const newState = state.filter(({ id }) => id != action.payload);
      return newState;
    },
  },
});

export const { addAria, removeAria } = AriasSlice.actions;
export default AriasSlice.reducer;
