/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
interface TokenState {
  token: string;
}

const initialState = {
  token: '',
} as TokenState;

const tokensSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    getAccessToken(state, { payload }) {
      localStorage.setItem('access-token', payload);
      state.token = payload;
    },
  },
});

export const { getAccessToken } = tokensSlice.actions;
export default tokensSlice.reducer;
