type Obj = {
  [key: string]: any;
};

export const removeFalsyValues = (obj: Obj): Obj => {
  const newObj = { ...obj };

  for (const key in newObj) {
    const value = Boolean(newObj[key]);
    const isFalsy = !value || newObj[key] === '-1';

    if (isFalsy) {
      delete newObj[key];
    }
  }

  return newObj;
};
