import { Box, Button, Link, Typography } from '@mui/material';
import { ErrorSpan } from 'components';
import React from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { dark, purple } from 'theme/palette';

type InputProps = {
  caption: string;
  fieldName: string;
  placeholder?: string;
  isDisabled?: boolean;
  accept?: Accept;
  max?: number;
  small?: boolean;
  justifyContent?: 'space-between' | 'space-around' | 'flex-start' | 'flex-end' | 'center';
};

const Drop: React.FC<InputProps> = (props) => {
  const {
    caption,
    fieldName,
    isDisabled,
    accept,
    max,
    justifyContent = 'space-between',
    small,
  } = props;

  const { formState, setValue, getValues, trigger } = useFormContext();

  const onDrop = (acceptedFiles) => {
    setValue(fieldName, acceptedFiles);

    if (errors[fieldName]) trigger();
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    accept: accept || { 'image/*': [] },
    disabled: isDisabled,
    maxFiles: max || 1,
  });

  const { errors } = formState;

  const files = acceptedFiles.map((file) => <span key={file.name}>{file.name}</span>);

  const fieldValue = getValues(fieldName);
  const isString = typeof fieldValue === 'string';
  const copyLabel = files[0]
    ? `${files[0].key?.toString().slice(0, 22)}...`
    : 'Drag file here to upload';

  const handleOnClick = (event) => event.stopPropagation();
  const cursor = isDisabled ? 'auto' : 'pointer';

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent={justifyContent}
      sx={{ cursor }}
      {...getRootProps()}
    >
      <Box display='flex'>
        <Typography
          component='p'
          display='block'
          textAlign='left'
          color={dark[200]}
          variant='body2'
        >
          {`${caption} `}
          <ErrorSpan errors={errors} name={fieldName} />
        </Typography>
      </Box>
      <input {...getInputProps()} />
      <Box
        sx={{
          borderColor: purple[100],
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: '6px',
        }}
        p={small ? '5px' : 1}
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        mt={1}
      >
        <Button variant='outlined' sx={{ height: small ? '26px' : '36px' }} disabled={isDisabled}>
          Select File...
        </Button>
        {isString ? (
          <Link href={fieldValue} target='_blank' rel='noopener noreferrer' onClick={handleOnClick}>
            Download File
          </Link>
        ) : (
          <Typography component='span' color={dark[200]}>
            {copyLabel}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Drop;
