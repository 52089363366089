import { Box, Button, Divider, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import usePagination from '@mui/material/usePagination';
import { SortDescriptor } from '@progress/kendo-data-query';
import {
  Grid as DataGrid,
  GridCellProps,
  GridColumn as Column,
  GridColumnMenuProps,
  GridColumnMenuSort,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import first from 'assets/images/svg/pagination/first.svg';
import last from 'assets/images/svg/pagination/last.svg';
import next from 'assets/images/svg/pagination/next.svg';
import previous from 'assets/images/svg/pagination/prev.svg';
import { DropDown } from 'components';
import StatusChip from 'components/StatusChip';
import { DefaultAvatar } from 'components/TrainingHeader';
import React, { FC, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { purple } from 'theme/palette';
import { BE_COLORS } from 'theme/palette/new';
import { generateColorsAndText } from 'utils/color-getter';
import { optionType } from 'utils/data-to-options';
import { formatDateStringNum, formatTime12Local, timeStrgToDate } from 'utils/format-date';

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
});

const Btn = styled('button')((props) => ({
  opacity: props.disabled ? 0.5 : 1,
  color: purple[500],
  fontWeight: 'bold',
  cursor: props.disabled ? 'default' : 'pointer',
  width: 38,
  height: 38,
  borderRadius: 38,
  margin: 0,
  padding: 0,
  border: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const paginationIcons = {
  first,
  last,
  next,
  previous,
};

const sizePerPage: optionType[] = [
  { id: 5, label: 5 },
  { id: 10, label: 10 },
  { id: 15, label: 15 },
  { id: 20, label: 20 },
  { id: 30, label: 30 },
];

const PaginationButton = (props: any) => (
  <Btn {...props} sx={{ bgcolor: 'transparent' }}>
    <img
      src={paginationIcons[props.name]}
      style={{ cursor: props.disabled ? 'default' : 'pointer' }}
    />
  </Btn>
);

export function NavigationPager(props: any) {
  const { totalCount, pnName, psName, isLocal } = props;
  const methods = useFormContext();
  const perPage = methods.watch(psName) || sizePerPage[1].id;
  const count = useMemo(() => Math.ceil(totalCount / perPage), [totalCount, perPage]);
  const page = methods.watch(pnName) || 1;

  const { items } = usePagination({
    count,
    showFirstButton: true,
    showLastButton: true,
    page,
    onChange(_, newPage) {
      methods.setValue(pnName, newPage);
    },
  });

  const base = (page - 1) * perPage;
  const from = base + 1;
  const to = base + perPage > totalCount ? totalCount : base + perPage;

  useEffect(() => {
    if (perPage > 5 && totalCount === 0) {
      methods.setValue(pnName, 1);
    }
  }, [perPage]);

  useEffect(() => {
    methods.setValue(psName, sizePerPage[isLocal ? 0 : 1].id);
  }, []);

  return (
    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' px={8}>
      <Divider sx={{ width: '100%', mt: 4, mb: 1 }} />
      <Box display='flex' alignItems='center' justifyContent='center' width='100%'>
        <Box mr={7}>
          <List>
            {items.map(({ page, type, selected, ...item }, index) => {
              let children: any = null;

              if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                children = null;
              } else if (Object.keys(paginationIcons).includes(type)) {
                children = <PaginationButton name={type} {...item} />;
              } else if (type === 'page') {
                children = (
                  <Btn
                    sx={{
                      bgcolor: selected ? purple[900] : 'transparent',
                      fontWeight: selected ? 'bold' : 300,
                    }}
                    {...item}
                    type='button'
                  >
                    {page}
                  </Btn>
                );
              } else {
                children = (
                  <button type='button' {...item}>
                    {type}
                  </button>
                );
              }

              return <li key={index}>{children}</li>;
            })}
          </List>
        </Box>
        <DropDown caption='' noCaption options={sizePerPage} fieldName={psName} />
        <Typography ml={1}>Items per page</Typography>
        <Typography ml={14}>
          Items {from} to {to} of {totalCount}
        </Typography>
      </Box>
    </Box>
  );
}

export type Column = {
  field?: string;
  width?: string;
  title: string;
  yesNoField?: boolean;
  isURL?: boolean;
  yesColor?: string;
  noColor?: string;
  yesText?: string;
  noText?: string;
  url?: string;
  urlGenerator?: (id: string | number, data?) => string;
  urlIdField?: string;
  urlText?: (any) => string;
  urlColor?: string;
  isBtn?: boolean;
  isBtnUnderlined?: boolean;
  disabled?: (unknown) => boolean;
  onClick?: (unknown) => void;
  btnTitle?: string;
  isDate?: boolean;
  isTime?: boolean;
  isImage?: boolean;
  btnSuccess?: boolean;
  sortable?: boolean;
  isStatus?: boolean;
  isPriority?: boolean;
  customComponent?: (dataItem) => JSX.Element;
};

type Props = {
  columns?: Column[];
  data: any[];
  totalCount?: number;
  pnName: string;
  psName: string;
  paginated?: boolean;
  sortField?: string;
  sortDirection?: string;
  isSortable?: boolean;
  isLocal?: boolean;
};

export const ColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <div>
      <GridColumnMenuSort {...props} />
    </div>
  );
};

const renderColumn = (column: Column) => {
  const {
    yesNoField,
    isURL,
    yesColor,
    noColor,
    yesText,
    noText,
    field,
    url,
    title,
    urlGenerator,
    urlIdField,
    urlText,
    urlColor = 'primary',
    isBtn,
    isBtnUnderlined,
    disabled,
    onClick,
    btnTitle,
    isDate,
    isTime,
    isImage,
    width,
    btnSuccess,
    sortable = false,
    isStatus,
    isPriority,
    customComponent,
  } = column;

  if (isBtn)
    return (
      <Column
        {...column}
        sortable={sortable}
        columnMenu={sortable ? ColumnMenu : null}
        key={column.field}
        cell={({ dataItem }: GridCellProps) => {
          const handleClick = onClick ? () => onClick(dataItem) : () => null;
          const isDisabled = disabled ? disabled(dataItem) : false;
          const rowTitle = field ? dataItem[field] : false;

          return (
            <td>
              <Button
                onClick={handleClick}
                size='small'
                disabled={isDisabled}
                color={btnSuccess ? 'success' : undefined}
                sx={{ textDecoration: `${isBtnUnderlined ? 'underline' : 'none'}` }}
              >
                {btnTitle || rowTitle || 'View More'}
              </Button>
            </td>
          );
        }}
      />
    );

  if (yesNoField)
    return (
      <Column
        {...column}
        sortable={sortable}
        columnMenu={sortable ? ColumnMenu : null}
        key={column.field}
        cell={({ dataItem }: GridCellProps) => {
          const { text, color } = generateColorsAndText(
            dataItem[field as string],
            yesColor,
            noColor,
            yesText,
            noText,
          );
          return (
            <td>
              <Typography component='p' color={color}>
                {text}
              </Typography>
            </td>
          );
        }}
      />
    );

  if (isURL)
    return (
      <Column
        {...column}
        sortable={sortable}
        columnMenu={sortable ? ColumnMenu : null}
        key={column.field}
        cell={({ dataItem }: GridCellProps) => {
          const generatedLink = (urlGenerator &&
            urlGenerator(dataItem[(urlIdField as string) || 'id'], dataItem)) as string;
          const isValidGeneratedLink =
            generatedLink && !['undefined', 'null'].some((str) => generatedLink.includes(str));
          const isDisabled = disabled ? disabled(dataItem) : false;

          const linkURL =
            (url as string) ||
            (isValidGeneratedLink && generatedLink) ||
            dataItem[field as string] ||
            dataItem[urlIdField as string];

          if (isDisabled || !linkURL) return <td></td>;
          return (
            <td>
              <Link color={urlColor} href={linkURL}>
                <Typography
                  component='p'
                  color={urlColor}
                  variant='body2'
                  style={{ textDecoration: 'underline' }}
                >
                  {(urlText && urlText(dataItem)) || dataItem[field as string] || 'View More'}
                </Typography>
              </Link>
            </td>
          );
        }}
        title={title || 'Actions'}
      />
    );

  if (isDate || isTime) {
    const formatValue = (value: string) => {
      if (!value) return '';
      if (isDate) return formatDateStringNum(value);
      if (isTime) return formatTime12Local(timeStrgToDate(value));
      return value;
    };

    return (
      <Column
        {...column}
        sortable={sortable}
        columnMenu={sortable ? ColumnMenu : null}
        key={column.field}
        cell={({ dataItem }: GridCellProps) => {
          const value = dataItem[field as string];
          const valueParsed = formatValue(value);
          return (
            <td>
              <Typography component='p'>{valueParsed}</Typography>
            </td>
          );
        }}
      />
    );
  }

  if (isImage) {
    return (
      <Column
        {...column}
        sortable={sortable}
        columnMenu={sortable ? ColumnMenu : null}
        width={width}
        key={column.field}
        cell={({ dataItem }: GridCellProps) => {
          const { photoUrl, firstName, lastName } = dataItem[field as string];
          return (
            <td style={{ display: 'flex', justifyContent: 'center' }}>
              <DefaultAvatar
                url={photoUrl}
                firstName={firstName}
                lastName={lastName}
                hasImage={photoUrl}
                customSize='30px'
                customFontSize='14px'
              />
            </td>
          );
        }}
      />
    );
  }

  if (isStatus) {
    return (
      <Column
        {...column}
        sortable={sortable}
        columnMenu={sortable ? ColumnMenu : null}
        width={width}
        key={column.field}
        cell={({ dataItem }: GridCellProps) => {
          const { attributeName, attributeValue } = dataItem[field as string];
          const { bgColor, mainColor, iconColor } = JSON.parse(attributeValue);
          return (
            <td>
              <StatusChip
                text={attributeName}
                color={BE_COLORS[mainColor] || bgColor}
                dotColor={BE_COLORS[iconColor] || mainColor}
                isStatus
              />
            </td>
          );
        }}
      />
    );
  }

  if (isPriority) {
    return (
      <Column
        {...column}
        sortable={sortable}
        columnMenu={sortable ? ColumnMenu : null}
        width={width}
        key={column.field}
        cell={({ dataItem }: GridCellProps) => {
          const { attributeName, attributeValue } = dataItem[field as string];
          return (
            <td>
              <StatusChip text={attributeName} color={attributeValue} />
            </td>
          );
        }}
      />
    );
  }

  if (customComponent) {
    return (
      <Column
        {...column}
        sortable={sortable}
        columnMenu={sortable ? ColumnMenu : null}
        width={width}
        key={column.field}
        cell={({ dataItem }: GridCellProps) => {
          return <td>{customComponent(dataItem)}</td>;
        }}
      />
    );
  }

  return (
    <Column
      {...column}
      sortable={sortable}
      columnMenu={sortable ? ColumnMenu : null}
      key={column.field}
    />
  );
};

const DataTable: FC<Props> = (props: Props) => {
  const {
    columns = [],
    data,
    totalCount,
    pnName,
    psName,
    paginated = true,
    sortField,
    sortDirection,
    isSortable,
    isLocal = false,
  } = props;
  const methods = useFormContext();
  const initialSort: Array<SortDescriptor> = [];

  const [sort, setSort] = React.useState(initialSort);

  const handleSortChange = (e: GridSortChangeEvent) => {
    methods.setValue(sortField as string, e.sort[0]?.field);
    methods.setValue(
      sortDirection as string,
      e.sort[0]?.dir === 'asc' ? '0' : e.sort[0]?.dir === 'desc' && '1',
    );
    setSort(e.sort);
  };

  return (
    <React.Fragment>
      <DataGrid
        data={data}
        sort={sort}
        onSortChange={handleSortChange}
        sortable={isSortable}
        className='dataTable'
      >
        {columns.map((column) => renderColumn(column))}
      </DataGrid>
      {paginated && (
        <NavigationPager
          pnName={pnName}
          psName={psName}
          totalCount={totalCount || 0}
          isLocal={isLocal}
        />
      )}
    </React.Fragment>
  );
};

export default DataTable;
