import { Button } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { axiosConfiguration } from 'axios/config';
import { AcademicYearItemProps } from 'modules/global-academics/centers/academic-setup/interfaces';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { ServerResponse } from 'services/client/attendance/attendanceService';
import Alert from 'UI/molecules/global/alerts';

interface Props {
  centerId: number;
}

// Requests
const getAcademicYears = async (centerId): Promise<AcademicYearItemProps[]> => {
  const { data } = await axiosConfiguration.get<AcademicYearItemProps[]>(
    `center/${centerId}/academic-years`,
  );
  return data;
};

const saveAcademicYear = async ({ academicYearData }): Promise<void> => {
  const method = academicYearData.id ? 'put' : 'post';
  await axiosConfiguration[method]<AcademicYearItemProps>('academic-years', academicYearData);
};

// Hooks
export const useAcademicYears = ({ centerId }: Props) => {
  const academicYearsQuery = useQuery(['academicYears'], () => getAcademicYears(centerId), {
    onSettled(data, error) {
      if (error) {
        toast.error(<Alert title='Error' subtitle='Error loading academic years!' />);
      }
    },
  });
  const academicYearsMutation = useMutation(
    (variables: { academicYearData: AcademicYearItemProps }) =>
      saveAcademicYear({ academicYearData: variables.academicYearData }),
    {
      onSuccess: () => {
        academicYearsQuery.refetch();
        toast.success(<Alert title='Success' subtitle='Academic years was saved successfully!' />);
      },
      onError: (error: unknown) => {
        const errors = error as Error | AxiosError;
        if (axios.isAxiosError(errors)) {
          const errorMessage = errors.response?.data as ServerResponse;
          toast(
            (t) => (
              <span>
                <Alert title='Error !' subtitle={errorMessage.message} />
                <Button color='primary' sx={{ my: 1 }} onClick={() => toast.dismiss(t.id)}>
                  Dismiss
                </Button>
              </span>
            ),
            {
              duration: 10000,
            },
          );
        } else {
          toast.error(<Alert title='Error' subtitle={errors.message} />);
        }
      },
    },
  );

  return {
    academicYearsQuery,
    academicYearsMutation,
  };
};
