import {
  Apartment,
  ContactPage,
  HouseOutlined,
  InventoryOutlined,
  PersonOutline,
  PieChartOutline,
  SupportAgent,
} from '@mui/icons-material';
import * as _ from 'lodash';
import moment from 'moment';
import { white } from 'theme/palette';

export const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const formatDateToAAAAMMDD = (date) => moment(date).format('YYYY-MM-DD');

export const sleepy = (ms) =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

export const getSvgIcon = (iconName: string, color = white[100]) => {
  const icons = {
    'my-dashboard': HouseOutlined,
    'client-management': ContactPage,
    reporting: PersonOutline,
    'quality-management': PieChartOutline,
    'center-management': Apartment,
    agency_management: InventoryOutlined,
    apartment: Apartment,
    support_agent: SupportAgent,
  };
  const NavIcon = icons[iconName];
  return NavIcon ? <NavIcon style={{ color }} /> : null;
};

export const filterByEveryObjVal = <T extends Record<string, unknown>>({
  list,
  queryText,
}: {
  list: T[];
  queryText: string;
}): T[] => {
  return _.filter(list, (obj) =>
    JSON.stringify(obj).toLowerCase().includes(queryText.toLowerCase()),
  );
};

export const filterByProp = <T extends Record<string, unknown>>({
  list,
  queryText,
  prop,
}: {
  list: T[];
  queryText: string;
  prop: string;
}): T[] => {
  return _.filter(list, (obj) => {
    const objValue = String(obj[prop] || '');
    return objValue.toLowerCase().includes(queryText.toLowerCase());
  });
};
