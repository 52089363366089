import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { axiosConfig } from './axiosConfig';

export interface NotificationApiRequest {
  page: number;
  pageSize: number;
  notificationId?: number;
}

export interface Notification {
  id: number;
  guid: string;
  title: string;
  description: string;
  recipientUserId: number;
  active: boolean;
  created: Date;
  updated: Date;
}

export default function useNotificationApi() {
  const getAll = createAsyncThunk('get-notifications', async (request: NotificationApiRequest) => {
    try {
      let endpointUrl = '/api/v1/Notifications/?';

      if (request.page > 0) {
        endpointUrl = endpointUrl.concat(`&Page=${request.page}&PageSize=${request.pageSize}`);
      } else {
        endpointUrl = endpointUrl.concat('&Page=1&PageSize=10');
      }

      const { data } = await axiosConfig.get<Notification[]>(endpointUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access-token') || null}`,
        },
      });
      return data as Notification[];
    } catch (error) {
      return error;
    }
  });

  const readNotification = createAsyncThunk(
    'read-notification',
    async (id: number, { dispatch }) => {
      const endpointUrl = `/api/v1/Notifications/read/${id}`;
      try {
        const { data } = await axiosConfig.post(endpointUrl, id, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access-token') || null}`,
          },
        });

        toast.success('Notification Read');
        dispatch(getAll({ page: 1, pageSize: 5 }));
        return data;
      } catch (error) {
        toast.error('error');
      }
    },
  );

  const dismissAll = createAsyncThunk(
    'dismiss-all',
    async (request: NotificationApiRequest, { dispatch }) => {
      const endpointUrl = '/api/v1/Notifications/dismissall/';
      try {
        const { data } = await axiosConfig.post(endpointUrl, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access-token') || null}`,
          },
        });

        toast.success('Notifications Dismissed');
        dispatch(getAll(request));
        return data;
      } catch (error) {
        toast.error('error dismissed notifications');
      }
    },
  );

  return {
    getAll,
    readNotification,
    dismissAll,
  };
}
