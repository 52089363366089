import { ErrorMessage } from '@hookform/error-message';
import { Box, InputBase, InputBaseProps, Typography } from '@mui/material';
import { ErrorSpan, WipChip } from 'components';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { dark } from 'theme/palette';
import { errorStyle, inputStyle } from 'theme/styles/inputs';

type InputProps = {
  caption: string;
  fieldName: string;
  placeholder?: string;
  isDisabled?: boolean;
  type?: InputBaseProps['type'];
  isWip?: boolean;
  justifyContent?: 'space-between' | 'space-around' | 'flex-start' | 'flex-end' | 'center';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
  customArrayErrors?: boolean;
  decimalScale?: number;
  maxLength?: number;
};

const DecimalInput: React.FC<InputProps> = (props) => {
  const {
    caption,
    fieldName,
    isWip,
    customArrayErrors,
    justifyContent = 'space-between',
    placeholder,
    decimalScale,
    maxLength,
    isDisabled,
  } = props;

  const { control, formState } = useFormContext();
  const { errors } = formState;

  const decimalInputFieldProps = {
    placeholder,
    fullWidth: true,
    sx: inputStyle,
  };

  return (
    <Box display='flex' flexDirection='column' justifyContent={justifyContent}>
      <Box display='flex'>
        <Typography
          component='p'
          display='block'
          textAlign='left'
          color={dark[200]}
          variant='body2'
        >
          {`${caption} `}
          {customArrayErrors ? (
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => <small style={errorStyle}>{message}</small>}
            />
          ) : (
            <ErrorSpan errors={errors} name={fieldName} />
          )}
        </Typography>
        {isWip && <WipChip />}
      </Box>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={''}
        render={({ field: { onChange, value } }) => (
          <NumericFormat
            {...decimalInputFieldProps}
            customInput={InputBase}
            onChange={onChange}
            value={value}
            decimalScale={decimalScale || 3}
            inputProps={{ maxLength }}
            disabled={isDisabled}
          />
        )}
      />
    </Box>
  );
};

export default DecimalInput;
