import { createSlice } from '@reduxjs/toolkit';
import { clientTypes, createClient, getClient } from '../../../../services/client/get';

interface ClientData {
  data: Array<clientTypes>;
  loading: boolean;
}

const initialState = {
  data: [],
  loading: false,
} as ClientData;

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {},
  extraReducers: {
    [getClient.pending.type]: (state) => {
      state.loading = true;
    },
    [getClient.fulfilled.type]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    [getClient.rejected.type]: () => {
      console.log('Error');
    },
    [createClient.pending.type]: (state) => {
      state.loading = true;
    },
    [createClient.fulfilled.type]: (state, { payload }) => {
      console.log(payload);
    },
  },
});

export default clientSlice.reducer;
