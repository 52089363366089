import { FC } from 'react';
import { BaseText, FontWeight, TextElement, TextProps } from './BaseText';

export const H2: FC<TextProps> = (props: TextProps) => {
  const { children } = props;
  return (
    <BaseText element={TextElement.h2} fontWeight={FontWeight.medium}>
      {children}
    </BaseText>
  );
};
