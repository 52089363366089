/* eslint-disable camelcase */
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  alertFields,
  AlertSchema,
  ClientAlertsResponse,
  dietFields,
  useAddAlert,
  useSaveAlertFile,
  useUpdateAlert,
} from 'services/client/alerts/alertsServices';
import { dateStrgToDate, formatDate, formatDateTime } from 'utils/format-date';
import { z } from 'zod';
import AlertForm from '.';

interface Props {
  open: boolean;
  onClose: () => void;
  heroRefetch: () => void;
  alertRefetch: () => void;
  formData: ClientAlertsResponse | null;
}

export type AlertSchemaType = z.infer<typeof AlertSchema>;

const ContainerAlertForm: React.FC<Props> = ({
  open,
  onClose,
  heroRefetch,
  alertRefetch,
  formData,
}) => {
  const methodsForm = useForm<AlertSchemaType>({
    resolver: zodResolver(AlertSchema),
  });

  const [fileUploadingProgress, setFileUploadingProgress] = useState<ProgressEvent>();
  const [selectedFile, setSelectedFile] = useState<FormData>();
  const [defaultFile, setDefaultFile] = useState<string | null>();

  const { saveClientImpairmentAlertFile } = useSaveAlertFile();

  const { id: clientId } = useParams();

  const { mutateAsync: addAlert, isLoading: addingAlert } = useAddAlert({
    routeParams: {
      clientId,
    },
  });

  const { mutateAsync: updateAlert, isLoading: updatingAlert } = useUpdateAlert({
    routeParams: {
      clientId,
    },
  });

  const uploadFile = async (fileToUpload) => {
    await saveClientImpairmentAlertFile.mutateAsync({
      alertFields: fileToUpload,
      onProgress: (progress) => {
        setFileUploadingProgress(progress);
      },
    });
  };

  const { reset, watch, resetField } = methodsForm;

  const handleOnClose = () => {
    onClose();
    reset();
    setDefaultFile(null);
  };

  const handleFormBody: SubmitHandler<AlertSchemaType> = async (data) => {
    const method = formData ? updateAlert : addAlert;

    await method(
      {
        ...data,
        clientId: Number(clientId),
        [alertFields.clientAlertId]: data.clientAlertId || 0,
        [alertFields.alertId]: data.alertId,
        [alertFields.startDate]: formatDateTime(data.startDate),
        [alertFields.endDate]: data.endDate ? formatDateTime(data.endDate) : null,
        [alertFields.notes]:
          data?.dietAlert && data?.dietAlert?.restrictionsNotes
            ? data?.dietAlert?.restrictionsNotes
            : data?.notes,
        dietAlert: data?.dietAlert
          ? {
              ...data?.dietAlert,
              [dietFields.dateAlertClosed]: data?.dietAlert?.dateAlertClosed
                ? formatDate(data?.dietAlert?.dateAlertClosed)
                : null,
              [dietFields.dateOfVerification]: data?.dietAlert?.dateOfVerification
                ? formatDate(data?.dietAlert?.dateOfVerification)
                : null,
            }
          : null,
      },
      {
        onSuccess: () => heroRefetch(),
      },
    );

    if (selectedFile) {
      await uploadFile(selectedFile);
      alertRefetch();
    } else {
      alertRefetch();
      handleOnClose();
    }

    handleOnClose();
  };

  const onSubmit = methodsForm.handleSubmit((data) => handleFormBody(data));

  const loading = addingAlert || updatingAlert;

  useEffect(() => {
    if (formData && formData !== null) {
      reset({
        ...formData,
        [alertFields.startDate]: dateStrgToDate(formData.startDate),
        [alertFields.endDate]: formData.endDate ? dateStrgToDate(formData.endDate) : null,
        dietAlert: formData.dietAlert
          ? {
              ...formData?.dietAlert,
              [dietFields.notes]: formData.notes,
              [dietFields.restrictionsNotes]: formData?.dietAlert.restrictionsNotes,
              [dietFields.dateAlertClosed]: dateStrgToDate(formData?.dietAlert?.dateAlertClosed),
              [dietFields.dateOfVerification]: dateStrgToDate(
                formData?.dietAlert?.dateOfVerification,
              ),
            }
          : undefined,
      });
      setDefaultFile(formData?.impairmentAlert?.doctorsNotePath);
    } else reset({ [alertFields.verifiedBy]: '', [alertFields.alertId]: null });
  }, [formData]);

  const alertId = watch(alertFields.alertId);

  useEffect(() => {
    resetField('dietAlert');
    resetField('impairmentAlert');
  }, [alertId]);

  useEffect(() => {
    if (!watch('impairmentAlert.doctorsNoteReviewed')) {
      setSelectedFile(undefined);
      setDefaultFile(null);
    }
  }, [watch('impairmentAlert.doctorsNoteReviewed')]);

  const titleForm = formData ? 'Update Alert' : 'Add New Alert';

  return (
    <FormProvider {...methodsForm}>
      <AlertForm
        setSelectedFile={setSelectedFile}
        defaultFile={defaultFile}
        setFileUploadingProgress={setFileUploadingProgress}
        fileUploadingProgress={fileUploadingProgress}
        uploadFile={uploadFile}
        title={titleForm}
        isLoading={loading}
        modalState={open}
        onSubmit={onSubmit}
        onClose={handleOnClose}
      />
    </FormProvider>
  );
};

export default ContainerAlertForm;
