import { UploadingFile } from 'components/fields/MultiFileUpload/fileStore';
import { reportFile } from 'services/global-academics/centers/incident-reporting/incidentDetails';

export const parseFilesObj = (files = []) => {
  return files.map((file) => ({
    [reportFile.fileName]: file['servername'],
    [reportFile.displayName]: file['name'],
    [reportFile.containerName]: file['container'],
    [reportFile.fileUrl]: file['uri'],
    [reportFile.size]: file['size'],
    [reportFile.type]: file['type'],
  }));
};

export const formatIncidentFiles = (files: any[] = []): UploadingFile[] => {
  return files.map((file) => ({
    blob: undefined,
    container: file['containerName'] || '',
    name: file['displayName'] || '',
    servername: file['fileName'] || '',
    progress: 100,
    size: file['size'] || 0,
    type: file['type'] || '',
    uploadUrl: '',
    uri: file['fileUrl'] || '',
  }));
};
