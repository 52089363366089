import { ErrorMessage } from '@hookform/error-message';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Autocomplete, Box, MenuItem, Select, TextField, Typography } from '@mui/material';
import ErrorSpan from 'components/ErrorSpan';
import { Paragraph } from 'components/Text';
import { FontWeight } from 'components/Text/BaseText';
import WipChip from 'components/WipChip';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { dark, grey } from 'theme/palette';
import { selectStyle } from 'theme/styles/components/select';
import { dropDownWithSearchStyles, errorStyle } from 'theme/styles/inputs';
import { optionType } from 'utils/data-to-options';
import { stringToBoolean } from 'utils/string-to-boolean';

type DropDownProps = {
  caption: string;
  fieldName: string;
  options: optionType[];
  numberType?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  customArrayErrors?: boolean;
  justifyContent?: 'space-between' | 'space-around' | 'flex-start' | 'flex-end' | 'center';
  alt?: string;
  isRemovable?: boolean;
  isWip?: boolean;
  noCaption?: boolean;
  searchEnable?: boolean;
  isDisplayOnly?: boolean;
};

const DropDown: React.FC<DropDownProps> = (props) => {
  const {
    caption,
    fieldName,
    options = [],
    placeholder,
    isDisabled,
    isReadOnly,
    numberType,
    customArrayErrors,
    justifyContent = 'space-between',
    alt,
    isRemovable,
    isWip,
    noCaption,
    searchEnable = false,
    isDisplayOnly,
  } = props;

  const { control, formState, resetField } = useFormContext();
  const { errors } = formState;
  const hasErrors = errors[fieldName];
  const handleChange =
    (cb) =>
    ({ target }) => {
      const num = Number(target.value);
      const isNumber = !Number.isNaN(num);
      const isString = typeof target.value === 'string';
      const { value: boolValue, isValidBoolean } = stringToBoolean(target.value);

      if (isString && !isValidBoolean && !isNumber) {
        cb(target.value);
        return;
      }

      const val = isValidBoolean ? boolValue : num;
      cb(val);
    };

  const handleRemoveValue = () => {
    resetField(fieldName, {
      defaultValue: numberType ? null : '',
    });
  };

  return (
    <Box display='flex' flexDirection='column' justifyContent={justifyContent}>
      {!noCaption && (
        <Typography
          component='p'
          display='block'
          textAlign='left'
          color={dark[200]}
          variant='body2'
          mb={1}
        >
          {`${caption} `}
          {customArrayErrors ? (
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => <small style={errorStyle}>{message}</small>}
            />
          ) : (
            <ErrorSpan errors={errors} name={fieldName} />
          )}

          {!!alt && (
            <Typography
              component='span'
              color={dark[200]}
              fontWeight={300}
              fontSize={12}
              sx={{ opacity: 0.5 }}
              ml={0.5}
            >
              {`(${alt})`}
            </Typography>
          )}
          {isWip && <WipChip />}
        </Typography>
      )}
      <Controller
        name={fieldName}
        control={control}
        defaultValue={numberType ? 0 : ''}
        render={({ field: { onChange, value, ref } }) => {
          if (isDisplayOnly) {
            const selectedOption = options.find((option) => option.id === value);
            return (
              <Paragraph fontWeight={FontWeight.regular}>
                {selectedOption ? selectedOption.label : '-'}
              </Paragraph>
            );
          }
          if (!searchEnable)
            return (
              <Select
                endAdornment={
                  isRemovable && value ? (
                    <CloseRoundedIcon
                      onClick={handleRemoveValue}
                      sx={{ mr: 2, cursor: 'pointer', color: grey[400] }}
                      fontSize='small'
                    />
                  ) : null
                }
                onChange={handleChange(onChange)}
                disabled={isDisabled}
                readOnly={isReadOnly}
                value={numberType ? value || 0 : value}
                displayEmpty
                sx={selectStyle}
                className={hasErrors && 'field-error'}
                MenuProps={selectStyle.MenuProps}
                ref={ref}
              >
                <MenuItem disabled value={numberType ? 0 : ''}>
                  <Typography color='darker' variant='caption'>
                    {placeholder}
                  </Typography>
                </MenuItem>
                {options.map(({ id, label }) => (
                  <MenuItem disabled={isDisabled} value={`${id}`} key={`${id}`}>
                    <Typography component='p' color='darker' variant='caption'>
                      {`${label}`}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            );
          return (
            <Autocomplete
              ref={ref}
              value={options.find((option) => option.id === value) || null}
              options={options}
              disabled={isDisabled}
              onChange={(event, newValue) => {
                onChange(newValue ? newValue.id : numberType ? 0 : '');
              }}
              sx={dropDownWithSearchStyles}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => `${option.label}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{ ...params.InputProps }}
                  placeholder={placeholder}
                />
              )}
              renderOption={(props, option) => (
                <MenuItem {...props} value={`${option.id}`} key={`${option.id}`}>
                  <Typography component='p' color='darker' variant='caption'>
                    {`${option.label}`}
                  </Typography>
                </MenuItem>
              )}
            />
          );
        }}
      />
    </Box>
  );
};

export default DropDown;
