import { yupResolver } from '@hookform/resolvers/yup';
import { zodResolver } from '@hookform/resolvers/zod';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Box, Button, SxProps, Typography } from '@mui/material';
import { Stepper } from 'components';
import { FC, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { dark } from 'theme/palette';
import { CardShadowed } from 'UI/molecules/global/cards';
import { ZodSchema } from 'zod';
import { create } from 'zustand';
import CancelModal from './cancel-modal';
type WizardState = {
  page: number;
  data: { [key: string]: any };
  isReady: boolean;
  next: () => void;
  prev: () => void;
  goTo: (page: number) => void;
  save: (data: { [key: string]: any }, key?: string) => void;
  setReady: () => void;
  resetData: () => void;
};

export const useWizardStore = create<WizardState>((set) => ({
  page: 0,
  data: {},
  isReady: false,
  next: () => set((state) => ({ page: state.page + 1 })),
  prev: () => set((state) => ({ page: state.page - 1 })),
  goTo: (page) => set(() => ({ page })),
  save: (data, key) => {
    if (key) set((state) => ({ data: { ...state.data, [key]: data } }));
    else set((state) => ({ data: { ...state.data, ...data } }));
  },
  setReady: () => set(() => ({ isReady: true })),
  resetData: () => set(() => ({ data: {}, page: 0, isReady: false })),
}));

const getLocalKey = (formName: string) => `WIZARD_FORM#${formName}`;

const getResolver = (name) => {
  const resolvers = {
    zod: zodResolver,
    yup: yupResolver,
  };

  return resolvers[name] || resolvers['zod'];
};

type StepProps = {
  children: string | JSX.Element | JSX.Element[];
  label: string | string[];
  resolver?: ZodSchema<any>;
  defaults?: any;
  resolverName?: string;
  formKey?: string;
  isLast?: boolean;
};

type BaseProps = {
  children: JSX.Element | JSX.Element[];
  persist?: boolean;
  onSubmit: (data: any, isCompleted: boolean, page?: number) => void;
  goBackUrl: string;
  onChange?: (page: number) => void;
  footerLabel?: string;
  noSFL?: boolean;
  direction?: 'horizontal' | 'vertical';
  noValidation?: boolean;
};

type Props = BaseProps &
  (BaseProps['persist'] extends true ? { formName: string } : { formName?: string });

const Manager: FC<Props> = (props: Props) => {
  const {
    children,
    persist,
    formName,
    goBackUrl,
    footerLabel,
    onSubmit,
    noSFL,
    direction = 'horizontal',
    noValidation,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { page, prev, next, data: wizardData, isReady, resetData } = useWizardStore();
  const navigate = useNavigate();

  const safePage = page < 0 ? 0 : page;
  const isArray = Array.isArray(children);
  const pages = isArray ? children : [children];
  const items = isArray
    ? children.map(({ props: { label } }) => ({ label }))
    : [{ label: children.props.label }];
  const pageNum = safePage + 1;
  const pagesNum = pages.length;
  const isFirstPage = pageNum === 1;
  const isLastPage = pageNum === pagesNum;

  const onCancel = () => {
    if (!isFirstPage) {
      prev();
    } else onOpen();
  };

  const handleSFL = () => {
    onSubmit({}, false, safePage + 1);
  };

  const onClose = () => setIsModalOpen(false);
  const onOpen = () => setIsModalOpen(true);
  const goBack = () => navigate(goBackUrl);
  const onLeave = () => {
    setIsModalOpen(false);
    goBack();

    if (persist && formName) {
      const key = getLocalKey(formName);

      localStorage.removeItem(key);
    }
  };

  useEffect(() => {
    if (isReady) {
      onSubmit(wizardData, true);
      resetData();
    }
  }, [isReady]);

  useEffect(() => () => resetData(), []);

  const formStepName = Array.isArray(items[safePage].label)
    ? items[safePage].label.join('')
    : items[safePage].label;

  const isHorizontal = direction === 'horizontal';

  const flexContainerProps: SxProps = isHorizontal
    ? { display: 'flex', flexDirection: 'column' }
    : {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
      };

  const stepperContainerProps: SxProps = isHorizontal
    ? { py: 4, px: 10 }
    : {
        pl: 10,
        flex: 1,
        height: '300px',
      };

  const isDisabled = wizardData?.isCompleted;
  const formContainerProps: SxProps = isHorizontal ? {} : { flex: 5 };
  const submitBtnProps: any = noValidation
    ? {
        onClick: () => {
          if (isLastPage) goBack();
          else next();
        },
      }
    : {
        type: 'submit',
        form: formStepName,
        disabled: isLastPage && isDisabled,
      };

  const sFLProps = {
    onClick: handleSFL,
    form: formStepName,
    disabled: isDisabled,
  };

  return (
    <Box>
      <CancelModal open={isModalOpen} onClose={onClose} onLeave={onLeave} />

      <Box sx={flexContainerProps}>
        <Box sx={stepperContainerProps}>
          <Stepper items={items} onPageChange={() => null} value={safePage} direction={direction} />
        </Box>

        <Box sx={formContainerProps}>
          <CardShadowed sx={{ mb: 3, p: direction === 'vertical' ? 0 : null }}>
            {pages.map((el, idx) => (
              <Box key={idx}>{idx === safePage && el}</Box>
            ))}
          </CardShadowed>

          <Typography fontSize={14} fontWeight={400} color={dark[200]} mb={4}>
            Page {pageNum} of {pagesNum} {footerLabel}
          </Typography>

          <Box mb={8} display='flex' flexDirection='row'>
            <Button
              sx={{ width: 166 }}
              variant='secondarybtn'
              onClick={onCancel}
              startIcon={<ArrowBackIosNewRoundedIcon />}
            >
              {isFirstPage ? 'Cancel' : 'Previous Step'}
            </Button>
            <Box sx={{ ml: 'auto' }}>
              {!noSFL && !isLastPage && (
                <Button sx={{ width: 166 }} variant='secondarybtn' {...sFLProps} type='submit'>
                  Save for Later
                </Button>
              )}
              <Button
                sx={{ width: isLastPage ? 230 : 166 }}
                style={{ marginLeft: isLastPage ? 'auto' : 12 }}
                variant='primarybtn'
                endIcon={<ArrowForwardIosRoundedIcon />}
                {...submitBtnProps}
              >
                {isLastPage ? 'Save and Complete' : 'Next Step'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const Step = (props: StepProps) => {
  const { resolver, resolverName, label, formKey = '', isLast } = props;

  const prevDataRef = useRef<any>();
  const methods = useForm({
    resolver: getResolver(resolverName)(resolver as ZodSchema<any>),
  });

  const { next, save, setReady, data: wizardData } = useWizardStore();

  const handleSubmit = (data) => {
    save(data, formKey);

    if (isLast) setReady();
    if (!isLast) next();
  };

  const [mounted, setUseMounted] = useState(false);

  useEffect(() => {
    const prevData = prevDataRef.current;
    const currentData = methods.watch();

    if (prevData && JSON.stringify(prevData) !== JSON.stringify(currentData)) {
      save(currentData, formKey);
    }

    prevDataRef.current = currentData;
  }, [JSON.stringify(methods.watch())]);

  useEffect(() => {
    if (!mounted && JSON.stringify(prevDataRef.current) !== wizardData[formKey]) {
      methods.reset(wizardData[formKey]);
      setUseMounted(true);
    }
  }, [formKey, JSON.stringify(wizardData)]);

  const onSubmit = methods.handleSubmit((data) => handleSubmit(data));

  const formStepName = Array.isArray(label) ? label.join('') : label;

  return (
    <FormProvider {...methods}>
      <Box component='form' id={formStepName} onSubmit={onSubmit}>
        {props.children}
      </Box>
    </FormProvider>
  );
};

export default {
  Manager,
  Step,
};
