import { BLUE, GREY, RED } from 'theme/palette/new';

export const textAreaStyle = {
  mt: 1,
  bgcolor: GREY[100],
  px: 2,
  border: `1px solid ${GREY[400]}`,
  borderRadius: '10px',
  fontSize: '14px',
  pt: 1,
  '&:hover': {
    border: `1px solid ${GREY[600]}`,
    '& svg': {
      color: GREY[600],
    },
  },
  '&.Mui-focused': {
    border: `1px solid ${BLUE[600]}`,
    '& svg': {
      color: GREY[600],
    },
  },
  '&.Mui-disabled': {
    border: `1px solid ${GREY[200]}`,
    '& svg': {
      color: GREY[200],
    },
  },
  '&.field-error': {
    border: `1px solid ${RED[600]}`,

    '& > textarea': {
      color: RED[600],
    },
    '& svg': {
      color: GREY[600],
    },
  },

  '& svg': {
    color: GREY[400],
    marginRight: 0.5,
  },
};
