import { useState } from 'react';

export const useOpen = (initState = false) => {
  const [isOpen, setIsOpen] = useState(initState);
  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }
  return {
    isOpen,
    toggleOpen,
    open,
    close,
  };
};
