import { createSlice } from '@reduxjs/toolkit';
import useNotificationApi from '../../../services/notificationApi';

const { getAll, dismissAll } = useNotificationApi();

interface SidebarState {
  notificationData: object[];
  loading: boolean;
  error: string;
  loadingDismissed: boolean;
}

const initialState = {
  notificationData: [],
  loading: false,
  error: '',
  loadingDismissed: false,
} as SidebarState;

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: {
    [getAll.pending.type]: (state) => {
      state.loading = true;
    },
    [getAll.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.notificationData = payload;
    },
    [dismissAll.pending.type]: (state) => {
      state.loadingDismissed = true;
    },
    [dismissAll.fulfilled.type]: (state) => {
      state.loadingDismissed = false;
    },
  },
});

export default notificationSlice.reducer;
