import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import TrainingHeader from 'components/TrainingHeader';
import { TicketStatus } from 'enums/helpDeskTicketForm';
import { useManagersByCenter } from 'helpers/useManagers';
import useSessionUser from 'hooks/useSessionUser';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useAttendanceType,
  useCenterSupportType,
  useProgramType,
  useTicektPriority,
  useTicketStatus,
} from 'services/catalogs/help-desk-tickets';
import {
  providedServicesTypesFields,
  useServicesProvided,
} from 'services/catalogs/service-type/serviceTypeCatalogService';
import {
  useSearchAllStaffMembers,
  useSearchAllStaffMembersByRole,
  useStaffMemberHeader,
} from 'services/global-academics/staff-members';
import {
  TicketFormBooleanDefaults,
  ticketFormFields,
  TicketFormSchema,
  TicketFormType,
  useAddTicket,
  useGetTicket,
} from 'services/help-desk/form';
import { dataToOptions } from 'utils/data-to-options';
import { formatIncidentFiles, parseFilesObj } from 'utils/incidentReport/files';
import TicketDetail from '.';

const TicketFormContainer: React.FC = () => {
  const { ticketId } = useParams();
  const navigate = useNavigate();
  const methods = useForm<TicketFormType>({
    resolver: zodResolver(TicketFormSchema),
    defaultValues: {
      ...TicketFormBooleanDefaults,
    },
  });

  const centerSupportType = useCenterSupportType();
  const programType = useProgramType();
  const ticketPriority = useTicektPriority();
  const attendanceType = useAttendanceType();
  const ticketStatus = useTicketStatus();

  const centerSupportTypeOptions = dataToOptions(centerSupportType || [], 'id', 'name');
  const programTypeOptions = dataToOptions(programType || [], 'id', 'name');
  const ticketPriorityOptions = dataToOptions(ticketPriority || [], 'id', 'name');
  const attendanceTypeOptions = dataToOptions(attendanceType || [], 'id', 'name');
  const ticketStatusOptions = dataToOptions(ticketStatus || [], 'id', 'name');
  const catalogs = {
    centerSupportTypeOptions,
    programTypeOptions,
    ticketPriorityOptions,
    attendanceTypeOptions,
    ticketStatusOptions,
  };

  const { data: servicesProvided } = useServicesProvided({});

  const servicesProvidedOptions = dataToOptions(
    servicesProvided || [],
    providedServicesTypesFields.id,
    providedServicesTypesFields.displayName,
  );

  const { id } = useSessionUser();
  const { data }: any = useStaffMemberHeader({
    config: { enabled: !!id },
    routeParams: { id },
  });
  const centerId = data?.centerId;
  const { associateDirectorOptions } = useManagersByCenter(centerId);

  const { data: staffMembersData } = useSearchAllStaffMembers({
    params: { query: centerId, fields: 'CenterId' },
    config: { enabled: !!centerId },
  });
  const { key: staffMembers } = staffMembersData;
  const staffInfoParsedToOptions = staffMembers?.map((element) => ({
    ...element.document,
    id: Number(element.document.id),
    label: `${element.document.firstName} ${element.document.lastName}`,
  }));

  const { data: staffMembersByAdminRole = [] } = useSearchAllStaffMembersByRole({
    params: { role: 'admin' },
  });
  const staffMembersByAdminRoleTOptions = staffMembersByAdminRole?.map((element) => ({
    id: Number(element.id),
    label: `${element.firstName} ${element.lastName}`,
  }));

  const { data: ticketInfoById } = useGetTicket({
    routeParams: { id: ticketId },
    config: { enabled: !!ticketId },
  });

  const { mutateAsync: addNewTicket } = useAddTicket({});
  const goBack = '/help-desk';

  const onSubmit = (data) => {
    const formBody = {
      ...data,
      [ticketFormFields.supportTypeId]: data.supportTypeId === 0 ? null : data.supportTypeId,
      [ticketFormFields.programTypeId]: data.programTypeId === 0 ? null : data.programTypeId,
      [ticketFormFields.providedServiceTypeId]:
        data.providedServiceTypeId === 0 ? null : data.providedServiceTypeId,
      [ticketFormFields.attendanceTypeId]:
        data.attendanceTypeId === 0 ? null : data.attendanceTypeId,
      [ticketFormFields.assignedAssociateDirectorId]:
        data.assignedAssociateDirectorId === 0 ? null : data.assignedAssociateDirectorId,
      [ticketFormFields.additionalNotificationId]:
        data.additionalNotificationId === 0 ? null : data.additionalNotificationId,
      [ticketFormFields.associatedUserInfo]: null,
      [ticketFormFields.managerInvolvedInfo]: null,
      [ticketFormFields.associatedUser]: null,
      [ticketFormFields.managerInvolved]: null,
      [ticketFormFields.helpDeskOwnerId]: null,
      [ticketFormFields.helpDeskRecordTypeId]: null,
      [ticketFormFields.supportingDocumentationFiles]: parseFilesObj(
        data?.supportingDocumentationFiles,
      ),
    };
    addNewTicket(formBody, {
      onSuccess: () => {
        navigate(goBack);
      },
    });
  };

  const handleSubmit = methods.handleSubmit(onSubmit);
  const newStatusId = ticketStatusOptions.find((element) => element.label === TicketStatus.new)?.id;

  useEffect(() => {
    methods.setValue(ticketFormFields.statusId, newStatusId as number);
    if (ticketInfoById) {
      methods.reset({
        ...ticketInfoById,
        [ticketFormFields.dateNeededBy]: new Date(ticketInfoById.dateNeededBy),
        [ticketFormFields.associatedUser]: ticketInfoById.associatedUserInfo
          ? `${ticketInfoById.associatedUserInfo?.firstName} ${ticketInfoById.associatedUserInfo?.lastName}`
          : '-',
        [ticketFormFields.managerInvolved]: ticketInfoById.managerInvolvedInfo
          ? `${ticketInfoById.managerInvolvedInfo?.firstName} ${ticketInfoById.managerInvolvedInfo?.lastName}`
          : '-',
        [ticketFormFields.supportingDocumentationFiles]: formatIncidentFiles(
          ticketInfoById['supportingDocumentationFilePath'],
        ),
      });
    }
  }, [ticketInfoById, newStatusId]);

  return (
    <Box component={'main'} p={2}>
      <TrainingHeader />
      <FormProvider {...methods}>
        <TicketDetail
          handleSubmit={handleSubmit}
          catalogs={catalogs}
          associateDirector={associateDirectorOptions}
          staffMembers={staffInfoParsedToOptions}
          serviceList={servicesProvidedOptions}
          staffMembersByAdminRole={staffMembersByAdminRoleTOptions}
        />
      </FormProvider>
    </Box>
  );
};

export default TicketFormContainer;
