import { FC } from 'react';
import { BaseText, FontWeight, TextElement, TextProps, WithFontWeight } from './BaseText';

export const H3: FC<TextProps & WithFontWeight> = (props: TextProps & WithFontWeight) => {
  const { children, fontWeight } = props;
  return (
    <BaseText element={TextElement.h3} fontWeight={fontWeight || FontWeight.regular}>
      {children}
    </BaseText>
  );
};
