import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button } from '@mui/material';
import ModalForm from 'components/ModalForm';
import ModalHeading from 'components/ModalHeading';
import { H5 } from 'components/Text';
import { FontWeight } from 'components/Text/BaseText';
import { FC } from 'react';
import { DragDrop, DragNDropProps } from './DragDrop';
import { useFileStore } from './fileStore';

type MultifileUploadModalProps = {
  onClose: () => void;
  onSend: () => void;
  isOpen: boolean;
} & DragNDropProps;
export const MultifileUploadModal: FC<MultifileUploadModalProps> = (props) => {
  const { onClose, onSend, isOpen, ...dragNDropProps } = props;
  const { isEditing, files } = useFileStore();

  const hasFiles = files.length > 0;

  return (
    <ModalForm open={isOpen} onClose={onClose} disableBackClick={isEditing}>
      <Box>
        <ModalHeading onClose={onClose} title='Upload File' isDisabled={isEditing} />

        <DragDrop {...dragNDropProps} />

        <Box display='flex' justifyContent='center' pb={3} pt={2}>
          <Button
            variant='PACE_Secondary'
            sx={{ width: 120, mr: 2 }}
            onClick={onClose}
            disabled={isEditing}
          >
            <H5 fontWeight={FontWeight.regular}>Close</H5>
          </Button>

          <Button
            variant='primarybtn'
            sx={{ width: 120 }}
            onClick={onSend}
            disabled={isEditing || !hasFiles}
          >
            <FileUploadOutlinedIcon sx={{ mr: 1 }} />

            <H5 fontWeight={FontWeight.regular}>Upload</H5>
          </Button>
        </Box>
      </Box>
    </ModalForm>
  );
};
