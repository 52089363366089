import { Box, Typography } from '@mui/material';
import {
  Calendar,
  CalendarNavigationItem,
  CalendarNavigationItemProps,
} from '@progress/kendo-react-dateinputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import CustomDatePicker from 'components/CustomDatePicker';
import ErrorSpan from 'components/ErrorSpan';
import { Paragraph } from 'components/Text';
import { FontWeight } from 'components/Text/BaseText';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { dark } from 'theme/palette';
import { formatDateStringNum } from 'utils/format-date';

type DatePickerProps = {
  caption: string;
  fieldName: string;
  placeholder?: string;
  isDisabled?: boolean;
  justifyContent?: 'space-between' | 'space-around' | 'flex-start' | 'flex-end' | 'center';
  defaultValue?: Date;
  isDisplayOnly?: boolean;
};

export const CustomNavigationItem = (props: CalendarNavigationItemProps) => {
  const { value, text } = props;

  const isValueAYear = Number.isSafeInteger(Number(value));
  const formattedValue = `${value.toLocaleString('en-us', { month: 'short' })}`;

  const label = isValueAYear ? text : formattedValue;

  return <CalendarNavigationItem {...props}>{label}</CalendarNavigationItem>;
};

export const CustomCalendar = (props) => (
  <Calendar {...props} navigationItem={CustomNavigationItem} />
);

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    caption,
    fieldName,
    isDisabled,
    justifyContent = 'space-between',
    defaultValue,
    isDisplayOnly,
  } = props;

  const { control, formState } = useFormContext();
  const { errors } = formState;
  const hasErrors = errors[fieldName];

  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <LocalizationProvider language='en-US'>
      <IntlProvider locale='en'>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent={justifyContent}
          width='100%'
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <Typography
            component='p'
            display='block'
            textAlign='left'
            color={dark[200]}
            variant='body2'
            mb={1}
          >
            {`${caption} `}
            <ErrorSpan errors={errors} name={fieldName} />
          </Typography>
          <Controller
            name={fieldName}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, value } }) => {
              if (isDisplayOnly) {
                return (
                  <Paragraph fontWeight={FontWeight.regular}>
                    {value ? formatDateStringNum(value) : '-'}
                  </Paragraph>
                );
              }
              return (
                <CustomDatePicker
                  onChange={onChange}
                  value={value}
                  disabled={isDisabled}
                  focused={isFocused}
                  hasErrors={!!hasErrors}
                />
              );
            }}
          />
        </Box>
      </IntlProvider>
    </LocalizationProvider>
  );
};

export default DatePicker;
