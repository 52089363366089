import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { QueryFunctionContext } from 'react-query/types/core/types';
import Alert from 'UI/molecules/global/alerts';
import { removeFalsyValues } from 'utils/remove-falsy-values';
import { api, methods } from '../../axios/config';

export type QueryKeyT = [string, object | undefined];
export type QueryParams = {
  url: string;
};

export type PaginatedResult<T> = {
  totalCount: number;
  totalPages: number;
  key: T[];
  succeed: boolean;
  message: string | null;
  unreadCount?: number;
};

export interface UseServiceProps<T> {
  routeParams?: object;
  params?: object;
  config?: UseQueryOptions<T[], Error, T[], QueryKeyT>;
  serviceName?: string;
}

export interface UseServicePropsSingle<T> {
  routeParams?: object;
  params?: object;
  config?: UseQueryOptions<T, Error, T, QueryKeyT>;
  serviceName?: string;
}

export interface UseServicePropsSingleQueryParams<T> {
  queryParams: QueryParams;
  params?: object;
  config?: UseQueryOptions<T, Error, T, QueryKeyT>;
  serviceName?: string;
}

export const getPaginated = <T,>(responseData: PaginatedResult<T> | null | undefined) => {
  return responseData
    ? responseData
    : ({
        totalCount: 0,
        totalPages: 0,
        key: [],
        succeed: true,
        message: null,
      } as PaginatedResult<T>);
};

export const fetcher = <T,>({ queryKey }: QueryFunctionContext<QueryKeyT, any>): Promise<T> => {
  const [url, params] = queryKey;
  return api.get<T>(url, { params: { ...params } }).then((res) => res.data);
};

export const useFetch = <T,>(
  url: string | null,
  params?: object,
  config?: UseQueryOptions<T, Error, T, QueryKeyT>,
) => {
  const cleanedParams = removeFalsyValues(params || {});
  const context = useQuery<T, Error, T, QueryKeyT>(
    [url!, cleanedParams],
    ({ queryKey }) => fetcher({ queryKey, meta: undefined }),
    {
      enabled: !!url,
      refetchOnWindowFocus: false,
      onError(err) {
        toast.error(<Alert title='Error' subtitle={err.message} />);
      },
      ...config,
    },
  );

  return context;
};

export const useFetchInfinite = (url, params, config) => {
  const cleanedParams = removeFalsyValues(params || {});
  const [page, setPage] = useState(1);

  const context = useInfiniteQuery({
    queryKey: [url, cleanedParams],
    queryFn: ({ pageParam = 1 }) =>
      fetcher({ queryKey: [url, { ...cleanedParams, pageNumber: pageParam }], meta: undefined }),
    enabled: !!url,
    onError(err: any) {
      toast.error(<Alert title='Error' subtitle={err.message} />);
    },
    onSuccess() {
      setPage(page + 1);
    },
    getNextPageParam({ totalPages }) {
      const nextPage = page > totalPages ? undefined : page;

      return nextPage;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...config,
  });

  useEffect(() => {
    const { data } = context;

    if (data?.pages) setPage(data.pages.length + 1);
  }, []);

  const refetchFromStart = () => {
    setPage(1);
    context.refetch();
  };

  return { ...context, refetchFromStart };
};

const useGenericMutation = <T, S>(
  func: (data: T | S) => Promise<AxiosResponse<S>>,
  url: string,
  params?: object,
  serviceName?: string,
  invalidationKey?: string,
) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, T | S>(func, {
    onError: (error) => {
      const data = error.response?.data as { message: '' };

      const msg = axios.isAxiosError(error) ? data.message : '';
      toast.error(<Alert title='Error' subtitle={`Error saving ${serviceName}!, ${msg}`} />);
    },
    onSuccess: () => {
      const cleanedParams = removeFalsyValues(params || {});
      toast.success(<Alert title='Success' subtitle={`${serviceName} was saved successfully!`} />);
      queryClient.invalidateQueries([invalidationKey || url!, cleanedParams]);
    },
  });
};

export const useDelete = (
  url: string,
  params?: object,
  serviceName?: string,
  invalidationKey?: string,
) => {
  return useGenericMutation(
    () => api.delete(url, undefined),
    url,
    params,
    serviceName,
    invalidationKey,
  );
};

export const useDeleteFD = (
  url: string,
  params?: object,
  serviceName?: string,
  invalidationKey?: string,
) => {
  return useGenericMutation(
    (data) => api.deleteFD(url, { data }),
    url,
    params,
    serviceName,
    invalidationKey,
  );
};

type ApiMethodKey = keyof typeof api;
const createMutation =
  <T extends ApiMethodKey>(apiMethod: T) =>
  <T, S>(url: string, params?: object, serviceName?: string, invalidationKey?: string) => {
    return useGenericMutation<T, S>(
      (data) => api[apiMethod]<S>(url, data),
      url,
      params,
      serviceName,
      invalidationKey,
    );
  };

export const usePost = createMutation(methods.post);
export const usePut = createMutation(methods.put);
export const useUpdate = createMutation(methods.patch);

export const usePostFD = createMutation(methods.postFD);
export const usePutFD = createMutation(methods.putFD);
export const useUpdateFD = createMutation(methods.patchFD);
