/* eslint-disable camelcase */
import { API_ROUTES } from 'constants/apiRoutes';
import { useFetch, usePut, UseServiceProps, UseServicePropsSingle } from 'utils/react-query';
import { pathToUrl } from 'utils/routes';
import { z } from 'zod';
import { ServerResponse } from '../../attendance/attendanceService';
// import { asOptionalField } from 'constants/zodTypes';

const SERVICE_NAME = 'Household';

export const phoneRegex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);
export const zidCodeRegex = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/);

export const phoneValidations = z
  .string()
  .regex(phoneRegex, 'Invalid number')
  .optional()
  .nullable()
  .transform((val) => val?.replace(/-/g, ''));

export enum houseHoldDetailsFields {
  id = 'id',
  clientId = 'clientId',
  email = 'email',
  emergencyFirstName = 'emergencyFirstName',
  emergencyContactRelationship = 'emergencyContactRelationship',
  emergencyLastName = 'emergencyLastName',
  address = 'address',
  emergencyContactPhone = 'emergencyContactPhone',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  city = 'city',
  state = 'state',
  zipCode = 'zipCode',
  phoneType = 'phoneType',
  phone = 'phone',
  parents = 'parents',
  members = 'members',
  emergencyContacts = 'emergencyContacts',
}

export enum members {
  id = 'id',
  firstName = 'firstName',
  lastName = 'lastName',
  relationship = 'relationship',
  address = 'address',
}

export enum addressFields {
  id = 'id',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  city = 'city',
  state = 'state',
  zipCode = 'zipCode',
  county = 'county',
}

export enum parentFields {
  id = 'id',
  firstName = 'firstName',
  lastName = 'lastName',
  relationship = 'relationship',
  approvedContact = 'approvedContact',
  livesWithClient = 'livesWithClient',
  address = 'address',
  primaryPhone = 'primaryPhone',
  primaryPhoneType = 'primaryPhoneType',
  primaryEmail = 'primaryEmail',
  secondaryPhone = 'secondaryPhone',
  secondaryPhoneType = 'secondaryPhoneType',
  contactPreference = 'contactPreference',
}

export enum emergencyContactFields {
  id = 'id',
  firstName = 'firstName',
  lastName = 'lastName',
  relationship = 'relationship',
  primaryPhoneNumber = 'primaryPhoneNumber',
}

export const houseHoldDetailsEmergencyContacts = z
  .object({
    [emergencyContactFields.id]: z.number().nullable().optional().default(0),
    [emergencyContactFields.firstName]: z
      .string()
      .trim()
      .min(1, { message: 'Required' })
      .nullable(),
    [emergencyContactFields.lastName]: z.string().trim().min(1, { message: 'Required' }).nullable(),
    [emergencyContactFields.relationship]: z.number().positive({ message: 'Required' }),
    [emergencyContactFields.primaryPhoneNumber]: phoneValidations,
  })
  .nullable()
  .optional();

export const contacts = z.object({
  emergencyContacts: z.array(houseHoldDetailsEmergencyContacts),
});

export const houseHoldDetailsAddress = z.object({
  [addressFields.id]: z.number().nullable().optional(),
  [addressFields.addressLine1]: z.string({ invalid_type_error: 'Required' }).trim(),
  [addressFields.addressLine2]: z.string(),
  [addressFields.city]: z.string().trim().min(1, { message: 'Required' }).nullable(),
  [addressFields.state]: z.string().trim().min(1, { message: 'Required' }).nullable(),
  [addressFields.zipCode]: z.string().trim().min(5).max(5).regex(zidCodeRegex),
  [addressFields.county]: z.string().nullable().optional(),
});

export const houseHoldDetailsParentsObject = z
  .object({
    [parentFields.id]: z.number().optional().nullable().default(0),
    [parentFields.firstName]: z.string().trim().min(1, { message: 'Required' }),
    [parentFields.lastName]: z.string().trim().min(1, { message: 'Required' }),
    [parentFields.relationship]: z.number().positive({ message: 'Required' }),
    [parentFields.approvedContact]: z.boolean().nullable().optional().default(false),
    [parentFields.livesWithClient]: z.boolean().nullable().optional().default(false),
    [parentFields.address]: houseHoldDetailsAddress.nullable().optional(),
    [parentFields.primaryPhone]: phoneValidations,
    [parentFields.primaryPhoneType]: z.number().positive({ message: 'Required' }),
    [parentFields.primaryEmail]: z.union([z.literal(''), z.string().email()]).nullable(),
    [parentFields.secondaryPhone]: z
      .string()
      .optional()
      .nullable()
      .transform((val) => (val ? val?.replace(/-/g, '') : null)),
    [parentFields.secondaryPhoneType]: z
      .number()
      .nullable()
      .optional()
      .transform((value) => (value === 0 ? null : value)),
    [parentFields.contactPreference]: z
      .number()
      .nullable()
      .optional()
      .transform((value) => (value === 0 ? null : value)),
  })
  .nullable()
  .optional();

export const emergencyContacts = z.array(houseHoldDetailsEmergencyContacts);

export const houseHoldDetailsMembers = z
  .object({
    [members.id]: z.number().nullable().optional(),
    [members.firstName]: z.string().trim().min(1, { message: 'Required' }),
    [members.lastName]: z.string().trim().min(1, { message: 'Required' }),
    [members.relationship]: z.number().positive({ message: 'Required' }),
  })
  .nullable()
  .optional();

export const houseHoldDetailsSchemaObject = z.object({
  [houseHoldDetailsFields.id]: z.number().nullable().optional(),
  [houseHoldDetailsFields.clientId]: z.number().optional().nullable(),
  [houseHoldDetailsFields.email]: z.string().email(),
  [houseHoldDetailsFields.addressLine1]: z.string().nullable().optional(),
  [houseHoldDetailsFields.addressLine2]: z.string().nullable().optional(),
  [houseHoldDetailsFields.city]: z.string().nullable().optional(),
  [houseHoldDetailsFields.state]: z.string().nullable().optional(),
  [houseHoldDetailsFields.zipCode]: z.string().nullable().optional(),
  [houseHoldDetailsFields.phoneType]: z
    .number()
    .nullable()
    .optional()
    .transform((value) => (value === 0 ? null : value)),
  [houseHoldDetailsFields.phone]: phoneValidations,
  [houseHoldDetailsFields.address]: houseHoldDetailsAddress.nullable().optional(),
  [houseHoldDetailsFields.parents]: z.array(houseHoldDetailsParentsObject),
  [houseHoldDetailsFields.members]: z.array(houseHoldDetailsMembers),
  [houseHoldDetailsFields.emergencyContacts]: emergencyContacts,
});

export type HouseHoldDetailsFields = z.infer<typeof houseHoldDetailsSchemaObject>;
export type OnboardingEmergencyContactInformationFields = z.infer<
  typeof houseHoldDetailsEmergencyContacts
>;

export const useHouseholdDetails = ({
  routeParams,
  params,
  config,
}: UseServicePropsSingle<HouseHoldDetailsFields>) =>
  useFetch<HouseHoldDetailsFields>(
    pathToUrl(API_ROUTES.HOUSEHOLD_DETAILS, routeParams),
    params,
    config,
  );

export const useUpdateHouseholdDetail = ({
  routeParams,
}: UseServiceProps<HouseHoldDetailsFields>) =>
  usePut<ServerResponse, HouseHoldDetailsFields>(
    pathToUrl(API_ROUTES.CLIENT_HOUSEHOLD_DETAILS),
    undefined,
    SERVICE_NAME,
    pathToUrl(API_ROUTES.HOUSEHOLD_DETAILS, routeParams),
  );
