/* eslint-disable camelcase */
import { API_ROUTES } from 'constants/apiRoutes';
import { ServerResponse } from 'services/client/attendance/attendanceService';
import { Center } from 'services/global-academics/centers';
import {
  useDeleteFD,
  useFetch,
  usePost,
  UseServiceProps,
  UseServicePropsSingle,
} from 'utils/react-query';
import { pathToUrl } from 'utils/routes';
import { z } from 'zod';

export enum fields {
  id = 'id',
}

export const CenterSchema = z.object({
  [fields.id]: z.number().optional(),
});

export type CenterType = z.infer<typeof CenterSchema>;

const UPDATE_SERVICE_NAME = 'Update Photo';

const DELETE_SERVICE_NAME = 'Delete Photo';

export const useCentersByAgency = ({ routeParams, params, config }: UseServiceProps<CenterType>) =>
  useFetch<CenterType[]>(pathToUrl(API_ROUTES.CENTERS_BY_AGENCY, routeParams), params, config);

export const useUploadPhoto = ({ routeParams }: UseServiceProps<FormData>) =>
  usePost<ServerResponse, FormData>(
    pathToUrl(API_ROUTES.CENTER_PHOTO, routeParams),
    undefined,
    UPDATE_SERVICE_NAME,
    pathToUrl(API_ROUTES.CENTER_INFO, routeParams),
  );

export const useCenterInformation = ({
  routeParams,
  params,
  config,
}: UseServicePropsSingle<Center>) =>
  useFetch<Center>(pathToUrl(API_ROUTES.CENTER_INFO, routeParams), params, config);

export const useDeleteCenterPhoto = ({ routeParams }: UseServiceProps<FormData>) =>
  useDeleteFD(
    pathToUrl(API_ROUTES.CENTER_PHOTO, routeParams),
    undefined,
    DELETE_SERVICE_NAME,
    pathToUrl(API_ROUTES.CENTER_INFO, routeParams),
  );
