import { axiosConfiguration } from 'axios/config';
import { CenterFields } from 'modules/global-academics/centers/form';
import { toast } from 'react-hot-toast';
import { useQuery } from 'react-query';
import Alert from 'UI/molecules/global/alerts';

export type Center = {
  createdDate: string;
  modifiedDate: string;
  address: Address;
  agency: Agency;
  id: number;
  agencyId: number;
  name: string;
  districtId: string;
  centerId: string;
  addressId: number;
  capacity: number;
  profilePhoto: string | null;
  bannerPhoto: string | null;
  profilePhotoURL: string | null;
  bannerPhotoURL: string | null;
};

export interface Address {
  id: number;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  county: string;
}

export interface Agency {
  id: number;
  agencyName: string;
  agencyId: string;
  headquarterLocation: string | null;
  contactPerson: string | null;
  contactPhone: string;
  avatarProfilePhoto: string;
}

interface ConventionalParams {
  agencyId: number;
  centerId: number;
  formBody: CenterFields;
}

export const getCenters = async (id: number) => {
  const { data: response } = await axiosConfiguration.get(`agency/${id}/center/`);
  return response;
};

export interface GlobalResponse {
  key: number;
  succeed: boolean;
  message: string | null;
}

type createParams = Omit<ConventionalParams, 'centerId'>;
export const createCenter = async (body: createParams) => {
  const { data: response } = await axiosConfiguration.post(
    `agency/${body.agencyId}/center`,
    body.formBody,
  );
  return response;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateCenter = async (body: any) => {
  const { data: response } = await axiosConfiguration.put(
    `agency/${body.agencyId}/center`,
    body.formBody,
  );
  return response;
};

export const getCenterInformation = async (body) => {
  const { data: response } = await axiosConfiguration.get(
    `agency/${body.agencyId}/center/${body.centerId}`,
  );
  return response;
};

interface profilePhotoParams extends Omit<ConventionalParams, 'formBody'> {
  fileParam: FormData;
}

export const uploadPhotoProfile = async (body: profilePhotoParams) => {
  const { data: response } = await axiosConfiguration.post(
    `agency/${body.agencyId}/center/${body.centerId}/files`,
    body.fileParam,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response;
};

interface deleteParams extends Omit<ConventionalParams, 'formBody'> {
  deleteFormBody: FormData;
}

export const deleteFile = async (body: deleteParams) => {
  const { data: response } = await axiosConfiguration.delete(
    `agency/${body.agencyId}/center/${body.centerId}/files`,
    { data: body.deleteFormBody },
  );
  return response;
};

export const useCenter = ({ agencyId }) => {
  const centerQuery = useQuery('get-center', () => getCenters(agencyId), {
    enabled: agencyId ? true : false,
    onError: () => {
      toast.success(<Alert title='Error' subtitle='An error occurred while loading the centers' />);
    },
  });

  return {
    centerQuery,
  };
};
