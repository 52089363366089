import { ErrorMessage } from '@hookform/error-message';
import { Autocomplete, Box, Chip, FormControl, TextField, Typography } from '@mui/material';
import ErrorSpan from 'components/ErrorSpan';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { dark } from 'theme/palette';
import { autoCompleteStyles, errorStyle } from 'theme/styles/inputs';
import { optionType } from 'utils/data-to-options';

type MultiSelectProps = {
  caption: string;
  fieldName: string;
  options?: optionType[];
  numberType?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  customArrayErrors?: boolean;
  justifyContent?: 'space-between' | 'space-around' | 'flex-start' | 'flex-end' | 'center';
  alt?: string;
};

const MultiSelect: React.FC<MultiSelectProps> = (props) => {
  const {
    caption,
    fieldName,
    options = [],
    placeholder,
    isDisabled,
    customArrayErrors,
    justifyContent = 'space-between',
    alt,
  } = props;

  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <Box display='flex' flexDirection='column' justifyContent={justifyContent}>
      <Typography component='p' display='block' textAlign='left' color={dark[200]} variant='body2'>
        {`${caption} `}
        {customArrayErrors ? (
          <ErrorMessage
            errors={errors}
            name={fieldName}
            render={({ message }) => <small style={errorStyle}>{message}</small>}
          />
        ) : (
          <ErrorSpan errors={errors} name={fieldName} />
        )}

        {!!alt && (
          <Typography
            component='span'
            color={dark[200]}
            fontWeight={300}
            fontSize={12}
            sx={{ opacity: 0.5 }}
            ml={0.5}
          >
            {`(${alt})`}
          </Typography>
        )}
      </Typography>
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { onChange, value = [], ref } }) => {
          return (
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                multiple
                ref={ref}
                value={(options || []).filter((option) => value.includes(option.id)) || []}
                options={options}
                disabled={isDisabled}
                onChange={(_, newValue) => {
                  onChange(newValue.map(({ id }) => id));
                }}
                sx={autoCompleteStyles}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => `${option.label}`}
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      sx={{ maxWidth: '50px' }}
                      size='small'
                      color='primary'
                      key={index}
                      label={
                        <Typography component='small' variant='caption'>
                          {option.label}
                        </Typography>
                      }
                    />
                  ));
                }}
                limitTags={2}
                size='small'
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{ ...params.InputProps }}
                    placeholder={placeholder}
                  />
                )}
              />
            </FormControl>
          );
        }}
      />
    </Box>
  );
};

export default MultiSelect;
