import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import useSessionUser from 'hooks/useSessionUser';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useStaffMemberById } from 'services/global-academics/staff-members';
import { grey, light, shadow } from 'theme/palette';
import { BLACK, BLUE, WHITE } from 'theme/palette/new';
import { pathToUrl } from 'utils/routes';
import { getSvgIcon } from 'utils/utils';
import Child from './child';

const Sidebar: React.FC<any> = ({
  parentNodes,
  moduleName,
  idParam,
  controller,
  permissions = {},
}) => {
  const { centerId, agencyId, id: clientId } = useParams();
  const { id: userId } = useSessionUser();
  const { data: userInfo } = useStaffMemberById({
    routeParams: { id: userId },
    config: { enabled: !!userId },
  });

  const centerID = Number(centerId || userInfo?.centerId || 0);
  const agencyID = Number(agencyId || 1);
  const clientID = Number(clientId || userId);
  const menuPosition = localStorage.getItem('menu-position');

  const [menu, setMenu] = useState<string | false>(
    menuPosition && menuPosition !== null ? menuPosition : 'menu0',
  );

  const childMenu = parentNodes.find((node) => node.controller === controller)?.nonMain || [];

  const handleExpandMenu =
    (MENU: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      localStorage.setItem('menu-position', MENU);
      setMenu(newExpanded ? MENU : false);
    };

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === `/client/${clientId}/`) setMenu('menu0');
  }, [pathname]);

  return (
    <Box
      component='div'
      width='100%'
      pr={{ xs: 0, md: 2 }}
      pt={{ xs: 0, md: 2 }}
      position='sticky'
      top={moduleName.includes('agency') ? '0px' : '148px'}
      mb={4}
      alignSelf='flex-start'
    >
      <Box
        component='div'
        pr={{ xs: 0, md: 1 }}
        display='flex'
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        borderRadius={{
          xs: '6px',
          md: '0px 4px 4px 4px',
          lg: '4px',
        }}
        justifyContent={{
          xs: 'center',
          md: 'start',
        }}
        boxShadow={shadow[200]}
        p={{
          xs: 1,
          md: 0,
        }}
        bgcolor={WHITE[100]}
      >
        <Box
          py={{ xs: 0, md: 1 }}
          component='div'
          mb={{
            xs: 0,
            md: '24px',
          }}
          display={{ xs: 'none', md: 'flex' }}
          flexDirection={{
            xs: 'row',
            md: 'column',
          }}
          justifyContent='space-between'
          alignItems='center'
          bgcolor={{
            xs: 'none',
            md: WHITE[200],
          }}
          mt={{
            xs: 0,
            md: '25px',
          }}
          borderRadius='0px 6px 6px 0px'
          height='100%'
          width={{
            xs: '100%',
            md: '68px',
          }}
        >
          {parentNodes.map((noMain: any) => {
            if (noMain.requiredPermission && !permissions[noMain.requiredPermission]) return null;
            return (
              <React.Fragment key={noMain.name}>
                <NavLink
                  style={({ isActive }) =>
                    isActive ? { textDecoration: 'none' } : { textDecoration: 'none' }
                  }
                  to={noMain.action ? noMain.action : '/'}
                >
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    height={44}
                    width={44}
                    borderRadius={{
                      xs: 2,
                      md: 1,
                    }}
                    sx={{
                      my: 0.5,
                      cursor: 'pointer',
                      ':hover': {
                        backgroundColor: BLUE[300],
                      },
                    }}
                    bgcolor={noMain.controller == controller ? BLACK[100] : WHITE[200]}
                  >
                    <Box
                      component='span'
                      sx={{
                        color: {
                          xs: noMain.controller == controller ? light[100] : BLACK[100],
                          md: light[100],
                        },
                        ':hover': {
                          color: light[100],
                        },
                      }}
                    >
                      {noMain.icon &&
                        getSvgIcon(
                          noMain.icon,
                          noMain.controller == controller ? WHITE[100] : BLACK[100],
                        )}
                    </Box>
                  </Box>
                </NavLink>
              </React.Fragment>
            );
          })}
        </Box>
        <Box
          display={{ xs: 'block', md: 'block' }}
          component='nav'
          my={{ xs: '0px', md: '16px' }}
          px={1}
          width={{ xs: '100%', md: 'calc(100% - 56px)' }}
        >
          {childMenu.map(
            ({ id, name, action = '', childNodes, requiredPermission }: any, index) => {
              if (requiredPermission && !permissions[requiredPermission]) return null;
              return (
                <Box component='ul' key={name}>
                  <Box component='nav'>
                    {childNodes && childNodes.length > 0 ? (
                      <Accordion
                        disableGutters
                        elevation={0}
                        sx={{ padding: '0px', margin: '0px' }}
                        expanded={menu === `menu${index}`}
                        onChange={handleExpandMenu(`menu${index}`)}
                        TransitionProps={{ unmountOnExit: true }}
                      >
                        <AccordionSummary
                          sx={{
                            padding: '0px',
                            margin: '0px',
                            display: 'flex',
                            height: '30px!important',
                          }}
                        >
                          <Box
                            bgcolor={menu === `menu${index}` ? BLUE[300] : 'none'}
                            sx={{
                              ':hover': {
                                bgcolor: menu === `menu${index}` ? 'none' : BLUE[300],
                              },
                            }}
                            display='flex'
                            px={1}
                            height='30px'
                            justifyContent='space-between'
                            alignItems='center'
                            borderRadius='4px'
                            width='100%'
                            component='div'
                          >
                            <Typography color={BLACK[100]} variant='caption' fontWeight={700}>
                              {name}
                            </Typography>

                            {menu === `menu${index}` ? (
                              <KeyboardArrowDownRoundedIcon sx={{ color: BLACK[100] }} />
                            ) : (
                              <ChevronRightRoundedIcon sx={{ color: BLACK[100] }} />
                            )}
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            padding: '0px',
                            borderLeft: { xs: `1px solid ${grey[300]}`, md: 'none' },
                          }}
                        >
                          {childNodes?.map(
                            ({ id, name, action = '', childNodes, requiredPermission }) => {
                              if (requiredPermission && !permissions[requiredPermission])
                                return null;
                              return (
                                // TODO: idParam to use dynamically by agency
                                <Child
                                  key={name}
                                  childNodes={childNodes}
                                  name={name}
                                  id={id || ''}
                                  route={pathToUrl(action || '', {
                                    id: id || '',
                                    clientId: clientID || '',
                                    agencyId: agencyID || '',
                                    centerId: centerID || '',
                                  })}
                                  idParam={idParam}
                                  moduleName={moduleName}
                                />
                              );
                            },
                          )}
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      <Box my={id === 10 ? 2 : 1}>
                        <Box
                          sx={{
                            cursor: 'pointer',
                            ':hover': {
                              bgcolor: BLUE[300],
                            },
                          }}
                          display='flex'
                          height='30px'
                          justifyContent='space-between'
                          alignItems='center'
                          borderRadius='4px'
                          width='100%'
                          component='div'
                        >
                          <NavLink
                            onClick={() => {
                              setMenu(false);
                              localStorage.setItem('menu-position', `menu${index}`);
                            }}
                            style={({ isActive }) => ({
                              textDecoration: 'none',
                              color: isActive ? WHITE[100] : BLACK[100],
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              justifyContent: 'start',
                              alignItems: 'center',
                              borderRadius: '4px',
                              paddingLeft: '8px',
                              paddingRight: '8px',
                              ...(isActive ? { backgroundColor: BLACK[100] } : {}),
                            })}
                            to={pathToUrl(action || '', {
                              clientId: clientID,
                              agencyId: agencyID,
                              centerId: centerID,
                            })}
                          >
                            <Typography variant='caption' fontWeight={700}>
                              {name}
                            </Typography>
                          </NavLink>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            },
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
