import { Avatar, Badge, Box, Button, Divider, Grid, Typography } from '@mui/material';
import CameraIcon from 'assets/images/svg/camera-icon.svg';
import PrimaryCameraIcon from 'assets/images/svg/camera-primary-icon.svg';
import ModalForm from 'components/ModalForm';
import ModalHeading from 'components/ModalHeading';
import useSessionUser from 'hooks/useSessionUser';
import { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import {
  useAddStaffMemberAvatar,
  useDeleteStaffMemberAvatar,
  useStaffMemberHeader,
} from 'services/global-academics/staff-members';
import { purple } from 'theme/palette';
import { BoxShadowed } from 'UI/molecules/global/cards';
import { useRandomColorAvatar } from 'UI/organisms/tasks/taskCard/hooks';
import { formatDateStringNum } from 'utils/format-date';

const getUrl = (files: any[]) => {
  if (files.length === 0) return '';

  return URL.createObjectURL(files[0]);
};

const NameAvatar = ({
  firstName = '',
  lastName = '',
  size,
  fz,
}: {
  firstName: string;
  lastName: string;
  size?: string;
  fz?: string;
}) => {
  return (
    <Avatar
      sx={{
        height: size || 150,
        width: size || 150,
        bgcolor: firstName ? useRandomColorAvatar(firstName) : 'none',
      }}
    >
      <Typography variant='h3' letterSpacing={-1} fontSize={fz || '48px'}>
        {`${firstName.split(' ')[0][0]}`} {lastName ? `${lastName.split(' ')[0][0]}` : null}
      </Typography>
    </Avatar>
  );
};

type HeaderChipProps = { field: string; label?: string };
const HeaderChip = (props: HeaderChipProps) => (
  <Box
    component='div'
    display={'flex'}
    justifyContent={{ xs: 'flex-start', md: 'center' }}
    alignItems='center'
  >
    <Typography component='p' color='darker' variant='body1'>
      {props.field}:
    </Typography>
    <Typography component='p' variant='body1' color='primary' ml={0.3}>
      {props.label}
    </Typography>
  </Box>
);

type DefaultAvatarProps = {
  url?: string;
  firstName: string;
  lastName: string;
  hasImage: boolean;
  customSize?: string;
  customFontSize?: string;
};

export const DefaultAvatar = ({
  url,
  firstName = '',
  lastName = '',
  hasImage,
  customSize,
  customFontSize,
}: DefaultAvatarProps) => {
  if (hasImage)
    return (
      <Avatar
        sx={{ width: customSize || '250px', height: customSize || '250px' }}
        src={url}
        alt='avatar-image'
      />
    );
  return (
    <NameAvatar firstName={firstName} lastName={lastName} size={customSize} fz={customFontSize} />
  );
};

type AvatarFormProps = {
  userData: any;
  saveAvatar: (any) => void;
  removeAvatar: () => void;
  isLoading: boolean;
  onClose: () => void;
};
const AvatarForm = (props: AvatarFormProps) => {
  const { userData, onClose } = props;

  const { photoUrl, firstName, lastName } = userData || {};
  const headingText = photoUrl ? 'Change your Profile Photo' : 'Add your Profile Photo';

  const { control, watch, reset } = useForm();
  const files = watch('file') || [];
  const isPhotoDirty = !!files[0] || !!photoUrl;
  const hasAvatar = !!photoUrl;

  const save = () => {
    props.saveAvatar(files[0]);
  };

  return (
    <Box component='form' onSubmit={() => null}>
      <ModalHeading onClose={onClose} title={headingText} />
      <Controller
        name='file'
        control={control}
        render={({ field: { onChange } }) => (
          <Dropzone multiple={false} onDrop={onChange}>
            {({ isDragActive, open }) => (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                bgcolor={isDragActive ? 'red' : 'transparent'}
              >
                {/* <input {...getInputProps()} /> */}
                <Badge
                  overlap='circular'
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                  <DefaultAvatar
                    url={getUrl(files) || photoUrl || ''}
                    firstName={firstName}
                    lastName={lastName}
                    hasImage={!!(files[0] || photoUrl)}
                  />
                </Badge>

                <Divider sx={{ my: 2 }} flexItem />

                {isPhotoDirty && (
                  <Box display='flex' mb={3}>
                    <Button
                      variant='secondarybtn'
                      onClick={hasAvatar ? props.removeAvatar : reset}
                      disabled={props.isLoading}
                      sx={{ mr: 1 }}
                    >
                      {hasAvatar && !props.isLoading ? 'Delete photo' : 'Cancel'}
                    </Button>
                    <Button variant='primarybtn' onClick={save} disabled={props.isLoading}>
                      {props.isLoading ? 'Saving...' : 'Save Avatar'}
                    </Button>
                    {!hasAvatar && (
                      <Button variant='primarybtn' onClick={save} disabled={props.isLoading}>
                        {props.isLoading ? 'Saving...' : 'Save Avatar'}
                      </Button>
                    )}
                  </Box>
                )}

                {!isPhotoDirty && (
                  <Button
                    sx={{ ml: 2, mb: 3 }}
                    startIcon={<Box component='img' src={CameraIcon} alt='camera-icon' />}
                    variant='primarybtn'
                    type='button'
                    onClick={open}
                  >
                    Upload Profile Photo
                  </Button>
                )}
              </Box>
            )}
          </Dropzone>
        )}
      />
    </Box>
  );
};

const TrainingHeader = () => {
  const { id } = useSessionUser();
  const [isOpen, setIsOpen] = useState(false);
  const { data }: any = useStaffMemberHeader({
    config: { enabled: !!id },
    routeParams: { id },
  });

  const { mutateAsync: addPhoto, isLoading: isAdding } = useAddStaffMemberAvatar({
    routeParams: { id: id },
    config: { enabled: !!id },
  });

  const { mutateAsync: delePhoto, isLoading: isRemoving } = useDeleteStaffMemberAvatar({
    routeParams: { id: id },
    config: { enabled: !!id },
  });

  const saveAvatar = async (file) => {
    const avatarProfilePhoto = new FormData();
    avatarProfilePhoto.append('avatarProfilePhoto', file);
    addPhoto(avatarProfilePhoto, {
      onSettled: closeModal,
    });
  };

  const removeAvatar = () => {
    delePhoto(null, {
      onSettled: closeModal,
    });
  };

  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  const dateOfHire = formatDateStringNum(data?.memberSince as string);
  const hasAvatar = !!data?.photoUrl;

  return (
    <BoxShadowed height='116px' p={3}>
      <Box
        component='div'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        width='30%'
      >
        <Box component='div' display='flex' alignItems='center'>
          <Badge
            overlap='circular'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <Avatar
                sx={{
                  width: 26,
                  height: 26,
                  cursor: 'pointer',
                  bgcolor: purple[100],
                  border: '2px solid white',
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                <Box component='img' src={CameraIcon} alt='camera-icon' onClick={openModal} />
              </Avatar>
            }
          >
            {hasAvatar ? (
              <Avatar
                sx={{ width: '68px', height: '68px' }}
                src={data?.photoUrl}
                alt='avatar-image'
              />
            ) : (
              <NameAvatar
                firstName={data?.firstName}
                lastName={data?.lastName}
                size='65px'
                fz='18px'
              />
            )}
          </Badge>
          <Box component='div' ml={2}>
            <Typography component='h1' color='darker' variant='body1'>
              {data?.firstName}
            </Typography>
            <Typography component='h1' color='customGrey' variant='body2'>
              {data?.lastName}
            </Typography>
          </Box>
        </Box>
        <Button
          startIcon={<Box component='img' src={PrimaryCameraIcon} alt='camera-icon' />}
          size='small'
          sx={{ display: { md: 'none' } }}
        >
          Change profile picture
        </Button>
      </Box>

      <Divider orientation='vertical' flexItem sx={{ mx: 2 }} />

      <Grid
        component='div'
        container
        display='flex'
        mt={{ xs: 2, md: 0 }}
        alignItems='center'
        justifyContent={{ xs: 'flex-start' }}
      >
        <Grid component='div' item xs={12} md={'auto'} mr={{ xs: 0, md: 2 }}>
          <HeaderChip field='Center' label={data?.centerName} />
        </Grid>
        <Grid component='div' item xs={12} md={'auto'} mr={{ xs: 0, md: 2 }}>
          <HeaderChip field='Original Date of Hire' label={dateOfHire} />
        </Grid>
        <Grid component='div' item xs={12} md={'auto'} mr={{ xs: 0, md: 2 }}>
          <HeaderChip field='Position' label={data?.jobTitle} />
        </Grid>
        <Grid component='div' item xs={12} md={'auto'} mr={{ xs: 0, md: 2 }}>
          <HeaderChip field='Status' label={data?.status} />
        </Grid>
      </Grid>

      <ModalForm open={isOpen} onClose={closeModal}>
        <AvatarForm
          userData={data}
          saveAvatar={saveAvatar}
          removeAvatar={removeAvatar}
          isLoading={isAdding || isRemoving}
          onClose={closeModal}
        />
      </ModalForm>
    </BoxShadowed>
  );
};

export default TrainingHeader;
