import {
  DateSelectArg,
  EventClickArg,
  EventContentArg,
  EventDropArg,
  EventInput,
} from '@fullcalendar/core';
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { EventResizeDoneArg } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Box, Card, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  EventParamsInterface,
  useEvents,
} from 'services/global-academics/academic-setup/calendarService';
import { dark, grey, light, shadow } from 'theme/palette';
import { LinearProgressBar } from 'UI/molecules/global/linear-progress-bar';
import ModalEvents, { ModalFieldsTypes } from './SaveCalendarForm';

let eventGuid = 0;
export const createEventId = () => String(eventGuid++);
interface Props {
  event: EventContentArg;
}

const CustomEventSlot: React.FC<Props> = ({ event }) => {
  return (
    <Box component='div' width='100%' height='100%'>
      <Box
        component='div'
        borderRadius='4px'
        border={event.isDragging ? '1px dashed white' : event.borderColor}
        width='100%'
        height='100%'
        bgcolor={event.backgroundColor}
      >
        <Typography ml={1} color={event.textColor} variant='caption'>
          {event.event.title}
        </Typography>
      </Box>
    </Box>
  );
};

const CalendarEvents: React.FC = () => {
  const { centerId } = useParams();
  const { eventsQuery, eventsMutation, eventUpdateMutation } = useEvents({
    centerId: Number(centerId),
  });

  const getRandomColor = (colors: Array<string>) => {
    // get random index value
    const randomIndex = Math.floor(Math.random() * colors.length);

    // get random item
    const color = colors[randomIndex];

    return color;
  };

  const memoizationOfData = useMemo(() => {
    const events = eventsQuery.data
      ? eventsQuery.data.map((element) => {
          const response: EventInput = {
            id: element.id.toString(),
            start:
              element.startTime !== null
                ? new Date(
                    `${
                      new Date(element.startDate).toISOString().replace(/T.*$/, '') +
                      'T' +
                      element.startTime
                    }`,
                  ).toISOString()
                : new Date(element.startDate).toISOString(),
            end:
              element.endTime !== null
                ? new Date(
                    `${
                      new Date(element.endDate).toISOString().replace(/T.*$/, '') +
                      'T' +
                      element.endTime
                    }`,
                  ).toISOString()
                : new Date(element.endDate).toISOString(),
            allDay: element.isAllday,
            title: element.eventName,
            description: element.description,
            backgroundColor: getRandomColor([
              '#FECF97',
              '#E2BAFB',
              '#F49999',
              '#FFF2AB',
              '#C5E496',
              '#FE9572',
              '#BAF3D5',
              '#E9AFB8',
              '#B3DCE6',
              '#F9DEC9',
            ]),
            borderColor: 'transparent',
            textColor: element.isAllday ? dark[200] : light[100],
            isSchoolClosed: element.isSchoolClosed,
            reasonSchoolClosed: element.reasonSchoolClosed,
            descriptionSchoolClosed: element.descriptionSchoolClosed,
          };

          return response;
        })
      : [];

    return events;
  }, [eventsQuery.data]);

  const [dataEvent, setDataEvent] = useState<ModalFieldsTypes>();
  const [dataEventAsync, setDataEventAsync] = useState<ModalFieldsTypes>();
  const [modalEvent, setModalEvent] = React.useState<boolean>(false);
  const [editModalEvent, setEditModalEvent] = React.useState<boolean>(false);

  const handleEventClick = React.useCallback((args: EventClickArg) => {
    const event = args.event;
    if (_.isEmpty(event.start) !== null) {
      setDataEventAsync({
        id: Number(event.id),
        name: event.title,
        startDate: event.start,
        allDay: event.allDay,
        endDate: event.allDay ? event.start : event.end,
        description: event.extendedProps.description,
        isSchoolClosed: event.extendedProps.isSchoolClosed,
        reasonSchoolClosed: event.extendedProps.reasonSchoolClosed,
        descriptionSchoolClosed: event.extendedProps.descriptionSchoolClosed,
      });

      onOpenModalEdit();
    }
  }, []);

  const handleOndropEvent = (args: EventDropArg) => {
    if (args.event._context.calendarApi.view.type === 'timeGridWeek') {
      if (args.event.end === null) {
        const startDate = args.event.start?.getTime();
        const oneHour = 60 * 60000;
        if (startDate)
          setDataEventAsync({
            id: Number(args.event.id),
            name: args.event.title,
            startDate: args.event.start,
            endDate: new Date(startDate + oneHour),
            allDay: args.event.allDay,
            description: args.event.extendedProps.description,
            onCancel: args.revert,
            isSchoolClosed: args.event.extendedProps.isSchoolClosed,
            reasonSchoolClosed: args.event.extendedProps.reasonSchoolClosed,
            descriptionSchoolClosed: args.event.extendedProps.descriptionSchoolClosed,
          });

        setEditModalEvent(true);
      }
    }

    if (_.isEmpty(args.event.start) !== null) {
      const formBody = {
        id: Number(args.event.id),
        eventName: args.event.title,
        startTime: args.event.start
          ? `${args.event.start.getHours()}:${args.event.start.getMinutes()}:${args.event.start.getSeconds()}`
          : null,
        startDate: args.event.start ? args.event.start.toISOString().replace(/T.*$/, '') : null,
        endDate: args.event.end ? args.event.end.toISOString().replace(/T.*$/, '') : null,
        endTime: args.event.end
          ? `${args.event.end.getHours()}:${args.event.end.getMinutes()}:${args.event.end.getSeconds()}`
          : null,
        description: args.event.extendedProps.description,
        isAllday: args.event.allDay,
        reasonSchoolClosed: args.event.extendedProps.reasonSchoolClosed,
        descriptionSchoolClosed: args.event.extendedProps.descriptionSchoolClosed,
      };

      eventUpdateMutation.mutateAsync({
        eventData: { centerId: Number(centerId), data: formBody },
      });
    }
  };

  const handleResizeEvent = (args: EventResizeDoneArg) => {
    const event = args.event;

    const formBody = {
      id: Number(event.id),
      eventName: event.title,
      startTime: event.start
        ? `${event.start.getHours()}:${event.start.getMinutes()}:${event.start.getSeconds()}`
        : null,
      startDate: event.start ? event.start.toISOString().replace(/T.*$/, '') : null,
      endDate: event.end ? event.end.toISOString().replace(/T.*$/, '') : null,
      endTime: event.end
        ? `${event.end.getHours()}:${event.end.getMinutes()}:${event.end.getSeconds()}`
        : null,
      description: event.extendedProps.description,
      isAllday: event.allDay,
      reasonSchoolClosed: event.extendedProps.reasonSchoolClosed,
      descriptionSchoolClosed: event.extendedProps.descriptionSchoolClosed,
    };

    eventUpdateMutation.mutateAsync({ eventData: { centerId: Number(centerId), data: formBody } });
  };

  const handleDateSelect = React.useCallback((selectInfo: DateSelectArg) => {
    setDataEvent({
      id: 0,
      name: '',
      allDay: selectInfo.allDay,
      startDate: selectInfo.start,
      endDate: selectInfo.allDay ? selectInfo.start : selectInfo.end,
      description: '',
      isSchoolClosed: false,
      reasonSchoolClosed: null,
      descriptionSchoolClosed: '',
    });
    onOpenModalEvent();
    const calendarApi = selectInfo.view.calendar;
    calendarApi.unselect();
  }, []);

  const onCloseModalEvent = React.useCallback(() => {
    setModalEvent((current) => !current);
  }, [modalEvent]);

  /* @Open Modal for create a new event */
  const onOpenModalEvent = React.useCallback(() => {
    setModalEvent(true);
  }, [modalEvent]);

  const handleAsyncEvents = async (formData: EventParamsInterface) => {
    if (formData.id !== 0) {
      await eventUpdateMutation.mutateAsync({
        eventData: { centerId: Number(centerId), data: formData },
      });
      onCloseModalEdit();
    } else {
      await eventsMutation.mutateAsync({
        eventData: { centerId: Number(centerId), data: formData },
      });
      onCloseModalEvent();
    }
  };

  /* @Open Modal for update a event */
  const onOpenModalEdit = useCallback(() => {
    setEditModalEvent(true);
  }, [editModalEvent]);

  const onCloseModalEdit = useCallback(() => {
    setEditModalEvent((current) => !current);
  }, [editModalEvent]);

  return (
    <Box component='div'>
      <ModalEvents
        open={modalEvent}
        onClose={onCloseModalEvent}
        isUpdate={false}
        isLoading={eventsMutation.isLoading}
        passDataForm={dataEvent || null}
        getDataForm={handleAsyncEvents}
      />
      <ModalEvents
        open={editModalEvent}
        onClose={onCloseModalEdit}
        passDataForm={dataEventAsync || null}
        isUpdate={true}
        isLoading={eventUpdateMutation.isLoading}
        getDataForm={handleAsyncEvents}
      />
      <Card sx={{ boxShadow: shadow[100], p: 2, borderRadius: '10px' }}>
        {eventsQuery.isLoading ? (
          <Box display='flex' p={1}>
            <Box
              borderRadius='6px'
              border={`1px solid ${grey[300]}`}
              height='auto'
              width='100%'
              p={1}
            >
              <Typography>Loading Calendar...</Typography>
              <LinearProgressBar />
            </Box>
            <Box
              borderRadius='6px'
              border={`1px solid ${grey[300]}`}
              ml={2}
              height='auto'
              width='300px'
              p={1}
            >
              <Typography component='p' color='darker'>
                Loading Information...
              </Typography>
              <LinearProgressBar />
            </Box>
          </Box>
        ) : (
          <Box display='flex' flexDirection={{ xs: 'column', md: 'row', lg: 'row' }}>
            <div className='demo-app-main'>
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                initialView='listMonth'
                editable={true}
                selectable={true}
                eventResize={handleResizeEvent}
                eventDrop={handleOndropEvent}
                forceEventDuration={true}
                titleFormat={{ year: 'numeric', month: 'short', day: 'numeric' }}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'listMonth,dayGridMonth,timeGridWeek,timeGridDay',
                }}
                locale='us'
                nowIndicator={true}
                eventContent={(value) => <CustomEventSlot event={value} />}
                events={memoizationOfData}
                locales={allLocales}
                select={handleDateSelect}
                /* allDayMaintainDuration={true} */
                eventClick={handleEventClick}
              />
            </div>
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default CalendarEvents;
