import AddRounded from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useAcademicYears } from 'services/global-academics/academic-setup/academicYearService';
import { grey } from 'theme/palette';
import { CardContentBorderedForAction, CardShadowed } from 'UI/molecules/global/cards';
import SaveAcademicYearForm from '../forms/SaveAcademicYear';
import { AcademicYearItemProps } from '../interfaces';
import SemestersSection from '../semester';

interface MyComponentProps {
  centerId: string;
}

interface AddAcademicYear {
  onClick: () => void;
}

const AddAcademicYear: React.FC<AddAcademicYear> = ({ onClick }) => {
  return (
    <CardContentBorderedForAction>
      <Box
        component='div'
        display='flex'
        alignItems='center'
        onClick={() => {
          onClick();
        }}
      >
        <Box
          component='div'
          display='flex'
          alignItems='center'
          style={{ cursor: 'pointer' }}
          gap='4px'
        >
          <AddRounded color='primary' fontWeight={700} style={{ fontSize: '16px' }} />
          <Typography fontSize='14px' fontWeight={500} color='primary'>
            Create a new Academic Year
          </Typography>
        </Box>
      </Box>
    </CardContentBorderedForAction>
  );
};

const AcademicYearItem: React.FC<AcademicYearItemProps> = ({
  id,
  yearName,
  yearStartDate,
  yearEndDate,
  isDefault,
  onEdit,
}) => {
  const formattedStartDate = moment(yearStartDate).format('MMM Do');
  const formattedEndDate = moment(yearEndDate).format('MMM Do');
  const extractedYear = moment(yearEndDate).format('YYYY');

  return (
    <Box component='div' display='flex' justifyContent='space-between' alignItems='center'>
      <Box component='div' display='flex' flexDirection='column' gap='4px'>
        {isDefault && (
          <Typography fontSize='14px' color='primary'>
            DEFAULT ACADEMIC YEAR
          </Typography>
        )}
        <Typography fontSize='20px' fontWeight={600}>
          {yearName}
        </Typography>
        <Typography fontSize='14px' color={grey[200]}>
          {formattedStartDate} - {formattedEndDate}, {extractedYear}{' '}
        </Typography>
      </Box>
      <Box
        component='div'
        display='flex'
        alignItems='center'
        style={{ cursor: 'pointer' }}
        gap='8px'
        onClick={() => {
          onEdit(id);
        }}
      >
        <EditRoundedIcon fontWeight={500} color='primary' style={{ fontSize: '14px' }} />
        <Typography fontSize='14px' fontWeight={500} color='primary'>
          Edit Academic Year
        </Typography>
      </Box>
    </Box>
  );
};

export default function AcademicYear(props: MyComponentProps): JSX.Element {
  const { centerId } = props;
  const { academicYearsQuery, academicYearsMutation } = useAcademicYears({ centerId: +centerId });

  const academicYears = academicYearsQuery.data || [];
  const hasItems = academicYears.length > 0;

  const [isAddAcademicYearFormOpen, setIsAddAcademicYearFormOpen] = useState(false);
  const [academicYearToEdit, setAcademicYearToEdit] = useState<string | null>(null);

  const AddForm = isAddAcademicYearFormOpen && !academicYearToEdit && (
    <CardShadowed>
      <SaveAcademicYearForm
        onCancel={() => {
          setIsAddAcademicYearFormOpen(false);
        }}
        isSaving={academicYearsMutation.isLoading}
        onAddAcademicYear={async (academicYearData) => {
          await academicYearsMutation.mutateAsync({ academicYearData });
          setIsAddAcademicYearFormOpen(false);
        }}
      />
    </CardShadowed>
  );

  return (
    <>
      <Box component='div' display='flex' justifyContent='flex-end' marginBottom={3}>
        <Button
          startIcon={<AddRounded />}
          onClick={() => setIsAddAcademicYearFormOpen(true)}
          variant='primarybtn'
        >
          Add New Academic Year
        </Button>
      </Box>
      {hasItems &&
        academicYears.map((academicYearProps, index) => (
          <Box key={`academicYearItem ${index}`} marginBottom={3}>
            <CardShadowed>
              {academicYearToEdit !== academicYearProps.id ? (
                <AcademicYearItem
                  {...academicYearProps}
                  onEdit={(id) => {
                    setAcademicYearToEdit(id);
                  }}
                />
              ) : (
                <SaveAcademicYearForm
                  data={academicYearProps}
                  onCancel={() => {
                    setAcademicYearToEdit(null);
                  }}
                  isSaving={academicYearsMutation.isLoading}
                  onEditAcademicYear={async (academicYearData) => {
                    await academicYearsMutation.mutateAsync({ academicYearData });
                    setAcademicYearToEdit(null);
                  }}
                />
              )}

              <SemestersSection centerId={centerId} yearId={academicYearProps.id} />
            </CardShadowed>
          </Box>
        ))}

      {!hasItems && !isAddAcademicYearFormOpen && !academicYearToEdit && (
        <CardShadowed>
          <AddAcademicYear onClick={() => setIsAddAcademicYearFormOpen(true)} />
        </CardShadowed>
      )}

      {AddForm}
    </>
  );
}
