import { Box, Skeleton } from '@mui/material';
import React from 'react';

const ClientSkeleton: React.FC = () => {
  return (
    <Box component='div'>
      <Skeleton
        sx={{ borderRadius: 1 }}
        variant='rectangular'
        animation='wave'
        width='200px'
        height='24px'
      />
    </Box>
  );
};

export default ClientSkeleton;
