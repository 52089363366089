import { useEffect, useState } from 'react';
import {
  NavigationType,
  useNavigation,
  UserPermissionsMap,
  userUserPermissions,
} from 'services/navigation';

const permissionObject = (arr: any[]) => {
  return arr.reduce((acc, curr) => {
    const [key, value]: any = Object.entries(curr)[0];
    acc[value] = key;
    return acc;
  }, {} as UserPermissionsMap);
};

export const useNavigationMenu = () => {
  const [navigationMenu, setNavigationMenu] = useState<NavigationType[] | null>(null);
  const [permissionsMenu, setPermissionsMenu] = useState<any>(null);
  const navigationInLocalStorage = JSON.parse(localStorage.getItem('sidebar-data') || 'null');
  const permissionsInLocalStorage = JSON.parse(
    localStorage.getItem('sidebar-permissions') || 'null',
  );

  const { data: navigationItems, isLoading: loadingNavigation } = useNavigation({
    config: { enabled: !navigationInLocalStorage },
  });
  const { data: userPermissions, isLoading: loadingPermissions } = userUserPermissions({
    config: { enabled: !permissionsInLocalStorage },
  });

  const isLoading = loadingNavigation || loadingPermissions;

  useEffect(() => {
    if (navigationInLocalStorage && permissionsInLocalStorage) {
      setNavigationMenu(navigationInLocalStorage);
      setPermissionsMenu(permissionsInLocalStorage);
    } else if (navigationItems && userPermissions && !isLoading) {
      setNavigationMenu(navigationItems);
      setPermissionsMenu(permissionObject(userPermissions || []));
      localStorage.setItem('sidebar-data', JSON.stringify(navigationItems));
      localStorage.setItem(
        'sidebar-permissions',
        JSON.stringify(permissionObject(userPermissions || [])),
      );
    }
  }, [navigationItems, userPermissions, isLoading]);

  return { navigationMenu, permissionsMenu, isLoading };
};
