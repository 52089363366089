import { ErrorMessage } from '@hookform/error-message';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import ErrorSpan from 'components/ErrorSpan';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { dark } from 'theme/palette';
import { errorStyle, selectStyle } from 'theme/styles/inputs';
import { stringToBoolean } from 'utils/string-to-boolean';

const options = [
  { label: '4\'0"' },
  { label: '4\'1"' },
  { label: '4\'2"' },
  { label: '4\'3"' },
  { label: '4\'4"' },
  { label: '4\'5"' },
  { label: '4\'6"' },
  { label: '4\'7"' },
  { label: '4\'8"' },
  { label: '4\'9"' },
  { label: '5\'0"' },
  { label: '5\'1"' },
  { label: '5\'2"' },
  { label: '5\'3"' },
  { label: '5\'4"' },
  { label: '5\'5"' },
  { label: '5\'6"' },
  { label: '5\'7"' },
  { label: '5\'8"' },
  { label: '5\'9"' },
  { label: '6\'0"' },
  { label: '6\'1"' },
  { label: '6\'2"' },
  { label: '6\'3"' },
  { label: '6\'4"' },
  { label: '6\'5"' },
  { label: '6\'6"' },
  { label: '6\'7"' },
  { label: '6\'8"' },
  { label: '6\'9"' },
  { label: '7\'0"' },
];

type DropDownProps = {
  caption: string;
  fieldName: string;
  placeholder?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  customArrayErrors?: boolean;
  justifyContent?: 'space-between' | 'space-around' | 'flex-start' | 'flex-end' | 'center';
  alt?: string;
};

const DropDown: React.FC<DropDownProps> = (props) => {
  const {
    caption,
    fieldName,
    placeholder,
    isDisabled,
    isReadOnly,
    customArrayErrors,
    justifyContent = 'space-between',
    alt,
  } = props;

  const { control, formState } = useFormContext();
  const { errors } = formState;

  const handleChange =
    (cb) =>
    ({ target }) => {
      const num = Number(target.value);
      const isNumber = !Number.isNaN(num);
      const isString = typeof target.value === 'string';
      const { value: boolValue, isValidBoolean } = stringToBoolean(target.value);

      if (isString && !isValidBoolean && !isNumber) {
        cb(target.value);
        return;
      }

      const val = isValidBoolean ? boolValue : num;
      cb(val);
    };

  return (
    <Box display='flex' flexDirection='column' justifyContent={justifyContent}>
      <Typography component='p' display='block' textAlign='left' color={dark[200]} variant='body2'>
        {`${caption} `}
        {customArrayErrors ? (
          <ErrorMessage
            errors={errors}
            name={fieldName}
            render={({ message }) => <small style={errorStyle}>{message}</small>}
          />
        ) : (
          <ErrorSpan errors={errors} name={fieldName} />
        )}

        {!!alt && (
          <Typography
            component='span'
            color={dark[200]}
            fontWeight={300}
            fontSize={12}
            sx={{ opacity: 0.5 }}
            ml={0.5}
          >
            {`(${alt})`}
          </Typography>
        )}
      </Typography>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={''}
        render={({ field: { onChange, value, ref } }) => (
          <Select
            onChange={handleChange(onChange)}
            disabled={isDisabled}
            readOnly={isReadOnly}
            value={value}
            displayEmpty
            sx={selectStyle}
            MenuProps={selectStyle.MenuProps}
            ref={ref}
          >
            <MenuItem disabled value={''}>
              <Typography color='darker' variant='caption'>
                {placeholder}
              </Typography>
            </MenuItem>
            {options.map(({ label }) => (
              <MenuItem disabled={isDisabled} value={`${label}`} key={`${label}`}>
                <Typography component='p' color='darker' variant='caption'>
                  {`${label}`}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </Box>
  );
};

export default DropDown;
