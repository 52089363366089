import { useCatalogs } from 'services/catalogs/catalogsService';

enum CONSTANTS {
  REASON_SCHOOL_CLOSE = 'ReasonSchoolClosed',
}

export type SchoolStatus = {
  id: string;
  schoolStatus: string;
};

export const useReasonSchoolStatus = (): SchoolStatus[] => {
  const { catalogsQuery } = useCatalogs({ internalName: CONSTANTS.REASON_SCHOOL_CLOSE });
  if (catalogsQuery.data) {
    const schoolStatuses = catalogsQuery.data.attributes.map((attribute) => {
      return {
        id: attribute.id,
        schoolStatus: attribute.attributeName,
      };
    });
    return schoolStatuses;
  }
  return [];
};
