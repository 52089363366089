import { Box, Typography } from '@mui/material';
import {
  Calendar,
  CalendarNavigationItem,
  CalendarNavigationItemProps,
  TimePicker as TP,
} from '@progress/kendo-react-dateinputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import ErrorSpan from 'components/ErrorSpan';
import { CustomPopup } from 'modules/global-academics/centers/academic-setup/forms/SaveCalendarForm';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { dark } from 'theme/palette';

type TimePickerProps = {
  caption: string;
  fieldName: string;
  placeholder?: string;
  isDisabled?: boolean;
  justifyContent?: 'space-between' | 'space-around' | 'flex-start' | 'flex-end' | 'center';
};
export const CustomNavigationItem = (props: CalendarNavigationItemProps) => {
  const { value, text } = props;

  const isValueAYear = Number.isSafeInteger(Number(value));
  const formattedValue = `${value.toLocaleString('en-us', { month: 'short' })}`;

  const label = isValueAYear ? text : formattedValue;

  return <CalendarNavigationItem {...props}>{label}</CalendarNavigationItem>;
};

export const CustomCalendar = (props) => (
  <Calendar {...props} navigationItem={CustomNavigationItem} />
);

const TimePicker: React.FC<TimePickerProps> = (props) => {
  const { caption, fieldName, isDisabled, justifyContent = 'space-between', placeholder } = props;
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const { control, formState } = useFormContext();
  const { errors } = formState;
  const hasErrors = errors[fieldName];

  return (
    <LocalizationProvider language='en-US'>
      <IntlProvider locale='en'>
        <Box display='flex' flexDirection='column' justifyContent={justifyContent} width='100%'>
          <Typography
            component='p'
            display='block'
            textAlign='left'
            color={dark[200]}
            variant='body2'
            mb={1}
          >
            {`${caption} `}
            <ErrorSpan errors={errors} name={fieldName} />
          </Typography>
          <Controller
            name={fieldName}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TP
                onChange={onChange}
                value={value}
                disabled={isDisabled}
                placeholder={placeholder || 'Time'}
                popup={CustomPopup}
                className={`timepicker ${hasErrors && 'field-error'} ${isFocused && 'focused'}`}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            )}
          />
        </Box>
      </IntlProvider>
    </LocalizationProvider>
  );
};

export default TimePicker;
