import { Box } from '@mui/material';
import { purple } from 'theme/palette';

const OutSideBox = ({
  children,
  bgcolor,
}: {
  children: string | JSX.Element | JSX.Element[];
  bgcolor?: string;
}) => {
  return (
    <Box px={128} mx={-128} py={4} mt={-4} bgcolor={bgcolor || purple[800]}>
      {children}
    </Box>
  );
};

export default OutSideBox;
