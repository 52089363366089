import { FC } from 'react';
import { BaseText, FontWeight, TextElement, TextProps, WithFontWeight } from './BaseText';

export const H5: FC<TextProps & WithFontWeight> = (props: TextProps & WithFontWeight) => {
  const { children, fontWeight } = props;
  return (
    <BaseText element={TextElement.h5} fontWeight={fontWeight || FontWeight.regular} {...props}>
      {children}
    </BaseText>
  );
};
