import { FieldErrors } from 'react-hook-form';
import { errorStyle } from 'theme/styles/inputs';

type ErrorSpanProps = {
  errors: FieldErrors;
  name: string;
};

const ErrorSpan: React.FC<ErrorSpanProps> = (props: ErrorSpanProps) => {
  const { errors, name } = props;

  if (errors[name]) {
    return <small style={errorStyle}>{errors[name]?.message as React.ReactNode}</small>;
  }

  return null;
};

export default ErrorSpan;
