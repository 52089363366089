import { createSlice } from '@reduxjs/toolkit';

interface MenuState {
  open: boolean;
  modal: boolean;
}

const initialState = {
  open: false,
  modal: false,
} as MenuState;

const MenuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    handleMenuOpen(state) {
      state.open = true;
    },
    handleMenuClose(state) {
      state.open = false;
    },
    handleOpenModal(state) {
      state.modal = true;
    },
    handleCloseModal(state) {
      state.modal = false;
    },
  },
});

export const { handleMenuOpen, handleMenuClose, handleOpenModal, handleCloseModal } =
  MenuSlice.actions;
export default MenuSlice.reducer;
