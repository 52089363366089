import { axiosConfiguration } from 'axios/config';
import { API_ROUTES } from 'constants/apiRoutes';
import { Teacher } from 'modules/global-academics/centers/teachers/interfaces';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import Alert from 'UI/molecules/global/alerts';
import { useFetch, UseServiceProps } from 'utils/react-query';
import { pathToUrl } from 'utils/routes';

enum StaffCategories {
  'teachers' = 1,
  'counselors' = 2,
  'case-managers' = 3,
  'academic-advisors' = 4,
  'transition-counselors' = 5,
  'associate-director' = 6,
}

interface Props {
  centerId: number;
  teacherId?: number;
}

export interface SaveTeacherResult {
  key: number;
  succeed: boolean;
  message: string | null;
}

const getTeachers = async (centerId: number) => {
  const { data: response } = await axiosConfiguration.get<Teacher[]>(
    `centers/${centerId}/teachers`,
  );
  return response;
};

const getTeacherById = async (teacherId) => {
  if (teacherId) {
    const { data: response } = await axiosConfiguration.get<Teacher>(`teachers/${teacherId}`);
    return response;
  }

  return;
};

const saveTeacher = async ({ centerId, teacherData }) => {
  const { data: response } = await axiosConfiguration.post(
    `centers/${centerId}/teachers`,
    teacherData,
  );
  return response;
};

const updateTeacher = async ({ centerId, teacherData }) => {
  const { data: response } = await axiosConfiguration.put<SaveTeacherResult>(
    `centers/${centerId}/teachers`,
    teacherData,
  );
  return response;
};

const removeAvatarTeacher = async ({ teacherId }): Promise<void> => {
  if (teacherId) {
    const { data: response } = await axiosConfiguration.delete(`teachers/${teacherId}/avatar`);
    return response;
  }

  return;
};

const uploadAvatarTeacher = async ({ teacherId, teacherData }) => {
  if (teacherId) {
    const { data: response } = await axiosConfiguration.post(
      `teachers/${teacherId}/avatar`,
      teacherData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response;
  }
  return;
};

export const useTeachers = ({ centerId, teacherId }: Props) => {
  const teachersQuery = useQuery(['get-teachers'], () => getTeachers(centerId), {
    staleTime: 120000,
    onError: () => {
      toast.error(<Alert title='Error' subtitle='Error loading events!' />);
    },
  });

  const teacherQuery = useQuery(['get-teacher-by-id', teacherId], () => getTeacherById(teacherId), {
    staleTime: 120000,
    enabled: teacherId ? true : false,
    onError: () => {
      toast.error(<Alert title='Error' subtitle='Error loading teacher!' />);
    },
  });

  const teacherMutation = useMutation(
    (variables: { centerId: number; teacherData: Teacher }) =>
      saveTeacher({ centerId: variables.centerId, teacherData: variables.teacherData }),
    {
      onSuccess: () => {
        teachersQuery.refetch();
        toast.success(<Alert title='Success' subtitle='Teacher created successfully' />);
      },
      onError: () => {
        toast.error(<Alert title='Error' subtitle='Error create teacher!' />);
      },
    },
  );

  const updateTeacherMutation = useMutation(
    (variables: { centerId: number; teacherData: Teacher }) =>
      updateTeacher({ centerId: variables.centerId, teacherData: variables.teacherData }),
    {
      onSuccess: () => {
        teachersQuery.refetch();
        toast.success(<Alert title='Success' subtitle='Teacher updated successfully' />);
      },
      onError: () => {
        toast.error(<Alert title='Error' subtitle='Error to update teacher!' />);
      },
    },
  );

  const removeTeacherAvatarMutation = useMutation(
    (variables: { teacherId: number }) => removeAvatarTeacher({ teacherId: variables.teacherId }),
    {
      onSuccess: () => {
        if (teacherId) {
          teacherQuery.refetch();
        }
        toast.success(<Alert title='Success' subtitle='Was removed successfully' />);
      },
      onError: () => {
        toast.error(<Alert title='Error' subtitle='Error to update teacher!' />);
      },
    },
  );

  const uploadAvatarTeacherMutation = useMutation(
    (variables: { teacherId: number; teacherData: FormData }) =>
      uploadAvatarTeacher({ teacherId: variables.teacherId, teacherData: variables.teacherData }),
    {
      onSuccess: () => {
        teachersQuery.refetch();
        toast.success(
          <Alert title='Success' subtitle='Avatar Profile Picture Uploaded successfully' />,
        );
      },
      onError: () => {
        toast.error(<Alert title='Error' subtitle='Error uploading Avatar Profile Picture' />);
      },
    },
  );

  return {
    teachersQuery,
    teacherQuery,
    teacherMutation,
    updateTeacherMutation,
    removeTeacherAvatarMutation,
    uploadAvatarTeacherMutation,
  };
};

/* TODO: Move to react-query utilities */

export const useTeacher = ({ routeParams, params, config }: UseServiceProps<Teacher>) =>
  useFetch<Teacher[]>(
    pathToUrl(API_ROUTES.STAFF_CATEGORIES, {
      ...routeParams,
      categoryId: StaffCategories.teachers,
    }),
    params,
    config,
  );

export const useCounselors = ({ routeParams, params, config }: UseServiceProps<Teacher>) =>
  useFetch<Teacher[]>(
    pathToUrl(API_ROUTES.STAFF_CATEGORIES, {
      ...routeParams,
      categoryId: StaffCategories.counselors,
    }),
    params,
    config,
  );

export const useCaseManagers = ({ routeParams, params, config }: UseServiceProps<Teacher>) =>
  useFetch<Teacher[]>(
    pathToUrl(API_ROUTES.STAFF_CATEGORIES, {
      ...routeParams,
      categoryId: StaffCategories['case-managers'],
    }),
    params,
    config,
  );

export const useAcademicAdvisors = ({ routeParams, params, config }: UseServiceProps<Teacher>) =>
  useFetch<Teacher[]>(
    pathToUrl(API_ROUTES.STAFF_CATEGORIES, {
      ...routeParams,
      categoryId: StaffCategories['academic-advisors'],
    }),
    params,
    config,
  );

export const useTransitionCounselors = ({
  routeParams,
  params,
  config,
}: UseServiceProps<Teacher>) =>
  useFetch<Teacher[]>(
    pathToUrl(API_ROUTES.STAFF_CATEGORIES, {
      ...routeParams,
      categoryId: StaffCategories['transition-counselors'],
    }),
    params,
    config,
  );
export const useAssociateDirector = ({ routeParams, params, config }: UseServiceProps<Teacher>) =>
  useFetch<Teacher[]>(
    pathToUrl(API_ROUTES.STAFF_CATEGORIES, {
      ...routeParams,
      categoryId: StaffCategories['associate-director'],
    }),
    params,
    config,
  );
