import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { Checkbox, DropDown } from 'components';
import DatePicker from 'components/fields/DatePicker';
import Input from 'components/fields/Input';
import InputDummy from 'components/fields/InputDummy';
import { TransitionModal } from 'modules/client/incident-reporting/dialogs/dialogMessage';
import React, { Dispatch, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  useAlerts,
  useHowInfoWasVerifiedCatalog,
} from 'services/catalogs/alerts/alertsCatalogServices';
import { alertFields } from 'services/client/alerts/alertsServices';
import { shadow } from 'theme/palette';
import DropzoneFile from 'UI/molecules/global/dropzone-pdf';
import { dataToOptions } from 'utils/data-to-options';
import { getAlertLevelColor } from '..';
import { AlertSchemaType } from './container';

enum alertType {
  diet = 'diet(includesfoodallergies)',
  impairment = 'impairment-hearing',
}

interface Props {
  onSubmit: () => void;
  title: string;
  modalState: boolean;
  onClose: () => void;
  isLoading: boolean;
  fileUploadingProgress?: ProgressEvent;
  setFileUploadingProgress?: Dispatch<SetStateAction<ProgressEvent | undefined>>;
  uploadFile: (file) => void;
  setSelectedFile: Dispatch<SetStateAction<FormData | undefined>>;
  defaultFile?: string | null;
}

const AlertForm: React.FC<Props> = ({
  modalState,
  onSubmit,
  onClose,
  isLoading,
  title,
  fileUploadingProgress,
  setSelectedFile,
  defaultFile,
  setFileUploadingProgress,
}) => {
  const { watch } = useFormContext<AlertSchemaType>();
  const alertId = watch(alertFields.alertId);

  const handleFileFormat = (file: File) => {
    const note = new FormData();
    if (alertId !== null) {
      note.append('AlertFile', file);
      note.append('ClientAlertId', watch('id'));
      note.append('AlertTypeId', alertId.toString());
    }

    return {
      note,
    };
  };

  const { alertsCategoryQuery } = useAlerts();
  const howInformationWasVerified = useHowInfoWasVerifiedCatalog();

  const alerts = alertsCategoryQuery.data || [];
  const alertsTypes = dataToOptions(alerts, 'id', 'type');

  const dietId = alertsTypes
    .filter(
      (element) =>
        element.label.toString().toLocaleLowerCase().replace(/\s/g, '') === alertType.diet,
    )
    ?.shift()?.id;

  const impairmentId = alertsTypes
    .filter(
      (element) =>
        element.label.toString().toLowerCase().replace(/\s/g, '') === alertType.impairment,
    )
    ?.shift()?.id;

  const categoryAndLevel = watch(alertFields.alertId)
    ? alerts.filter((element) => element.id === watch(alertFields.alertId))?.shift()
    : null;

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: '10px',
        },
      }}
      scroll={dietId === alertId || impairmentId === alertId ? 'paper' : 'body'}
      fullWidth
      open={modalState}
      onClose={onClose}
      TransitionComponent={TransitionModal}
    >
      <DialogTitle sx={{ px: 5, py: 0 }} mt={3.5} mb={5}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography component='h1' color='darker' variant='h6'>
            {title}
          </Typography>
          <CloseRoundedIcon onClick={onClose} sx={{ ':hover': { cursor: 'pointer' } }} />
        </Box>
      </DialogTitle>
      <DialogContent sx={{ px: 5 }}>
        <Box component='form' id='alertForm' onSubmit={onSubmit}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
              columnGap: 2,
              rowGap: 1,
            }}
          >
            <DropDown
              options={alertsTypes}
              caption='Alert Type'
              numberType
              fieldName={alertFields.alertId}
              placeholder='Select One'
            />
            <InputDummy
              caption='Category'
              defaultValue={categoryAndLevel?.categoryInfo?.attributeName || ''}
              placeholder='Autopopulated from alert type'
            />
            <InputDummy
              caption='Alert Level'
              color={getAlertLevelColor(categoryAndLevel?.levelInfo?.attributeName.toLowerCase())}
              defaultValue={categoryAndLevel?.levelInfo?.attributeName || ''}
              placeholder='Autopopulated from alert type'
            />
            {dietId !== alertId && (
              <Input
                textarea
                multiline
                rows={4}
                caption='Notes/Reactions'
                fieldName={alertFields.notes}
                placeholder='500 Characters Max.'
              />
            )}
          </Box>
          {dietId === alertId && (
            <Box borderRadius={2}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
                  columnGap: 2,
                }}
                mt={2}
              >
                <Box
                  mb={{ xs: 1, md: 0 }}
                  sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', rowGap: 1 }}
                >
                  <Input
                    caption='Food Allergy - type'
                    placeholder='Food Allergy Type'
                    fieldName={'dietAlert.foodAllergyType'}
                  />
                  <Input
                    caption='Action to be taken'
                    placeholder='Action to be taken'
                    fieldName={'dietAlert.actionToBeTaken'}
                  />
                </Box>
                <Input
                  textarea
                  rows={4.5}
                  multiline
                  caption='Notes/Reactions'
                  placeholder='500 Characters Max.'
                  fieldName={'dietAlert.notes'}
                />
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
                  columnGap: 2,
                  rowGap: 1,
                }}
                mt={1}
              >
                <DatePicker
                  caption='Date of verification'
                  placeholder='MM/DD/YYYY'
                  fieldName={'dietAlert.dateOfVerification'}
                />
                <Input
                  caption='verification completed by'
                  placeholder='verification completed by'
                  fieldName={'dietAlert.verificationCompletedBy'}
                />
              </Box>
              <Divider sx={{ my: 2 }} />
              <Typography mb={2} component='h3' color='darker' variant='body1'>
                Date of Close
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
                  columnGap: 2,
                  rowGap: 1,
                }}
              >
                <DatePicker
                  caption='Date Alert Close'
                  placeholder='MM/DD/YYYY'
                  fieldName={'dietAlert.dateAlertClosed'}
                />
                <DropDown
                  options={howInformationWasVerified}
                  caption='How Information Was Verified'
                  placeholder='Select One'
                  fieldName={'dietAlert.howInformationWasVerifiedId'}
                />
                <Input
                  caption='With Whom Verification Was Made'
                  placeholder='With Whom Verification Was Made'
                  fieldName={'dietAlert.withWhomVerificationWasMade'}
                />
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
                  columnGap: 2,
                  rowGap: 1,
                }}
              >
                <Checkbox
                  toggle
                  placement='start'
                  caption='Religious Restrictions?'
                  fieldName={'dietAlert.medicalOrReligiousRestrictions'}
                />
                {watch('dietAlert.medicalOrReligiousRestrictions') && (
                  <Input
                    textarea
                    multiline
                    rows={4}
                    caption='Notes'
                    fieldName='dietAlert.restrictionsNotes'
                    placeholder='500 Characters Max.'
                  />
                )}
              </Box>
            </Box>
          )}

          {impairmentId === alertId && (
            <React.Fragment>
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
                  columnGap: 2,
                  rowGap: 0,
                }}
                mt={1}
              >
                <Checkbox
                  placement='start'
                  fieldName='impairmentAlert.requireMechanicalAid'
                  toggle
                  caption='Does youth require mechanical aid?'
                />
                <Checkbox
                  placement='start'
                  fieldName='impairmentAlert.mechanicalAidWithClient'
                  toggle
                  caption='Is mechanical aid with client?'
                />
                <Checkbox
                  placement='start'
                  fieldName='impairmentAlert.doctorsNoteReviewed'
                  toggle
                  caption='Doctor note reviewed/uploaded?'
                />
                <Checkbox
                  placement='start'
                  fieldName='impairmentAlert.medicalAttentionNeeded'
                  toggle
                  caption='Medical attention needed?'
                />
              </Box>
              {watch('impairmentAlert.doctorsNoteReviewed') && (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
                    columnGap: 2,
                    rowGap: 0,
                  }}
                  mt={1}
                >
                  <div>
                    <Typography mb={1} component='p' color='darker' variant='body2'>
                      Upload Note
                    </Typography>
                    <DropzoneFile
                      extensionAccepted='application/pdf'
                      defaultFile={defaultFile}
                      uploadProgress={fileUploadingProgress}
                      shouldUpdateFile={true}
                      noRemoveButton={!!defaultFile}
                      error={false}
                      stringDefaultFile
                      onSelectFile={(file) => {
                        const { note } = handleFileFormat(file);
                        setSelectedFile(note);
                      }}
                      onChangeFile={async (file) => {
                        const { note } = handleFileFormat(file);
                        if (setFileUploadingProgress) setFileUploadingProgress(undefined);
                        setSelectedFile(note);
                      }}
                    />
                  </div>
                </Box>
              )}
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
                  columnGap: 2,
                  rowGap: 1,
                }}
              >
                <Input
                  multiline
                  rows={4}
                  textarea
                  caption='Limitation'
                  fieldName='impairmentAlert.limitations'
                  placeholder='500 characters max.'
                />
                <Input
                  multiline
                  rows={4}
                  textarea
                  caption='Abilities'
                  fieldName='impairmentAlert.abilities'
                  placeholder='500 characters max.'
                />
              </Box>
            </React.Fragment>
          )}
          <Divider sx={{ my: 2 }} />
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
              columnGap: 2,
              rowGap: 1,
            }}
            mt={1}
          >
            <DatePicker
              fieldName={alertFields.startDate}
              caption='Start Date'
              placeholder='MM/DD/YYYY'
            />
            <DatePicker
              fieldName={alertFields.endDate}
              caption='End Date'
              placeholder='MM/DD/YYYY'
            />
            <Input
              fieldName={alertFields.verifiedBy}
              caption='Verified By'
              placeholder='Verified By'
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          py: 4,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: dietId === alertId || impairmentId === alertId ? shadow[400] : 'none',
        }}
      >
        <Button sx={{ width: '167px' }} onClick={onClose} variant='secondarybtn'>
          Cancel
        </Button>
        <Button sx={{ width: '167px' }} type='submit' form='alertForm' variant='primarybtn'>
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertForm;
