import CommentsFormContainer from './comments/container';
import AdminTicketFormContainer from './ticket/container';

const TicketsAndCommentsWrapper = () => {
  return (
    <>
      <AdminTicketFormContainer />
      <CommentsFormContainer />
    </>
  );
};

export default TicketsAndCommentsWrapper;
