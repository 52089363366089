export enum CATALOGS_NAMES {
  Dosage = 'Dosage',
  Frequency = 'Frequency',
  MedicationType = 'MedicationType',
  Routes = 'Routes',
  DrugsType = 'DrugsType',
  MedicationName = 'MedicationName',
  Participation = 'HealthEducationParticipation',
  Delivery = 'HealthEducationServiceDelivery',
  AlertCategory = 'AlertCategory',
  HowInfoWasVerified = 'HowInfoWasVerified',
  PhoneType = 'PhoneType',
  RelationShipToClient = 'Relationship',
  ContactPreference = 'ContactPreference',
  RiskDomain = 'RiskDomain',
  ImplementationStatus = 'GoalStatus',
  CurrentStageOfChange = 'CurrentStageOfChange',
  LivingSituationAtIntake = 'LivingSituationAtIntake',
  HxOfRunawayBx = 'HxOfRunawayBx',
  ReferralType = 'ReferralType',
  ServiceType = 'ServiceType',
  ContactAttempt = 'ContactAttempt',
  Attendance = 'Attendance',
  RosterStatus = 'RosterStatus',
  AttendanceStatus = 'AttendanceStatus',
  GenderBirth = 'GenderBirth',
  PrimaryLanguage = 'PrimaryLanguage',
  ProgramService = 'ProgramService',
  CommentsRecordType = 'CommentsRecordType',
  DesignationPlan = 'DesignationPlan',
  ProgramType = 'ProgramType',
  // SCHOOL INFORMATION
  DiplomaOptions = 'DiplomaOption',
  SchoolStatus = 'SchoolStatus',
  ESEDesignation = 'ESEDesignation',
  // TRANSITION PLANNING
  PlannedAcademicPlacement = 'PlannedAcademicPlacement',
  PlannedEmploymentPlacement = 'PlannedEmploymentPlacement',
  PlacementDetails = 'PlacementDetails',
  // REFERRAL
  ReferralSource = 'ReferralSource',
  // EMAR
  ReasonMedicationNotAdministered = 'ReasonMedicationNotAdministered',
  // AGENCY COURSE DATABASE
  AgencyCourseFrequency = 'AgencyCourseFrequency',
  // TREATMENT PLANNING
  GoalStatus = 'GoalStatus',
  // TAINING
  CertificationType = 'CertificationType',
  // GROWTH AND CHANGE
  GrowthAndChangeStage = 'GrowthAndChangeStage',
  // STAGE
  STAGE = 'Stage',
  // LEGAL ASSESSMENT
  LEGAL = 'MisdemeanorOrFelonyReferral',
  // LAST TIME EXAM
  PonaLastTimeExam = 'PonaLastTimeExam',
  // Health Issues
  PonaHealthIssues = 'PonaHealthIssues',
  // Nightly Experiences
  PonaNightlyExperiences = 'PonaNightlyExperiences',
  // Daily Meals
  PonaDailyMeals = 'PonaDailyMeals',
  // Fruits in past 7 days
  PonaFruitInPast7Days = 'PonaFruitInPast7Days',
  // Salads in past 7 days
  PonaSaladInPast7Days = 'PonaSaladInPast7Days',
  // Allergies
  PonaAllergies = 'PonaAllergies',
  // Weight Description
  PonaWeightDescription = 'PonaWeightDescription',
  // Weight Control
  PonaMethodsToControlWeight = 'PonaMethodsToControlWeight',
  // Drugs Used
  PonaDrugsUsed = 'PonaDrugsUsed',
  // Menstrual Cycle
  PonaMenstrualCycleDescription = 'PonaMenstrualCycleDescription',
  // Menstrual Problems
  PonaMenstrualProblems = 'PonaMenstrualProblems',
  // Birth Control
  PonaBirthControlType = 'PonaBirthControlType',
  // Sexual Health NA
  PonaSexualHealthNA = 'PonaSexualHealthNA',
  // Lexile Level
  LexileLevel = 'LexileLevel',
  // Risk Factors
  RiskFactors = 'RiskFactors',
}
