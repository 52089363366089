import { Button } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { axiosConfiguration } from 'axios/config';
import { SemesterItemProps } from 'modules/global-academics/centers/academic-setup/interfaces';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import Alert from 'UI/molecules/global/alerts';

interface Props {
  centerId?: number | null;
  yearId?: number;
}

export interface Response {
  key: number;
  succeed: boolean;
  message: string;
}

export interface SemestersByYearId {
  createdDate: string;
  modifiedDate: string;
  id: number;
  semesterName: string;
  startDate: string;
  endDate: string;
  centerId: number;
  academicYearId: number;
}

// Requests
const getSemesters = async (centerId): Promise<SemesterItemProps[]> => {
  const { data } = await axiosConfiguration.get<SemesterItemProps[]>(
    `center/${centerId}/semesters`,
  );
  return data;
};

const getSemesterById = async (centerId, semesterId): Promise<SemesterItemProps[]> => {
  const { data } = await axiosConfiguration.get<SemesterItemProps[]>(
    `center/${centerId}/semester/${semesterId}`,
  );
  return data;
};

const getSemesterByYearId = async (centerId, yearId) => {
  const { data: response } = await axiosConfiguration.get<SemesterItemProps[]>(
    `center/${centerId}/academic-year/${yearId}/semester`,
  );
  return response;
};

const saveSemester = async ({ semesterData }): Promise<void> => {
  if (semesterData.id) {
    await axiosConfiguration.put<SemesterItemProps>('semester', semesterData);
    return;
  }
  await axiosConfiguration.post<SemesterItemProps>('semester', semesterData);
};

const createSemester = async (semesterData) => {
  const method = semesterData.id ? 'put' : 'post';
  const { data: response } = await axiosConfiguration[method]('semester', semesterData);
  return response;
};

// Hooks
export const useSemesters = ({ centerId, yearId }: Props) => {
  const semestersQuery = useQuery(['semesters'], () => getSemesters(centerId), {
    staleTime: 120000,
    enabled: centerId ? true : false,
    onError: (error: unknown) => {
      const errors = error as Error | AxiosError;
      if (axios.isAxiosError(errors)) {
        const errorMessage = errors.response?.data as Response;
        toast(
          (t) => (
            <span>
              <Alert title='Warning !' subtitle={errorMessage.message} />
              <Button color='warning' sx={{ my: 1 }} onClick={() => toast.dismiss(t.id)}>
                Dismiss
              </Button>
            </span>
          ),
          {
            duration: 10000,
          },
        );
      } else {
        toast.error(<Alert title='Error' subtitle='Error to load semesters' />);
      }
    },
  });

  const semestersQueryByYear = useQuery(
    ['semesters-by-yearId', yearId],
    () => getSemesterByYearId(centerId, yearId),
    {
      enabled: yearId ? true : false,
      onError: (error: unknown) => {
        const errors = error as Error | AxiosError;
        if (axios.isAxiosError(errors)) {
          const errorMessage = errors.response?.data as Response;
          toast(
            (t) => (
              <span>
                <Alert title='Warning !' subtitle={errorMessage.message} />
                <Button color='warning' sx={{ my: 1 }} onClick={() => toast.dismiss(t.id)}>
                  Dismiss
                </Button>
              </span>
            ),
            {
              duration: 10000,
            },
          );
        } else {
          toast.error(<Alert title='Error' subtitle={errors.message} />);
        }
      },
    },
  );

  const createSemesterMutation = useMutation(
    (variables: { yearId: number; semesterData: SemesterItemProps }) =>
      createSemester(variables.semesterData),
    {
      onSuccess: () => {
        semestersQueryByYear.refetch();
        toast.success(<Alert title='Success' subtitle='Semester Created Successfully' />);
      },
      onError: (error: unknown) => {
        const errors = error as Error | AxiosError;
        if (axios.isAxiosError(errors)) {
          const errorMessage = errors.response?.data as Response;
          toast(
            (t) => (
              <span>
                <Alert title='Warning !' subtitle={errorMessage.message} />
                <Button color='warning' sx={{ my: 1 }} onClick={() => toast.dismiss(t.id)}>
                  Dismiss
                </Button>
              </span>
            ),
            {
              duration: 10000,
            },
          );
        } else {
          toast.error(<Alert title='Error' subtitle={errors.message} />);
        }
      },
    },
  );

  const semestersMutation = useMutation(
    (variables: { semesterData: SemesterItemProps }) =>
      saveSemester({ semesterData: variables.semesterData }),
    {
      onSettled(error) {
        if (error) {
          toast.error(<Alert title='Error' subtitle='Error saving semester!' />);
          return;
        }
        toast.success(<Alert title='Success' subtitle='Semester was saved successfully!' />);
        semestersQuery.refetch();
      },
    },
  );

  return {
    semestersQuery,
    semestersMutation,
    semestersQueryByYear,
    createSemesterMutation,
  };
};

export const useSemester = ({ centerId, semesterId }) => {
  const semesterQuery = useQuery(['semester', semesterId], () =>
    getSemesterById(centerId, semesterId),
  );
  return {
    semesterQuery,
  };
};
