/* hooks */
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
/* components */
import { Avatar, Box, Button, Container, Grid, Typography } from '@mui/material';
/* icons */
import BellIcon from 'assets/images/svg/bell-icon.svg';
import useScrollPosition from 'hooks/scrollposition';
import React from 'react';
/* redux */
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useHero } from 'services/client/client-hero';
import { AppDispatch, RootState } from 'store';
import { handleOpenModal } from 'store/slices/menu';
/* utils */
import { dark, grey, light, purple, shadow, yellow } from 'theme/palette';
import { useRandomColorAvatar } from 'UI/organisms/tasks/taskCard/hooks';

type Props = {
  inputID: string;
  treatmentButton?: JSX.Element | boolean;
  genericButton?: JSX.Element | boolean;
  notSaveButton?: boolean;
  isLoading?: boolean;
  isUpdating?: boolean;
  onCancel?: () => void;
  noCancelButton?: boolean;
};

const TopHeaderBar: React.FC<Props> = ({
  inputID,
  treatmentButton,
  notSaveButton,
  isLoading,
  isUpdating,
  genericButton,
  onCancel,
  noCancelButton,
}) => {
  const { id: clientID } = useParams();

  const { heroQuery } = useHero({ clientId: Number(clientID) });
  const { data } = heroQuery;

  const navigate = useNavigate();
  const { position } = useScrollPosition();
  const { open } = useSelector((state: RootState) => state.menu);
  const dispatch = useDispatch<AppDispatch>();

  const openModal = () => {
    dispatch(handleOpenModal());
  };

  if (heroQuery.status === 'loading') {
    return (
      <React.Fragment>
        {!open ? (
          <Box
            component='div'
            boxShadow={shadow[400]}
            bgcolor={light[100]}
            display='flex'
            width='100%'
            position='fixed'
            justifyContent='flex-start'
            alignItems='center'
            zIndex={99}
            top={{ xs: '70px', md: '80px' }}
            height='60px'
            left='0px'
          >
            <Typography component='h3' color={dark[200]} variant='body1'>
              Loading information...
            </Typography>
          </Box>
        ) : null}
      </React.Fragment>
    );
  }

  const goToClients = () => navigate('/client/search');

  return (
    <React.Fragment>
      {!open && (
        <Box
          component='div'
          boxShadow={shadow[400]}
          bgcolor={light[100]}
          display='flex'
          width='100%'
          position='fixed'
          zIndex={99}
          top={{ xs: '70px', md: '80px' }}
          left='0px'
          padding={1}
        >
          <Container sx={{ px: { xs: 0, sm: 0, md: 0 } }} maxWidth='xl' component='div'>
            <Box
              display='flex'
              flexDirection={{ xs: 'row', sm: 'row' }}
              width='100%'
              component='div'
            >
              <Box
                width={{
                  xs: '100%',
                  md: 'calc(100% - 270px)',
                }}
              >
                <Grid display={position ? 'flex' : 'none'} component='div' container>
                  <Box
                    mr={{ xs: 0, md: 1.5, lg: 1.8 }}
                    px={{ xs: 0, md: 1 }}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    component='div'
                    maxWidth={{
                      xs: '70%',
                      md: '270px',
                      lg: '270px',
                    }}
                    width={{
                      xs: '200px',
                      md: '270px',
                      lg: '270px',
                    }}
                  >
                    <Box
                      component='div'
                      width={{ xs: '100%', md: '100%' }}
                      display={'flex'}
                      flexDirection='row'
                      justifyContent={{ xs: 'flex-start', md: 'flex-start' }}
                      alignItems='center'
                    >
                      <Box component='div' mr={{ xs: 2, md: 4 }}>
                        {data?.photoUrl && data?.photoUrl !== null ? (
                          <React.Fragment>
                            <Avatar src={data?.photoUrl} />
                          </React.Fragment>
                        ) : (
                          <Avatar
                            sx={{
                              boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.14)',
                              bgcolor: data?.firstName
                                ? useRandomColorAvatar(data?.firstName)
                                : 'none',
                            }}
                          >
                            <Typography component='h3' letterSpacing={-1} variant='body1'>
                              {`${data?.firstName.split(' ')[0][0]}`}{' '}
                              {data?.lastName ? `${data?.lastName.split(' ')[0][0]}` : null}
                            </Typography>
                          </Avatar>
                        )}
                      </Box>
                      <Box width='100%'>
                        <Typography
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          textAlign='left'
                          component='h3'
                          color={dark[200]}
                          variant='body1'
                          fontWeight={600}
                        >
                          {data?.firstName}
                        </Typography>
                        <Typography
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          textAlign='left'
                          component='p'
                          color={grey[400]}
                          variant='body2'
                        >
                          {`${data?.middleName !== null ? data?.middleName : ''}  ${
                            data?.lastName !== null ? data?.lastName : ''
                          }`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Grid
                    mr={{ xs: 0, md: 1, lg: 1 }}
                    pr={0.5}
                    component='div'
                    display={{ xs: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
                    item
                    xs={0}
                    md='auto'
                    lg='auto'
                  >
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      height='100%'
                      flexDirection='row'
                      sx={{
                        ['@media (max-width:1152px)']: {
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        },
                      }}
                      component='div'
                    >
                      <Typography mr={0.5} component='p' color={dark[200]} variant='body2'>
                        ID:
                      </Typography>
                      <Typography component='p' color={purple[100]} variant='body2'>
                        {data?.id}
                      </Typography>
                    </Box>
                  </Grid>
                  {data?.preferredName && (
                    <Grid
                      px={0.5}
                      component='div'
                      item
                      xs={0}
                      sx={{ display: { xs: 'none', md: 'flex' } }}
                      md='auto'
                      lg='auto'
                    >
                      <Box
                        display='flex'
                        alignItems='center'
                        height='100%'
                        flexDirection='row'
                        component='div'
                        sx={{
                          ['@media (max-width:1152px)']: {
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          },
                        }}
                      >
                        <Typography mr={0.5} component='p' color={dark[200]} variant='body2'>
                          Preferred Name:
                        </Typography>
                        <Typography component='p' color={purple[100]} variant='body2'>
                          {data?.preferredName}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {data?.status && (
                    <Grid
                      px={0.5}
                      mx={{ xs: 0, md: 1, lg: 1 }}
                      component='div'
                      item
                      xs={0}
                      sx={{ display: { xs: 'none', md: 'flex' } }}
                      md='auto'
                      lg='auto'
                    >
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        height='100%'
                        flexDirection='row'
                        component='div'
                        sx={{
                          ['@media (max-width:1152px)']: {
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          },
                        }}
                      >
                        <Typography mr={0.5} component='p' color={dark[200]} variant='body2'>
                          Status:
                        </Typography>
                        <Typography
                          component='p'
                          color={purple[100]}
                          variant='body2'
                          fontWeight={600}
                        >
                          {data?.status}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {data?.centerName && (
                    <Grid
                      px={0.5}
                      component='div'
                      item
                      xs={0}
                      sx={{ display: { xs: 'none', md: 'flex' } }}
                      md='auto'
                      lg='auto'
                    >
                      <Box
                        display='flex'
                        alignItems='center'
                        height='100%'
                        flexDirection='row'
                        component='div'
                        sx={{
                          ['@media (max-width:1152px)']: {
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          },
                        }}
                      >
                        <Typography mr={0.5} component='p' color={dark[200]} variant='body2'>
                          Center:
                        </Typography>
                        <Typography component='p' color={purple[100]} variant='body2'>
                          {data?.centerName}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {data?.alerts && data?.alerts.length > 0 && (
                    <Grid
                      component='div'
                      item
                      xs={3}
                      sx={{ display: { xs: 'none', md: 'flex' } }}
                      md='auto'
                      lg='auto'
                    >
                      <Box
                        mx={{ lg: 1 }}
                        component='div'
                        display='flex'
                        height='100%'
                        width='100%'
                        justifyContent='flex-start'
                        alignItems='center'
                      >
                        <Box
                          onClick={() => openModal()}
                          bgcolor={yellow[100]}
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                          borderRadius='6px'
                          width='30px'
                          height='30px'
                          component='div'
                          sx={{
                            ':hover': {
                              opacity: 0.8,
                              cursor: 'pointer',
                            },
                          }}
                        >
                          <Box
                            component='img'
                            width='20px'
                            height='20px'
                            src={BellIcon}
                            alt='bell-icon'
                          />
                        </Box>
                        <Typography ml={0.5} component='p' color={yellow[100]} variant='caption'>
                          Alerts ({data?.alerts.length})
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box
                component='div'
                mt={{
                  xs: 1,
                  md: 0,
                }}
                display='flex'
                alignItems='center'
                justifyContent='flex-end'
                width={{
                  xs: '50%',
                  md: '270px',
                }}
              >
                {!noCancelButton && (
                  <Button variant='secondarybtn' onClick={onCancel || goToClients}>
                    Cancel
                  </Button>
                )}
                {treatmentButton && treatmentButton}
                {genericButton && genericButton}
                {notSaveButton && notSaveButton === true ? null : (
                  <Button
                    startIcon={
                      <SaveRoundedIcon
                        sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' } }}
                      />
                    }
                    type='submit'
                    form={inputID}
                    sx={{ ml: 1, px: 2.2 }}
                    variant='primarybtn'
                  >
                    {isUpdating ? 'Updating...' : isLoading ? 'Loading Info...' : 'Save'}
                  </Button>
                )}
              </Box>
            </Box>
          </Container>
        </Box>
      )}
    </React.Fragment>
  );
};

export default TopHeaderBar;
