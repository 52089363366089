import { axiosConfiguration } from 'axios/config';
import { toast } from 'react-hot-toast';
import { useQuery } from 'react-query';
import Alert from 'UI/molecules/global/alerts';

export interface Catalog {
  id: number;
  name: string;
  internalName: string;
  createdDate: Date;
  modifiedDate: Date;
  attributes: Attribute[];
}

export interface Attribute {
  id: string;
  attributeName: string;
  createdDate: Date;
  attributeValue: string | null;
  modifiedDate: Date;
}

interface Props {
  internalName: string;
  isAssessmentCatalog?: boolean;
}

export const getCatalogByInternalName = async ({
  internalName,
  isAssessmentCatalog,
}): Promise<Catalog> => {
  const { data } = await axiosConfiguration.get<Catalog>(
    `${isAssessmentCatalog ? 'assessment-' : ''}catalogs/details?internalName=${internalName}`,
  );
  return data;
};

export const getAssessmentCatalogByInternalName = async ({ internalName }): Promise<Catalog> => {
  const { data } = await axiosConfiguration.get<Catalog>(
    `assessment-catalogs/details?internalName=${internalName}`,
  );
  return data;
};

export const useCatalogs = ({ internalName, isAssessmentCatalog }: Props) => {
  const catalogsQuery = useQuery(
    ['catalogs', internalName],
    () => getCatalogByInternalName({ internalName, isAssessmentCatalog }),
    {
      staleTime: Infinity,
      onSettled(data, error) {
        if (error) {
          toast.error(<Alert title='Error' subtitle={`Error loading ${internalName}!`} />);
        }
      },
    },
  );
  return {
    catalogsQuery,
  };
};

export const useAssessmentCatalogs = ({ internalName }: Props) => {
  const catalogsQuery = useQuery(
    ['assessmentCatalogs', internalName],
    () => getAssessmentCatalogByInternalName({ internalName }),
    {
      staleTime: Infinity,
      onSettled(data, error) {
        if (error) {
          toast.error(<Alert title='Error' subtitle={`Error loading ${internalName}!`} />);
        }
      },
    },
  );
  return {
    catalogsQuery,
  };
};
