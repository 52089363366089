import { Box, Card, CardContent, styled } from '@mui/material';
import { grey, shadow } from 'theme/palette';

export const BoxShadowed = styled(Box)({
  boxShadow: shadow[100],
  borderRadius: '10px',
  gap: '12px',
  display: 'flex',
  padding: '40px 44px 40px 44px',
  border: `1px solid ${grey[300]}`,
});

export const CardShadowed = styled(Card)({
  boxShadow: shadow[100],
  borderRadius: '10px',
  gap: '12px',
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 44px 40px 44px',
  border: `1px solid ${grey[300]}`,
});

export const CardContentBordered = styled(CardContent)({
  border: `1px solid ${grey[300]}`,
  borderRadius: '10px',
  padding: '12px 21px 12px 12px !important',
});

export const CardContentBorderedForAction = styled(CardContent)({
  border: `1px solid ${grey[300]}`,
  borderRadius: '10px',
  padding: '25px 25px 25px 25px !important',
});
