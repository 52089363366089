import { axiosConfiguration } from 'axios/config';
import { API_ROUTES } from 'constants/apiRoutes';
import fileDownload from 'js-file-download';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { ClientHeroInterface } from 'types/client/client-hero';
import Alert from 'UI/molecules/global/alerts';
import { useFetch, UseServicePropsSingle } from 'utils/react-query';
import { pathToUrl } from 'utils/routes';

export type FormInputs = {
  File: FileList;
};

interface Params {
  clientId: number;
  body: FormInputs;
}

interface Param {
  clientId: number;
}

export const fetchHeroData = async (clientId: number) => {
  const { data: response } = await axiosConfiguration.get<ClientHeroInterface>(
    `client-header/${clientId}`,
  );
  return response;
};

const uploadPhoto = async ({ clientId, body }: Params) => {
  const informationPhoto = new FormData();
  informationPhoto.append('File', body.File[0]);
  const { data: response } = await axiosConfiguration.post(
    `client/${clientId}/add-photo`,
    informationPhoto,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response;
};

const removePhoto = async (clientId: number) => {
  const { data: response } = await axiosConfiguration.delete(
    `client/${Number(clientId)}/delete-photo`,
  );
  return response;
};

const downloadProfileAvatar = async (clientId: number) => {
  const { data: response } = await axiosConfiguration.get(
    `client/${Number(clientId)}/download-photo`,
    {
      responseType: 'blob',
    },
  );
  return response;
};

export const useHero = ({ clientId }: Param) => {
  const heroQuery = useQuery(['get-hero-info', clientId], () => fetchHeroData(clientId));

  const uploadPhotoHeroMutation = useMutation(
    (variables: { clientId: number; body: FormInputs }) =>
      uploadPhoto({ clientId: variables.clientId, body: variables.body }),
    {
      onSuccess: () => {
        heroQuery.refetch();
        toast.success(
          <Alert
            title='Success'
            subtitle='The image was uploaded successfully, wait a moment to see reflect the change on the profile'
          />,
        );
      },
      onError: () => {
        <Alert
          title='Error'
          subtitle='An error occurred while trying to upload the Avatar Profile'
        />;
      },
    },
  );

  const removePhotoHeroMutation = useMutation(
    (variables: { clientId: number }) => removePhoto(variables.clientId),
    {
      onSuccess: () => {
        heroQuery.refetch();
        toast.success(<Alert title='Success' subtitle='Photo Avatar was deleted successfully' />);
      },
      onError: () => {
        <Alert
          title='Error'
          subtitle='An error occurred while trying to delete the Avatar Profile'
        />;
      },
    },
  );

  const downloadProfileAvatarPhotoHeroMutation = useMutation(
    (variables: { clientId: number }) => downloadProfileAvatar(variables.clientId),
    {
      onSuccess: (file) => {
        const fileExtensionName =
          heroQuery.data?.photoUrl && heroQuery.data?.photoUrl.split('.').pop();

        if (!fileExtensionName) {
          toast.error(<Alert title='Error' />);
        }

        fileDownload(file, `profile.${fileExtensionName}`);
      },
      onError: () => {
        <Alert
          title='Error'
          subtitle='An error occurred while trying to download the Avatar Profile'
        />;
      },
    },
  );
  return {
    heroQuery,
    uploadPhotoHeroMutation,
    removePhotoHeroMutation,
    downloadProfileAvatarPhotoHeroMutation,
  };
};

/* @TODO: Convert all services to new utilities */
export const useHeader = ({
  routeParams,
  params,
  config,
}: UseServicePropsSingle<ClientHeroInterface>) =>
  useFetch<ClientHeroInterface>(pathToUrl(API_ROUTES.CLIENT_HEADER, routeParams), params, config);
