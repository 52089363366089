/* eslint-disable react/prop-types */
import { Avatar, Badge, Box, Typography } from '@mui/material';
import { useRandomColorAvatar } from 'UI/organisms/tasks/taskCard/hooks';
import CameraIcon from 'assets/images/svg/camera-icon.svg';
import React, { useState } from 'react';
import { grey, light, purple, shadow } from 'theme/palette';
import ModalForm from 'components/ModalForm';
import AvatarForm from './Modal/form';
import { useDeleteCenterPhoto, useUploadPhoto } from 'services/center';

type Props = {
  dynamicData: any;
  centerId?: number | string;
  agencyId?: number | string;
};

const UpdateAvatarPhoto: React.FC<Props> = (props) => {
  const { dynamicData, centerId, agencyId } = props;
  const [avatarModal, setOpenAvatarModal] = useState<boolean>(false);

  const { mutateAsync: uploadPhoto, isLoading: isUploading } = useUploadPhoto({
    routeParams: { id: agencyId, centerId: centerId },
    config: { enabled: !!agencyId },
  });

  const { mutateAsync: deletePhoto, isLoading: isRemoving } = useDeleteCenterPhoto({
    routeParams: { id: agencyId, centerId: centerId },
  });

  const onSave = async (file) => {
    const profileFile = new FormData();
    profileFile.append('profileFile', file);
    const method = uploadPhoto;

    method(profileFile, {
      onSuccess: () => closeModal(),
    });
  };

  const handleRemovePhoto = async () => {
    const fileTypes = new FormData();
    fileTypes.append('fileTypes', 'ProfilePhoto');
    const method = deletePhoto;

    method(fileTypes, {
      onSuccess: () => closeModal(),
    });
  };

  const openModal = () => {
    setOpenAvatarModal(true);
  };

  const closeModal = () => {
    setOpenAvatarModal((current) => !current);
  };

  return (
    <>
      <Badge
        overlap='circular'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <Avatar
            onClick={openModal}
            sx={{
              width: 26,
              height: 26,
              cursor: 'pointer',
              bgcolor: purple[100],
              border: '2px solid white',
            }}
          >
            <Box component='img' src={CameraIcon} alt='camera-icon' />
          </Avatar>
        }
      >
        {dynamicData.profilePhotoURL ? (
          <Avatar
            sx={{
              height: 70,
              width: 70,
              bgcolor: light[100],
              borderRadius: 2,
              boxShadow: shadow[200],
              color: grey[800],
            }}
            src={dynamicData.profilePhotoURL}
          />
        ) : (
          <Avatar
            sx={{
              height: 70,
              width: 70,
              bgcolor: light[100],
              borderRadius: 2,
              boxShadow: shadow[200],
              color: grey[800],
            }}
            variant='square'
          >
            <Typography
              component='h3'
              color={useRandomColorAvatar(dynamicData?.name)}
              variant='h4'
            >{`${dynamicData?.name.split(' ')[0][0]}`}</Typography>
          </Avatar>
        )}
      </Badge>
      <ModalForm open={avatarModal} onClose={closeModal}>
        <AvatarForm
          dynamicData={dynamicData}
          saveAvatar={onSave}
          removeAvatar={handleRemovePhoto}
          isLoading={isUploading || isRemoving}
          onClose={closeModal}
        />
      </ModalForm>
    </>
  );
};

export default UpdateAvatarPhoto;
