import { NotificationType } from 'services/notifications';
import { create } from 'zustand';

type notificationsState = {
  notifications: NotificationType[];
  unreadCounter: number;
  addNotifications: (notification: NotificationType[]) => void;
  readNotification: (id: number, updateState: boolean) => void;
  clearNotifications: () => void;
  updateUnreadCounter: (newCounter) => void;
};

export const useNotificationStore = create<notificationsState>((set) => ({
  notifications: [],
  unreadCounter: 0,
  addNotifications: (newNotifications) =>
    set((state) => {
      const notificationsSet = new Set(
        [...state.notifications, ...newNotifications].map((item) => JSON.stringify(item)),
      );
      const mergedNotifications = Array.from(notificationsSet).map((item) => JSON.parse(item));
      return { notifications: mergedNotifications };
    }),
  readNotification: (id, updateState) =>
    set((state) => ({
      notifications: updateState
        ? state.notifications.filter((notification) => notification.id !== id)
        : state.notifications.map((notification) =>
            notification.id === id ? { ...notification, active: false } : notification,
          ),
    })),
  clearNotifications: () => set({ notifications: [] }),
  updateUnreadCounter: (newCounter) => set(() => ({ unreadCounter: newCounter })),
}));
