/* eslint-disable camelcase */
import { API_ROUTES } from 'constants/apiRoutes';
import { PaginatedsearchResponse } from 'services/client/clients';
import {
  getPaginated,
  PaginatedResult,
  useDelete,
  useFetch,
  usePost,
  usePostFD,
  usePut,
  UseServiceProps,
  UseServicePropsSingle,
} from 'utils/react-query';
import { pathToUrl } from 'utils/routes';
import { z } from 'zod';

const SERVICE_NAME = 'Staff Members';

export enum fields {
  id = 'id',
  centerId = 'centerId',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  jobCategoryId = 'jobCategoryId',
  jobCategoryInfo = 'jobCategoryInfo',
  jobTitleId = 'jobTitleId',
  jobTitleInfo = 'jobTitleInfo',
  activeStatus = 'activeStatus',
  profilePhotoURL = 'profilePhotoURL',
  roles = 'roles',
  roleId = 'roleId',
  centerName = 'centerName',
  centerInfo = 'centerInfo',
  memberSince = 'memberSince',
  jobTitle = 'jobTitle',
  status = 'status',
  supervisorId = 'supervisorId',
  isSupervisor = 'isSupervisor',
  fullName = 'fullName',
  jobTitleName = 'jobTitleName',

  pageNumber = 'pageNumber',
  pageSize = 'pageSize',
}

export const JobTitleSchema = z.object({
  jobCategoryName: z.string().nullable(),
  id: z.number(),
  jobCategoryId: z.number(),
  titleName: z.string(),
  isActive: z.boolean(),
});

export const JobCategorySchema = z.object({
  id: z.number(),
  agencyId: z.number(),
  categoryName: z.string(),
  description: z.string(),
});

export const CenterInfoSchema = z.object({
  agencyId: z.number(),
  id: z.number(),
  name: z.string(),
});

export const RolSchema = z.union([
  z.object({
    id: z.number(),
    rolId: z.number(),
    rolName: z.string(),
  }),
  z.number(),
]);

export const StaffMembersSchema = z
  .object({
    [fields.id]: z.number().optional(),
    [fields.centerId]: z.number().optional(),
    [fields.centerName]: z.string().optional(),
    [fields.centerInfo]: CenterInfoSchema.optional(),
    [fields.memberSince]: z.string().optional(),
    [fields.jobTitle]: z.string().optional(),
    [fields.status]: z.string().optional(),
    [fields.firstName]: z.string().trim().min(1, { message: 'Required' }),
    [fields.lastName]: z.string().trim().min(1, { message: 'Required' }),
    [fields.email]: z.string().email().trim().min(1, { message: 'Required' }),
    [fields.jobCategoryId]: z.number().positive({ message: 'Required' }),
    [fields.jobCategoryInfo]: JobCategorySchema.optional(),
    [fields.jobTitleId]: z.number().positive({ message: 'Required' }),
    [fields.jobTitleInfo]: JobTitleSchema.optional(),
    [fields.activeStatus]: z.boolean({ invalid_type_error: 'Required' }).optional(),
    [fields.profilePhotoURL]: z.string().optional().nullable(),
    [fields.roles]: z.array(RolSchema),
    [fields.supervisorId]: z.number().optional().nullable(),
    [fields.isSupervisor]: z.boolean().optional(),
  })
  .superRefine((data, ctx) => {
    const { roles } = data;
    if (!roles?.includes(1) && !data[fields.supervisorId])
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Required',
        path: [fields.supervisorId],
      });
    if (roles?.length < 1)
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Required',
        path: [fields.roles],
      });
  });

export const StaffSupervisorsSchema = z.object({
  [fields.id]: z.number().optional(),
  [fields.fullName]: z.string().optional(),
  [fields.jobTitleId]: z.number().optional(),
  [fields.jobTitleName]: z.string().optional(),
  [fields.centerId]: z.number().optional(),
  [fields.centerName]: z.string().optional(),
});

export type StaffMembersType = z.infer<typeof StaffMembersSchema>;
export type StaffSupervisorsType = z.infer<typeof StaffSupervisorsSchema>;
export type PaginatedStaffMembersBySupervisor = PaginatedResult<StaffMembersType>;
type sessionStaffMember = {
  id: number;
};
export const useStaffMember = () =>
  useFetch<sessionStaffMember>(pathToUrl(API_ROUTES.STAFF_MEMBERS));

export const useStaffMembersByCenter = ({
  routeParams,
  params,
  config,
}: UseServiceProps<StaffMembersType>) =>
  useFetch<StaffMembersType[]>(
    pathToUrl(API_ROUTES.STAFF_MEMBERS_BY_CLIENT, routeParams),
    params,
    config,
  );

export const useStaffMemberHeader = ({
  routeParams,
  params,
  config,
}: UseServicePropsSingle<StaffMembersType>) =>
  useFetch<StaffMembersType>(
    pathToUrl(API_ROUTES.STAFF_MEMBER_HEADER_BY_ID, routeParams),
    params,
    config,
  );

export const useStaffMembersByAgency = ({
  routeParams,
  params,
  config,
}: UseServiceProps<StaffMembersType>) =>
  useFetch<StaffMembersType[]>(
    pathToUrl(API_ROUTES.STAFF_MEMBERS_BY_AGENCY, routeParams),
    params,
    config,
  );

export const useAddStaffMember = ({ routeParams }: UseServiceProps<StaffMembersType>) =>
  usePost<StaffMembersType[], StaffMembersType>(
    pathToUrl(API_ROUTES.STAFF_MEMBERS, routeParams),
    undefined,
    SERVICE_NAME,
    pathToUrl(API_ROUTES.STAFF_MEMBERS_BY_CLIENT, routeParams),
  );

export const useUpdateStaffMember = ({ routeParams }: UseServiceProps<StaffMembersType>) =>
  usePut<StaffMembersType[], StaffMembersType>(
    pathToUrl(API_ROUTES.STAFF_MEMBERS, routeParams),
    undefined,
    SERVICE_NAME,
    pathToUrl(API_ROUTES.STAFF_MEMBERS_BY_CLIENT, routeParams),
  );

export const useStaffMemberById = ({
  routeParams,
  params,
  config,
}: UseServicePropsSingle<StaffMembersType>) =>
  useFetch<StaffMembersType>(
    pathToUrl(API_ROUTES.STAFF_MEMBERS_BY_ID, routeParams),
    params,
    config,
  );

export const useAddStaffMemberAvatar = ({ routeParams }: UseServiceProps<StaffMembersType>) =>
  usePostFD<StaffMembersType, FormData>(
    pathToUrl(API_ROUTES.STAFF_MEMBERS_AVATAR, routeParams),
    undefined,
    SERVICE_NAME,
    pathToUrl(API_ROUTES.STAFF_MEMBER_HEADER_BY_ID, routeParams),
  );

export const useDeleteStaffMemberAvatar = ({ routeParams }: UseServiceProps<StaffMembersType>) =>
  useDelete(
    pathToUrl(API_ROUTES.STAFF_MEMBERS_AVATAR, routeParams),
    undefined,
    SERVICE_NAME,
    pathToUrl(API_ROUTES.STAFF_MEMBER_HEADER_BY_ID, routeParams),
  );

export const useStaffSupervisorsByCenter = ({
  routeParams,
  params,
  config,
}: UseServiceProps<StaffSupervisorsType>) =>
  useFetch<StaffSupervisorsType[]>(
    pathToUrl(API_ROUTES.STAFF_SUPERVISORS_BY_CENTER, routeParams),
    params,
    config,
  );

export const useStaffMembersBySupervisor = ({
  routeParams,
  params,
  config,
}: UseServicePropsSingle<PaginatedStaffMembersBySupervisor>) => {
  const { data, ...res } = useFetch<PaginatedStaffMembersBySupervisor>(
    pathToUrl(API_ROUTES.STAFF_MEMBERS_BY_SUPERVISOR, routeParams),
    params,
    config,
  );

  return {
    data: getPaginated(data),
    ...res,
  };
};

export const useSearchAllStaffMembers = ({
  routeParams,
  params,
  config,
}: UseServicePropsSingle<PaginatedsearchResponse>) => {
  const { data, ...res } = useFetch<PaginatedsearchResponse>(
    pathToUrl(API_ROUTES.STAFF_MEMBERS_SEARCH_ALL, routeParams),
    params,
    config,
  );

  return {
    data: getPaginated(data),
    ...res,
  };
};

export const useSearchAllStaffMembersByRole = ({
  routeParams,
  params,
  config,
}: UseServiceProps<StaffMembersType>) =>
  useFetch<StaffMembersType[]>(
    pathToUrl(API_ROUTES.STAFF_MEMEBRS_BY_ROLE, routeParams),
    params,
    config,
  );
