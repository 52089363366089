import AddRounded from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Box, Button, Grid, InputBase, MenuItem, Select, Typography } from '@mui/material';
import { dark } from '@mui/material/styles/createPalette';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { CustomCalendar } from 'components/fields/DatePicker';
import moment from 'moment';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSemesters } from 'services/global-academics/academic-setup/semestersService';
import { grey } from 'theme/palette';
import { errorStyle, inputStyle, selectStyle } from 'theme/styles/inputs';
import { CardContentBorderedForAction } from 'UI/molecules/global/cards';
import { AddBlockFormProps, BlockFormFields } from '../interfaces';

const SaveBlock: React.FC<AddBlockFormProps> = ({
  data,
  academicYears,
  isSaving,
  onAddBlock,
  onEditBlock,
  onCancel,
  centerId,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm<BlockFormFields>({ defaultValues: data });

  const yearId = watch('yearid');
  const { semestersQueryByYear } = useSemesters({ centerId, yearId: yearId });
  const semesters = semestersQueryByYear.data || [];
  const hasSemesters = semesters.length > 0;
  const { academicYearId } = data?.semesterInfo || {};

  React.useEffect(() => {
    if (academicYearId) setValue('yearid', academicYearId);
    else {
      const defaultYear = academicYears.find((year) => year.isDefault);
      if (defaultYear) setValue('yearid', Number(defaultYear.id));
    }
  }, [academicYearId]);

  const onSubmitBlock = (formData) => {
    if (isSaving) return;

    const formBody = {
      ...formData,
      startDate: moment(formData.startDate).format('YYYY-MM-DD'),
    };

    if (!data) {
      delete formBody.id;
      onAddBlock && onAddBlock(formBody);
      return;
    }

    onEditBlock && onEditBlock(formBody);
    return;
  };

  const getFormTitle = () => {
    if (!data) {
      return (
        <>
          <AddRounded color='primary' fontWeight={700} style={{ fontSize: '16px' }} />
          <Typography fontSize='14px' fontWeight={500} color='primary'>
            Create a new Block
          </Typography>
        </>
      );
    }
    return (
      <>
        <EditRoundedIcon fontWeight={700} color='primary' style={{ fontSize: '14px' }} />
        <Typography fontSize='14px' fontWeight={500} color='primary'>
          Edit Block
        </Typography>
      </>
    );
  };
  return (
    <CardContentBorderedForAction>
      <Box component='form' onSubmit={handleSubmit(onSubmitBlock)}>
        <Box
          component='div'
          display='flex'
          alignItems='center'
          style={{ cursor: 'pointer' }}
          gap='4px'
          marginBottom='21px'
        >
          {getFormTitle()}
        </Box>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Box component='div' mb={1} pr={{ xs: 0, md: 1 }}>
              <Typography component='p' color={dark[200]} variant='body2'>
                Block Name
                {errors.blockName && <small style={errorStyle}>{errors.blockName.message}</small>}
              </Typography>
              <Controller
                name='blockName'
                control={control}
                defaultValue=''
                rules={{ required: { value: true, message: 'Required' } }}
                render={({ field: { onChange, value, ref } }) => (
                  <InputBase
                    onChange={onChange}
                    value={value || ''}
                    inputRef={ref}
                    disabled={isSaving}
                    fullWidth
                    sx={inputStyle}
                    placeholder='Block Name'
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box component='div' mb={1} pr={{ xs: 0, md: 1 }}>
              <Typography component='p' color={dark[200]} variant='body2' mb={1}>
                Start Date {errors.startDate && <small style={errorStyle}>Required</small>}
              </Typography>
              <Controller
                name='startDate'
                control={control}
                rules={{ required: { value: true, message: 'Required' } }}
                render={({ field: { onChange, ref, value } }) => (
                  <DatePicker
                    calendar={CustomCalendar}
                    onChange={onChange}
                    ref={ref}
                    disabled={isSaving}
                    value={(value && moment(value).toDate()) || null}
                    placeholder='MM/DD/YYYY'
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box component='div' mb={1}>
              <Typography component='p' color={dark[200]} variant='body2'>
                Academic Year
                {errors.yearid && <small style={errorStyle}>{errors.yearid.message}</small>}
              </Typography>
              <Controller
                name='yearid'
                control={control}
                rules={{ required: { value: true, message: 'Required' } }}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    value={value ?? ''}
                    onChange={(value) => onChange(value)}
                    ref={ref}
                    disabled={isSaving}
                    sx={selectStyle}
                    MenuProps={selectStyle.MenuProps}
                    placeholder='Academic Year'
                    displayEmpty
                    renderValue={(value) =>
                      value ? (
                        academicYears.find((year) => Number(year.id) === value)?.yearName
                      ) : (
                        <Typography
                          component='p'
                          fontSize='14px'
                          color={grey[200]}
                          variant='caption'
                        >
                          Academic Year
                        </Typography>
                      )
                    }
                  >
                    {academicYears.map((year) => (
                      <MenuItem key={`yearKey ${year.id}`} value={year.id}>
                        <Typography component='p' color={dark[200]} variant='caption'>
                          {year.yearName}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={4} pr={1}>
            <Box component='div' mb={1}>
              <Typography component='p' color={dark[200]} variant='body2'>
                Semester
                {errors.semesterId && <small style={errorStyle}>{errors.semesterId.message}</small>}
              </Typography>
              <Controller
                name='semesterId'
                control={control}
                rules={{ required: { value: true, message: 'Required' } }}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    value={hasSemesters ? value ?? '' : ''}
                    onChange={(value) => onChange(value)}
                    ref={ref}
                    disabled={isSaving || !hasSemesters}
                    sx={selectStyle}
                    MenuProps={selectStyle.MenuProps}
                    placeholder='Semester'
                    displayEmpty
                    renderValue={(value) =>
                      value ? (
                        semesters.find((semester) => semester.id === value)?.semesterName
                      ) : (
                        <Typography
                          component='p'
                          fontSize='14px'
                          color={grey[200]}
                          variant='caption'
                        >
                          Semester
                        </Typography>
                      )
                    }
                  >
                    {semesters.map((semester) => (
                      <MenuItem key={`semesterKey ${semester.id}`} value={semester.id}>
                        <Typography component='p' color={dark[200]} variant='caption'>
                          {semester.semesterName}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
          </Grid>
        </Grid>
        <Box component='div' display='flex' justifyContent='right' marginTop='32px'>
          <Button
            variant='secondarybtn'
            sx={{ paddingLeft: '40px', paddingRight: '40px' }}
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            variant='primarybtn'
            type='submit'
            sx={{ paddingLeft: '40px', paddingRight: '40px', ml: 2 }}
          >
            {isSaving ? 'Saving..' : 'Save'}
          </Button>
        </Box>
      </Box>
    </CardContentBorderedForAction>
  );
};

export default SaveBlock;
