import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
} from '@progress/kendo-react-charts';
import { FC } from 'react';
import { GREY, PURPLE } from 'theme/palette/new';
import { DataRecord } from '../Bar';

const labelContent = (props) => {
  const formatedNumber = Number(props.dataItem.value).toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 0,
  });
  return formatedNumber;
};

type PieChartProps = {
  series: DataRecord[];
};
const PieChart: FC<PieChartProps> = (props: PieChartProps) => {
  const { series } = props;
  return (
    <Chart seriesColors={['#FF8A00', GREY[400], PURPLE[400]]}>
      <ChartLegend position='bottom' />
      <ChartSeries>
        <ChartSeriesItem type='donut' data={series} field='value' categoryField='category'>
          <ChartSeriesLabels position='outsideEnd' background='none' content={labelContent} />
        </ChartSeriesItem>
      </ChartSeries>
    </Chart>
  );
};

export default PieChart;
