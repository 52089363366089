import { Avatar, Badge, Box, Button, Divider, Typography } from '@mui/material';
import { useRandomColorAvatar } from 'UI/organisms/tasks/taskCard/hooks';
import ModalHeading from 'components/ModalHeading';
import Dropzone from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import CameraIcon from 'assets/images/svg/camera-icon.svg';
import { grey, light, shadow } from 'theme/palette';

const getUrl = (files: any[]) => {
  if (files.length === 0) return '';

  return URL.createObjectURL(files[0]);
};

type DefaultAvatarProps = { url?: string; centerName: string; hasImage: boolean };
const DefaultAvatar = ({ url, centerName = '', hasImage }: DefaultAvatarProps) => {
  if (hasImage)
    return (
      <Avatar
        sx={{
          width: '250px',
          height: '250px',
          bgcolor: light[100],
          borderRadius: 2,
          boxShadow: shadow[200],
          color: grey[800],
        }}
        src={url}
        alt='avatar-image'
        variant='square'
      />
    );
  return <NameAvatar centerName={centerName} />;
};

const NameAvatar = ({
  centerName = '',
  size,
  fz,
}: {
  centerName: string;
  size?: string;
  fz?: string;
}) => {
  return (
    <Avatar
      sx={{
        height: size || 150,
        width: size || 150,
        bgcolor: centerName ? useRandomColorAvatar(centerName) : 'none',
        borderRadius: 2,
        boxShadow: shadow[200],
      }}
    >
      <Typography variant='h3' letterSpacing={-1} fontSize={fz || '48px'}>
        {`${centerName.split(' ')[0][0]}`}
      </Typography>
    </Avatar>
  );
};

type AvatarFormProps = {
  dynamicData: any;
  saveAvatar: (any) => void;
  removeAvatar: () => void;
  isLoading: boolean;
  onClose: () => void;
};

const AvatarForm = (props: AvatarFormProps) => {
  const { dynamicData, onClose, saveAvatar, removeAvatar, isLoading } = props;

  const { profilePhotoURL, name } = dynamicData || {};
  const headingText = profilePhotoURL ? 'Change Center Profile Photo' : 'Add Center Profile Photo';

  const { control, watch, reset } = useForm();
  const files = watch('file') || [];
  const isPhotoDirty = !!files[0] || !!profilePhotoURL;
  const hasAvatar = !!profilePhotoURL;

  const save = () => {
    saveAvatar(files[0]);
  };

  return (
    <Box component='form' onSubmit={() => null}>
      <ModalHeading onClose={onClose} title={headingText} />
      <Controller
        name='file'
        control={control}
        render={({ field: { onChange } }) => (
          <Dropzone multiple={false} onDrop={onChange}>
            {({ isDragActive, open }) => (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                bgcolor={isDragActive ? 'red' : 'transparent'}
              >
                <Badge
                  overlap='circular'
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                  <DefaultAvatar
                    url={getUrl(files) || profilePhotoURL || ''}
                    centerName={name}
                    hasImage={!!(files[0] || profilePhotoURL)}
                  />
                </Badge>

                <Divider sx={{ my: 2 }} flexItem />

                {isPhotoDirty && (
                  <Box display='flex' mb={3}>
                    <Button
                      variant='secondarybtn'
                      onClick={hasAvatar ? removeAvatar : reset}
                      disabled={isLoading}
                      sx={{ mr: 1 }}
                    >
                      {hasAvatar && !isLoading ? 'Delete photo' : 'Cancel'}
                    </Button>
                    {!hasAvatar && (
                      <Button variant='primarybtn' onClick={save} disabled={isLoading}>
                        {isLoading ? 'Saving...' : 'Save Photo'}
                      </Button>
                    )}
                  </Box>
                )}

                {!isPhotoDirty && (
                  <Button
                    sx={{ ml: 2, mb: 3 }}
                    startIcon={<Box component='img' src={CameraIcon} alt='camera-icon' />}
                    variant='primarybtn'
                    type='button'
                    onClick={open}
                  >
                    Upload Photo
                  </Button>
                )}
              </Box>
            )}
          </Dropzone>
        )}
      />
    </Box>
  );
};

export default AvatarForm;
