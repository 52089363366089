import { useAssessmentCatalogs, useCatalogs } from 'services/catalogs/catalogsService';
import { optionType } from 'utils/data-to-options';

export const useBaseCatalog = (
  internalName: string,
  altIdProp = 'id',
  altLabelProp = 'attributeName',
  altAttributes: string[] = [],
) => {
  return (): optionType[] => {
    const { catalogsQuery } = useCatalogs({ internalName });
    if (catalogsQuery.data) {
      const catalog = catalogsQuery.data.attributes.map((attribute) => {
        const id = Number(attribute[altIdProp]);
        const label = attribute[altLabelProp];

        const attrb = {
          id,
          label,
        };

        if (altAttributes[0]) altAttributes.forEach((key) => (attrb[key] = attribute[key]));

        return attrb;
      });
      return catalog;
    }
    return [];
  };
};

export const useAssessmentCatalog = (
  internalName: string,
  altIdProp = 'id',
  altLabelProp = 'attributeName',
  altAttributes: string[] = [],
) => {
  return (): optionType[] => {
    const { catalogsQuery } = useAssessmentCatalogs({ internalName });
    if (catalogsQuery.data) {
      const catalog = catalogsQuery.data.attributes.map((attribute) => {
        const id = Number(attribute[altIdProp]);
        const label = attribute[altLabelProp];

        const attrb = {
          id,
          label,
        };

        if (altAttributes[0]) altAttributes.forEach((key) => (attrb[key] = attribute[key]));

        return attrb;
      });
      return catalog;
    }
    return [];
  };
};
