import { useAuth0 } from '@auth0/auth0-react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button } from '@mui/material';
import background from 'assets/images/public/pace_login_bg.jpg';
import { H1, H4 } from 'components/Text';
import { FontWeight } from 'components/Text/BaseText';
import { BLACK, BLUE, GREY, RED, WHITE } from 'theme/palette/new';
import { BoxShadowed } from 'UI/molecules/global/cards';
export const NoAccount424 = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    localStorage.clear();
    logout({ returnTo: window.location.origin });
  };
  return (
    <Box
      sx={{
        backgroundImage: `url(${background})`,
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100%'
        width='100%'
        bgcolor='rgba(0,0,0,.2)'
      >
        <BoxShadowed
          height={370}
          width={752}
          bgcolor={WHITE[100]}
          display='flex'
          alignItems='center'
          flexDirection='column'
        >
          <ErrorOutlineIcon sx={{ color: RED[500], fontSize: 80, mt: 3 }} />

          <Box mt={2}>
            <H1 color={BLACK[100]}>Please Activate Your Account</H1>
          </Box>
          <Box>
            <H4 color={GREY[600]} fontWeight={FontWeight.regular}>
              Please contact your administrator to activate your account
            </H4>
          </Box>
          <Button
            variant='secondarybtn'
            sx={{
              backgroundColor: BLUE[400],
              color: BLACK[100],
              mt: 2,
            }}
            onClick={handleLogout}
            startIcon={<ArrowBackIcon />}
            size='small'
          >
            Back to login
          </Button>
        </BoxShadowed>
      </Box>
    </Box>
  );
};
