import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Box, Collapse, ListItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { dark, green, grey, light } from 'theme/palette';
import { BLACK, BLUE, WHITE } from 'theme/palette/new';
import { getSvgIcon } from 'utils/utils';

type Props = {
  name: string;
  icon: string;
  route: string;
  id: number;
  isEnable: boolean;
  permissions: any[];
  childNodes?: Array<{
    name: string;
    id?: number;
    action: string;
    requiredPermission: null | string;
  }>;
};

const Child: React.FC<Props> = (props) => {
  const { name, childNodes = [], icon, route, isEnable, permissions } = props;
  const isExpandable = childNodes && childNodes.length > 0;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <ListItem
        disabled={!isEnable}
        component='li'
        sx={{
          borderRadius: '4px',
          my: 1,
          cursor: 'pointer',
          padding: 0,
        }}
      >
        {isExpandable && (
          <Box
            onClick={() => setOpen(!open)}
            borderRadius='4px'
            mt={0.5}
            bgcolor={open ? green[200] : 'none'}
            sx={{
              ':hover': {
                bgcolor: open ? 'none' : BLUE[300],
              },
            }}
            pl={1}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            height='40px'
            width='100%'
            component='div'
          >
            <Box component='div' display='flex' justifyContent='flex-start' alignItems='center'>
              {getSvgIcon(icon, open ? WHITE[100] : BLACK[100])}
              <Typography fontWeight={400} color={dark[200]} ml={1} variant='body1'>
                {name}
              </Typography>
            </Box>
            {!open ? <ChevronRightRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
          </Box>
        )}

        {!isExpandable && (
          <NavLink
            style={({ isActive }) => ({
              textDecoration: 'none',
              width: '100%',
              backgroundColor: isActive ? BLACK[100] : '',
              borderRadius: '4px',
              color: isActive ? light[100] : BLACK[100],
            })}
            to={isEnable ? route || '' : 'disabled'}
          >
            {({ isActive }) => (
              <Box
                borderRadius='8px'
                pl={1}
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                height='40px'
                width='100%'
                component='div'
                sx={{ ':hover': { bgcolor: isActive ? BLACK[100] : BLUE[300] } }}
              >
                <Box component='div' display='flex' justifyContent='flex-start' alignItems='center'>
                  {getSvgIcon(icon, isActive ? WHITE[100] : BLACK[100])}
                  <Typography fontWeight={400} ml={1} variant='body1'>
                    {name}
                  </Typography>
                </Box>
              </Box>
            )}
          </NavLink>
        )}
      </ListItem>
      {isExpandable && isEnable && (
        <Collapse in={open} unmountOnExit>
          <Box px={2} borderBottom={`1px solid ${grey[300]}`}>
            {childNodes.map(({ name, action, id, requiredPermission }) => {
              if (requiredPermission && !permissions[requiredPermission]) return null;
              return (
                <Box key={id}>
                  <NavLink
                    end
                    style={({ isActive }) =>
                      isActive
                        ? { textDecoration: 'none', color: BLACK[100] }
                        : { textDecoration: 'none', color: dark[200] }
                    }
                    to={action || ''}
                  >
                    <Typography sx={{ ':hover': { color: green[200] } }} my={1} variant='body2'>
                      {name}
                    </Typography>
                  </NavLink>
                </Box>
              );
            })}
          </Box>
        </Collapse>
      )}
    </React.Fragment>
  );
};

export default Child;
