import { GREEN, GREY } from 'theme/palette/new';

export enum colorStates {
  'Completed' = '#7FC31C',
  'In Progress' = '#FF8A00',
  'Not Started' = '#789CE2',
}

type generateColorsAndTextReturn = {
  text: string;
  color: string;
};
export const generateColorsAndText = (
  isAffirmative: boolean,
  yesColor?: string,
  noColor?: string,
  yesText?: string,
  noText?: string,
): generateColorsAndTextReturn => {
  const result = isAffirmative
    ? {
        color: yesColor || GREEN[300],
        text: yesText || 'Yes',
      }
    : {
        color: noColor || GREY[300],
        text: noText || 'No',
      };
  return result;
};

interface GenerateColorsAndTextOptions {
  yesColor?: string;
  noColor?: string;
  yesText?: string;
  noText?: string;
}

export const generateColorsAndTextForStatusChip = (
  isAffirmative: boolean,
  options: GenerateColorsAndTextOptions = {},
) => {
  const {
    yesColor = 'green[200]',
    noColor = 'grey[910]',
    yesText = 'Yes',
    noText = 'No',
  } = options;

  const result = isAffirmative
    ? {
        color: yesColor,
        text: yesText,
      }
    : {
        color: noColor,
        text: noText,
      };

  return result;
};
