export const hasUndefinedValue = (obj: object): boolean =>
  Object.values(obj).some((value) => value === undefined || value === '');

export const isEmpty = (obj: object): boolean => Object.keys(obj).length === 0;

export const removeZeroValues = (obj): any => {
  const newObj = {};

  for (const key in obj) {
    const value = obj[key];

    if (typeof value === 'object' && value !== null) {
      newObj[key] = removeZeroValues(value);
    } else if (value !== 0) {
      newObj[key] = value;
    }
  }

  return newObj;
};
