export const purple = {
  100: '#D21242',
  200: '#D21242',
  300: '#E16181',
  400: '#690921',
  500: '#D21242',
  600: 'rgba(163, 93, 165, 0.15)',
  700: '#D21242',
  800: 'rgba(163, 93, 165, 0.05)',
  900: 'rgba(136, 56, 138, 0.5)',
};

export const blue = {
  100: '#232360',
  200: '#789CE2',
  300: '#0B44AD',
  400: 'rgba(120, 156, 226, 0.70)',
};

export const grey = {
  100: '#768396',
  200: '#97999B',
  300: '#ECECEC',
  400: '#575860',
  500: '#E8EDF3',
  600: '#F3F4F7',
  700: '#2C2C2C',
  800: '#606060',
  900: '#F8F8F8',
  910: '#6B6C76',
  920: '#BEBEBE',
  1000: '#E8E6E6',
};
export const green = {
  100: '#96C0E6',
  200: '#96C0E6',
  300: '#96C0E6',
  400: '#96C0E6',
  500: '#7FC31C',
  600: '#96C0E6',
};

export const greenLight = {
  100: '#96C0E6',
};

export const red = {
  100: '#E44343',
  200: '#EA5455',
  300: '#FCEAEA',
  400: 'rgba(234, 84, 85, 0.10)',
};

export const redLight = {
  600: '#FFD2C1',
  700: '#FFD2D3',
};

export const yellow = {
  100: '#FF8A00',
  200: '#FF9F43',
  300: '#FFDFC2',
  400: '#FFDFD5',
  500: '#F4E87C',
  600: '#84780B',
  700: '#FFD335',
};

export const dark = {
  100: '#2C2C2C',
  200: '#1B2124',
  300: '#1b2124b0',
  400: '#424242',
  500: '#44444B',
};
export const lightDark = {
  100: '#97999B',
};

export const light = {
  100: '#FFFFFF',
};

export const white = {
  100: '#FFFFFF',
};

export const shadow = {
  100: '0px 2px 8px rgba(0, 0, 0, 0.135216)',
  200: '0px 3px 14px rgba(0, 0, 0, 0.14)',
  300: '0px 1px 8px rgba(0, 0, 0, 0.1)',
  400: '0px 3px 14px rgba(0, 0, 0, 0.25)',
  500: '0px 2px 2px rgba(163, 93, 165, 0.25), 0px 1px 5px rgba(163, 93, 165, 0.25)',
  600: '0px 2px 2px rgba(0, 0, 0, 0.25), 0px 1px 5px rgba(0, 0, 0, 0.25)',
  700: 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
  800: 'linear-gradient(180deg, rgba(151, 153, 155, 0.03) 29.45%, #97999B 201.37%)',
};
