import { API_ROUTES } from 'constants/apiRoutes';
import { useFetch, UseServiceProps } from 'utils/react-query';
import { pathToUrl } from 'utils/routes';
import { z } from 'zod';

export enum navigationFields {
  id = 'id',
  name = 'name',
  icon = 'icon',
  description = 'description',
  controller = 'controller',
  action = 'action',
  isUnderConstruction = 'isUnderConstruction',
  isHiddenForTesting = 'isHiddenForTesting',
  nonMain = 'nonMain',
  main = 'main',
  childNodes = 'childNodes',
}

export const GenericNavigationSchema = z.object({
  [navigationFields.id]: z.number(),
  [navigationFields.name]: z.string(),
  [navigationFields.icon]: z.string(),
  [navigationFields.description]: z.string(),
  [navigationFields.controller]: z.string(),
  [navigationFields.action]: z.string(),
  [navigationFields.isUnderConstruction]: z.boolean(),
  [navigationFields.isHiddenForTesting]: z.boolean(),
  [navigationFields.childNodes]: z.array(z.string()).optional(),
});

export const NavigationSchema = GenericNavigationSchema.extend({
  [navigationFields.nonMain]: z.array(GenericNavigationSchema),
  [navigationFields.main]: z.array(GenericNavigationSchema),
});

export type UserPermissionsMap = {
  [key: string]: string;
};

export type NavigationType = z.infer<typeof NavigationSchema>;

export const useNavigation = ({ routeParams, params, config }: UseServiceProps<NavigationType>) =>
  useFetch<NavigationType[]>(pathToUrl(API_ROUTES.NAVIGATION, routeParams), params, config);

export const userUserPermissions = ({
  routeParams,
  params,
  config,
}: UseServiceProps<UserPermissionsMap>) =>
  useFetch<UserPermissionsMap[]>(
    pathToUrl(API_ROUTES.USER_PERMISSIONS, routeParams),
    params,
    config,
  );
