import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Avatar, Box, Button, Dialog, Divider, Typography } from '@mui/material';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { deleteFile, uploadPhotoProfile } from 'services/global-academics/centers';
import { blue, grey, light, purple, red, shadow } from 'theme/palette';
import Alert from 'UI/molecules/global/alerts';
import { useRandomColorAvatar } from '../tasks/taskCard/hooks';

export interface FileModalProps {
  open: boolean;
  onClose: () => void;
  isProfile: boolean;
  photo: string | null;
  refetchCenter: () => void;
  name?: string;
}

export interface FileTypes {
  profilePhoto: FileList;
  bannerPhoto: FileList;
}

export type PictureTypes = Array<{ preview: string; isNew: boolean }> | null;

const FileModal: React.FC<FileModalProps> = ({
  open,
  onClose,
  isProfile,
  photo,
  refetchCenter,
  name,
}) => {
  const { centerId, agencyId } = useParams();
  const agencyID = Number(agencyId);
  const centerID = Number(centerId);
  const photoState = photo ? [{ preview: photo, isNew: false }] : null;

  const [profile, setProfile] = useState<PictureTypes>(photoState);
  const [banner, setBanner] = useState<PictureTypes>(photoState);

  const { handleSubmit, setValue, control, reset } = useForm<FileTypes>();

  const onSubmit = (data: FileTypes) => {
    if (isProfile) {
      uploadPhoto(data.profilePhoto);
    }

    console.log(data);
  };

  const uploadPhoto = (photo: FileList) => {
    const ProfileFile = new FormData();
    ProfileFile.append('ProfileFile', photo[0]);

    uploadPhotoProfileMutation({
      agencyId: 1,
      centerId: centerID,
      fileParam: ProfileFile,
    });
  };

  const { mutate: uploadPhotoProfileMutation, isLoading: isUploading } = useMutation(
    uploadPhotoProfile,
    {
      onSuccess: () => {
        toast.success(
          <Alert title='Success' subtitle='Wait a few seconds for the change to be reflected' />,
        );
        refetchCenter();
        onClose();
      },
    },
  );

  const removePhotoPreview = () => {
    setProfile(photoState);
    setBanner(photoState);
    reset();
  };

  const onCloseModal = () => {
    onClose();
    setProfile(null);
  };

  const { mutate: deleteFileMutation, isLoading: isRemoving } = useMutation(deleteFile, {
    onSuccess: () => {
      toast.success(<Alert title='Success' subtitle='File was removed successfully' />);
      refetchCenter();
      onClose();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const deleteTypeFormat = (type: string) => {
    const fileTypes = new FormData();
    fileTypes.append('fileTypes', type);

    deleteFileMutation({
      agencyId: agencyID,
      centerId: centerID,
      deleteFormBody: fileTypes,
    });
  };

  return (
    <Dialog
      keepMounted={false}
      open={open}
      onClose={onCloseModal}
      PaperProps={{
        style: {
          borderRadius: '6px',
          boxShadow: shadow[200],
          minWidth: '350px',
          maxWidth: '500px',
        },
      }}
    >
      <Box component='div' p={2} width={{ xs: '350px', md: '500px' }}>
        <Box component='div' display='flex' justifyContent='space-between' alignItems='center'>
          {photo ? (
            <Typography component='h1' variant='h6' fontWeight={700} color={blue[100]}>
              Change {isProfile ? 'Profile' : 'Banner'} Photo
            </Typography>
          ) : (
            <Typography component='h1' variant='h6' fontWeight={700} color={blue[100]}>
              Add your {isProfile ? 'Profile' : 'Banner'} Photo
            </Typography>
          )}

          <CloseRoundedIcon
            onClick={() => onCloseModal()}
            sx={{
              color: blue[100],
              cursor: 'pointer',
              ':hover': { border: '1px solid black', borderRadius: '4px' },
            }}
          />
        </Box>
      </Box>
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        {isProfile ? (
          <Controller
            name='profilePhoto'
            rules={{ required: false }}
            control={control}
            render={({ field: { onChange, onBlur } }) => (
              <Dropzone
                noClick
                maxSize={1000000}
                onDropRejected={() =>
                  toast.error(<Alert title='Error' subtitle='Maximum file upload size is 1MB' />, {
                    duration: 6000,
                    style: {
                      borderLeft: `8px solid ${red[100]}`,
                      backgroundColor: purple[400],
                    },
                  })
                }
                accept={{ 'image/png': ['.png'], 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'] }}
                onDrop={(acceptedFiles) => {
                  if (acceptedFiles.length > 0) {
                    setProfile(
                      acceptedFiles.map((upFile) =>
                        Object.assign(upFile, {
                          preview: URL.createObjectURL(upFile),
                          isNew: true,
                        }),
                      ),
                    );
                    setValue('profilePhoto', acceptedFiles as unknown as FileList, {
                      shouldValidate: true,
                    });
                  }
                }}
              >
                {({ getRootProps, getInputProps, open: openSelection, isDragActive }) => (
                  <Box component='div' px={2}>
                    <div
                      style={{
                        border: isDragActive ? `3px dashed ${purple[100]}` : 'none',
                        backgroundColor: isDragActive ? purple[400] : 'transparent',
                        borderRadius: '6px',
                        padding: 10,
                      }}
                      {...getRootProps()}
                    >
                      <input
                        {...getInputProps({
                          id: 'spreadsheet',
                          onChange,
                          onBlur,
                        })}
                      />

                      <Box
                        component='div'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                      >
                        {profile && profile !== null ? (
                          profile.map((element, index) => (
                            <Avatar
                              key={index}
                              sx={{
                                height: 200,
                                width: 200,
                                bgcolor: light[100],
                                borderRadius: 2,
                                boxShadow: shadow[200],
                                color: grey[800],
                              }}
                              src={element.preview}
                              variant='square'
                            />
                          ))
                        ) : (
                          <Avatar
                            sx={{
                              height: 90,
                              width: 90,
                              bgcolor: light[100],
                              borderRadius: 2,
                              boxShadow: shadow[200],
                              color: grey[800],
                            }}
                            variant='square'
                          >
                            {name ? (
                              <Typography
                                component='h3'
                                color={useRandomColorAvatar(name)}
                                variant='h4'
                              >{`${name.split(' ')[0][0]}`}</Typography>
                            ) : null}
                          </Avatar>
                        )}
                      </Box>

                      <Divider sx={{ my: 2 }} />

                      {profile && profile.length > 0 ? (
                        <Box
                          component='div'
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                        >
                          {profile.map((element, index) => (
                            <Box key={index}>
                              {element.isNew ? (
                                <Button
                                  onClick={() => setProfile(photoState)}
                                  variant='secondarybtn'
                                  onClickCapture={() => removePhotoPreview()}
                                >
                                  Remove photo selected
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => deleteTypeFormat('ProfilePhoto')}
                                  variant='secondarybtn'
                                >
                                  {isRemoving ? 'Removing...' : 'Remove'}
                                </Button>
                              )}
                              {element.isNew ? (
                                <Button sx={{ ml: 2 }} type='submit' variant='primarybtn'>
                                  {isUploading ? 'Saving...' : 'Save New Profile Photo'}
                                </Button>
                              ) : (
                                <Button onClick={openSelection} sx={{ ml: 2 }} variant='primarybtn'>
                                  Upload Profile Photo
                                </Button>
                              )}
                            </Box>
                          ))}
                        </Box>
                      ) : (
                        <Box
                          component='div'
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                        >
                          <Button onClick={openSelection} variant='primarybtn'>
                            Add Profile
                          </Button>
                        </Box>
                      )}
                    </div>
                  </Box>
                )}
              </Dropzone>
            )}
          />
        ) : (
          <Controller
            name='bannerPhoto'
            rules={{ required: false }}
            control={control}
            render={({ field: { onChange, onBlur } }) => (
              <Dropzone
                noClick
                maxSize={1000000}
                onDropRejected={() =>
                  toast.error(<Alert title='Error' subtitle='Maximum file upload size is 1MB' />, {
                    duration: 6000,
                    style: {
                      borderLeft: `8px solid ${red[100]}`,
                      backgroundColor: purple[400],
                    },
                  })
                }
                accept={{ 'image/png': ['.png'], 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'] }}
                onDrop={(acceptedFiles) => {
                  if (acceptedFiles.length > 0) {
                    setBanner(
                      acceptedFiles.map((upFile) =>
                        Object.assign(upFile, {
                          preview: URL.createObjectURL(upFile),
                          isNew: true,
                        }),
                      ),
                    );
                    setValue('bannerPhoto', acceptedFiles as unknown as FileList, {
                      shouldValidate: true,
                    });
                  }
                }}
              >
                {({ getRootProps, getInputProps, open: openSelection, isDragActive }) => (
                  <Box component='div' px={2}>
                    <div
                      style={{
                        border: isDragActive ? `3px dashed ${purple[100]}` : 'none',
                        backgroundColor: isDragActive ? purple[400] : 'transparent',
                        borderRadius: '6px',
                        padding: 10,
                      }}
                      {...getRootProps()}
                    >
                      <input
                        {...getInputProps({
                          id: 'spreadsheet',
                          onChange,
                          onBlur,
                        })}
                      />

                      <Box
                        component='div'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                      >
                        {banner && banner !== null ? (
                          banner.map((element, index) => (
                            <Avatar
                              key={index}
                              sx={{
                                height: 200,
                                width: 200,
                                bgcolor: light[100],
                                borderRadius: 2,
                                boxShadow: shadow[200],
                                color: grey[800],
                              }}
                              src={element.preview}
                              variant='square'
                            />
                          ))
                        ) : (
                          <Avatar
                            sx={{
                              height: 90,
                              width: 90,
                              bgcolor: light[100],
                              borderRadius: 2,
                              boxShadow: shadow[200],
                              color: grey[800],
                            }}
                            variant='square'
                          />
                        )}
                      </Box>

                      <Divider sx={{ my: 2 }} />

                      {banner && banner.length > 0 ? (
                        <Box
                          component='div'
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                        >
                          {banner.map((element, index) => (
                            <Box key={index}>
                              {element.isNew ? (
                                <Button
                                  onClick={() => setBanner(photoState)}
                                  variant='secondarybtn'
                                  onClickCapture={() => removePhotoPreview()}
                                >
                                  Remove photo selected
                                </Button>
                              ) : (
                                <Button variant='secondarybtn'>Remove</Button>
                              )}
                              {element.isNew ? (
                                <Button sx={{ ml: 2 }} type='submit' variant='primarybtn'>
                                  {isUploading ? 'Saving...' : 'Save New Profile Photo'}
                                </Button>
                              ) : (
                                <Button onClick={openSelection} sx={{ ml: 2 }} variant='primarybtn'>
                                  Upload Profile Photo
                                </Button>
                              )}
                            </Box>
                          ))}
                        </Box>
                      ) : (
                        <Box
                          p={2}
                          component='div'
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                        >
                          <Button sx={{ ml: 2 }} onClick={openSelection} variant='primarybtn'>
                            Add Profile
                          </Button>
                        </Box>
                      )}
                    </div>
                  </Box>
                )}
              </Dropzone>
            )}
          />
        )}
      </Box>
    </Dialog>
  );
};

export default FileModal;
