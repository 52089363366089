import WestIcon from '@mui/icons-material/West';
import { Box, Typography } from '@mui/material';
import { LinkButton } from 'components';
import { H1, H5 } from 'components/Text';
import { FontWeight } from 'components/Text/BaseText';
import { useNavigationMenu } from 'hooks/useNavigationMenu';
import { useEffect, useState } from 'react';
import { BLUE } from 'theme/palette/new';
import { CardShadowed } from 'UI/molecules/global/cards';
export const RestrictedView = ({ permission, children }: { permission: string; children: any }) => {
  const { permissionsMenu: userPermissions } = useNavigationMenu();
  const [ml, setMl] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      const layoutEl = document.querySelector('[data-layout-ml]');
      const ml = layoutEl ? layoutEl['dataset']['layoutMl'] || 0 : 0;

      setMl(ml);
    }, 0);
  }, []);

  if ((userPermissions && userPermissions[permission]) || userPermissions === null) return children;

  return (
    <CardShadowed sx={{ height: 634, mt: 2, ml }}>
      <Box
        flex={1}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <Typography fontSize={80} fontWeight={800} color={BLUE[600]}>
          401
        </Typography>
        <H1 color={BLUE[600]}>Unauthorized!</H1>
        <Box width={611} mt={3} mb={6}>
          <H5 fontWeight={FontWeight.regular} textAlign='center'>
            Looks like you don’t have permission to access this section. Please log in and try
            again, or try going to another section.
          </H5>
        </Box>

        <LinkButton to='/' startIcon={<WestIcon />}>
          Go back to home page
        </LinkButton>
      </Box>
    </CardShadowed>
  );
};
