import { Box, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { dark, green, grey, shadow } from 'theme/palette';
import { BLUE } from 'theme/palette/new';

type Props = {
  name: string;
  id: number;
  route: string;
  moduleName: string;
  idParam: string | undefined;
  childNodes?: Array<{
    name: string;
    id: number;
    route: string;
  }>;
};

const Child: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(false);
  const { name, route } = props;

  return (
    <Box ml={1}>
      <Box
        height='32px'
        onClick={() => setOpen(!open)}
        borderRadius='6px'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <Tooltip
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'white',
                boxShadow: shadow[100],
                border: `1px solid ${grey[800]}`,
                color: dark[200],
                ['& .MuiTooltip-arrow']: {
                  color: '#dadde9',
                  '&::before': {
                    backgroundColor: 'white',
                    border: `1px solid ${grey[800]}`,
                  },
                },
              },
            },
          }}
          title={name.length >= 22 ? name : ''}
          placement='right-start'
        >
          <NavLink
            style={({ isActive }) => ({
              textDecoration: 'none',
              ...(isActive ? { color: BLUE[600] } : { color: grey[800] }),
            })}
            end
            to={route}
          >
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ':hover': {
                  color: green[100],
                },
              }}
              fontWeight={400}
              variant='caption'
            >
              {name}
            </Typography>
          </NavLink>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default Child;
