import useSessionUser from 'hooks/useSessionUser';
import { Navigate } from 'react-router-dom';
import { useStaffMemberById } from 'services/global-academics/staff-members';
import { LinearProgressBar } from 'UI/molecules/global/linear-progress-bar';

const PrivateAdminRoute = ({ children }: { children: JSX.Element }) => {
  const { id } = useSessionUser();
  const { data, isLoading } = useStaffMemberById({
    config: { enabled: !!id },
    routeParams: { id },
  });

  const { roles } = data || {};

  const isAdmin = roles?.some((role) => typeof role === 'object' && role?.rolName === 'Admin');

  if (isLoading || !id) return <LinearProgressBar />;

  return isAdmin ? children : <Navigate to='/agencies' />;
};

export default PrivateAdminRoute;
