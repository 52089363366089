import { ErrorMessage } from '@hookform/error-message';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, InputBase, InputBaseProps, Typography } from '@mui/material';
import { ErrorSpan, WipChip } from 'components';
import { Paragraph } from 'components/Text';
import { FontWeight } from 'components/Text/BaseText';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { dark } from 'theme/palette';
import { inputStyle } from 'theme/styles/components/input';
import { textAreaStyle } from 'theme/styles/components/textarea';
import { errorStyle } from 'theme/styles/inputs';

export type InputProps = {
  caption: string;
  fieldName: string;
  placeholder?: string;
  isDisabled?: boolean;
  type?: InputBaseProps['type'];
  isWip?: boolean;
  textarea?: boolean;
  justifyContent?: 'space-between' | 'space-around' | 'flex-start' | 'flex-end' | 'center';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
  customArrayErrors?: boolean;
  maxLength?: number;
  maxValue?: number;
  startAdornment?: any;
  endAdornment?: any;
  isDisplayOnly?: boolean;
};

const Input: React.FC<InputProps> = (props) => {
  const {
    caption,
    fieldName,
    placeholder,
    isDisabled,
    type,
    isWip,
    textarea,
    customArrayErrors,
    justifyContent = 'space-between',
    maxLength,
    maxValue,
    startAdornment,
    endAdornment,
    isDisplayOnly,
    ...rest
  } = props;

  const { control, formState } = useFormContext();
  const { errors } = formState;
  const sx = textarea ? { ...textAreaStyle, pb: '15px' } : inputStyle;
  const hasErrors = errors[fieldName];

  const handleOnChange =
    (cb) =>
    ({ target }) => {
      const { value } = target;

      if (maxLength && `${value}`.length > maxLength) return;

      if (type === 'number') {
        const numberOnlyText = value.replace(/\D/g, '');
        if (maxValue && numberOnlyText > maxValue) return;
        cb(numberOnlyText);
      } else {
        cb(value);
      }
    };
  return (
    <Box display='flex' flexDirection='column' justifyContent={justifyContent}>
      <Box display='flex'>
        <Typography
          component='p'
          display='block'
          textAlign='left'
          color={dark[200]}
          variant='body2'
        >
          {`${caption} `}
          {customArrayErrors ? (
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => <small style={errorStyle}>{message}</small>}
            />
          ) : (
            <ErrorSpan errors={errors} name={fieldName} />
          )}
        </Typography>
        {isWip && <WipChip />}
      </Box>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={''}
        render={({ field: { onChange, value, ref } }) => {
          if (isDisplayOnly) {
            return (
              <Box mt={2.5}>
                <Paragraph fontWeight={FontWeight.regular}>{value ? value : '-'}</Paragraph>
              </Box>
            );
          }
          return (
            <InputBase
              onChange={handleOnChange(onChange)}
              value={value}
              inputRef={ref}
              fullWidth
              sx={sx}
              placeholder={placeholder}
              disabled={isDisabled || isWip}
              type={type}
              inputProps={{ maxLength }}
              className={hasErrors && 'field-error'}
              startAdornment={startAdornment}
              endAdornment={
                <>
                  {endAdornment}
                  {value && !isDisabled ? (
                    <ClearIcon
                      onClick={() => onChange('')}
                      sx={{ cursor: 'pointer', fontSize: '18px' }}
                    />
                  ) : null}
                </>
              }
              {...rest}
            />
          );
        }}
      />
    </Box>
  );
};

export default Input;
