import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import { Chip } from '@mui/material';

const WipChip: React.FC = () => (
  <Chip
    icon={<ConstructionRoundedIcon />}
    label='Under Construction'
    color='warning'
    size='small'
    sx={{ mx: 1, maxHeight: '20px' }}
  />
);

export default WipChip;
