import { Button } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { axiosConfiguration } from 'axios/config';
import { BlockItemProps } from 'modules/global-academics/centers/academic-setup/interfaces';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { ServerResponse } from 'services/client/attendance/attendanceService';
import Alert from 'UI/molecules/global/alerts';

interface Props {
  centerId: number;
  semesterId?: number;
}

// Requests
const getBlocks = async (centerId): Promise<BlockItemProps[]> => {
  const { data } = await axiosConfiguration.get<BlockItemProps[]>(`center/${centerId}/block`);
  return data;
};

const getBlockBySemesterId = async (semesterId) => {
  if (semesterId) {
    const { data: response } = await axiosConfiguration['get']<BlockItemProps[]>(
      `semester/${semesterId}/block`,
    );
    return response;
  } else {
    return;
  }
};

const saveBlock = async ({ blockData }) => {
  const method = blockData.id ? 'put' : 'post';
  const { data: response } = await axiosConfiguration[method]<BlockItemProps>('block', blockData);
  return response;
};

// Hooks
export const useBlocks = ({ centerId, semesterId }: Props) => {
  const blocksQuery = useQuery(['blocks'], () => getBlocks(centerId), {
    onSettled(data, error) {
      if (error) {
        toast.error(<Alert title='Error' subtitle='Error loading blocks!' />);
      }
    },
  });

  const blocksQueryBySemesterId = useQuery(
    ['get-block-by-semester', semesterId],
    () => getBlockBySemesterId(semesterId),
    {
      enabled: semesterId ? true : false,
    },
  );

  const blocksMutation = useMutation(
    (variables: { blockData: BlockItemProps }) => saveBlock({ blockData: variables.blockData }),
    {
      onSuccess: () => {
        blocksQuery.refetch();
        toast.success(<Alert title='Success' subtitle='Block was saved successfully!' />);
      },
      onError: (error: unknown) => {
        const errors = error as Error | AxiosError;
        if (axios.isAxiosError(errors)) {
          const errorMessage = errors.response?.data as ServerResponse;
          toast(
            (t) => (
              <span>
                <Alert title='Error !' subtitle={errorMessage.message} />
                <Button color='primary' sx={{ my: 1 }} onClick={() => toast.dismiss(t.id)}>
                  Dismiss
                </Button>
              </span>
            ),
            {
              duration: 10000,
            },
          );
        } else {
          toast.error(<Alert title='Error' subtitle={errors.message} />);
        }
      },
    },
  );

  return {
    blocksQuery,
    blocksMutation,
    blocksQueryBySemesterId,
  };
};
