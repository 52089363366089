import { Box, List } from '@mui/material';
import { useNavigationMenu } from 'hooks/useNavigationMenu';
import React from 'react';
import { shadow } from 'theme/palette';
import { WHITE } from 'theme/palette/new';
import NavigationSkeleton from '../navigationSkeleton';
import Child from './child';

const Navigation: React.FC = () => {
  const { navigationMenu, isLoading, permissionsMenu: permissions }: any = useNavigationMenu();

  return (
    <React.Fragment>
      {isLoading ? (
        <NavigationSkeleton />
      ) : (
        <Box width='100%' px={0} mt={2} component='div'>
          <Box
            component='nav'
            borderRadius='6px'
            width='100%'
            p={1.5}
            bgcolor={WHITE[200]}
            boxShadow={shadow[200]}
          >
            <List component='ul' sx={{ width: '100%' }}>
              {navigationMenu &&
                navigationMenu.map((parentNode) => {
                  if (parentNode.requiredPermission && !permissions[parentNode.requiredPermission])
                    return null;
                  return (
                    <Child
                      key={parentNode.id}
                      name={parentNode.name}
                      id={parentNode.id}
                      icon={parentNode?.icon ?? ''}
                      route={parentNode.action}
                      childNodes={parentNode.main}
                      permissions={permissions}
                      isEnable={Boolean(
                        parentNode.action || (parentNode.main && parentNode.main[0]),
                      )}
                    />
                  );
                })}
            </List>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default Navigation;
