import moment from 'moment';
import { optionType } from 'utils/data-to-options';

export const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD');
export const formatDateTime = (date: Date) => moment(date).format('YYYY-MM-DDTHH:MM:SS');
export const formatTime = (date: Date) => moment(date).local().format('HH:mm:ss');
export const formatTime12 = (date: Date) => moment(date).format('HH:mm A');
export const formatTime12Local = (date: Date) => moment(date).local().format('HH:mm A');
export const formatHours = (start: string, end: string) => {
  const startTime = moment(start, 'HH:mm:ss');
  const endTime = moment(end, 'HH:mm:ss');

  if (!start || !end) return '0';

  const duration = moment.duration(endTime.diff(startTime));
  const hours = duration.hours();
  const minutes = duration.minutes();

  return `${hours}hrs ${minutes}min`;
};

export const dateStrgToDate = (dateStrg) => new Date(dateStrg);
export const timeStrgToDate = (timeStrg) => {
  const [hours, minutes, seconds] = timeStrg.split(':').map(Number);
  return new Date(1970, 0, 1, hours || 0, minutes || 0, seconds || 0);
};

export const formatDateString = (date: string) => moment(date).format('ll');
export const formatDateStringNum = (date: string) => moment(date).format('MM-DD-YYYY');
export const formatDateWithSlashes = (date: string) => moment(date).format('MM/DD/YYYY');

export const formatStringDate = (date: string) => {
  const hasTimeZone = date.includes(':');

  const [strDate] = hasTimeZone ? date.split(':') : [date];

  return moment(strDate).format('YYYY-MM-DD');
};

export const formatDateStringMonthYear = (date: Date) => moment(date).format('lll');
export const formatStringDateMonthYear = (date: string) => moment(date).format('lll');

export function generateArrayOfMonths(): optionType[] {
  const months = moment.months();
  const arrayOfMonths = months.map((element) => {
    const month = moment().month(element).format('M');
    return {
      id: Number(month),
      label: element,
    };
  });

  return arrayOfMonths;
}

export function generateArrayOfYears(): optionType[] {
  const max = new Date().getFullYear();
  const min = max - 13;
  const years: { id: number; label: number }[] = [];

  for (let i = max; i >= min; i--) {
    years.push({
      id: i,
      label: i,
    });
  }

  return years;
}
export const calculateAges = (date: Date) => {
  const diff = moment().diff(date, 'years');
  return diff;
};

export const formatDateNoTimeZone = (date: string) => moment.parseZone(date).format('ll');

export const getNextBussinesDays = (initialDate: Date): Date => {
  const newDate = new Date(initialDate);
  newDate.setDate(newDate.getDate() + 1);
  if (newDate.getDay() === 0 || newDate.getDay() === 6) return getNextBussinesDays(newDate);
  return newDate;
};
