import { axiosConfiguration } from 'axios/config';
import { AlertTypes } from 'modules/client/alerts';
import { useQuery } from 'react-query';
import { useCatalogs } from 'services/catalogs/catalogsService';
import { optionType } from 'utils/data-to-options';
import { CATALOGS_NAMES } from '../catalogsNames';

const getAlerts = async () => {
  const { data: response } = await axiosConfiguration['get']<AlertTypes>('alerts');
  return response;
};

export const useAlerts = () => {
  const alertsCategoryQuery = useQuery('get-alerts', () => getAlerts());
  return {
    alertsCategoryQuery,
  };
};

export enum alertCategory {
  medical = 'medical',
  general = 'general',
  safetyandsecurity = 'safetyandsecurity',
  mentalhealth = 'mentalhealth',
}

export enum alertLevel {
  low = 'low',
  critical = 'critical',
  spacial = 'special',
}

export const useAlertCategoryCatalog = (): optionType[] => {
  const { catalogsQuery } = useCatalogs({ internalName: CATALOGS_NAMES.AlertCategory });
  if (catalogsQuery.data) {
    const alerts = catalogsQuery.data.attributes.map((attribute) => {
      return {
        id: attribute.id,
        label: attribute.attributeName,
      };
    });
    return alerts;
  }
  return [];
};

export const useHowInfoWasVerifiedCatalog = (): optionType[] => {
  const { catalogsQuery } = useCatalogs({ internalName: CATALOGS_NAMES.HowInfoWasVerified });
  if (catalogsQuery.data) {
    const alerts = catalogsQuery.data.attributes.map((attribute) => {
      return {
        id: attribute.id,
        label: attribute.attributeName,
      };
    });
    return alerts;
  }
  return [];
};

export const useCategories = (): optionType[] => {
  const { catalogsQuery } = useCatalogs({ internalName: 'AlertCategory' });
  if (catalogsQuery.data) {
    const alerts = catalogsQuery.data.attributes.map((attribute) => {
      return {
        id: attribute.id,
        label: attribute.attributeName,
      };
    });
    return alerts;
  }
  return [];
};
