import { zodResolver } from '@hookform/resolvers/zod';
import AddRounded from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Box, Button, Grid, InputBase, Typography } from '@mui/material';
import { dark } from '@mui/material/styles/createPalette';
import { TimePicker } from '@progress/kendo-react-dateinputs';
import { DropDown } from 'components';
import moment from 'moment';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  fields as yearFields,
  useAcademicYearsByCenter,
} from 'services/global-academics/academic-years';
import { errorStyle, inputStyle } from 'theme/styles/inputs';
import { CardContentBorderedForAction } from 'UI/molecules/global/cards';
import { dataToOptions } from 'utils/data-to-options';
import { AddPeriodFormProps, periodFields, PeriodFormFields } from '../interfaces';

const SavePeriod: React.FC<AddPeriodFormProps> = ({
  data,
  onAddPeriod,
  onEditPeriod,
  onCancel,
  isSaving,
  centerId,
}) => {
  const { data: academicYears = [] } = useAcademicYearsByCenter({
    routeParams: { id: centerId },
    config: { enabled: !!centerId },
  });

  const yearsOption = dataToOptions(academicYears || [], yearFields.id, yearFields.yearName);

  const getFixedDates = () => {
    if (data) {
      const startTime = moment(data.startTime, 'HH:mm:ss').toString();
      const endTime = moment(data.endTime, 'HH:mm:ss').toString();
      return { startTime, endTime };
    }
  };
  const methods = useForm<PeriodFormFields>({
    resolver: zodResolver(periodFields),
    defaultValues: {
      ...data,
      ...getFixedDates(),
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const onSubmitPeriod = (formData) => {
    const fixedFormData = {
      ...formData,
      centerId,
      startTime: moment(formData.startTime).local().format('HH:mm'),
      endTime: moment(formData.endTime).local().format('HH:mm'),
    };

    if (!data) {
      const bodyForCreation = {
        ...fixedFormData,
      };
      onAddPeriod && onAddPeriod(bodyForCreation);
      return;
    }

    onEditPeriod &&
      onEditPeriod({
        ...fixedFormData,
        id: data.id,
      });
    return;
  };

  const getFormTitle = () => {
    if (!data) {
      return (
        <>
          <AddRounded color='primary' fontWeight={700} style={{ fontSize: '16px' }} />
          <Typography fontSize='14px' fontWeight={500} color='primary'>
            Create a new Period
          </Typography>
        </>
      );
    }
    return (
      <>
        <EditRoundedIcon fontWeight={700} color='primary' style={{ fontSize: '14px' }} />
        <Typography fontSize='14px' fontWeight={500} color='primary'>
          Edit Period
        </Typography>
      </>
    );
  };
  return (
    <FormProvider {...methods}>
      <CardContentBorderedForAction>
        <Box component='form' onSubmit={handleSubmit(onSubmitPeriod)}>
          <Box
            component='div'
            display='flex'
            alignItems='center'
            style={{ cursor: 'pointer' }}
            gap='4px'
            marginBottom='21px'
          >
            {getFormTitle()}
          </Box>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Box component='div' mb={1} pr={{ xs: 0, md: 1 }}>
                <Typography component='p' color={dark[200]} variant='body2'>
                  Period Name
                  {errors.periodName && (
                    <small style={errorStyle}>{errors.periodName.message}</small>
                  )}
                </Typography>
                <Controller
                  name='periodName'
                  control={control}
                  defaultValue=''
                  rules={{ required: { value: true, message: 'Required' } }}
                  render={({ field: { onChange, value, ref } }) => (
                    <InputBase
                      onChange={onChange}
                      value={value || ''}
                      inputRef={ref}
                      fullWidth
                      disabled={isSaving}
                      sx={inputStyle}
                      placeholder='Period Name'
                    />
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box component='div' mb={1} pr={{ xs: 0, md: 1 }}>
                <Typography component='p' color={dark[200]} variant='body2' mb={1}>
                  Start Time
                  {errors.startTime && <small style={errorStyle}>{errors.startTime.message}</small>}
                </Typography>
                <Controller
                  name='startTime'
                  control={control}
                  rules={{ required: { value: true, message: 'Required' } }}
                  render={({ field: { onChange, value, ref } }) => (
                    <TimePicker
                      onChange={onChange}
                      value={(value && moment(value).toDate()) || null}
                      ref={ref}
                      format='HH:mm'
                      disabled={isSaving}
                      placeholder='00:00'
                    />
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box component='div' mb={1}>
                <Typography component='p' color={dark[200]} variant='body2' mb={1}>
                  End Time
                  {errors.endTime && <small style={errorStyle}>{errors.endTime.message}</small>}
                </Typography>
                <Controller
                  name='endTime'
                  control={control}
                  rules={{ required: { value: true, message: 'Required' } }}
                  render={({ field: { onChange, value, ref } }) => (
                    <TimePicker
                      onChange={onChange}
                      value={(value && moment(value).toDate()) || null}
                      ref={ref}
                      disabled={isSaving}
                      format='HH:mm'
                      placeholder='00:00'
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', columnGap: 2 }}>
            <DropDown
              numberType
              caption='Academic Year'
              fieldName='academicYearId'
              placeholder='Select One'
              options={yearsOption}
            />
          </Box>
          <Box component='div' display='flex' justifyContent='right' marginTop='32px'>
            <Button
              variant='secondarybtn'
              sx={{ paddingLeft: '40px', paddingRight: '40px' }}
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primarybtn'
              type='submit'
              sx={{ paddingLeft: '40px', paddingRight: '40px', ml: 2 }}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </Button>
          </Box>
        </Box>
      </CardContentBorderedForAction>
    </FormProvider>
  );
};

export default SavePeriod;
