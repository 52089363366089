import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, CircularProgress } from '@mui/material';
import { api, axiosAzure, methods } from 'axios/config';
import { H5 } from 'components/Text';
import { FontWeight } from 'components/Text/BaseText';
import { useOpen } from 'hooks/useOpen';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { BLACK, BLUE, GREY } from 'theme/palette/new';
import { DragNDropProps } from './DragDrop';
import { FileCard } from './FileCard';
import { UploadingFile, useFileStore } from './fileStore';
import { MultifileUploadModal } from './MultifileUploadModal';

type MultifileProps = {
  fieldName: string;
  azureUrl: string;
  deleteUrl: string;
  fileNameField: string;
  readOnly?: boolean;
} & DragNDropProps;

const countFilesInProgress = (files: UploadingFile[]): number => {
  return files.filter((file) => file.progress > 0 && file.progress < 100).length;
};

const MultifileUpload: React.FC<MultifileProps> = (props: MultifileProps) => {
  const { fieldName, azureUrl, deleteUrl, fileNameField, readOnly, ...dragNDropProps } = props;
  const { isOpen, open, close } = useOpen();
  const { setValue, watch } = useFormContext();
  const {
    files,
    uploadingFiles,
    clearFiles,
    setUploadingFiles,
    updateUploadingFileProgress,
    isUploading,
    setIsUploading,
  } = useFileStore();

  const formFiles = watch(fieldName);

  const send = async () => {
    close();
    setIsUploading(true);
    try {
      const filePromises = files.map((file) => {
        const url = azureUrl.replace(fileNameField, file.name);
        return api[methods.get](url);
      });
      const res = await Promise.all(filePromises);
      const uploadFiles: UploadingFile[] = res.map(({ data }: any, idx) => {
        const file = files[idx];
        return {
          progress: 0,
          blob: file,
          servername: data.name,
          container: data.container,
          name: file.name,
          size: file.size,
          type: file.type,
          uploadUrl: data.uploadUrl,
          uri: data.uri,
        };
      });
      clearFiles();
      setUploadingFiles([...uploadFiles, ...uploadingFiles]);
      uploadFiles.forEach((uploadFile) => {
        axiosAzure.put(uploadFile.uploadUrl, uploadFile.blob, {
          onUploadProgress(progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            updateUploadingFileProgress(uploadFile.name, percentCompleted);
          },
        });
      });

      setValue(fieldName, [...uploadFiles, ...uploadingFiles]);
    } catch (error) {
      console.error('err', error);
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (formFiles) {
      setUploadingFiles(formFiles);
    }
  }, [JSON.stringify(formFiles)]);

  const handleDelete = (uploadedFile: UploadingFile) => async () => {
    try {
      await api.delete(deleteUrl, {
        data: [uploadedFile.servername],
      });

      const newUploadingFiles = uploadingFiles.filter(
        (file) => file.servername !== uploadedFile.servername,
      );
      setUploadingFiles(newUploadingFiles);
      setValue(fieldName, newUploadingFiles);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownload = (uploadedFile: UploadingFile) => () => {
    window.open(uploadedFile.uri);
  };

  const filesBeingUploaded = isUploading;

  useEffect(() => {
    setIsUploading(!!countFilesInProgress(uploadingFiles));
  }, [countFilesInProgress(uploadingFiles)]);
  return (
    <Box flexDirection='column'>
      {!readOnly && (
        <Button
          variant='PACE_Secondary'
          sx={{ width: 365, mb: 3 }}
          onClick={open}
          disabled={filesBeingUploaded || props.isDisabled}
        >
          {filesBeingUploaded ? (
            <CircularProgress size={20} sx={{ color: BLUE[600], mr: 1 }} />
          ) : (
            <FileUploadOutlinedIcon sx={{ mr: 1 }} />
          )}

          <H5 fontWeight={FontWeight.regular}>Upload file(s) here</H5>
        </Button>
      )}

      <MultifileUploadModal onClose={close} onSend={send} isOpen={isOpen} {...dragNDropProps} />

      {!!filesBeingUploaded && (
        <Box mb={2}>
          <H5>
            Uploading {countFilesInProgress(uploadingFiles)}/{uploadingFiles.length} Files...
          </H5>
        </Box>
      )}

      {!filesBeingUploaded && uploadingFiles[0] && (
        <Box display='flex' flexDirection='row' mb={2}>
          <H5 fontWeight={FontWeight.bold} color={BLACK[100]}>
            {uploadingFiles.length}&nbsp;
          </H5>
          <H5 fontWeight={FontWeight.regular} color={GREY[600]}>
            Files uploaded
          </H5>
        </Box>
      )}

      {uploadingFiles.map((file, idx) => (
        <FileCard
          key={file.servername}
          idx={idx}
          file={file}
          progress={file.progress}
          handleDownload={handleDownload(file)}
          handleDelete={!props.isDisabled && (handleDelete(file) as any)}
        />
      ))}
    </Box>
  );
};

export default MultifileUpload;
