import { Box, Typography } from '@mui/material';
import { ReactComponent as HeartLogo } from 'assets/images/svg/growth/heart.svg';
import { useGrowthAndChangeStages } from 'services/catalogs/growth-and-change-stages';

const Heart = ({
  heartColor,
  label,
  opacity,
}: {
  heartColor: string;
  label: string;
  opacity: number;
}) => (
  <Box alignItems='center'>
    <Box sx={{ opacity: `${opacity}%` }}>
      <HeartLogo fill={heartColor} stroke={heartColor} />
    </Box>
    <Typography textAlign='center'>{label}</Typography>
  </Box>
);

type Props = { current: number };
const GrowIndicator: React.FC<Props> = (props: Props) => {
  const { current } = props;
  const stages: any = useGrowthAndChangeStages();

  const stageIdx = stages.map(({ id }) => id).indexOf(current);
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 15,
        justifyContent: 'center',
      }}
    >
      {stages.map((heart, idx) => {
        const rawOpacity = 100 - (stageIdx - idx) * 20;
        const opacity = rawOpacity > 100 ? 100 : rawOpacity;
        const isDisabled = idx > stageIdx;
        return (
          <Heart
            heartColor={isDisabled ? '#D9D9D9' : (heart.attributeValue as string)}
            label={heart.label as string}
            key={idx}
            opacity={opacity}
          />
        );
      })}
    </Box>
  );
};

export default GrowIndicator;
