export const toKb = (sizeInBytes) => {
  return `${Math.floor(sizeInBytes / 1024)}Kb`;
};

export const toMb = (sizeInBytes) => {
  return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
};

export const fromMbToBytes = (sizeInMb) => {
  return sizeInMb * 1024 * 1024;
};

export const formatFileSize = (sizeInBytes: number): string => {
  if (sizeInBytes >= 1024 * 1024) {
    return toMb(sizeInBytes);
  } else {
    return toKb(sizeInBytes);
  }
};
