import { useAuth0 } from '@auth0/auth0-react';
/* Components */
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
/* Icons */
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Badge, Box, IconButton, Typography } from '@mui/material';
import logoDesktop from 'assets/images/logo/logo-desktop.svg';
import logoMobile from 'assets/images/logo/logo-mobile.svg';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
/* Utils */
import { useNavigate } from 'react-router-dom';
import { useGetNotifications } from 'services/notifications';
import { AppDispatch } from 'store';
import { handleMenuClose, handleMenuOpen } from 'store/slices/menu';
import { light, purple } from 'theme/palette';
import EnvironmentComponent from 'UI/molecules/global/environment';
import { useLockedBody } from 'usehooks-ts';
import { useNotificationStore } from './notificationMenu/notificationsStore';
/* Menu's */
const PrincipalMenu = lazy(() => import('./principalMenu'));
const NotificationMenu = lazy(() => import('./notificationMenu'));

type Props = {
  fixed?: boolean;
};

const Header: React.FC<Props> = ({ fixed }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { data, isLoading } = useGetNotifications({});
  const updateCounter = useNotificationStore((state) => state.updateUnreadCounter);
  const unreadCounter = useNotificationStore((state) => state.unreadCounter);

  const navigate = useNavigate();
  const { user } = useAuth0();

  const [blockScroll, setBlockScroll] = useState(false);
  const [notification, setNotification] = useState<boolean>(false);
  const [menu, setMenu] = useState(false);

  useLockedBody(blockScroll);

  const openLockedBody = () => setBlockScroll(true);

  const closeLockedBody = () => {
    setBlockScroll(false);
    setNotification(false);
    setMenu(false);
    dispatch(handleMenuClose());
  };

  /*
   * @ handler for open menu settings and scroll body: root locked
   */
  const openMenuSettings = () => {
    setMenu(true);
    setNotification(false);
    openLockedBody();
    dispatch(handleMenuOpen());
  };

  /*
   * @ handler for notification menu and scroll body: root locked
   */
  const openNotificationMenu = () => {
    setNotification(true);
    setMenu(false);
    openLockedBody();
    dispatch(handleMenuOpen());
  };

  useEffect(() => {
    if (data) {
      updateCounter(data.unreadCount);
    }
  }, [isLoading]);

  return (
    <Box
      position={`${fixed ? 'fixed' : 'relative'}`}
      top={0}
      component='header'
      bgcolor={purple[100]}
      zIndex={999}
      height={{
        xs: '70px',
        md: '80px',
      }}
      width='100%'
    >
      <Box display='flex' justifyContent='space-between' height='100%'>
        <Box
          marginLeft='21px'
          marginRight={1}
          display={{ xs: 'flex', md: 'none' }}
          justifyContent='center'
          alignItems='center'
          width='41.62px'
          height='100%'
          sx={{
            cursor: 'pointer',
          }}
        >
          <Box
            component='img'
            src={logoMobile}
            onClick={() => navigate('/')}
            width='41.62px'
            height='42.92px'
            alt='logo-mqi-enterprise'
          />
        </Box>
        <Box ml={2} component='div'>
          <Box
            marginLeft='160px'
            marginRight={1}
            display={{ xs: 'none', md: 'flex' }}
            justifyContent='center'
            alignItems='center'
            width='112px'
            height='100%'
            sx={{
              cursor: 'pointer',
            }}
          >
            <Box
              component='img'
              src={logoDesktop}
              onClick={() => navigate('/')}
              width='200px'
              height='60px'
              alt='logo-mqi-enterprise'
            />
            <EnvironmentComponent />
          </Box>
        </Box>
        <Box
          marginLeft={1}
          marginRight='21px'
          display={{ xs: 'flex', md: 'none' }}
          justifyContent='center'
          alignItems='center'
          sx={{
            cursor: 'pointer',
          }}
        >
          <IconButton aria-label='menu icon' type='button' onClick={openMenuSettings}>
            <MenuOutlinedIcon fontSize='large' style={{ color: light[100] }} />
          </IconButton>
        </Box>
        <Suspense
          fallback={
            <Box>
              <Typography variant='caption'>Opening menu...</Typography>
            </Box>
          }
        >
          {menu && (
            <PrincipalMenu
              open={menu}
              openNotificationMenu={openNotificationMenu}
              close={closeLockedBody}
            />
          )}
        </Suspense>
        <Suspense
          fallback={
            <Box>
              <Typography variant='caption' color='primary'>
                Opening notifications...
              </Typography>
            </Box>
          }
        >
          {notification && <NotificationMenu isOpen={notification} close={closeLockedBody} />}
        </Suspense>
        <Box
          marginLeft={1}
          marginRight='26px'
          display={{ xs: 'none', md: 'flex' }}
          justifyContent='space-between'
          alignItems='center'
        >
          <SearchOutlinedIcon style={{ color: 'white', display: 'none' }} fontSize='large' />
          <Badge badgeContent={unreadCounter} color='success'>
            <NotificationsNoneOutlinedIcon
              sx={{
                marginLeft: 2,
                cursor: 'pointer',
              }}
              onClick={() => openNotificationMenu()}
              style={{ color: 'white' }}
              fontSize='large'
            />
          </Badge>

          <Box ml={5} mr={2}>
            <Typography variant='body1' color={light[100]} fontWeight={700}>
              {user?.name || 'Jhon Doe'}
            </Typography>
          </Box>
          <SettingsOutlinedIcon
            sx={{
              cursor: 'pointer',
            }}
            fontSize='large'
            style={{ color: 'white' }}
            onClick={() => openMenuSettings()}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
