export type optionType = {
  id: string | number | boolean;
  label: string | number;
};

export type optionTypeWithSubItems = {
  id: string | number | boolean;
  idParentItem: string | number | boolean;
  idSubItem?: string | number | boolean;
  label: string | number;
};

type data = {
  [key: string]: any;
};

export const dataToOptions = (data: data[], idKey: string, labelKey: string): optionType[] =>
  data.map((option) => ({
    id: option[idKey],
    label: option[labelKey],
  }));

export const dataToOptionsWithSubItems = (
  data: data[],
  subArrayItems: string,
  parentId: string,
  subItemId: string,
  parentLabel: string,
  subItemLabel: string,
): optionTypeWithSubItems[] => {
  return data.flatMap((parent) => {
    if (parent[subArrayItems].length === 0) {
      return {
        id: parent[parentId],
        idParentItem: parent[parentId],
        label: parent[parentLabel],
      };
    } else {
      return parent[subArrayItems].map((subCategory) => ({
        id: parseInt(`${parent[parentId]}${subCategory[subItemId]}`),
        idParentItem: parseInt(parent[parentId]),
        idSubItem: subCategory[subItemId],
        label: `${parent[parentLabel]} - ${subCategory[subItemLabel]}`,
      }));
    }
  });
};
