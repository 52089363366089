const statusCodeHandler = (code) => {
  const codes = {
    401: { message: 'Error 401 Unathorized.' },
    403: { message: 'Error 403 Forbidden.' },
  };

  return codes[code];
};

const NO_ACCOUNT_STATUS = 424;

export const axiosRequestInterceptors = [
  (config) => {
    const token = localStorage.getItem('access-token');

    if (token) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }

    return config;
  },
  (err) => Promise.reject(err),
];

export const axiosResponseInterceptors = [
  (response) => {
    const status = statusCodeHandler(response.status);

    if (status) {
      const err = new Error(status.message);
      throw err;
      return;
    }

    return response;
  },
  (err) => {
    const { response } = err;

    if (response.status === NO_ACCOUNT_STATUS) {
      localStorage.setItem('no-account', 'true');
      window.location.replace('/no-account');
    }
    return Promise.reject(err);
  },
];

type QueryParams = {
  [key: string]: string | string[];
};

export const customParamsSerializer = (params: QueryParams): string => {
  const parts: string[] = [];
  for (const key in params) {
    if (Array.isArray(params[key])) {
      for (const value of params[key]) {
        if (value) parts.push(`${key}=${encodeURIComponent(value)}`);
      }
    } else {
      parts.push(`${key}=${encodeURIComponent(params[key] as string)}`);
    }
  }
  return parts.join('&');
};
