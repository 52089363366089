import AddRounded from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useSemesters } from 'services/global-academics/academic-setup/semestersService';
import { grey } from 'theme/palette';
import { CardContentBordered, CardContentBorderedForAction } from 'UI/molecules/global/cards';
import { LinearProgressBar } from 'UI/molecules/global/linear-progress-bar';
import SaveSemesterForm from '../forms/SaveSemester';
import { AddSemesterProps, SemesterItemProps } from '../interfaces';

interface MyComponentProps {
  centerId: string;
  yearId: string;
}

// Semester Components
const SemesterItem: React.FC<SemesterItemProps> = ({
  id,
  semesterName,
  startDate,
  endDate,
  onEdit,
}) => {
  const formattedStartDate = moment(startDate).format('MMM Do');
  const formattedEndDate = moment(endDate).format('MMM Do');
  const extractedYear = moment(endDate).format('YYYY');

  return (
    <CardContentBordered>
      <Box component='div' display='flex' justifyContent='space-between' alignItems='center'>
        <Box component='div' display='flex' flexDirection='column' gap='4px'>
          <Typography fontSize='14px'>{semesterName}</Typography>
          <Typography fontSize='14px' color={grey[200]}>
            {formattedStartDate} - {formattedEndDate}, {extractedYear}{' '}
          </Typography>
        </Box>
        <Box
          component='div'
          display='flex'
          alignItems='center'
          style={{ cursor: 'pointer' }}
          gap='8px'
          onClick={() => {
            onEdit(id);
          }}
        >
          <EditRoundedIcon fontWeight={500} color='primary' style={{ fontSize: '14px' }} />
          <Typography fontSize='14px' fontWeight={500} color='primary'>
            Edit Semester
          </Typography>
        </Box>
      </Box>
    </CardContentBordered>
  );
};

const AddSemester: React.FC<AddSemesterProps> = ({ onClick }) => {
  return (
    <CardContentBorderedForAction>
      <Box
        component='div'
        display='flex'
        alignItems='center'
        onClick={() => {
          onClick();
        }}
      >
        <Box
          component='div'
          display='flex'
          alignItems='center'
          style={{ cursor: 'pointer' }}
          gap='4px'
        >
          <AddRounded color='primary' fontWeight={700} style={{ fontSize: '16px' }} />
          <Typography fontSize='14px' fontWeight={500} color='primary'>
            Create a new Semester
          </Typography>
        </Box>
      </Box>
    </CardContentBorderedForAction>
  );
};
export default function SemestersSection(props: MyComponentProps): JSX.Element {
  const { centerId, yearId } = props;
  const { semestersQueryByYear, createSemesterMutation } = useSemesters({
    centerId: +centerId,
    yearId: +yearId,
  });
  const semesters = semestersQueryByYear.data || [];

  const [isAddSemesterFormOpen, setIsAddSemesterFormOpen] = useState(false);
  const [semesterToEdit, setSemesterToEdit] = useState<string | null>(null);

  if (semestersQueryByYear.isLoading) {
    return <LinearProgressBar />;
  }

  return (
    <>
      {semesters.length ? (
        semesters.map((semesterProps, index) =>
          semesterToEdit !== semesterProps.id ? (
            <SemesterItem
              key={`semesterItem ${index}`}
              {...semesterProps}
              onEdit={(id) => {
                setSemesterToEdit(id);
              }}
            />
          ) : (
            <SaveSemesterForm
              key={`semesterItem ${index}`}
              data={semesterProps}
              yearId={+yearId}
              onCancel={() => {
                setSemesterToEdit(null);
              }}
              isSaving={createSemesterMutation.isLoading}
              onEditSemester={async (semesterData) => {
                await createSemesterMutation.mutateAsync({ semesterData, yearId: +yearId });
                setSemesterToEdit(null);
              }}
            />
          ),
        )
      ) : (
        <></>
      )}

      {!isAddSemesterFormOpen && !semesterToEdit && (
        <AddSemester
          onClick={() => {
            setIsAddSemesterFormOpen(true);
          }}
        />
      )}

      {isAddSemesterFormOpen && !semesterToEdit && (
        <SaveSemesterForm
          yearId={+yearId}
          onCancel={() => {
            setIsAddSemesterFormOpen(false);
          }}
          isSaving={createSemesterMutation.isLoading}
          onAddSemester={async (semesterData) => {
            await createSemesterMutation.mutateAsync({ semesterData, yearId: +yearId });
            setIsAddSemesterFormOpen(false);
          }}
        />
      )}
    </>
  );
}
