import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import VideoFileOutlinedIcon from '@mui/icons-material/VideoFileOutlined';

export enum AcceptedFileTypes {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  GIF = 'image/gif',
  PDF = 'application/pdf',
  VIDEO = 'video/*',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export const ACCEPTED_FILE_TYPES: Record<AcceptedFileTypes, string[]> = {
  [AcceptedFileTypes.PNG]: ['.png'],
  [AcceptedFileTypes.JPEG]: ['.jpg', '.jpeg'],
  [AcceptedFileTypes.GIF]: ['.gif'],
  [AcceptedFileTypes.PDF]: ['.pdf'],
  [AcceptedFileTypes.VIDEO]: [],
  [AcceptedFileTypes.DOC]: ['.doc'],
  [AcceptedFileTypes.DOCX]: ['.docx'],
};

export const FILE_TYPE_ICONS: Record<AcceptedFileTypes, React.ComponentType> = {
  [AcceptedFileTypes.PNG]: InsertPhotoOutlinedIcon,
  [AcceptedFileTypes.JPEG]: InsertPhotoOutlinedIcon,
  [AcceptedFileTypes.GIF]: InsertPhotoOutlinedIcon,
  [AcceptedFileTypes.PDF]: PictureAsPdfOutlinedIcon,
  [AcceptedFileTypes.VIDEO]: VideoFileOutlinedIcon,
  [AcceptedFileTypes.DOC]: InsertDriveFileOutlinedIcon,
  [AcceptedFileTypes.DOCX]: InsertDriveFileOutlinedIcon,
};

export const createAcceptObject = (fileTypes: AcceptedFileTypes[]): Record<string, string[]> => {
  const acceptObject: Record<string, string[]> = {};

  fileTypes.forEach((fileType) => {
    if (ACCEPTED_FILE_TYPES[fileType]) {
      acceptObject[fileType] = ACCEPTED_FILE_TYPES[fileType];
    }
  });

  return acceptObject;
};

export const getAcceptedFileTypesString = (acceptedTypes: AcceptedFileTypes[]) => {
  const fileTypesSet = new Set<string>();

  acceptedTypes.forEach((type) => {
    const extensions = ACCEPTED_FILE_TYPES[type];
    if (extensions) {
      extensions.forEach((ext) => {
        const typeName = ext.slice(1).toUpperCase();
        fileTypesSet.add(typeName);
      });
    } else {
      const mimeTypeName = type.split('/')[1].toUpperCase();
      fileTypesSet.add(mimeTypeName);
    }
  });

  return Array.from(fileTypesSet).join(', ');
};
