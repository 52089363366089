import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, CircularProgress, IconButton, InputBase, Typography } from '@mui/material';
import { Paragraph } from 'components/Text';
import React, { FC, useState } from 'react';
import { BLACK, BLUE, GREY, RED } from 'theme/palette/new';
import { FILE_TYPE_ICONS } from 'utils/MIMETypes';
import { formatFileSize } from 'utils/toKb';
import { UploadingFile, useFileStore } from './fileStore';

type FileCardProps = {
  file: UploadingFile | File;
  idx: number;
  handleDelete?: () => void;
  handleRename?: (idx: number, filename: string, format: string) => void;
  handleDownload?: () => void;
  progress?: number;
};
export const FileCard: FC<FileCardProps> = (props) => {
  const { file, idx, handleDelete, handleRename, handleDownload, progress } = props;
  const { setIsEditing, isEditing } = useFileStore();
  const Icon = FILE_TYPE_ICONS[file.type];
  const filenameChunks = file.name.split('.');
  const format = filenameChunks.pop() || '';
  const plainName = filenameChunks.join('.');
  const [isEditingName, setIsEditingName] = useState(false);
  const [filename, setFilename] = useState(plainName);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setIsEditingName(false);
      setIsEditing(false);
      handleRename && handleRename(idx, filename, format);
    }
  };

  const setEditingOn = () => {
    setIsEditingName(true);
    setIsEditing(true);
  };

  const isUploading = progress && progress > 0 && progress < 100;

  return (
    <Box
      key={idx}
      display='flex'
      flexDirection='row'
      alignItems='center'
      pr={1}
      sx={{
        borderColor: BLUE[600],
        borderWidth: 1,
        borderRadius: '10px',
        borderStyle: 'solid',
        bgcolor: GREY[100],
        height: 70,
        mb: 1,
      }}
    >
      {isUploading ? (
        <CircularProgress
          variant='determinate'
          value={progress}
          sx={{ fontSize: 40, color: BLUE[600], mx: '12px' }}
        />
      ) : (
        <Icon sx={{ fontSize: 40, color: BLUE[600], mx: '12px' }} />
      )}
      <Box mr='auto'>
        {isEditingName || isEditing ? (
          <InputBase
            type='text'
            value={filename}
            onChange={({ target }) => setFilename(target.value)}
            onKeyDown={handleKeyDown}
            sx={{
              border: `1px solid ${GREY[400]}`,
              borderRadius: '8px',
              height: 30,
              pl: 1,
              color: GREY[400],
            }}
          />
        ) : (
          <Paragraph color={BLACK[100]}>
            {filename}.{format}
          </Paragraph>
        )}
        <Typography color={GREY[400]}>{formatFileSize(file.size)}</Typography>
      </Box>
      {!isUploading && handleDownload && (
        <IconButton onClick={handleDownload}>
          <DownloadOutlinedIcon sx={{ color: GREY[600] }} />
        </IconButton>
      )}
      {!isUploading && handleRename && (
        <IconButton onClick={setEditingOn}>
          <EditOutlinedIcon sx={{ color: BLACK[100] }} />
        </IconButton>
      )}
      {!isUploading && handleDelete && (
        <IconButton onClick={handleDelete}>
          <DeleteOutlineIcon sx={{ color: RED[600] }} />
        </IconButton>
      )}
    </Box>
  );
};
