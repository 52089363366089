import { API_ROUTES } from 'constants/apiRoutes';
import _ from 'lodash';
import { ServerResponse } from 'services/client/attendance/attendanceService';
import { useFetch, usePut, UseServiceProps, UseServicePropsSingle } from 'utils/react-query';
import { pathToUrl } from 'utils/routes';
import { z } from 'zod';

const SERVICE_NAME = 'Client Details';

export interface DCFValues {
  dcfFamilyServiceWorker: string | null;
  dcfServiceWorkerContactNumber: string | null;
}

export interface DJJValues {
  djjClientId: number | null;
  djjClientProbationOfficerName: string | null;
  djjClientContactNumber: string | null;
  djjClientEmail: string | null;
}

export enum clientDetails {
  id = 'id',
  clientId = 'clientID',
  firstName = 'firstName',
  lastName = 'lastName',
  birthDate = 'birthDate',
  middleName = 'middleName',
  preferedName = 'preferedName',
  pseudonym = 'pseudonym',
  photo = 'photo',
  birthGender = 'birthGender',
  genderIdentify = 'genderIdentify',
  preferedPronouns = 'preferedPronouns',
  race = 'race',
  ethnicity = 'ethnicity',
  primaryLanguage = 'primaryLanguage',
  status = 'status',
  transitionDate = 'transitionDate',
  dischargeDate = 'dischargeDate',
  programService = 'programService',
  socialSecurityNumber = 'socialSecurityNumber',
  djjClient = 'djjClient',
  djjClientId = 'djjClientId',
  DJJActiveSupervision = 'DJJActiveSupervision',
  djjClientEmail = 'djjClientEmail',
  djjClientProbationOfficerName = 'djjClientProbationOfficerName',
  djjClientContactNumber = 'djjClientContactNumber',
  PacePreviousEnrollment = 'pacePreviousEnrollment',
  PreviousEnrollmentDate = 'previousEnrollmentDate',
  PreviousDischargeDate = 'previousDischargeDate',
  dcfInvolvement = 'dcfInvolvement',
  dcfFamilyServiceWorker = 'dcfFamilyServiceWorker',
  dcfServiceWorkerContactNumber = 'dcfServiceWorkerContactNumber',
  fundingSources = 'fundingSources',
  additionalProgramMCKinney = 'additionalProgramMCKinney',
  additionalProgramJimmyRyce = 'additionalProgramJimmyRyce',
  transportationAssignmentId = 'transportationAssignmentId',
  centerId = 'centerId',
  programTypeId = 'programTypeId',
  referral = 'referral',
  programServiceAssignments = 'programServiceAssignments',
}

export enum referral {
  id = 'id',
  enrolledDate = 'enrolledDate',
  projectedTransitionDate = 'projectedTransitionDate',
}

export enum programServiceAssignment {
  programServiceAssignmentId = 'programServiceAssignmentId',
}

export const referralFields = z.object({
  /*  [referral.id]: z.number().nullable().optional().default(0), */
  [referral.enrolledDate]: z.date().nullable().optional(),
  [referral.projectedTransitionDate]: z.date().nullable().optional(),
});

export const referralBody = z.object({
  ...referralFields.shape,
  [referral.enrolledDate]: z.string().datetime().nullable(),
  [referral.projectedTransitionDate]: z.string().datetime().nullable(),
});

export const programServiceAssignmentFields = z.object({
  [programServiceAssignment.programServiceAssignmentId]: z.number().positive(),
});

export const validateNullableString = z
  .string()
  .transform((val) => (_.isEmpty(val) ? null : val))
  .nullable()
  .optional()
  .nullish();

export const validateZeroValue = z
  .number()
  .transform((val) => (val === 0 ? null : val))
  .transform((val) => (val === -1 ? null : val))
  .nullable()
  .optional();

export const clientDetailsFields = z.object({
  [clientDetails.id]: z.number().nullable().optional().default(0), // *
  [clientDetails.clientId]: z.number().nullable().optional(),
  [clientDetails.centerId]: z.number().nullable().optional().default(0),
  [clientDetails.firstName]: z.string().trim().min(1, { message: 'Required' }), // *
  [clientDetails.lastName]: z.string().trim().min(1, { message: 'Required' }), // *
  [clientDetails.middleName]: validateNullableString,
  [clientDetails.pseudonym]: validateNullableString,
  [clientDetails.preferedName]: validateNullableString,
  [clientDetails.birthDate]: z.date(), //*
  [clientDetails.photo]: validateNullableString,
  [clientDetails.birthGender]: validateZeroValue,
  [clientDetails.primaryLanguage]: z.number().positive({ message: 'Required' }), // *
  [clientDetails.ethnicity]: z.number().min(1),
  [clientDetails.race]: validateZeroValue,
  [clientDetails.referral]: z.any(),
  [clientDetails.programTypeId]: z.number().nullable(),
});

export type ClientDetailsFields = z.infer<typeof clientDetailsFields>;

export const useClientDetails = ({
  routeParams,
  params,
  config,
}: UseServicePropsSingle<ClientDetailsFields>) =>
  useFetch<ClientDetailsFields>(pathToUrl(API_ROUTES.CLIENT_BY_ID, routeParams), params, config);

export const useUpdateClientDetails = ({ routeParams }: UseServiceProps<ClientDetailsFields>) =>
  usePut<ServerResponse, ClientDetailsFields>(
    pathToUrl(API_ROUTES.CLIENT),
    undefined,
    SERVICE_NAME,
    pathToUrl(API_ROUTES.CLIENT_BY_ID, routeParams),
  );
