import { Box } from '@mui/material';
import { Paragraph } from 'components/Text';
import React, { useEffect, useRef, useState } from 'react';
import { BLUE } from 'theme/palette/new';

interface ScrollableContainerProps<T> {
  maxHeight: number;
  gradientEnable: boolean;
  queryContext: any;
  componentToRender: React.ComponentType<T>;
  showMoreText: string;
}

const ScrollableContainer = <T,>({
  maxHeight,
  gradientEnable,
  queryContext,
  componentToRender: ItemComponent,
  showMoreText,
}: ScrollableContainerProps<T>) => {
  const [isMaxHeightReached, setIsMaxHeightReached] = useState(false);
  const [showMoreContent, setShowMoreContent] = useState(true);

  const { data: commentsData, fetchNextPage, isFetchingNextPage, hasNextPage } = queryContext;
  const { pages = [] } = (commentsData as any) || {};

  const containerRef = useRef<HTMLDivElement>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const handleshowMoreContent = () => {
    setShowMoreContent(false);
  };

  const loadMoreItems = () => {
    if (observer.current && !isFetchingNextPage && hasNextPage) {
      observer.current.disconnect();
      fetchNextPage();
    }
  };

  const handleObserver = (node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreItems();
        }
      },
      { root: null, rootMargin: '0px', threshold: 1 },
    );
    if (node) observer.current.observe(node);
  };

  useEffect(() => {
    if (containerRef.current) {
      const currentHeight = containerRef.current.offsetHeight;
      setIsMaxHeightReached(currentHeight === maxHeight ? true : false);
    }
  }, [pages]);

  return (
    <>
      <Box
        component='div'
        maxHeight={maxHeight}
        sx={{
          overflowY: showMoreContent ? 'hidden' : 'auto',
          position: 'relative',
          mb: 2,
          ...(gradientEnable && {
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '50px',
              background: 'linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
              pointerEvents: 'none',
              display: showMoreContent ? 'initial' : 'none',
            },
          }),
        }}
        ref={containerRef}
      >
        {pages?.map((page) =>
          page?.key?.map((item, index) => (
            <ItemComponent
              key={index}
              ref={index === page.key.length - 2 ? handleObserver : null}
              {...item}
            />
          )),
        )}
      </Box>
      {showMoreContent && isMaxHeightReached && (
        <Box
          onClick={handleshowMoreContent}
          sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
        >
          <Paragraph underlined color={BLUE[800]}>
            {showMoreText}
          </Paragraph>
        </Box>
      )}
    </>
  );
};

export default ScrollableContainer;
