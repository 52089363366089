import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { Box, Button, Typography } from '@mui/material';
import { PrimaryTabItem, PrimaryTabs } from 'modules/global-academics/centers/academic-setup';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { dark } from 'theme/palette';
import TopHeaderBar from 'UI/organisms/client/global/client-top-header-bar';
import { create } from 'zustand';

type Tab = {
  name: string;
  label: string;
  Section: any;
  Form: any;
  disabled?: boolean;
};

type TabsPageProps = {
  caption?: string;
  defaultTabName: string;
  tabs: Tab[];
  noCaption?: boolean;
  noHeader?: boolean;
  fullWidth?: boolean;
  goBack?: () => void;
  goBackText?: string;
};

type TabsPageStoreType = {
  caption: string;
  setCaption: (string) => void;
};
export const useTabsPageStore = create<TabsPageStoreType>((set) => ({
  caption: '',
  setCaption: (caption) => set(() => ({ caption })),
}));

const TabsPage: React.FC<TabsPageProps> = (props: TabsPageProps) => {
  const {
    caption,
    defaultTabName,
    tabs,
    noCaption,
    noHeader,
    fullWidth = true,
    goBack,
    goBackText,
  } = props;
  const { caption: zustandCaption } = useTabsPageStore();
  const { pathname } = useLocation();
  const { section: s = '', id } = useParams();
  const [section, setSection] = useState<string>(defaultTabName);
  const navigate = useNavigate();

  const handleChange = (_, newValue: string) => {
    const defaultPath = `${pathname}${newValue}`;
    const NewTabPath = pathname.replace(`/${s}`, `/${newValue}`);
    const isEmpty = !s;

    const newPath = isEmpty ? defaultPath : NewTabPath;
    navigate(newPath);
    setSection(newValue);
  };

  useEffect(() => {
    if (s.includes(section)) return;

    const name = tabs.find((tab) => s === tab.name)?.name || defaultTabName;

    handleChange(undefined, name);
  }, [s]);

  const tab = tabs.find((tab) => tab.name === section);
  const Section = tab?.Section;
  const captionText = caption || zustandCaption;

  return (
    <Box component='div' mt={1}>
      {!noHeader && (
        <TopHeaderBar
          inputID='diagnosis-immunizations-form'
          notSaveButton
          onCancel={() => navigate(`/client/${id}/`)}
        />
      )}
      <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
        {!noCaption && (
          <Typography component='h1' color='darker' variant='h6'>
            {captionText}
          </Typography>
        )}

        {goBack && (
          <Button onClick={goBack} startIcon={<ArrowBackIosRoundedIcon />} size='small'>
            {goBackText || 'Return'}
          </Button>
        )}
      </Box>
      <Box sx={{ width: '100%' }} marginTop='32px'>
        <PrimaryTabs
          value={section}
          onChange={handleChange}
          style={{ color: dark[100] }}
          indicatorColor='secondary'
          variant={fullWidth ? 'fullWidth' : 'standard'}
        >
          {tabs.map(({ name, label, disabled }) => (
            <PrimaryTabItem value={name} label={label} disabled={disabled} key={name} />
          ))}
        </PrimaryTabs>
      </Box>

      {Section && <Section />}
    </Box>
  );
};

type FormsProps = { tabs: Tab[] };
export const Forms: React.FC<FormsProps> = (props: FormsProps) => {
  const { tabs } = props;
  const { section = '' } = useParams();

  const Form = tabs.find((tab) => section === tab.name)?.Form || null;

  return Form && <Form />;
};

export default TabsPage;
