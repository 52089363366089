import { TabProps } from '@mui/material';
import { dateZodType } from 'constants/zodTypes';
import { z } from 'zod';

export const periodFields = z.object({
  periodName: z.string().trim().min(1, { message: 'Required' }),
  endTime: dateZodType,
  startTime: dateZodType,
  centerId: z.number().nullable().optional(),
  academicYearId: z.number().positive({ message: 'Required' }),
});

export interface AcademicYearItemProps {
  id: string;
  yearName: string;
  yearStartDate: string;
  yearEndDate: string;
  isDefault: boolean;
  centerId?: number;
  onEdit: (id: string) => void;
}

export interface SemesterItemProps {
  id: string;
  semesterName: string;
  startDate: string;
  endDate: string;
  year: string;
  isActive: boolean;
  academicYearId: number;
  centerId?: number;
  yearId: number;
  onEdit: (id: string) => void;
}

export interface BlockItemProps {
  id: string;
  blockName: string;
  startDate: string;
  semesterInfo?: SemesterItemProps;
  semesterId?: string;
  onEdit: (id: string) => void;
}

export interface PeriodItemProps {
  id: string;
  periodName: string;
  startTime: string;
  endTime: string;
  onEdit: (id: string) => void;
}

export interface AddSemesterProps {
  onClick: () => void;
}

export interface AddBlockProps {
  onClick: () => void;
}

export interface AddPeriodProps {
  onClick: () => void;
}

export interface AddAcademicYearFormProps {
  onAddAcademicYear?: (data: AcademicYearItemProps) => void;
  onEditAcademicYear?: (data: AcademicYearItemProps) => void;
  isSaving?: boolean;
  onCancel: () => void;
  data?: AcademicYearItemProps;
}

export interface AddSemesterFormProps {
  onAddSemester?: (data: SemesterItemProps) => void;
  onEditSemester?: (data: SemesterItemProps) => void;
  isSaving?: boolean;
  yearId: number;
  onCancel: () => void;
  data?: SemesterItemProps;
}

export interface AddBlockFormProps {
  onAddBlock?: (data: BlockItemProps) => void;
  onEditBlock?: (data: BlockItemProps) => void;
  onCancel: () => void;
  centerId: number;
  academicYears: AcademicYearItemProps[];
  data?: BlockItemProps;
  isSaving: boolean;
}

export interface AddPeriodFormProps {
  onAddPeriod?: (data: PeriodItemProps) => void;
  onEditPeriod?: (data: PeriodItemProps) => void;
  onCancel: () => void;
  centerId: number;
  isSaving: boolean;
  data?: PeriodItemProps;
}

export interface AcademicSetupTabProps extends TabProps {
  optional?: boolean;
}

export type AcademicFormFields = {
  yearStartDate: string | null;
  yearEndDate: string | null;
  yearName: string;
  isDefault: boolean;
};

export type SemesterFormFields = {
  startDate: string | null;
  endDate: string | null;
  semesterName: string;
  academicYearId: number;
};

export type BlockFormFields = {
  blockName: string | null;
  startDate: string | null;
  semester: SemesterItemProps;
  yearid: number;
  semesterId?: string;
};

export type PeriodFormFields = {
  periodName: string;
  startTime: string;
  endTime: string;
  academicYearId: number;
};

/* Calendar Types */
export type Events = Event[];

export interface Event {
  centerInformation: CenterInformation;
  createdDate: string;
  modifiedDate: string;
  id: number;
  eventName: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  description: string;
  isAllday: boolean;
  isSchoolClosed?: boolean | null;
  reasonSchoolClosed: number | null;
  descriptionSchoolClosed: string | null;
}

export interface CenterInformation {
  id: number;
  agencyId: number;
  name: string;
  districtId: string;
  centerId: string;
  addressId: number;
  capacity: number;
  address: null;
  profilePhoto: string;
  bannerPhoto: string;
  profilePhotoURL: string | null;
  bannerPhotoURL: string | null;
}
