import { Box, Chip } from '@mui/material';
import React from 'react';

const EnvironmentComponent: React.FunctionComponent = (): JSX.Element => {
  return (
    <Box>
      {process.env.NODE_ENV && process.env.NODE_ENV !== 'production' && (
        <Chip sx={{ ml: 1 }} color='warning' label='Develop Environment' />
      )}
    </Box>
  );
};

export default EnvironmentComponent;
