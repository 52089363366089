import { Box, Skeleton } from '@mui/material';
import React from 'react';
import { grey, light, shadow } from '../../../../theme/palette';

const NavigationSkeleton: React.FC = () => {
  return (
    <Box component='div' p={2} width='100%'>
      <Box
        boxShadow={shadow[200]}
        borderRadius='0px 8px 8px 0px'
        height='328px'
        bgcolor={light[100]}
      >
        <Box mt={1} display='flex'>
          <Box
            py={1}
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='space-between'
            height='184px'
            bgcolor={grey[900]}
            width='56px'
          >
            <Skeleton
              sx={{ borderRadius: 1 }}
              variant='rectangular'
              animation='wave'
              width='24px'
              height='24px'
            />
            <Skeleton
              sx={{ borderRadius: 1 }}
              variant='rectangular'
              animation='wave'
              height='24px'
              width='24px'
            />
            <Skeleton
              sx={{ borderRadius: 1 }}
              variant='rectangular'
              animation='wave'
              height='24px'
              width='24px'
            />
            <Skeleton
              sx={{ borderRadius: 1 }}
              variant='rectangular'
              animation='wave'
              height='24px'
              width='24px'
            />
            <Skeleton
              sx={{ borderRadius: 1 }}
              variant='rectangular'
              animation='wave'
              height='24px'
              width='24px'
            />
          </Box>
          <Box px={1} width='calc(100% - 56px)'>
            <Box mt={1} mb={3} display='flex' alignItems='center' justifyContent='space-between'>
              <Skeleton
                sx={{ borderRadius: 1 }}
                variant='rectangular'
                animation='wave'
                height='17px'
                width='90%'
              />
              <Skeleton variant='circular' animation='wave' height='16px' width='16px' />
            </Box>
            <Box mb={3} display='flex' alignItems='center' justifyContent='space-between'>
              <Skeleton
                sx={{ borderRadius: 1 }}
                variant='rectangular'
                animation='wave'
                height='17px'
                width='90%'
              />
              <Skeleton variant='circular' animation='wave' height='16px' width='16px' />
            </Box>
            <Box mb={3} display='flex' alignItems='center' justifyContent='space-between'>
              <Skeleton
                sx={{ borderRadius: 1 }}
                variant='rectangular'
                animation='wave'
                height='17px'
                width='90%'
              />
              <Skeleton variant='circular' animation='wave' height='16px' width='16px' />
            </Box>
            <Box mb={3} display='flex' alignItems='center' justifyContent='space-between'>
              <Skeleton
                sx={{ borderRadius: 1 }}
                variant='rectangular'
                animation='wave'
                height='17px'
                width='90%'
              />
              <Skeleton variant='circular' animation='wave' height='16px' width='16px' />
            </Box>
            <Box mb={3} display='flex' alignItems='center' justifyContent='space-between'>
              <Skeleton
                sx={{ borderRadius: 1 }}
                variant='rectangular'
                animation='wave'
                height='17px'
                width='90%'
              />
              <Skeleton variant='circular' animation='wave' height='16px' width='16px' />
            </Box>
            <Box mb={3} display='flex' alignItems='center' justifyContent='space-between'>
              <Skeleton
                sx={{ borderRadius: 1 }}
                variant='rectangular'
                animation='wave'
                height='17px'
                width='90%'
              />
              <Skeleton variant='circular' animation='wave' height='16px' width='16px' />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NavigationSkeleton;
