import AddRounded from '@mui/icons-material/AddRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Box, Button, styled, Tab, Tabs, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAcademicYears } from 'services/global-academics/academic-setup/academicYearService';
import { useBlocks } from 'services/global-academics/academic-setup/blocksService';
import { usePeriods } from 'services/global-academics/academic-setup/periodsService';
import { dark, grey, purple } from 'theme/palette';
import {
  CardContentBordered,
  CardContentBorderedForAction,
  CardShadowed,
} from 'UI/molecules/global/cards';
import { LinearProgressBar } from 'UI/molecules/global/linear-progress-bar';
import AcademicYear from './academic-year';
import SaveBlockForm from './forms/SaveBlock';
import CalendarEvents from './forms/SaveCalendar';
import SavePeriodForm from './forms/SavePeriod';
import {
  AcademicSetupTabProps,
  AddPeriodProps,
  BlockItemProps,
  PeriodItemProps,
} from './interfaces';

export const PrimaryTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: purple[100],
  },
});

export const PrimaryTabItem = styled((props: AcademicSetupTabProps) => <Tab {...props}></Tab>)({
  textTransform: 'none',
  '&.Mui-selected': {
    color: dark[100],
    fontWeight: 500,
  },
});

// Blocks Components
const BlockItem: React.FC<BlockItemProps> = ({
  id,
  blockName,
  startDate,
  semesterInfo,
  onEdit,
}) => {
  const formattedStartDate = moment(startDate).format('ddd, MMM Do');
  return (
    <CardContentBordered>
      <Box component='div' display='flex' justifyContent='space-between' alignItems='center'>
        <Box component='div' display='flex' flexDirection='column' gap='4px'>
          <Typography fontSize='14px'>{blockName}</Typography>
          <Typography fontSize='14px' color={grey[200]}>
            Starts {formattedStartDate}
          </Typography>
          <Typography fontSize='14px' color={grey[200]}>
            {semesterInfo?.semesterName}
          </Typography>
        </Box>
        <Box
          component='div'
          display='flex'
          alignItems='center'
          style={{ cursor: 'pointer' }}
          gap='8px'
          onClick={() => {
            onEdit(id);
          }}
        >
          <EditRoundedIcon fontWeight={500} color='primary' style={{ fontSize: '14px' }} />
          <Typography fontSize='14px' fontWeight={500} color='primary'>
            Edit Block
          </Typography>
        </Box>
      </Box>
    </CardContentBordered>
  );
};

const AddBlock: React.FC<AddPeriodProps> = ({ onClick }) => {
  return (
    <CardContentBorderedForAction>
      <Box
        component='div'
        display='flex'
        alignItems='center'
        onClick={() => {
          onClick();
        }}
      >
        <Box
          component='div'
          display='flex'
          alignItems='center'
          style={{ cursor: 'pointer' }}
          gap='4px'
        >
          <AddRounded color='primary' fontWeight={700} style={{ fontSize: '16px' }} />
          <Typography fontSize='14px' fontWeight={500} color='primary'>
            Create a new Block
          </Typography>
        </Box>
      </Box>
    </CardContentBorderedForAction>
  );
};

// Periods Components
const PeriodItem: React.FC<PeriodItemProps> = ({ id, periodName, startTime, endTime, onEdit }) => {
  const formattedStartTime = startTime.substring(0, startTime.length - 3);
  const formattedEndTime = endTime.substring(0, startTime.length - 3);
  return (
    <CardContentBordered>
      <Box component='div' display='flex' justifyContent='space-between' alignItems='center'>
        <Box component='div' display='flex' flexDirection='column' gap='4px'>
          <Typography fontSize='14px'>{periodName}</Typography>
          <Typography fontSize='14px' color={grey[200]}>
            {formattedStartTime} - {formattedEndTime}
          </Typography>
        </Box>
        <Box
          component='div'
          display='flex'
          alignItems='center'
          style={{ cursor: 'pointer' }}
          gap='8px'
          onClick={() => {
            onEdit(id);
          }}
        >
          <EditRoundedIcon fontWeight={500} color='primary' style={{ fontSize: '14px' }} />
          <Typography fontSize='14px' fontWeight={500} color='primary'>
            Edit Period
          </Typography>
        </Box>
      </Box>
    </CardContentBordered>
  );
};

const AddPeriod: React.FC<AddPeriodProps> = ({ onClick }) => {
  return (
    <CardContentBorderedForAction>
      <Box
        component='div'
        display='flex'
        alignItems='center'
        onClick={() => {
          onClick();
        }}
      >
        <Box
          component='div'
          display='flex'
          alignItems='center'
          style={{ cursor: 'pointer' }}
          gap='4px'
        >
          <AddRounded color='primary' fontWeight={700} style={{ fontSize: '16px' }} />
          <Typography fontSize='14px' fontWeight={500} color='primary'>
            Create a new Period
          </Typography>
        </Box>
      </Box>
    </CardContentBorderedForAction>
  );
};

const tabHandlerBasedHash = (pathname: string) => {
  if (pathname.includes('academic-years')) return 'academic-years';
  if (pathname.includes('blocks')) return 'blocks';
  if (pathname.includes('periods')) return 'periods';
  if (pathname.includes('calendar')) return 'calendar';
  return 'academic-years';
};

export default function AcademicSetup() {
  const { centerId = '1', agencyId } = useParams();

  const [section, setSection] = useState<string>('academic-years');

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const isValidPathPattern = /academic-years|blocks|periods|calendar/;

    if (location.pathname) {
      setSection(tabHandlerBasedHash(location.pathname));
    }

    if (!isValidPathPattern.test(location.pathname)) {
      navigate('academic-years/');
    }
  }, [location]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`/agency/${agencyId}/center/${centerId}/academic-setup/${newValue}/`);
  };

  const BlocksSection = () => {
    const { blocksQuery, blocksMutation } = useBlocks({ centerId: +centerId });

    const [isAddBlockFormOpen, setIsAddBlockFormOpen] = useState(false);
    const [blockToEdit, setBlockToEdit] = useState<string | null>(null);
    const { academicYearsQuery } = useAcademicYears({ centerId: +centerId });

    const academicYears = academicYearsQuery.data || [];
    const blocks = blocksQuery.data || [];

    if (blocksQuery.isLoading) {
      return <LinearProgressBar />;
    }

    return (
      <CardShadowed>
        {blocks.length ? (
          blocks.map((blocksProps, index) =>
            blockToEdit !== blocksProps.id ? (
              <BlockItem
                key={`blockItem ${index}`}
                {...blocksProps}
                onEdit={(id) => {
                  setBlockToEdit(id);
                }}
              />
            ) : (
              <SaveBlockForm
                key={`blockItem ${index}`}
                data={blocksProps}
                isSaving={blocksMutation.isLoading}
                academicYears={academicYears}
                centerId={+centerId}
                onCancel={() => {
                  setBlockToEdit(null);
                }}
                onEditBlock={async (blockData) => {
                  await blocksMutation.mutateAsync({ blockData });
                  setBlockToEdit(null);
                }}
              />
            ),
          )
        ) : (
          <></>
        )}

        {!isAddBlockFormOpen && !blockToEdit && (
          <AddBlock
            onClick={() => {
              setIsAddBlockFormOpen(true);
            }}
          />
        )}

        {isAddBlockFormOpen && !blockToEdit && (
          <SaveBlockForm
            academicYears={academicYears}
            centerId={+centerId}
            onCancel={() => {
              setIsAddBlockFormOpen(false);
            }}
            isSaving={blocksMutation.isLoading}
            onAddBlock={async (blockData) => {
              await blocksMutation.mutateAsync({ blockData });
              setIsAddBlockFormOpen(false);
            }}
          />
        )}
      </CardShadowed>
    );
  };

  const PeriodsSection = () => {
    const { periodsQuery, periodsMutation } = usePeriods({ centerId: +centerId });

    const [isAddPeriodFormOpen, setIsAddPeriodFormOpen] = useState(false);
    const [periodToEdit, setPeriodToEdit] = useState<string | null>(null);

    const periods = periodsQuery.data || [];

    if (periodsQuery.isLoading) {
      return <LinearProgressBar />;
    }

    return (
      <CardShadowed>
        {periods.length ? (
          periods.map((periodsProps, index) =>
            periodToEdit !== periodsProps.id ? (
              <PeriodItem
                key={`periodItem ${index}`}
                {...periodsProps}
                onEdit={(id) => {
                  setPeriodToEdit(id);
                }}
              />
            ) : (
              <SavePeriodForm
                centerId={Number(centerId)}
                key={`periodItem ${index}`}
                data={periodsProps}
                onCancel={() => {
                  setPeriodToEdit(null);
                }}
                isSaving={periodsMutation.isLoading}
                onEditPeriod={async (periodData) => {
                  await periodsMutation.mutateAsync({ periodData });
                  setPeriodToEdit(null);
                }}
              />
            ),
          )
        ) : (
          <></>
        )}

        {!isAddPeriodFormOpen && !periodToEdit && (
          <AddPeriod
            onClick={() => {
              setIsAddPeriodFormOpen(true);
            }}
          />
        )}

        {isAddPeriodFormOpen && !periodToEdit && (
          <SavePeriodForm
            centerId={Number(centerId)}
            onCancel={() => {
              setIsAddPeriodFormOpen(false);
            }}
            isSaving={periodsMutation.isLoading}
            onAddPeriod={async (periodData) => {
              await periodsMutation.mutateAsync({ periodData });
              setIsAddPeriodFormOpen(false);
            }}
          />
        )}
      </CardShadowed>
    );
  };

  return (
    <>
      {/* Title and Return Nav */}
      <Box component='div' display='flex' justifyContent='space-between' marginTop='31px'>
        <Box component='div'>
          <Typography component='h1' color='primary' variant='h6'>
            Center Academic Set Up
          </Typography>
        </Box>

        <Box display='flex' justifyContent='flex-end' alignItems='flex-start' component='div'>
          <Button
            startIcon={<ArrowBackIosRoundedIcon />}
            size='small'
            sx={{ textTransform: 'capitalize', cursor: 'pointer' }}
            onClick={() => {
              navigate(`/agency/${agencyId}/center/${centerId}/center-information`);
            }}
          >
            Return to Center Information
          </Button>
        </Box>
      </Box>

      {/* Tabs */}
      <Box sx={{ width: '100%' }} marginTop='32px'>
        <PrimaryTabs
          value={section}
          onChange={handleChange}
          style={{ color: dark[100] }}
          indicatorColor='secondary'
          aria-label='Academic Setup Tabs'
          variant='fullWidth'
        >
          <PrimaryTabItem value='academic-years' label='Academic Years and Semesters' />
          <PrimaryTabItem
            value='blocks'
            label={
              <Box component='div' display='flex' alignItems='center' gap='4px'>
                Blocks
                <Typography color={grey[100]} fontSize='12px'>
                  (Optional)
                </Typography>
              </Box>
            }
          />
          <PrimaryTabItem value='periods' label='Periods' />
          <PrimaryTabItem value='calendar' label='Center Calendar' />
        </PrimaryTabs>
      </Box>

      {/* Forms */}
      <Box component='div' marginTop='60px'>
        {section === 'academic-years' && <AcademicYear centerId={centerId} />}
        {section === 'blocks' && <BlocksSection />}
        {section === 'periods' && <PeriodsSection />}
        {section === 'calendar' && <CalendarEvents />}
      </Box>
    </>
  );
}
