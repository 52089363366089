import { phoneRegex } from 'services/client/demographics/household-details/householdDetailsServices';
import { z } from 'zod';

export const switchZodType = z.boolean().optional().default(false);

export const dateZodType = z.union([z.date(), z.string().nonempty()]);

export function asOptionalField<T extends z.ZodTypeAny>(schema: T) {
  return schema
    .nullable()
    .optional()
    .transform((val) => (val ? val : null));
}

export const requiredPhoneValidations = z
  .string()
  .regex(phoneRegex, 'Invalid number')
  .trim()
  .min(1, { message: 'Required' });

export const refineFields = (data, ctx, requirements, fields) => {
  const reqs = requirements.map((req) => !!data[req]);
  const canCheck = reqs.every((req) => req);

  if (!canCheck) return;

  fields.forEach((field) => {
    if (!data[field]) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Field Can't be empty",
        path: [field],
      });
    }
  });
};
