import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { purple } from 'theme/palette';
import { TreatmentDataResponse } from 'types/client/treatment-planning';

const treatmentID = localStorage.getItem('treatmentID');

export const getTreatmentDetails = createAsyncThunk(
  'get-treatment-details',
  async (clientID: number) => {
    try {
      const { data } = await axios.get<TreatmentDataResponse>(
        `https://mqi-api-qa.azure-api.net/api/client/${clientID}/treatment-planning/${treatmentID}`,
      );

      toast.success('Form data has been loaded', {
        duration: 2000,
        style: {
          borderLeft: `5px solid ${purple[100]}`,
          backgroundColor: purple[400],
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

interface treatmentData {
  data: object;
  isLoading: boolean;
}

const initialState = {
  data: {},
  isLoading: false,
} as treatmentData;

const treatmentSlice = createSlice({
  name: 'treatment',
  initialState,
  reducers: {},
  extraReducers: {
    [getTreatmentDetails.pending.type]: (state) => {
      state.isLoading = true;
    },
    [getTreatmentDetails.fulfilled.type]: (state, { payload: data }) => {
      const info = {
        id: data.id,
        planDevelopmentStartDate:
          data.planDevelopmentStartDate !== null ? new Date(data.planDevelopmentStartDate) : null,
        planDevelopmentCompletedDate:
          data.planDevelopmentCompletedDate !== null
            ? new Date(data.planDevelopmentCompletedDate)
            : null,
        managerApprovalDate:
          data.managerApprovalDate !== null ? new Date(data.managerApprovalDate) : null,
        strengths: data.strengths !== null ? data.strengths : '',
        needs: data.needs !== null ? data.needs : '',
        goals: data.goals,
      };

      state.data = info;
      state.isLoading = false;
    },
  },
});

export default treatmentSlice.reducer;
