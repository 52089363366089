import { Avatar, Badge, Box, Container, Typography } from '@mui/material';
import CameraIcon from 'assets/images/svg/camera-icon.svg';
import { useQuery } from 'react-query';
import { Outlet, useParams } from 'react-router-dom';
import { AgencyResponse, getAgencyInformation } from 'services/global-academics/agencies';
import { grey, light, purple, shadow } from 'theme/palette';
import Header from 'UI/organisms/global/header';
import Navigation from 'UI/organisms/global/navigation';
import GreyHeaderInfo from 'UI/organisms/grey-header-info';
import { useRandomColorAvatar } from 'UI/organisms/tasks/taskCard/hooks';

const TrainingLayout = () => {
  const { agencyId } = useParams();
  const agencyID = Number(agencyId);

  const { data: agency, refetch: refetchAgency } = useQuery<AgencyResponse>({
    queryKey: ['get-agency-information'],
    queryFn: () => getAgencyInformation(agencyID),
  });

  return (
    <Box component='div'>
      <Header fixed={false} />
      <Container component='div' maxWidth='xl' sx={{ p: { xs: 1.3 } }}>
        <Box flexDirection={{ xs: 'column', md: 'row' }} component='div' display='flex'>
          <Box
            component='div'
            display='flex'
            flexDirection='column'
            alignItems={{
              xs: 'flex-start',
              md: 'normal',
            }}
            width={{
              xs: '100%',
              md: '270px',
            }}
          >
            <Navigation />
          </Box>
          <Box
            component='div'
            width={{
              xs: '100%',
              md: 'calc(100% - 270px)',
            }}
          >
            <Box component='div' pl={{ xs: 0, md: 2 }} pt={2}>
              {agency && (
                <GreyHeaderInfo
                  refetchCenter={refetchAgency}
                  isOnlyDetail={false}
                  avatarComponent={
                    <Badge
                      overlap='circular'
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={
                        <Avatar
                          sx={{
                            width: 26,
                            height: 26,
                            cursor: 'pointer',
                            bgcolor: purple[100],
                            border: '2px solid white',
                          }}
                        >
                          <Box component='img' src={CameraIcon} alt='camera-icon' />
                        </Avatar>
                      }
                    >
                      {agency.avatarProfilePhotoURL ? (
                        <Avatar
                          sx={{
                            height: 70,
                            width: 70,
                            bgcolor: light[100],
                            borderRadius: 2,
                            boxShadow: shadow[200],
                            color: grey[800],
                          }}
                          src={agency.avatarProfilePhotoURL}
                        />
                      ) : (
                        <Avatar
                          sx={{
                            height: 70,
                            width: 70,
                            bgcolor: light[100],
                            borderRadius: 2,
                            boxShadow: shadow[200],
                            color: grey[800],
                          }}
                          variant='square'
                        >
                          <Typography
                            component='h3'
                            color={useRandomColorAvatar(agency.agencyName)}
                            variant='h4'
                          >{`${agency.agencyName.split(' ')[0][0]}`}</Typography>
                        </Avatar>
                      )}
                    </Badge>
                  }
                  photo={agency.avatarProfilePhotoURL}
                  name={agency?.agencyName}
                  agencyAddress={`${agency?.city !== null ? agency.city : ''} ${
                    agency?.state !== null ? agency.state : ''
                  }`}
                />
              )}
            </Box>
            <Box component='div' pl={{ xs: 0, md: 1 }}>
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default TrainingLayout;
