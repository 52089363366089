import { useCatalogs } from 'services/catalogs/catalogsService';
import { optionType } from 'utils/data-to-options';

enum CONSTANTS {
  CENTER_SUPPORT_TYPE = 'SupportType',
  PROGRAM_TYPE = 'ProgramType',
  TICKET_PRIORITY = 'TicketPriority',
  SERVICE_TYPE = 'ServiceType',
  ATTENDANCE_TYPE = 'Attendance',
  TICKET_STATUS = 'TicketStatus',
}

export type catalogs = {
  centerSupportTypeOptions: optionType[];
  programTypeOptions: optionType[];
  ticketPriorityOptions: optionType[];
  attendanceTypeOptions: optionType[];
  ticketStatusOptions: optionType[];
};

export type GeneralType = {
  id: string;
  name: string;
};

const useGeneralType = (internalName: string): GeneralType[] => {
  const { catalogsQuery } = useCatalogs({ internalName });
  if (catalogsQuery.data) {
    return catalogsQuery.data.attributes.map((attribute) => {
      return {
        id: attribute.id,
        name: attribute.attributeName,
      };
    });
  }
  return [];
};

export const useCenterSupportType = (): GeneralType[] => {
  return useGeneralType(CONSTANTS.CENTER_SUPPORT_TYPE);
};

export const useProgramType = (): GeneralType[] => {
  return useGeneralType(CONSTANTS.PROGRAM_TYPE);
};

export const useTicektPriority = (): GeneralType[] => {
  return useGeneralType(CONSTANTS.TICKET_PRIORITY);
};

export const useServiceType = (): GeneralType[] => {
  return useGeneralType(CONSTANTS.SERVICE_TYPE);
};

export const useAttendanceType = (): GeneralType[] => {
  return useGeneralType(CONSTANTS.ATTENDANCE_TYPE);
};

export const useTicketStatus = (): GeneralType[] => {
  return useGeneralType(CONSTANTS.TICKET_STATUS);
};
