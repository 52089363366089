import { create } from 'zustand';

export interface UploadingFile {
  blob: File | undefined;
  servername: string;
  container: string;
  name: string;
  size: number;
  type: string;
  progress: number;
  uploadUrl: string;
  uri: string;
}

export interface FileStore {
  files: File[];
  isEditing: boolean;
  isUploading: boolean;
  uploadingFiles: UploadingFile[];
  getFiles: () => File[];
  setFiles: (files: File[]) => void;
  clearFiles: () => void;
  getIsEditing: () => boolean;
  setIsEditing: (isEditing: boolean) => void;
  getIsUploading: () => boolean;
  setIsUploading: (isUploading: boolean) => void;
  getUploadingFiles: () => UploadingFile[];
  setUploadingFiles: (uploadingFiles: UploadingFile[]) => void;
  updateUploadingFileProgress: (name: string, progress: number) => void;
  setUploadingFileDone: (name: string) => void;
  clearUploadingFiles: () => void;
}

export const useFileStore = create<FileStore>((set, get) => ({
  files: [],
  isEditing: false,
  isUploading: false,
  uploadingFiles: [],
  getFiles: () => get().files,
  setFiles: (files: File[]) => set({ files }),
  clearFiles: () => set({ files: [] }),
  getIsEditing: () => get().isEditing,
  setIsEditing: (isEditing: boolean) => set({ isEditing }),
  getIsUploading: () => get().isUploading,
  setIsUploading: (isUploading: boolean) => set({ isUploading }),
  getUploadingFiles: () => get().uploadingFiles,
  setUploadingFiles: (uploadingFiles: UploadingFile[]) => set({ uploadingFiles }),
  updateUploadingFileProgress: (name: string, progress: number) => {
    set((state) => ({
      uploadingFiles: state.uploadingFiles.map((file) =>
        file.name === name ? { ...file, progress } : file,
      ),
    }));
  },
  setUploadingFileDone: (name: string) => {
    set((state) => ({
      uploadingFiles: state.uploadingFiles.map((file) =>
        file.name === name ? { ...file, done: true, progress: 100 } : file,
      ),
    }));
  },
  clearUploadingFiles: () => set({ uploadingFiles: [] }),
}));
