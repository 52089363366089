import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { Avatar, Badge, Button, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CameraIcon from 'assets/images/svg/camera-icon.svg';
import { useCenterPickerStore } from 'components/CenterPicker';
import React from 'react';
import ProgressiveImage from 'react-progressive-graceful-image';
import { Address } from 'services/global-academics/centers';
import { grey, light, purple, shadow } from 'theme/palette';
import FileModal from '../file-modal-photo';
import { useRandomColorAvatar } from '../tasks/taskCard/hooks';
interface Props {
  name: string;
  photo: string | null;
  optionalButton?: JSX.Element | boolean;
  optionalActionButton?: JSX.Element | boolean;
  avatarComponent?: JSX.Element | boolean;
  agencyAddress?: string;
  agencyAddressComplement?: string;
  optionalName?: string;
  isOnlyDetail: boolean;
  onOpenProfileModal?: () => void;
  openModalProfile?: boolean;
  onCloseModalProfile?: () => void;
  refetchCenter: () => void;
  address?: Address;
}

const GreyHeaderInfo: React.FC<Props> = ({
  onOpenProfileModal,
  openModalProfile,
  onCloseModalProfile,
  name,
  photo,
  optionalButton,
  optionalActionButton,
  agencyAddress,
  agencyAddressComplement,
  optionalName,
  isOnlyDetail,
  refetchCenter,
  address,
  avatarComponent,
}) => {
  const { setIsOpen } = useCenterPickerStore();
  return (
    <>
      <Box
        bgcolor={grey[600]}
        px={{ xs: 2, md: 4 }}
        py={{ xs: 1, md: 2 }}
        borderRadius={isOnlyDetail ? '6px' : '0px 0px 6px 6px'}
      >
        {openModalProfile && onCloseModalProfile && (
          <FileModal
            refetchCenter={refetchCenter}
            open={openModalProfile}
            onClose={onCloseModalProfile}
            isProfile={true}
            photo={photo}
            name={name}
          />
        )}
        <Box
          component='div'
          width='100%'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box component='div' display='flex' width='100%'>
            <Box component='div' display='flex' justifyContent='flex-start' alignItems='center'>
              {avatarComponent ? (
                avatarComponent
              ) : (
                <Badge
                  overlap='circular'
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    !isOnlyDetail &&
                    onOpenProfileModal && (
                      <Avatar
                        onClick={() => onOpenProfileModal()}
                        sx={{
                          width: 26,
                          height: 26,
                          cursor: 'pointer',
                          bgcolor: purple[100],
                          border: '2px solid white',
                        }}
                      >
                        <Box component='img' src={CameraIcon} alt='camera-icon' />
                      </Avatar>
                    )
                  }
                >
                  {photo ? (
                    <ProgressiveImage src={photo} placeholder={photo}>
                      {(src, loading) => (
                        <Box
                          component='img'
                          className={`image${loading ? ' loading' : ' loaded'}`}
                          src={src}
                          sx={{ objectFit: 'cover', borderRadius: '6px', boxShadow: shadow[200] }}
                          width={70}
                          height={70}
                          alt='teacher profile'
                        />
                      )}
                    </ProgressiveImage>
                  ) : (
                    <Avatar
                      sx={{
                        height: 70,
                        width: 70,
                        bgcolor: light[100],
                        borderRadius: 2,
                        boxShadow: shadow[200],
                        color: grey[800],
                      }}
                      variant='square'
                    >
                      <Typography
                        component='h3'
                        color={useRandomColorAvatar(name)}
                        variant='h4'
                      >{`${name.split(' ')[0][0]}`}</Typography>
                    </Avatar>
                  )}
                </Badge>
              )}
            </Box>
            <Box
              component='div'
              width={{ xs: '100%', md: 'auto' }}
              mx={2}
              display='flex'
              flexDirection='column'
              alignItems='flex-start'
            >
              <Box component='div' display='flex' justifyContent='flex-end' alignItems='center'>
                <Typography mr={1} component='h3' color='primary' variant='body1'>
                  {name}
                </Typography>
                {optionalActionButton && optionalActionButton}
              </Box>
              {optionalName && (
                <Typography component='p' color={grey[800]} variant='body2'>
                  {optionalName}
                </Typography>
              )}
              <Box component='div'>
                {agencyAddress && (
                  <Typography component='p' color={grey[800]} variant='body2'>
                    {agencyAddress}
                  </Typography>
                )}
                {agencyAddressComplement && (
                  <Typography component='p' color={grey[800]} variant='body2'>
                    {agencyAddressComplement}
                  </Typography>
                )}

                {address && (
                  <Box component='div'>
                    <Typography component='p' color={grey[800]} variant='body2'>
                      {address.addressLine1 && address.addressLine1}{' '}
                      {address.city && `/ ${address.city}`} {address.state && `/ ${address.state}`}{' '}
                      {address.county && `/ ${address.county}`}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          {optionalButton && (
            <Box width='300px' component='div' display='flex' justifyContent='flex-end'>
              {optionalButton}
            </Box>
          )}
          <Button
            sx={{ width: 216 }}
            variant='secondarybtn'
            startIcon={<ChangeCircleOutlinedIcon />}
            onClick={() => setIsOpen(true)}
          >
            Change Center
          </Button>
        </Box>
      </Box>
      <Box mt={4}>
        <Divider />
      </Box>
    </>
  );
};

export default GreyHeaderInfo;
