import { FC } from 'react';
import { BaseText, FontWeight, TextElement, TextProps, WithFontWeight } from './BaseText';

export const H4: FC<TextProps & WithFontWeight> = (props: TextProps & WithFontWeight) => {
  const { children, fontWeight, ...rest } = props;
  return (
    <BaseText element={TextElement.h4} fontWeight={fontWeight || FontWeight.regular} {...rest}>
      {children}
    </BaseText>
  );
};
