import { Accordion, StepConnector, StepLabel, stepLabelClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BLUE, GREY, RED } from 'theme/palette/new';
import { dark, grey, purple, red, shadow } from '../../palette';

export const inputStyle = {
  pl: 2,
  pr: 1,
  mt: 1,
  bgcolor: grey[600],
  height: '38px',
  borderBottom: `1px solid ${purple[100]}`,
  borderRadius: '4px 4px 0px 0px',
  '&.Mui-focused': {
    border: `1px solid ${purple[300]}`,
    borderBottom: `1px solid ${purple[100]}`,
  },
  fontSize: '14px',
};

export const inputErrorStyles = {
  pl: 2,
  pr: 2,
  mt: 1,
  bgcolor: grey[600],
  height: '38px',
  borderBottom: `1px solid ${red[100]}`,
  borderRadius: '4px 4px 0px 0px',
  fontSize: '14px',
};

export const autoCompleteStyles = {
  width: '100%',
  borderRadius: '4px 4px 0px 0px',
  borderColor: 'transparent',
  mt: '7px',
  '& .MuiAutocomplete-popupIndicator': { height: '25px', width: '25px' },
  bgcolor: grey[600],
  '& .MuiOutlinedInput-root': {
    height: 'auto',
    minHeight: '38px',
    fontSize: '14px',
    borderBottom: `1px solid ${purple[100]}`,
    borderRadius: '4px 4px 0px 0px',
    paddingLeft: '10px',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: 'transparent',
  },
  '& .MuiInputBase-sizeSmall': {
    padding: '4px !important',
  },
  '.MuiSelect-select': {
    fontSize: '14px',
  },
};

export const dropDownWithSearchStyles = {
  width: '100%',
  borderRadius: '4px 4px 0px 0px',
  borderColor: 'transparent',
  '& .MuiAutocomplete-popupIndicator': { height: '25px', width: '25px' },
  bgcolor: grey[600],
  '& .MuiOutlinedInput-root': {
    height: 'auto',
    minHeight: '38px',
    fontSize: '12px',
    borderBottom: `1px solid ${purple[100]}`,
    borderRadius: '4px 4px 0px 0px',
    paddingLeft: '10px',
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: 'transparent',
  },
  '& .MuiInputBase-sizeSmall': {
    padding: '4px !important',
  },
  '.MuiSelect-select': {
    fontSize: '14px',
  },
};
export const autoCompleteMultiStyles = {
  ...autoCompleteStyles,
  borderRadius: '10px',
  '& .MuiAutocomplete-root': {
    ...autoCompleteStyles['& .MuiAutocomplete-root'],
  },
  '& .MuiFormControl-root': {
    height: '48px',
    borderRadius: '10px',
  },
  '& .MuiInputBase-root': {
    padding: 0,
    height: '100%',
    borderRadius: '10px',
    '& > input': {
      marginLeft: '45px',
    },
  },
  '& .MuiInputBase-adornedStart': {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  '& .multisearch': {
    position: 'absolute',
    top: '13px',
    left: '12px',
  },
};

export const autoCompleteAltStyles = (height = '38px') => ({
  width: '100%',
  borderRadius: '4px 4px 0px 0px',
  borderColor: 'transparent',
  mt: 1,
  '& .MuiAutocomplete-popupIndicator': { height: '25px', width: '25px' },
  bgcolor: grey[600],
  '& .MuiOutlinedInput-root': {
    fontSize: '14px',
    borderBottom: `1px solid ${purple[100]}`,
    borderRadius: '4px 4px 0px 0px',
    paddingLeft: '10px',
    paddingTop: '2px',
    paddingBottom: '2px',
    height,
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: 'transparent',
  },
  '& .MuiInputBase-sizeSmall': {
    padding: '4px !important',
  },
  '.MuiSelect-select': {
    fontSize: '14px',
  },
  '.MuiAutocomplete-clearIndicator': {
    display: 'none',
  },
  '& input': {
    height: '100%',
  },
  '& input::placeholder': {
    color: 'black',
    height: '100%',
    position: 'absolute',
    top: 4,
  },
  '.MuiChip-root + input': {
    display: 'none',
  },
  '.MuiAutocomplete-tag + input': {
    display: 'none',
  },
});

export const errorStyle = {
  marginLeft: 1,
  color: red[100],
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const dpStyle = (isDisabled) => ({
  bgcolor: GREY[100], // Cambiado para igualar GREY[100]
  height: '48px', // Cambiado para igualar la altura
  width: '100%',
  borderRadius: '10px', // Cambiado para igualar el borde redondeado
  border: `1px solid ${isDisabled ? GREY[200] : GREY[400]}`, // Ajuste del borde
  fontSize: '14px',
  boxSizing: 'border-box',
  paddingLeft: '8px',
  paddingRight: '8px', // Agregado para igualar el padding derecho
  zIndex: 1,
  color: isDisabled ? GREY[200] : GREY[600],

  '&:hover': {
    border: `1px solid ${GREY[600]}`, // Ajustado para el hover
    '& svg': {
      color: GREY[600],
    },
  },

  '&.focused': {
    border: `1px solid ${BLUE[600]}`, // Ajustado para el estado de enfoque
    '& svg': {
      color: GREY[600],
    },
  },

  '&.Mui-disabled': {
    border: `1px solid ${GREY[200]}`, // Ajustado para el estado deshabilitado
    '& svg': {
      color: GREY[200],
    },
  },

  '&.field-error': {
    border: `1px solid ${RED[600]}`, // Ajustado para el estado de error
    '& > input': {
      color: RED[600],
    },
    '& svg': {
      color: GREY[600],
    },
  },

  '& svg': {
    color: GREY[400],
    marginRight: 0.5,
  },

  '& input': {
    height: '100%',
    bgcolor: 'transparent',
    border: 'none',
    width: 'auto',
    outline: 'none',
    color: isDisabled ? GREY[200] : GREY[600],
    textAlign: 'center',

    '&:focus': {
      outline: 'none',
    },
  },
});

export const dpCalendarContainerStyle = {
  transition: 'height .3s ease-in-out',
  bgcolor: 'transparent',
  position: 'absolute',
  zIndex: 1310,
  overflow: 'hidden',
};

export const selectStyle = {
  bgcolor: grey[600],
  height: '38px',
  width: '100%',
  color: dark[200],
  mt: 1,
  borderRadius: '4px 4px 0px 0px',
  borderBottom: `1px solid ${purple[100]}`,
  fontSize: '14px',
  border: 'none',

  '.MuiSelect-select': {
    display: 'flex',
  },
  '&.Mui-focused': {
    border: `1px solid ${purple[300]}`,
    /* borderBottom: `1px solid ${purple[100]}`, */
  },
  '.MuiOutlinedInput-notchedOutline': { border: 0, borderBottom: `1px solid ${purple[100]}` },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 0,
    mt: 1,
    borderBottom: `1px solid ${purple[100]}`,
    borderRadius: 'none',
  },
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
      sx: {
        maxHeight: '320px',
        borderRadius: '6px',
        zIndex: 999999,
      },
    },
  },
};

export const selectErrorStyles = {
  bgcolor: grey[600],
  height: '38px',
  width: '100%',
  mt: 1,
  borderRadius: '4px 4px 0px 0px',
  borderBottom: `1px solid ${red[100]}`,
  fontSize: '14px',
  border: 'none',
  '.MuiOutlinedInput-notchedOutline': { border: 0, borderBottom: `1px solid ${red[100]}` },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 0,
    borderBottom: `1px solid ${red[100]}`,
    borderRadius: 'none',
  },
};

export const textAreaStyle = {
  mt: 1,
  bgcolor: grey[600],
  px: 2,
  borderBottom: `1px solid ${purple[100]}`,
  borderRadius: '4px 4px 0px 0px',
  fontSize: '14px',
  '&.Mui-focused': {
    border: `1px solid ${purple[300]}`,
    borderBottom: `1px solid ${purple[100]}`,
  },
};

export const textAreaStyleReadOnly = {
  mt: 1,
  bgcolor: 'none',
  fontSize: '14px',
};

export const CustomAccordion = styled(Accordion)(() => {
  return {
    borderRadius: 15,
    boxShadow: shadow[200],
    marginBottom: 10,
    '.MuiAccordionDetails-root': {},
    '.MuiAccordionSummary-root': {
      display: 'flex',
      justifyContent: 'space-between',
      height: '96px',
    },
  };
});

export const CustomAccordionElement = styled(Accordion)(() => {
  return {
    border: `1px solid ${grey[300]}`,
    borderRadius: 10,
    mb: 2,
    boxShadow: 'none',
  };
});

export const ColorlibStepLabel = styled(StepLabel)(() => ({
  marginBotton: -10,
  padding: 0,
  [`& .${stepLabelClasses.label}`]: {
    [`&.${stepLabelClasses.completed}`]: {
      color: purple[100],
    },
    [`&.${stepLabelClasses.active}`]: {
      color: purple[100],
    },
    color: grey[800],
  },
}));

export const QontoConnector = styled(StepConnector)(() => ({
  marginLeft: '12px',
  marginRight: '12px',
  marginBottom: '-12px',
  marginTop: '-7px',
}));

export const ColorParentStepLabel = styled(StepLabel)(() => ({
  [`& .${stepLabelClasses.label}`]: {
    [`&.${stepLabelClasses.completed}`]: {
      color: dark[400],
    },
    [`&.${stepLabelClasses.active}`]: {
      color: dark[100],
    },
    color: dark[400],
  },
}));

export const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ ownerState }) => ({
    color: 'red',
    display: 'flex',
    height: 1,
    alignItems: 'center',
    ...(ownerState.active && {
      color: purple[100],
      marginLeft: 8,
    }),
    ...(!ownerState.active && {
      color: grey[400],
      marginLeft: 8,
    }),
    '& .QontoStepIcon-completedIcon': {
      color: purple[100],
      zIndex: 1,
      fontSize: 18,
      width: '8px',
      height: '8px',
      backgroundColor: purple[100],
      borderRadius: '50%',
    },
    '& .QontoStepIcon-circle': {
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);
