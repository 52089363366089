import { useStaffMember } from 'services/global-academics/staff-members';

const useSessionUser = () => {
  const { data } = useStaffMember();
  const { id } = data || { id: 0 };

  const sessionUser = {
    id,
  };

  return sessionUser;
};

export default useSessionUser;
