/* eslint-disable camelcase */
import { axiosConfiguration } from 'axios/config';
import { API_ROUTES } from 'constants/apiRoutes';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { ServerResponse } from 'services/client/attendance/attendanceService';
import Alert from 'UI/molecules/global/alerts';
import { useFetch, usePost, usePut, UseServiceProps } from 'utils/react-query';
import { pathToUrl } from 'utils/routes';
import { z } from 'zod';
import { IProgress } from '../general-fields/GeneralFieldsService';

export enum alertFields {
  id = 'id',
  alertId = 'alertId',
  startDate = 'startDate',
  endDate = 'endDate',
  verifiedBy = 'verifiedBy',
  clientAlertId = 'clientAlertId',
  notes = 'notes',
}

export enum dietFields {
  id = 'id',
  clientAlertId = 'clientAlertId',
  foodAllergyType = 'foodAllergyType',
  actionToBeTaken = 'actionToBeTaken',
  dateOfVerification = 'dateOfVerification',
  verificationCompletedBy = 'verificationCompletedBy',
  dateAlertClosed = 'dateAlertClosed',
  howInformationWasVerifiedId = 'howInformationWasVerifiedId',
  withWhomVerificationWasMade = 'withWhomVerificationWasMade',
  medicalOrReligiousRestrictions = 'medicalOrReligiousRestrictions',
  restrictionsNotes = 'restrictionsNotes',
  notes = 'notes',
}

export enum impairmentFields {
  id = 'id',
  clientAlertId = 'clientAlertId',
  requireMechanicalAid = 'requireMechanicalAid',
  mechanicalAidWithClient = 'mechanicalAidWithClient',
  doctorsNoteReviewed = 'doctorsNoteReviewed',
  medicalAttentionNeeded = 'medicalAttentionNeeded',
  doctorsNotePath = 'doctorsNotePath',
  limitations = 'limitations',
  abilities = 'abilities',
}

export const ImpairmentSchema = z
  .object({
    [impairmentFields.id]: z.number().nullable().optional(),
    [impairmentFields.clientAlertId]: z.number().nullable().optional(),
    [impairmentFields.requireMechanicalAid]: z.boolean().default(false).nullable().optional(),
    [impairmentFields.mechanicalAidWithClient]: z.boolean().default(false).nullable().optional(),
    [impairmentFields.doctorsNoteReviewed]: z.boolean().default(false).nullable().optional(),
    [impairmentFields.medicalAttentionNeeded]: z.boolean().default(false).nullable().optional(),
    [impairmentFields.limitations]: z.string().nullable().optional(),
    [impairmentFields.abilities]: z.string().nullable().optional(),
  })
  .nullable()
  .optional();

export const DietSchema = z
  .object({
    [dietFields.id]: z.number().nullable().optional(),
    [dietFields.clientAlertId]: z.number().nullable().optional(),
    [dietFields.foodAllergyType]: z.string().nullable().optional(),
    [dietFields.actionToBeTaken]: z.string().nullable().optional(),
    [dietFields.dateOfVerification]: z.date().nullable().optional(),
    [dietFields.verificationCompletedBy]: z.string().nullable().optional(),
    [dietFields.dateAlertClosed]: z.date().nullable().optional(),
    [dietFields.withWhomVerificationWasMade]: z.string().nullable().optional(),
    [dietFields.howInformationWasVerifiedId]: z.number().nullable().optional(),
    [dietFields.medicalOrReligiousRestrictions]: z.boolean().default(false).nullable(),
    [dietFields.notes]: z.string().nullable().optional(),
    [dietFields.restrictionsNotes]: z.string().nullable().optional(),
  })
  .nullable()
  .optional();

export const AlertSchema = z.object({
  [alertFields.id]: z.number().nullable().optional(),
  [alertFields.alertId]: z
    .number({
      required_error: 'Required',
      invalid_type_error: 'Required',
    })
    .nullable(),
  [alertFields.startDate]: z.date(),
  [alertFields.endDate]: z.date().optional().nullable(),
  [alertFields.verifiedBy]: z.string(),
  [alertFields.clientAlertId]: z.number().nullable().optional(),
  [alertFields.notes]: z.string().nullable(),
  dietAlert: DietSchema,
  impairmentAlert: ImpairmentSchema,
});

export const AlertBodySchema = z.object({
  ...AlertSchema.shape,
  dietAlert: z.object({}).nullable().optional(),
  impairmentAlert: z.object({}).nullable().optional(),
  clientId: z.number().optional(),
  startDate: z.string().datetime(),
  endDate: z.string().datetime().optional().nullable(),
});

export interface ClientAlertsResponse {
  dietAlert?: {
    id: number;
    clientAlertId: number;
    foodAllergyType: string;
    actionToBeTaken: string;
    dateOfVerification: string;
    verificationCompletedBy: string;
    dateAlertClosed: string;
    howInformationWasVerifiedId?: number;
    withWhomVerificationWasMade: string;
    medicalOrReligiousRestrictions: boolean;
    restrictionsNotes?: string;
  };
  impairmentAlert?: {
    id: number;
    clientAlertId: number;
    requireMechanicalAid: boolean;
    mechanicalAidWithClient: boolean;
    doctorsNoteReviewed: boolean;
    medicalAttentionNeeded: boolean;
    doctorsNotePath: string;
    limitations: string;
    abilities: string;
  };
  created: string;
  modifiedDate: string;
  id: number;
  clientId: number;
  alertId: number;
  startDate: string;
  endDate: string;
  notes: string;
  verifiedBy: string;
}

export type AlertBodySchemaType = z.infer<typeof AlertBodySchema>;

export interface FileTypes {
  alertFields: FormData;
}

const saveFile = async ({ file, onProgress }) => {
  await axiosConfiguration.post('client-alerts-files', file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: onProgress,
  });
  return;
};

export const useSaveAlertFile = () => {
  const saveClientImpairmentAlertFile = useMutation(
    (variables: { alertFields: FileTypes; onProgress: IProgress }) =>
      saveFile({
        file: variables.alertFields,
        onProgress: variables.onProgress,
      }),
    {
      onSuccess: () => {
        toast.success(<Alert title='Success' subtitle='File was saved successfully' />);
      },
    },
  );

  return {
    saveClientImpairmentAlertFile,
  };
};

export const useClientAlerts = ({
  routeParams,
  params,
  config,
}: UseServiceProps<ClientAlertsResponse>) =>
  useFetch<ClientAlertsResponse[]>(
    pathToUrl(API_ROUTES.CLIENT_ALERTS, routeParams),
    params,
    config,
  );

export const useAddAlert = ({ routeParams }: UseServiceProps<AlertBodySchemaType>) =>
  usePost<ServerResponse, AlertBodySchemaType>(
    pathToUrl(API_ROUTES.CLIENT_ALERT),
    undefined,
    'Alert Added.',
    pathToUrl(API_ROUTES.CLIENT_ALERTS, routeParams),
  );

export const useUpdateAlert = ({ routeParams }: UseServiceProps<AlertBodySchemaType>) =>
  usePut<ServerResponse, AlertBodySchemaType>(
    pathToUrl(API_ROUTES.CLIENT_ALERT),
    undefined,
    'Alert Updated.',
    pathToUrl(API_ROUTES.CLIENT_ALERTS, routeParams),
  );
