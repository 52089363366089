import { Teacher } from 'modules/global-academics/centers/teachers/interfaces';
import {
  useAcademicAdvisors,
  useAssociateDirector,
  useCaseManagers,
  useCounselors,
  useTransitionCounselors,
} from 'services/global-academics/teachers';
import { dataToOptions } from 'utils/data-to-options';

const FIELDS = {
  ID: 'id',
  FIRSTNAME: 'firstName',
  LASTNAME: 'lastName',
  FULLNAME: 'fullname',
};

const managersMapper = (managers: Teacher[] = []) =>
  managers.map((manager) => ({
    [FIELDS.ID]: manager[FIELDS.ID],
    [FIELDS.FULLNAME]: `${manager[FIELDS.FIRSTNAME]} ${manager[FIELDS.LASTNAME]}`,
  }));

export const useManagersByCenter = (centerId: number) => {
  const managersParams = {
    routeParams: { id: centerId },
    config: { enabled: !!centerId },
  };

  const { data: counselors } = useCounselors(managersParams);
  const { data: caseManagers } = useCaseManagers(managersParams);
  const { data: academicAdvisors } = useAcademicAdvisors(managersParams);
  const { data: transitionCounselors } = useTransitionCounselors(managersParams);
  const { data: associateDirector } = useAssociateDirector(managersParams);

  const counselorsOptions = dataToOptions(
    managersMapper(counselors || []),
    FIELDS.ID,
    FIELDS.FULLNAME,
  );
  const caseManagersOptions = dataToOptions(
    managersMapper(caseManagers || []),
    FIELDS.ID,
    FIELDS.FULLNAME,
  );
  const academicAdvisorsOptions = dataToOptions(
    managersMapper(academicAdvisors || []),
    FIELDS.ID,
    FIELDS.FULLNAME,
  );
  const transitionCounselorsOptions = dataToOptions(
    managersMapper(transitionCounselors || []),
    FIELDS.ID,
    FIELDS.FULLNAME,
  );
  const associateDirectorOptions = dataToOptions(
    managersMapper(associateDirector || []),
    FIELDS.ID,
    FIELDS.FULLNAME,
  );

  return {
    counselorsOptions,
    caseManagersOptions,
    academicAdvisorsOptions,
    transitionCounselorsOptions,
    associateDirectorOptions,
  };
};
