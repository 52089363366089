import { Button } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { axiosConfiguration } from 'axios/config';
import { PeriodItemProps } from 'modules/global-academics/centers/academic-setup/interfaces';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { ServerResponse } from 'services/client/attendance/attendanceService';
import Alert from 'UI/molecules/global/alerts';

interface Props {
  centerId: number;
}

// Requests
/* @GET */
const getPeriods = async (centerId): Promise<PeriodItemProps[]> => {
  const { data } = await axiosConfiguration['get']<PeriodItemProps[]>(`center/${centerId}/period`);
  return data;
};

/* @POST AND PUT */
const savePeriod = async ({ periodData }): Promise<void> => {
  const method = periodData.id ? 'put' : 'post';
  await axiosConfiguration[method]<PeriodItemProps>('period', periodData);
};

/* @HOOKS */
export const usePeriods = ({ centerId }: Props) => {
  const periodsQuery = useQuery(['periods', centerId], () => getPeriods(centerId), {
    staleTime: 120000,
    onSettled(data, error) {
      if (error) {
        toast.error(<Alert title='Error' subtitle='Error loading periods!' />);
      }
    },
  });

  const periodsMutation = useMutation(
    (variables: { periodData: PeriodItemProps }) =>
      savePeriod({ periodData: variables.periodData }),
    {
      onSuccess: () => {
        periodsQuery.refetch();
        toast.success(<Alert title='Success' subtitle='Period was saved successfully!' />);
      },
      onError: (error: unknown) => {
        const errors = error as Error | AxiosError;
        if (axios.isAxiosError(errors)) {
          const errorMessage = errors.response?.data as ServerResponse;
          toast(
            (t) => (
              <span>
                <Alert title='Error !' subtitle={errorMessage.message} />
                <Button color='primary' sx={{ my: 1 }} onClick={() => toast.dismiss(t.id)}>
                  Dismiss
                </Button>
              </span>
            ),
            {
              duration: 10000,
            },
          );
        } else {
          toast.error(<Alert title='Error' subtitle={errors.message} />);
        }
      },
    },
  );

  return {
    periodsQuery,
    periodsMutation,
  };
};
