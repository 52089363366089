import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, IconButton, Typography } from '@mui/material';
import { blue, dark } from 'theme/palette';

type ModalHeadingProps = {
  onClose: () => void;
  title: string;
  subTitle?: string;
  isDisabled?: boolean;
  tittlePadding?: number;
};

const ModalHeading: React.FC<ModalHeadingProps> = (props: ModalHeadingProps) => {
  const { onClose, title, subTitle, isDisabled = false, tittlePadding } = props;
  return (
    <Box
      component='div'
      display='flex'
      justifyContent='space-between'
      alignItems='flex-start'
      mb={4}
      flexDirection='row'
    >
      <Box display='flex' flexDirection='column'>
        <Typography component='h3' color={dark[200]} variant='h6' pt={tittlePadding}>
          {title}
        </Typography>
        {!!subTitle && (
          <Typography component='span' color={blue[200]} fontSize='14px' fontWeight={400}>
            {subTitle}
          </Typography>
        )}
      </Box>
      <IconButton disabled={isDisabled} onClick={onClose}>
        <CloseRoundedIcon
          sx={{
            color: blue[100],
            cursor: 'pointer',
            ':hover': { border: '1px solid black', borderRadius: '4px' },
          }}
        />
      </IconButton>
    </Box>
  );
};

export default ModalHeading;
