import { Box, Skeleton } from '@mui/material';
import React from 'react';

const SkeletonElement: React.FC = () => {
  return (
    <Box component='div' width='100%' my={{ xs: 2, md: 2 }} px={{ xs: 0, md: 2 }}>
      <Box
        component='div'
        display='flex'
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent='space-between'
      >
        <Skeleton
          component='div'
          sx={{
            borderRadius: '6px',
            height: { xs: 58, md: 118 },
            mr: { xs: 0, md: 2 },
            mt: { xs: 2, md: 0 },
            width: { xs: '100%', md: '25%' },
          }}
          variant='rectangular'
        />
        <Skeleton
          component='div'
          sx={{
            borderRadius: '6px',
            height: { xs: 58, md: 118 },
            mr: { xs: 0, md: 2 },
            mt: { xs: 2, md: 0 },
            width: { xs: '100%', md: '25%' },
          }}
          variant='rectangular'
        />
        <Skeleton
          component='div'
          sx={{
            borderRadius: '6px',
            height: { xs: 58, md: 118 },
            mr: { xs: 0, md: 2 },
            mt: { xs: 2, md: 0 },
            width: { xs: '100%', md: '25%' },
          }}
          variant='rectangular'
        />
        <Skeleton
          component='div'
          sx={{
            borderRadius: '6px',
            height: { xs: 58, md: 118 },
            mr: { xs: 0, md: 0 },
            mt: { xs: 2, md: 0 },
            width: { xs: '100%', md: '25%' },
          }}
          variant='rectangular'
        />
      </Box>
      <Box component='div' display='flex' flexDirection={{ xs: 'column', md: 'row' }} mt={2}>
        <Skeleton
          component='div'
          sx={{
            borderRadius: '6px',
            height: { xs: 150, md: 300 },
            mr: { xs: 0, md: 2 },
            width: { xs: '100%', md: '70' },
          }}
          variant='rectangular'
        />
        <Skeleton
          component='div'
          sx={{
            borderRadius: '6px',
            height: { xs: 150, md: 300 },
            mr: { xs: 0, md: 0 },
            width: { xs: '100%', md: '30%' },
          }}
          variant='rectangular'
        />
      </Box>
      <Box component='div' mt={2} display='flex' justifyContent='space-between'>
        <Skeleton
          component='div'
          sx={{ borderRadius: '6px', mr: 2 }}
          variant='rectangular'
          width={100}
          height={10}
        />
        <Skeleton
          component='div'
          sx={{ borderRadius: '6px' }}
          variant='rectangular'
          width={150}
          height={10}
        />
      </Box>
    </Box>
  );
};

export default SkeletonElement;
