import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { Box, Divider, Typography } from '@mui/material';
import CancelAndSave from 'components/CancelAndSave';
import CommentCard from 'components/Comments/card';
import Input from 'components/fields/Input';
import ScrollableContainer from 'components/ScrollableContainer';
import { DefaultAvatar } from 'components/TrainingHeader';
import useSessionUser from 'hooks/useSessionUser';
import { useState } from 'react';
import { useStaffMemberHeader } from 'services/global-academics/staff-members';
import { commentsFields } from 'services/help-desk/comments';
import { dark } from 'theme/palette';

type Props = {
  handleSubmit: () => void;
  isLoading: boolean;
  queryContext: any;
};

const CommentsForm: React.FC<Props> = (props: Props) => {
  const { handleSubmit, isLoading, queryContext } = props;

  const [showCancelAndSave, setShowCancelAndSave] = useState(false);

  const { id } = useSessionUser();
  const { data }: any = useStaffMemberHeader({
    config: { enabled: !!id },
    routeParams: { id },
  });

  const firstName = data?.firstName;
  const lastName = data?.lastName;
  const photoUrl = data?.photoUrl;

  const { data: commentsData } = queryContext;
  const { pages = [] } = (commentsData as any) || {};
  const [{ totalCount } = { totalCount: undefined }] = pages;

  const handleInputClick = () => {
    setShowCancelAndSave(true);
  };

  return (
    <Box
      mt={1}
      mb={10}
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '70% 30%' },
      }}
      component='form'
      id='ticket-comment'
      onSubmit={handleSubmit}
    >
      <Box>
        <Box
          component='div'
          sx={{ display: 'flex', width: '27%', justifyContent: 'space-between' }}
          mb={2}
        >
          <Typography component='h2' color={dark[200]} sx={{ fontSize: '22px' }}>
            Discussion
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ChatOutlinedIcon sx={{ fontSize: '22px' }} />
          </Box>
          <Typography component='h3' sx={{ fontSize: '14px', lineHeight: '2.5' }}>
            {`${totalCount || 0} Comment${totalCount > 1 ? 's' : ''}`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr' },
            columnGap: 2,
            rowGap: 2,
            paddingRight: 3,
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ marginTop: '8px' }}>
              <DefaultAvatar
                url={photoUrl}
                firstName={firstName}
                lastName={lastName}
                hasImage={photoUrl ? true : false}
                customSize='30px'
                customFontSize='14px'
              />
            </Box>
            <Box sx={{ width: '100%', marginLeft: 1 }}>
              <Input
                caption=''
                fieldName={commentsFields.text}
                textarea
                multiline
                rows={3}
                placeholder='Add a comment here.'
                customArrayErrors
                onClick={handleInputClick}
              />
              {showCancelAndSave && (
                <CancelAndSave
                  onCancel={() => setShowCancelAndSave(false)}
                  noMb
                  placement={'flex-start'}
                  isSaving={isLoading}
                />
              )}
            </Box>
          </Box>
          <Divider sx={{ width: '100%', mt: 4, mb: 4 }} />
          <ScrollableContainer
            maxHeight={700}
            gradientEnable
            queryContext={queryContext}
            componentToRender={CommentCard}
            showMoreText='Show more comments'
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CommentsForm;
