import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { useNotificationStore } from 'UI/organisms/global/header/notificationMenu/notificationsStore';
import { useGetNotifications } from './notifications';

const URL = `${process.env.REACT_APP_SERVICE_MQI_URL}/notificationHub`;

export const useNotificationSignal = () => {
  const token = localStorage.getItem('access-token');
  const { refetch } = useGetNotifications({
    config: { enabled: !!token },
  });
  const updateCounter = useNotificationStore((state) => state.updateUnreadCounter);
  let connection: HubConnection;

  const createConnection = (token) => {
    connection = new HubConnectionBuilder()
      .withUrl(URL, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => token,
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();

    connection.on('ReceiveNotification', () => {
      refetch()
        .then((refetchedData) => {
          updateCounter(refetchedData?.data?.unreadCount);
        })
        .catch((error) => {
          console.error('Error fetching notifications:', error);
        });
    });

    try {
      connection.start();
      console.log('SignalR Connected.');
    } catch (err) {
      console.log(err);
    }
  };

  return {
    createConnection,
  };
};
