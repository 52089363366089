/* Icons */
import AddRoundedIcon from '@mui/icons-material/AddRounded';
/* Components */
import { Box, Button, Typography } from '@mui/material';
import { Grid as DataGrid, GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { alertLevel } from 'services/catalogs/alerts/alertsCatalogServices';
import { ClientAlertsResponse, useClientAlerts } from 'services/client/alerts/alertsServices';
import { useHero } from 'services/client/client-hero';
import { blue, dark, red, yellow } from 'theme/palette';
import { LinearProgressBar } from 'UI/molecules/global/linear-progress-bar';
import TopHeaderBar from 'UI/organisms/client/global/client-top-header-bar';
import { formatDateString } from 'utils/format-date';
import ContainerAlertForm from './form/container';

export type AlertTypes = Alerts[];

export const getAlertLevelColor = (level?: string) => {
  return level === alertLevel.critical
    ? red[200]
    : level === alertLevel.spacial
    ? yellow[200]
    : level === alertLevel.low
    ? blue[200]
    : dark[200];
};

export interface Alerts {
  id: number;
  createdDate: string;
  modifiedDate: null | string;
  type: string;
  category: string;
  level: string;
  categoryInfo: {
    id: number;
    attributeName: string;
    attributeValue: string | null;
    createdDate: string;
    modifiedDate: string;
  };
  levelInfo: {
    id: number;
    attributeName: string;
    attributeValue: string | null;
    createdDate: string;
    modifiedDate: string;
  };
}

const AlertPage: React.FC = () => {
  const { id: clientID } = useParams();
  const navigate = useNavigate();
  const clientId = Number(clientID);

  const {
    data: alerts = [],
    isLoading,
    isFetching,
    isRefetching,
    refetch: alertRefetch,
  } = useClientAlerts({
    routeParams: { clientId },
    config: { enabled: clientId ? true : false },
  });

  const loading = isLoading || isFetching || isRefetching;

  const [modalState, setModalState] = useState<boolean>(false);
  const [formState, setFormState] = useState<ClientAlertsResponse | null>(null);

  const openModal = (alertData: ClientAlertsResponse | null) => {
    setFormState(alertData);
    setModalState(true);
  };

  const onClose = () => setModalState(false);

  const { heroQuery } = useHero({ clientId });

  return (
    <Box component='div' mb={2}>
      <TopHeaderBar
        notSaveButton={true}
        inputID='some-fields'
        onCancel={() => navigate(`/client/${clientID}/`)}
      />
      <ContainerAlertForm
        alertRefetch={alertRefetch}
        formData={formState}
        heroRefetch={heroQuery.refetch}
        open={modalState}
        onClose={onClose}
      />
      <Box component='div' display='flex' justifyContent='space-between' alignItems='center'>
        <Typography component='h1' color={dark[200]} variant='h6'>
          Alerts Tab
        </Typography>
        <Button startIcon={<AddRoundedIcon />} variant='primarybtn' onClick={() => openModal(null)}>
          Add new Alert
        </Button>
      </Box>
      {loading ? (
        <LinearProgressBar />
      ) : (
        <Box component='div' mt={2}>
          <DataGrid
            rowHeight={40}
            data={alerts}
            style={{ maxHeight: '400px', height: 'auto', maxWidth: '100%' }}
          >
            <Column field='alertInfo.categoryInfo.attributeName' title='Category' width='200px' />
            <Column field='alertInfo.type' title='Alert Type' width='250px' />
            <Column
              field='alertInfo.levelInfo.attributeName'
              title='Alert Level'
              width='100px'
              cell={(props: GridCellProps) => (
                <td>
                  <a
                    style={{
                      color: getAlertLevelColor(
                        props.dataItem.alertInfo.levelInfo.attributeName.toLowerCase(),
                      ),
                      fontWeight: 500,
                    }}
                  >
                    {props.dataItem.alertInfo.levelInfo.attributeName}
                  </a>
                </td>
              )}
            />
            <Column
              field='startDate'
              title='Start Date'
              width='150px'
              cell={(props: GridCellProps) => (
                <td>
                  <a>
                    {props.dataItem.startDate !== null
                      ? formatDateString(props.dataItem?.startDate)
                      : 'No Date'}
                  </a>
                </td>
              )}
            />
            <Column
              field='endDate'
              title='End Date'
              width='200px'
              cell={(props: GridCellProps) => (
                <td>
                  <a>
                    {props.dataItem.endDate !== null
                      ? formatDateString(props.dataItem.endDate)
                      : 'No Date'}{' '}
                  </a>
                </td>
              )}
            />
            <Column field='verifiedBy' title='Verified By' width='210px' />
            <Column
              title='Actions'
              width='150px'
              cell={(props: GridCellProps) => (
                <td>
                  <Button onClick={() => openModal(props.dataItem)} size='small'>
                    View More
                  </Button>
                </td>
              )}
            />
          </DataGrid>
        </Box>
      )}
    </Box>
  );
};

export default AlertPage;
