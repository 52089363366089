import { createSlice } from '@reduxjs/toolkit';

interface OnbordingData {
  data: object;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataParent: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataChild: any;
}

const initialState = {
  data: {},
  dataChild: {},
  dataParent: {},
} as OnbordingData;

const onbordingSlice = createSlice({
  name: 'onbording',
  initialState,
  reducers: {
    addParents(state, { payload }) {
      state.dataParent = {
        ...state.dataParent,
        ...payload,
      };
    },
    addChilds(state, { payload }) {
      state.dataChild = {
        ...state.dataChild,
        ...payload,
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const { addChilds, addParents, resetState } = onbordingSlice.actions;
export default onbordingSlice.reducer;
