import { API_ROUTES } from 'constants/apiRoutes';
import { dateZodType } from 'constants/zodTypes';
import {
  getPaginated,
  PaginatedResult,
  useFetch,
  usePost,
  UseServiceProps,
  UseServicePropsSingle,
} from 'utils/react-query';
import { pathToUrl } from 'utils/routes';
import { z } from 'zod';

export enum notificationFields {
  guid = 'guid',
  active = 'active',
  created = 'created',
  userPhoto = 'userPhoto',
  id = 'id',
  title = 'title',
  description = 'description',
  recipientUserId = 'recipientUserId',
  redirectTo = 'redirectTo',

  pageNumber = 'pageNumber',
  pageSize = 'pageSize',
}

export const NotificationSchema = z.object({
  [notificationFields.guid]: z.string().optional(),
  [notificationFields.active]: z.boolean(),
  [notificationFields.created]: dateZodType,
  [notificationFields.userPhoto]: z.string().nullable(),
  [notificationFields.id]: z.number(),
  [notificationFields.title]: z.string(),
  [notificationFields.description]: z.string(),
  [notificationFields.recipientUserId]: z.string(),
  [notificationFields.redirectTo]: z.string(),
});

export type NotificationType = z.infer<typeof NotificationSchema>;

export type PaginatedsearchResponse = PaginatedResult<NotificationType>;

const SERVICE_NAME = 'Notication Readed';

export const useGetNotifications = ({
  routeParams,
  params,
  config,
}: UseServicePropsSingle<PaginatedsearchResponse>) => {
  const { data, ...res } = useFetch<PaginatedsearchResponse>(
    pathToUrl(API_ROUTES.NOTIFICATIONS, routeParams),
    params,
    config,
  );

  return {
    data: getPaginated(data),
    ...res,
  };
};

export const useReadNotification = ({ routeParams }: UseServiceProps<any>) =>
  usePost<[], any>(
    pathToUrl(API_ROUTES.READ_NOTIFICATION, routeParams),
    undefined,
    SERVICE_NAME,
    pathToUrl(API_ROUTES.NOTIFICATIONS, routeParams),
  );

export const useReadAllNotifications = ({ routeParams }: UseServiceProps<any>) =>
  usePost<[], any>(
    pathToUrl(API_ROUTES.READ_ALL_NOTIFICATIONS, routeParams),
    undefined,
    SERVICE_NAME,
  );
