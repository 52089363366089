import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  CommentSchema,
  commentsFields,
  CommentsType,
  useAddComment,
  useGetCommentsInfinite,
} from 'services/help-desk/comments';
import CommentsForm from '.';

const CommentsFormContainer = () => {
  const { ticketId } = useParams();
  const methods = useForm<CommentsType>({
    resolver: zodResolver(CommentSchema),
  });

  const queryContext = useGetCommentsInfinite({
    routeParams: { id: ticketId },
    params: { pageNumber: 1, pageSize: 10 },
    config: { enabled: !!ticketId },
  });

  const { mutateAsync: addNewComment, isLoading } = useAddComment({
    routeParams: { id: ticketId },
  });

  const onSubmit = (data) => {
    addNewComment(data, {
      onSuccess: () => {
        queryContext.refetchFromStart();
        methods.resetField(commentsFields.text);
      },
    });
  };

  const handleSubmit = methods.handleSubmit(onSubmit);

  useEffect(() => {
    methods.setValue(commentsFields.id, 0);
    methods.setValue(commentsFields.helpDeskTicketId, Number(ticketId));
  }, [ticketId]);

  return (
    <Box component={'main'} pl={2} pr={2}>
      <FormProvider {...methods}>
        <CommentsForm
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          queryContext={queryContext}
        />
      </FormProvider>
    </Box>
  );
};

export default CommentsFormContainer;
