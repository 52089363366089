import { FormProvider, useForm, useFormContext } from 'react-hook-form';

export const withFormProvider = (WrappedComponent) => {
  return (props) => {
    const formMethods = useForm();

    return (
      <FormProvider {...formMethods}>
        <WrappedComponent {...props} />
      </FormProvider>
    );
  };
};

export const useGetParams = (paramNames: string[]) => {
  const params = {};
  const { watch } = useFormContext();

  paramNames.forEach((paramName) => {
    params[paramName] = watch(paramName);
  });

  return params;
};
