import { GREY, RED } from 'theme/palette/new';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const selectStyle = {
  pl: 0,
  height: '48px',
  bgcolor: GREY[100],
  borderRadius: '10px',
  '.MuiSelect-select': {
    display: 'flex',
  },
  '&:hover fieldset': {
    border: `1px solid ${GREY[600]}`,
    '& svg': {
      color: GREY[600],
    },
  },
  '&.field-error': {
    '& .MuiTypography-caption': {
      color: RED[600],
    },
    '& fieldset': {
      border: `1px solid ${RED[600]}`,
      '& svg': {
        color: RED[600],
      },
    },
  },
  fieldset: {
    border: `1px solid ${GREY[400]}`,
  },
  // '.MuiOutlinedInput-notchedOutline': { border: 0, borderBottom: `1px solid ${BLUE[600]}` },
  '.MuiTypography-caption': {
    fontSize: '14px',
    '&.field-error': {
      color: RED[600],
    },
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: 1,
    borderColor: GREY[400],
    borderRadius: 'none',
  },
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
      sx: {
        maxHeight: '320px',
        borderRadius: '6px',
        zIndex: 999999,
      },
    },
  },
};
