import { useEffect, useState } from 'react';

const useScrollPosition = () => {
  const [position, setPosition] = useState(false);

  useEffect(() => {
    const updatePosition = () => {
      if (window.pageYOffset <= 174) {
        setPosition(false);
      } else {
        setPosition(true);
      }
    };

    window.addEventListener('scroll', updatePosition);

    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return {
    position,
  };
};

export default useScrollPosition;
