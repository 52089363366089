import { BLUE, GREY } from 'theme/palette/new';

export const checkboxStyle = (checked, disabled) => ({
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& svg': {
    color: disabled ? GREY[200] : checked ? BLUE[600] : GREY[400],
  },
  '& input:hover + svg': {
    color: disabled ? GREY[200] : checked ? BLUE[600] : GREY[600],
  },
});
