import { Box, Container } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { light } from '../../theme/palette';
import Header from '../../UI/organisms/global/header';
import Navigation from '../../UI/organisms/global/navigation';

const MainLayout: React.FC = () => {
  return (
    <Box component='div' bgcolor={light[100]} height='100%'>
      <Header />
      <Container maxWidth='xl' component='div' sx={{ height: '100%' }}>
        <Box component='div' display='flex' flexDirection={{ xs: 'column', md: 'row' }}>
          <Box
            component='div'
            display={{
              md: 'flex',
            }}
            width={{
              xs: '100%',
              md: '270px',
            }}
          >
            <Navigation />
          </Box>
          <Box
            component='main'
            height='100%'
            width={{
              xs: '100%',
              md: 'calc(100% - 270px)',
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default MainLayout;
