export enum TrainingRoutes {
  myTraining = 'my-training',
  myStaffTraining = 'my-staff-training',
  trainingDashboard = 'training-dashboard',
}

export enum IncidentReportSectionRoutes {
  girlsInvolved = 'girls-involved',
  employeesAndProperty = 'employees-property',
}
