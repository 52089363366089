import axios from 'axios';

export function environment(env: string | undefined): string | undefined {
  if (env !== 'production') {
    return `${process.env.REACT_APP_SERVICE_MQI_URL}`;
  }

  if (env === 'production') {
    return `${process.env.REACT_APP_SERVICE_MQI_URL}`;
  }

  return '';
}

export const baseUrl = environment(process.env.NODE_ENV);

export const axiosConfig = axios.create({
  baseURL: baseUrl,
  headers: {
    accept: '*/*',
    Authorization: `Bearer ${localStorage.getItem('access-token')}`,
  },
});
