import AddRounded from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Box, Button, Grid, InputBase, Typography } from '@mui/material';
import { dark } from '@mui/material/styles/createPalette';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { CustomCalendar } from 'components/fields/DatePicker';
import moment from 'moment';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { errorStyle, inputStyle } from 'theme/styles/inputs';
import { CardContentBorderedForAction } from 'UI/molecules/global/cards';
import { formatDateToAAAAMMDD } from 'utils/utils';
import { AddSemesterFormProps, SemesterFormFields } from '../interfaces';

const SaveSemester: React.FC<AddSemesterFormProps> = ({
  data,
  yearId,
  isSaving = false,
  onAddSemester,
  onEditSemester,
  onCancel,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SemesterFormFields>({
    defaultValues: data,
  });

  const { centerId } = useParams();

  const onSubmitSemester = (formData) => {
    if (isSaving) return;
    const fixedFormData = {
      ...formData,
      isActive: true,
      academicYearId: yearId,
      centerId: Number(centerId),
      startDate: formatDateToAAAAMMDD(formData.startDate),
      endDate: formatDateToAAAAMMDD(formData.endDate),
    };

    if (!data) {
      onAddSemester && onAddSemester(fixedFormData);
      return;
    }
    onEditSemester && onEditSemester(fixedFormData);
    return;
  };

  const getFormTitle = () => {
    if (!data) {
      return (
        <>
          <AddRounded color='primary' fontWeight={700} style={{ fontSize: '16px' }} />
          <Typography fontSize='14px' fontWeight={500} color='primary'>
            Create a new Semester
          </Typography>
        </>
      );
    }
    return (
      <>
        <EditRoundedIcon fontWeight={700} color='primary' style={{ fontSize: '14px' }} />
        <Typography fontSize='14px' fontWeight={500} color='primary'>
          Edit Semester
        </Typography>
      </>
    );
  };

  return (
    <CardContentBorderedForAction>
      <Box component='form' onSubmit={handleSubmit(onSubmitSemester)}>
        <Box
          component='div'
          display='flex'
          alignItems='center'
          style={{ cursor: 'pointer' }}
          gap='4px'
          marginBottom='21px'
        >
          {getFormTitle()}
        </Box>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Box component='div' mb={1} pr={{ xs: 0, md: 1 }}>
              <Typography component='p' color={dark[200]} variant='body2'>
                Semester Name
                {errors.semesterName && (
                  <small style={errorStyle}>{errors.semesterName.message}</small>
                )}
              </Typography>
              <Controller
                name='semesterName'
                control={control}
                defaultValue=''
                rules={{ required: { value: true, message: 'Required' } }}
                render={({ field: { onChange, value, ref } }) => (
                  <InputBase
                    onChange={onChange}
                    value={value || ''}
                    inputRef={ref}
                    fullWidth
                    sx={inputStyle}
                    placeholder='Semester Name'
                    disabled={isSaving}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box component='div' mb={1} pr={{ xs: 0, md: 1 }}>
              <Typography component='p' color={dark[200]} variant='body2' mb={1}>
                Start Date {errors.startDate && <small style={errorStyle}>Required</small>}
              </Typography>
              <Controller
                name='startDate'
                control={control}
                defaultValue={null}
                rules={{ required: { value: true, message: 'Required' } }}
                render={({ field: { onChange, ref, value } }) => (
                  <DatePicker
                    calendar={CustomCalendar}
                    onChange={onChange}
                    ref={ref}
                    value={(value && moment(value).toDate()) || null}
                    placeholder='MM/DD/YYYY'
                    disabled={isSaving}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box component='div' mb={1}>
              <Typography component='p' color={dark[200]} variant='body2' mb={1}>
                End Date {errors.endDate && <small style={errorStyle}>Required</small>}
              </Typography>
              <Controller
                name='endDate'
                control={control}
                rules={{ required: { value: true, message: 'Required' } }}
                render={({ field: { onChange, ref, value } }) => (
                  <DatePicker
                    calendar={CustomCalendar}
                    ref={ref}
                    onChange={onChange}
                    value={(value && moment(value).toDate()) || null}
                    placeholder='MM/DD/YYYY'
                    disabled={isSaving}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
        <Box component='div' display='flex' justifyContent='right' marginTop='32px'>
          <Button
            variant='secondarybtn'
            sx={{ paddingLeft: '40px', paddingRight: '40px' }}
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            variant='primarybtn'
            type='submit'
            sx={{ paddingLeft: '40px', paddingRight: '40px', ml: 2 }}
          >
            {isSaving ? 'Saving..' : 'Save'}
          </Button>
        </Box>
      </Box>
    </CardContentBorderedForAction>
  );
};

export default SaveSemester;
