import { yupResolver } from '@hookform/resolvers/yup';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import {
  Box,
  Button,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  InputBase,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import { DatePicker, DateTimePicker } from '@progress/kendo-react-dateinputs';
import { Popup } from '@progress/kendo-react-popup';
import { CustomCalendar } from 'components/fields/DatePicker';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useReasonSchoolStatus } from 'services/catalogs/calendar-reason-school-status/calendarResonSchoolStatusService';
import {
  EventParamsInterface,
  useEvents,
} from 'services/global-academics/academic-setup/calendarService';
import { blue, red, shadow } from 'theme/palette';
import { errorStyle, inputStyle, selectStyle, textAreaStyle } from 'theme/styles/inputs';
import * as yup from 'yup';

/* @FC for resolve issue of Z-index */
export const CustomPopup = (props) => {
  return (
    <Popup
      anchorAlign={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      style={{
        zIndex: 9999,
      }}
      popupAlign={{
        horizontal: 'center',
        vertical: 'top',
      }}
      {...props}
    />
  );
};

/* @Schema of validation fields form */
const schema = yup.object().shape({
  name: yup.string().required(),
  allDay: yup.boolean().optional(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  description: yup.string().optional(),
  isSchoolClosed: yup.boolean().optional().nullable().default(false),
  reasonSchoolClosed: yup.number().optional().nullable().notOneOf([0], 'Required'),
  descriptionSchoolClosed: yup.string().optional().nullable(),
});

interface Props {
  open: boolean /* @St open: boolean state for open Modal */;
  onClose: () => void /* @onClose: fn for close Modal */;
  isUpdate: boolean /* @St isUpdate: boolean for know is creation or update Modal */;
  getDataForm: (
    formData: EventParamsInterface,
  ) => void /* @Fn getDataForm: fn for catch data returned of the form */;
  passDataForm: ModalFieldsTypes | null /* @Fn passDataForm: data type ModalFieldsTypes for reset initial fields when the modal is open */;
  isLoading: boolean;
}

export interface ModalFieldsTypes {
  id: number;
  name: string;
  allDay: boolean;
  startDate: Date | null;
  endDate: Date | null;
  description: string;
  onCancel?: () => void;
  isSchoolClosed: boolean;
  reasonSchoolClosed: number | null;
  descriptionSchoolClosed: string | null;
}

const ModalEvents: React.FC<Props> = ({
  open,
  onClose,
  passDataForm,
  getDataForm,
  isUpdate,
  isLoading,
}): JSX.Element => {
  const { centerId } = useParams();
  const { eventDeleteMutation } = useEvents({ centerId: Number(centerId) });

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm<ModalFieldsTypes>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (passDataForm) {
      reset(passDataForm);
    }
  }, [reset, passDataForm]);

  /* @Fn:(ModalFieldsTypes) onSubmit for handle form */
  const onSubmit = (dataForm: ModalFieldsTypes) => {
    const formBody = {
      startTime: dataForm.startDate
        ? `${dataForm.startDate.getHours()}:${dataForm.startDate.getMinutes()}:${dataForm.startDate.getSeconds()}`
        : null,
      endTime: dataForm.endDate
        ? `${dataForm.endDate.getHours()}:${dataForm.endDate.getMinutes()}:${dataForm.endDate.getSeconds()}`
        : null,
      isAllday: dataForm.allDay,
      startDate: dataForm.startDate ? dataForm.startDate.toISOString().replace(/T.*$/, '') : null,
      endDate: dataForm.endDate ? dataForm.endDate.toISOString().replace(/T.*$/, '') : null,
      eventName: dataForm.name,
      description: dataForm.description,
      descriptionSchoolClosed: dataForm.descriptionSchoolClosed,
      isSchoolClosed: dataForm.isSchoolClosed,
      reasonSchoolClosed: dataForm.reasonSchoolClosed,
    };

    if (!isUpdate) {
      getDataForm({
        id: 0,
        ...formBody,
      });
    }
    if (isUpdate) {
      getDataForm({
        id: dataForm.id,
        ...formBody,
      });
    }
  };

  const reasonSchoolStatus = useReasonSchoolStatus();
  const handleCloseOnEvent = (value: boolean) => {
    setValue('allDay', true);
    if (!value) {
      setValue('descriptionSchoolClosed', null);
      setValue('reasonSchoolClosed', null);
    }
  };

  const handleChangeDatesIfAllDay = () => {
    if (watch('allDay')) {
      const start = getValues('startDate');
      if (start) {
        setValue('endDate', start);
      }
    }
  };

  const deleteEvent = async () => {
    await eventDeleteMutation.mutateAsync({
      centerId: Number(centerId),
      eventId: Number(passDataForm?.id),
    });
    onClose();
  };

  const onCancelEventEdit = async () => {
    if (passDataForm?.onCancel) passDataForm?.onCancel();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: '6px',
          boxShadow: shadow[200],
          minWidth: '350px',
          maxWidth: '500px',
        },
      }}
    >
      <Box component='div' p={2}>
        <Box
          component='div'
          mb={2}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          {isUpdate ? (
            <Typography component='h1' color='darker' variant='h6'>
              Update Event
            </Typography>
          ) : (
            <Typography component='h1' color='darker' variant='h6'>
              Add a Center Calendar event
            </Typography>
          )}
          <CloseRoundedIcon
            onClick={onClose}
            sx={{
              color: blue[100],
              cursor: 'pointer',
              ':hover': { border: '1px solid black', borderRadius: '4px' },
            }}
          />
        </Box>
        <Box component='form' onSubmit={handleSubmit(onSubmit)}>
          <Grid container mb={1}>
            <Grid item xs={12} md={6} pr={{ xs: 0, md: 1 }}>
              <Typography component='p' color='darker' variant='body2'>
                Event Name
                {errors.name && <small style={errorStyle}>{errors.name.message}</small>}
              </Typography>
              <Controller
                name='name'
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <InputBase
                    onChange={onChange}
                    value={value || ''}
                    inputRef={ref}
                    sx={inputStyle}
                    fullWidth
                    placeholder='Event Name'
                  />
                )}
              />
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Box component='div' mb={2}>
            <Controller
              name='isSchoolClosed'
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <FormControlLabel
                  labelPlacement='start'
                  onChange={(event: React.SyntheticEvent, checked) => {
                    onChange(checked);
                    handleCloseOnEvent(checked);
                  }}
                  inputRef={ref}
                  sx={{ ml: 0 }}
                  label={
                    <Typography component='p' color='darker' variant='body2'>
                      School Closed on Event?
                    </Typography>
                  }
                  control={<Switch checked={value || false} size='small' />}
                />
              )}
            />
          </Box>
          {watch('isSchoolClosed') && (
            <Grid component='div' container>
              <Grid item xs={12} md={6} pr={{ xs: 0, md: 1 }}>
                <Typography component='p' color='darker' variant='body2'>
                  Reason School Closed{' '}
                  {errors.reasonSchoolClosed && (
                    <small style={errorStyle}>{errors.reasonSchoolClosed?.message}</small>
                  )}
                </Typography>
                <Controller
                  name='reasonSchoolClosed'
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      onChange={onChange}
                      value={value || 0}
                      inputRef={ref}
                      displayEmpty
                      MenuProps={selectStyle.MenuProps}
                      sx={selectStyle}
                      labelId='level-label'
                    >
                      <MenuItem value={0}>
                        <Typography component='em' color='darker' variant='caption'>
                          Select One
                        </Typography>
                      </MenuItem>
                      {reasonSchoolStatus &&
                        reasonSchoolStatus.map((element) => (
                          <MenuItem key={element.id} value={element.id}>
                            <Typography component='p' color='darker' variant='caption'>
                              {element.schoolStatus}
                            </Typography>
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} pl={{ xs: 0, md: 1 }}>
                <Typography component='p' color='darker' variant='body2'>
                  Description (Optional)
                </Typography>
                <Controller
                  name='descriptionSchoolClosed'
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <InputBase
                      multiline
                      inputRef={ref}
                      value={value || ''}
                      onChange={onChange}
                      rows={4}
                      sx={textAreaStyle}
                      fullWidth
                      placeholder='Description'
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
          <Divider sx={{ my: 2 }} />
          <Grid container mb={2}>
            <Grid item md={6}>
              <Controller
                name='allDay'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    onChange={(event: React.SyntheticEvent, checked) => {
                      onChange(checked);
                      handleChangeDatesIfAllDay();
                    }}
                    labelPlacement='start'
                    sx={{ ml: 0 }}
                    label={
                      <Typography component='p' color='darker' variant='body2'>
                        All day
                      </Typography>
                    }
                    control={<Switch checked={value || false} size='small' color='primary' />}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container mb={2}>
            <Grid item xs={12} md={6} pr={{ xs: 0, md: 1 }} mb={{ xs: 1, md: 0 }}>
              <Typography component='p' color='darker' variant='body2' mb={1}>
                Start Date
                {errors.startDate && <small style={errorStyle}>{errors.startDate.message}</small>}
              </Typography>
              <Controller
                name='startDate'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    {watch('allDay') ? (
                      <DatePicker
                        calendar={CustomCalendar}
                        onChange={onChange}
                        placeholder='Start Date Event'
                        value={value || null}
                      />
                    ) : (
                      <DateTimePicker
                        popup={CustomPopup}
                        onChange={onChange}
                        placeholder='Start Date Event'
                        value={value || null}
                      />
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} pl={{ xs: 0, md: 1 }}>
              <Typography component='p' color='darker' variant='body2' mb={1}>
                End Date
                {errors.endDate && <small style={errorStyle}>Require</small>}
              </Typography>
              <Controller
                name='endDate'
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <>
                    {watch('allDay') ? (
                      <DatePicker
                        calendar={CustomCalendar}
                        ref={ref}
                        onChange={onChange}
                        placeholder='End Date Event'
                        value={value || null}
                      />
                    ) : (
                      <DateTimePicker
                        popup={CustomPopup}
                        onChange={onChange}
                        placeholder='End Date Event'
                        value={value || null}
                      />
                    )}
                  </>
                )}
              />
            </Grid>
          </Grid>
          <Grid container mb={1}>
            <Grid item xs={12} md={6} pr={{ xs: 0, md: 1 }}>
              <Typography component='p' color='darker' variant='body2'>
                Description (Optional)
              </Typography>
              <Controller
                name='description'
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <InputBase
                    onChange={onChange}
                    value={value || ''}
                    inputRef={ref}
                    multiline
                    rows={4}
                    fullWidth
                    sx={textAreaStyle}
                    placeholder='Description about the event'
                  />
                )}
              />
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Box component='div' display='flex' justifyContent='center' alignItems='center'>
            <Button onClick={async () => await onCancelEventEdit()} variant='secondarybtn'>
              Cancel
            </Button>
            {isUpdate && (
              <Button
                onClick={() => deleteEvent()}
                startIcon={<DeleteOutlineRoundedIcon />}
                sx={{ bgcolor: red[200], ':hover': { bgcolor: red[100] }, ml: 1 }}
                variant='primarybtn'
              >
                {eventDeleteMutation.isLoading ? 'Removing event...' : 'Delete'}
              </Button>
            )}
            {isUpdate ? (
              <Button type='submit' variant='primarybtn' sx={{ ml: 2 }}>
                {isLoading ? 'Updating event...' : 'Update event'}
              </Button>
            ) : (
              <Button type='submit' sx={{ ml: 2 }} variant='primarybtn'>
                {isLoading ? 'Creating event...' : 'Create event'}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ModalEvents;
