import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
} from '@progress/kendo-react-charts';
import { FC } from 'react';
import { GREY, PURPLE } from 'theme/palette/new';

export type DataRecord = {
  category: string;
  value: number;
};
type BarChartProps = {
  data: {
    name: string;
    series: DataRecord[];
  }[];
};
const BarChart: FC<BarChartProps> = (props: BarChartProps) => {
  const { data } = props;
  return (
    <Chart seriesColors={[PURPLE[400], GREY[400]]}>
      <ChartLegend position='bottom' orientation='horizontal' />
      <ChartTooltip />
      <ChartSeries>
        {data.map((item) => (
          <ChartSeriesItem
            key={item.name}
            name={item.name}
            type='column'
            stack
            data={item.series}
            field='value'
            categoryField='category'
          />
        ))}
      </ChartSeries>
    </Chart>
  );
};

export default BarChart;
