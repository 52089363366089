import { Box, Typography } from '@mui/material';
import React from 'react';
import { purple } from '../../../../theme/palette';

type Props = {
  rightElement: boolean;
  rightText?: string;
  children: JSX.Element;
  rightChildElement?: JSX.Element;
};

const BreadcrumbNavigation: React.FC<Props> = ({
  children,
  rightElement,
  rightText,
  rightChildElement,
}) => {
  return (
    <Box
      component='div'
      display={{ xs: 'none', md: 'flex' }}
      alignItems='center'
      justifyContent='space-between'
      width='100%'
      mb={2}
    >
      {children}
      {rightElement && (
        <Box component='div'>
          <Typography
            component='p'
            sx={{ cursor: 'pointer' }}
            color={purple[100]}
            variant='body2'
            fontWeight={500}
          >
            {rightText}
          </Typography>
        </Box>
      )}
      {rightChildElement && <Box component='div'>{rightChildElement}</Box>}
    </Box>
  );
};

export default BreadcrumbNavigation;
