import { z } from 'zod';

export function getDefaults<T extends z.ZodTypeAny>(
  schema: z.AnyZodObject | z.ZodEffects<any>,
): z.infer<T> {
  // Check if it's a ZodEffect
  if (schema instanceof z.ZodEffects) {
    // Check if it's a recursive ZodEffect
    if (schema.innerType() instanceof z.ZodEffects) return getDefaults(schema.innerType());
    // return schema inner shape as a fresh zodObject
    return getDefaults(z.ZodObject.create(schema.innerType().shape));
  }

  function getDefaultValue(schema: z.ZodTypeAny): unknown {
    if (schema instanceof z.ZodDefault) return schema._def.defaultValue();
    if (schema instanceof z.ZodArray) return [];
    if (schema instanceof z.ZodString) return '';
    if (schema instanceof z.ZodNumber) return 0;
    if (schema instanceof z.ZodBoolean) return false;
    if (schema instanceof z.ZodUnion) {
      const isDate = !!Object(schema)._def.options.find((option) => option instanceof z.ZodDate);

      if (isDate) return '';
    }
    // return an content of object recursivly
    if (schema instanceof z.ZodObject) return getDefaults(schema);

    if (!('innerType' in schema._def)) return undefined;
    return getDefaultValue(schema._def.innerType);
  }

  return Object.fromEntries(
    Object.entries(schema.shape).map(([key, value]) => {
      return [key, getDefaultValue(value as z.ZodTypeAny)];
    }),
  );
}

export function getBooleanDefaults<T extends z.ZodTypeAny>(
  schema: z.AnyZodObject | z.ZodEffects<any>,
): Partial<z.infer<T>> {
  // Check if it's a ZodEffect
  if (schema instanceof z.ZodEffects) {
    // Check if it's a recursive ZodEffect
    if (schema.innerType() instanceof z.ZodEffects) return getBooleanDefaults(schema.innerType());
    // return schema inner shape as a fresh zodObject
    return getBooleanDefaults(z.ZodObject.create(schema.innerType().shape));
  }

  function getDefaultValue(schema: z.ZodTypeAny): unknown {
    if (schema instanceof z.ZodBoolean) return false;
    if (schema instanceof z.ZodObject) return getBooleanDefaults(schema);

    if (!('innerType' in schema._def)) return undefined;
    return getDefaultValue(schema._def.innerType);
  }

  return Object.fromEntries(
    Object.entries(schema.shape)
      .map(([key, value]) => {
        return [key, getDefaultValue(value as z.ZodTypeAny)];
      })
      .filter(([value]) => value !== undefined),
  ) as Partial<z.infer<T>>;
}
