import { Box } from '@mui/material';
import { PrimaryTabItem, PrimaryTabs } from 'modules/global-academics/centers/academic-setup';
import { useNavigate, useParams } from 'react-router-dom';
import { dark } from 'theme/palette';

type Tab = {
  name: string;
  label: string;
  disabled?: boolean;
};

type TabsPageProps = {
  defaultTabName: string;
  tabs: Tab[];
  isSingle?: boolean;
};

const HeaderTabs: React.FC<TabsPageProps> = (props: TabsPageProps) => {
  const { defaultTabName, tabs, isSingle } = props;
  const navigate = useNavigate();
  const { centerId, agencyId } = useParams();

  const handleChange = (_, newValue: string) => {
    navigate(`/agency/${agencyId}/center/${centerId}/training-dashboard/${newValue}/`);
  };

  return (
    <Box component='div' ml={1} p={2}>
      <Box sx={{ width: '100%' }}>
        <PrimaryTabs
          value={defaultTabName}
          onChange={handleChange}
          style={{ color: dark[100] }}
          indicatorColor='secondary'
          variant={isSingle ? 'fullWidth' : 'standard'}
        >
          {tabs.map(({ name, label, disabled }) => (
            <PrimaryTabItem
              value={name}
              label={label}
              disabled={disabled}
              key={name}
              style={{ alignItems: 'baseline' }}
            />
          ))}
        </PrimaryTabs>
      </Box>
    </Box>
  );
};

export default HeaderTabs;
