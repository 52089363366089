import axios, { AxiosError } from 'axios';
import { axiosConfiguration } from 'axios/config';
import ErrorDismissToast from 'components/Notifications/error-dismiss';
import { Events } from 'modules/global-academics/centers/academic-setup/interfaces';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { ServerResponse } from 'services/client/attendance/attendanceService';
import Alert from 'UI/molecules/global/alerts';

interface Props {
  centerId: number;
}

/* Requests */
const getEvents = async (centerId: number) => {
  const { data: response } = await axiosConfiguration.get<Events>(
    `centers/${centerId}/calendar-events`,
  );
  return response;
};

export interface EventParamsInterface {
  id: number;
  eventName: string;
  startDate: string | null;
  endDate: string | null;
  startTime: string | null;
  endTime: string | null;
  description: string | null;
  isAllday: boolean;
  descriptionSchoolClosed: string | null;
  reasonSchoolClosed: number | null;
}

type eventParams = {
  centerId: number;
  data: EventParamsInterface;
};
const saveEvent = async ({ eventBody }): Promise<void> => {
  const { data: response } = await axiosConfiguration.post(
    `centers/${eventBody.centerId}/calendar-events`,
    eventBody.data,
  );

  return response;
};

const updateEvent = async ({ eventBody }): Promise<void> => {
  const { data: response } = await axiosConfiguration.put(
    `centers/${eventBody.centerId}/calendar-events`,
    eventBody.data,
  );
  return response;
};

const deleteEvent = async ({ centerId, eventId }): Promise<void> => {
  const { data: response } = await axiosConfiguration.delete(
    `centers/${centerId}/calendar-events/${eventId}`,
  );
  return response;
};

/* Hook's */

export const useEvents = ({ centerId }: Props) => {
  const eventsQuery = useQuery(['get-events', centerId], () => getEvents(centerId), {
    staleTime: 120000,
    onError: () => {
      toast.error(<Alert title='Error' subtitle='Error loading events!' />);
    },
  });

  const eventsMutation = useMutation(
    (variables: { eventData: eventParams }) => saveEvent({ eventBody: variables.eventData }),
    {
      onSuccess: () => {
        eventsQuery.refetch();
        toast.success(<Alert title='Success' subtitle='Event Was created successfully' />);
      },
      onError: (error: unknown) => {
        const errors = error as Error | AxiosError;
        if (axios.isAxiosError(errors)) {
          const errorMessage = errors.response?.data as ServerResponse;
          toast(
            (t) => (
              <ErrorDismissToast title='Error' toastId={t.id} subtitle={errorMessage.message} />
            ),
            {
              duration: 10000,
            },
          );
        } else {
          toast.error(<Alert title='Error' subtitle={errors.message} />);
        }
      },
    },
  );

  const eventUpdateMutation = useMutation(
    (variables: { eventData: eventParams }) => updateEvent({ eventBody: variables.eventData }),
    {
      onSuccess: () => {
        eventsQuery.refetch();
        toast.success(<Alert title='Success' subtitle='Event was updated successfully' />);
      },
      onError: (error: unknown) => {
        const errors = error as Error | AxiosError;
        if (axios.isAxiosError(errors)) {
          const errorMessage = errors.response?.data as ServerResponse;
          toast(
            (t) => (
              <ErrorDismissToast title='Error' toastId={t.id} subtitle={errorMessage.message} />
            ),
            {
              duration: 10000,
            },
          );
        } else {
          toast.error(<Alert title='Error' subtitle={errors.message} />);
        }
      },
    },
  );

  const eventDeleteMutation = useMutation(
    (variables: { centerId: number; eventId: number }) =>
      deleteEvent({ centerId: variables.centerId, eventId: variables.eventId }),
    {
      onSuccess: () => {
        eventsQuery.refetch();
        toast.success(<Alert title='Success' subtitle='The event was deleted successfully' />);
      },
      onError: () => {
        toast.error(<Alert title='Error' />);
      },
    },
  );

  return {
    eventsQuery,
    eventsMutation,
    eventUpdateMutation,
    eventDeleteMutation,
  };
};
