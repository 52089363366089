/* eslint-disable react/prop-types */
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { dark } from 'theme/palette';

type CustomSwitchProps = {
  caption: string;
  fieldName: string;
  defaultValue: boolean;
  required?: boolean;
  isChecked?: boolean;
};

const CustomSwitch: React.FC<CustomSwitchProps> = (props) => {
  const { caption, fieldName, defaultValue, required, isChecked } = props;
  const { control } = useFormContext();

  return (
    <Box
      component='div'
      mb={1}
      height='100%'
      display='flex'
      justifyContent='flex-start'
      alignItems='center'
    >
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        rules={{ required: required }}
        render={({ field: { onChange, value, ref } }) => (
          <FormControlLabel
            onChange={onChange}
            inputRef={ref}
            labelPlacement='start'
            sx={{ ml: 0 }}
            label={
              <Typography component='p' color={dark[200]} variant='body2'>
                {`${caption}`}
              </Typography>
            }
            control={<Switch checked={value || isChecked} color='primary' size='small' />}
          />
        )}
      />
    </Box>
  );
};

export default CustomSwitch;
