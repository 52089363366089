import { Typography } from '@mui/material';
import React from 'react';

type Props = {
  title: string;
  subtitle?: string;
};

const Alert: React.FC<Props> = ({ title, subtitle }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography component='h3' fontWeight={600} variant='body1'>
        {title}
      </Typography>
      {subtitle && (
        <Typography component='p' color='currentcolor' fontWeight={400} variant='body2'>
          {subtitle}
        </Typography>
      )}
    </div>
  );
};

export default Alert;
