import { Box } from '@mui/material';
import { DefaultAvatar } from 'components/TrainingHeader';
import moment from 'moment';
import { forwardRef } from 'react';
import { CommentsType, StaffMemberInfoByComment } from 'services/help-desk/comments';

const CommentCard = forwardRef<HTMLDivElement, CommentsType>((data: CommentsType, ref) => {
  const { text, date, staffMemberInfo } = data;

  const { firstName, lastName, profilePhotoURL } = staffMemberInfo as StaffMemberInfoByComment;

  return (
    <Box ref={ref} sx={{ display: 'flex', mb: 4 }}>
      <DefaultAvatar
        url={profilePhotoURL ?? ''}
        firstName={firstName ?? ''}
        lastName={lastName ?? ''}
        hasImage={profilePhotoURL ? true : false}
        customSize='30px'
        customFontSize='14px'
      />
      <Box sx={{ width: '100%', marginLeft: 1, paddingTop: 0.5 }}>
        <Box component={'div'} sx={{ mb: 2 }}>
          <Box component='span' sx={{ fontWeight: 600 }}>{`${firstName} ${lastName}`}</Box>
          <Box component='span' sx={{ marginLeft: 1, fontWeight: 300, fontSize: '12px' }}>
            {moment(date).fromNow()}
          </Box>
        </Box>
        <p>{text}</p>
      </Box>
    </Box>
  );
});

export default CommentCard;
