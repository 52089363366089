/* eslint-disable @typescript-eslint/no-explicit-any */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { dark, purple } from 'theme/palette';

interface DropzoneFileProps {
  onSelectFile: (image: File) => void;
  onChangeFile: (image: File) => void;
  onDeleteFile?: () => void;
  defaultFile?: string | null;
  shouldUpdateFile?: boolean;
  extensionAccepted: 'application/pdf';
  uploadProgress?: ProgressEvent;
  stringDefaultFile?: boolean;
  noRemoveButton?: boolean;
  error?: boolean;
}

const DropzoneFile: React.FC<DropzoneFileProps> = ({
  onSelectFile,
  onChangeFile,
  onDeleteFile,
  error,
  defaultFile,
  shouldUpdateFile,
  extensionAccepted,
  uploadProgress,
  stringDefaultFile,
  noRemoveButton,
}) => {
  const [file, setFile] = useState<File | undefined>();

  const showDropZone = !file && !defaultFile;
  const showFileSelected = file && !defaultFile;
  const showFileUploaded = !file && defaultFile;
  const showFileChanged = file && defaultFile;

  const percentCompleted =
    uploadProgress && Math.round((uploadProgress.loaded * 100) / uploadProgress.total);

  const fileData = stringDefaultFile ? defaultFile : defaultFile && JSON.parse(defaultFile);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      [extensionAccepted]: ['.pdf'],
    },
    maxFiles: 1,
    onDrop: (droppedFiles) => {
      const droppedFile = droppedFiles[0];
      setFile(droppedFile);
      if (shouldUpdateFile) {
        onChangeFile(droppedFile);
      } else {
        onSelectFile(droppedFile);
      }
    },
  });

  if (error) {
    return (
      <Typography fontSize='16px' color='red' ml={1}>
        Error ocurred when performing file action
      </Typography>
    );
  }

  return (
    <Box
      component='div'
      border={isDragActive ? `2px dashed ${purple[100]}` : `1px solid ${purple[100]}`}
      borderRadius='4px'
    >
      {showDropZone && (
        <Box
          component='div'
          p={1}
          height='100%'
          borderRadius={2}
          /*  */
        >
          <input {...getInputProps()} />
          <Box component='div' display='flex' justifyContent='space-between' alignItems='center'>
            <Button {...getRootProps()} variant='secondarybtn'>
              <Typography
                component='p'
                color='primary'
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                variant='body2'
              >
                Select File
              </Typography>
            </Button>
            <Typography
              ml={1}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              component='p'
              color={dark[200]}
              variant='body2'
            >
              Drop PDF here
            </Typography>
          </Box>
        </Box>
      )}
      {(showFileSelected || showFileChanged) && (
        <Box
          component='div'
          justifyContent='space-between'
          justifyItems='center'
          alignItems='center'
          p={2}
          display='flex'
        >
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <PictureAsPdfRoundedIcon fontSize='large' />
            <Box>
              <Typography
                width='80%'
                component='p'
                sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                color='darker'
                variant='body2'
                ml={1}
              >
                {file?.name ?? 'No Filename'}
              </Typography>
              {uploadProgress && !error ? (
                Number(percentCompleted) < 100 ? (
                  <Typography component='p' variant='body2' color='gray' ml={1}>
                    <LinearProgress variant='determinate' value={percentCompleted} />
                  </Typography>
                ) : (
                  <Typography component='p' variant='body2' color='green' ml={1}>
                    PDF uploaded successfully
                  </Typography>
                )
              ) : (
                <Typography component='p' color='gray' variant='body2' ml={1}>
                  Pending to upload
                </Typography>
              )}
            </Box>
          </Box>
          <Box component='div' display='flex' justifyContent='flex-end' alignItems='center'>
            <CloseRoundedIcon
              onClick={async () => {
                if (percentCompleted && onDeleteFile) {
                  await onDeleteFile();
                }
                setFile(undefined);
              }}
              sx={{ cursor: 'pointer' }}
            />
          </Box>
        </Box>
      )}
      {showFileUploaded && (
        <Box
          component='div'
          justifyContent='space-between'
          alignItems='center'
          p={2}
          display='flex'
        >
          <Box
            display='flex'
            justifyContent='space-between'
            onClick={() => {
              if (stringDefaultFile) {
                window.open(fileData);
              } else if (fileData) {
                window.open(fileData.FileUrl);
              }
            }}
            sx={{ cursor: 'pointer' }}
            alignItems='center'
          >
            <PictureAsPdfRoundedIcon fontSize='large' />
            <Typography
              width='90%'
              sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
              component='p'
              color='darker'
              variant='body2'
              ml={1}
            >
              File Attached
            </Typography>

            <DownloadRoundedIcon sx={{ ml: 1 }} />
          </Box>

          <Box component='div' display='flex' justifyContent='flex-end' alignItems='center'>
            {noRemoveButton ? (
              <Button size='small' {...getRootProps()}>
                Change
              </Button>
            ) : (
              <CloseRoundedIcon
                onClick={async () => {
                  if (onDeleteFile) await onDeleteFile();
                }}
                sx={{ cursor: 'pointer' }}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DropzoneFile;
