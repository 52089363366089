/* Redux */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
/* Components */
import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  Dialog,
  Divider,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import CameraIcon from 'assets/images/svg/camera-icon.svg';
/* Hooks */
import notificationIcon from 'assets/images/svg/notification.svg';
import React, { useCallback, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { alertCategory } from 'services/catalogs/alerts/alertsCatalogServices';
import { FormInputs, useHero } from 'services/client/client-hero';
import { useClientDetails } from 'services/client/demographics/client-details/clientDetailsServices';
import { AppDispatch } from 'store';
import { handleOpenModal } from 'store/slices/menu';
/* Utils */
import { blue, dark, green, grey, light, purple, red, shadow, yellow } from 'theme/palette';
import Alert from 'UI/molecules/global/alerts';
import { useRandomColorAvatar } from 'UI/organisms/tasks/taskCard/hooks';

const ClientRecordHero: React.FC = () => {
  const [avatarImage, setAvatarImage] = useState<Array<{ preview: string }>>([]);
  const [changes, setChanges] = useState<boolean | null>(null);
  const [changesRemove] = useState<boolean | null>(null);
  const [removeState, setRemoveState] = useState<boolean>(false);

  const { id: clientID } = useParams();
  const location = useLocation();

  const {
    heroQuery,
    uploadPhotoHeroMutation,
    removePhotoHeroMutation,
    downloadProfileAvatarPhotoHeroMutation,
  } = useHero({
    clientId: Number(clientID),
  });

  const heroInfo = heroQuery.data;

  const onDeleteFile = () => {
    setAvatarImage([]);
    setRemoveState(false);
  };

  const { refetch: clientDetailsInformationRefetch } = useClientDetails({
    routeParams: { id: clientID },
    config: { enabled: !!clientID },
  });

  const form = useForm<FormInputs>();

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    await uploadPhotoHeroMutation.mutateAsync(
      { clientId: Number(clientID), body: data },
      {
        onSuccess: () => clientDetailsInformationRefetch(),
      },
    );
    handleOnCloseModalForUpdate();
  };

  useEffect(() => {
    if (changes !== null) {
      heroQuery.refetch();
    }
  }, [changes]);

  useEffect(() => {
    if (changesRemove !== null) {
      heroQuery.refetch();
    }
  }, [changesRemove]);

  const remove = () => {
    removePhotoHeroMutation.mutateAsync({ clientId: Number(clientID) });
    handleOnCloseModalForUpdate();
  };

  const downloadAvatar = () => {
    downloadProfileAvatarPhotoHeroMutation.mutateAsync({ clientId: Number(clientID) });
  };

  const [openModalPhoto, setOpenModalPhoto] = useState<boolean>(false);

  const handleModalForUpdatePhoto = useCallback(() => {
    setOpenModalPhoto(true);
    setChanges(true);
  }, [openModalPhoto]);

  const handleOnCloseModalForUpdate = useCallback(() => {
    setOpenModalPhoto(false);
    setChanges(false);
    onDeleteFile();
  }, [openModalPhoto]);

  const dispatch = useDispatch<AppDispatch>();

  const openModal = () => {
    dispatch(handleOpenModal());
  };

  const medical = heroInfo?.alerts?.filter(
    (element) => element.category.toLowerCase() === alertCategory.medical,
  );
  const general = heroInfo?.alerts?.filter(
    (element) => element.category.toLowerCase().replace(/\s+/g, '') === alertCategory.general,
  );
  const safeAndSecurity = heroInfo?.alerts?.filter(
    (element) =>
      element.category.toLowerCase().replace(/\s+/g, '') === alertCategory.safetyandsecurity,
  );
  const mentalHealth = heroInfo?.alerts?.filter(
    (element) => element.category.toLowerCase().replace(/\s+/g, '') === alertCategory.mentalhealth,
  );

  if (heroQuery.isLoading) {
    return (
      <Box
        component='div'
        display='flex'
        justifyContent='flex-start'
        flexDirection='column'
        alignItems='start'
        height='250px'
      >
        <Skeleton sx={{ borderRadius: '6px', mt: -7 }} height='250px' width='100%' />
      </Box>
    );
  }

  return (
    <Box
      mb={2}
      p={{ xs: 1, md: 1, lg: 2 }}
      bgcolor='white'
      borderRadius='4px'
      boxShadow='0px 3px 14px rgba(0, 0, 0, 0.14)'
    >
      <Dialog
        open={openModalPhoto}
        onClose={handleOnCloseModalForUpdate}
        PaperProps={{
          style: {
            borderRadius: '6px',
            boxShadow: shadow[200],
            minWidth: '350px',
            maxWidth: '500px',
          },
        }}
      >
        <Box component='div' p={2} width={{ xs: '350px', md: '500px' }}>
          <Box component='div' display='flex' justifyContent='space-between' alignItems='center'>
            {heroInfo?.photoUrl && heroInfo?.photoUrl !== null ? (
              <Typography component='h1' variant='h6' fontWeight={700} color={blue[100]}>
                Change your Profile Photo
              </Typography>
            ) : (
              <Typography component='h1' variant='h6' fontWeight={700} color={blue[100]}>
                Add your Profile Photo
              </Typography>
            )}
            <CloseRoundedIcon
              onClick={() => handleOnCloseModalForUpdate()}
              sx={{
                color: blue[100],
                cursor: 'pointer',
                ':hover': { border: '1px solid black', borderRadius: '4px' },
              }}
            />
          </Box>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Controller
              control={form.control}
              name='File'
              rules={{
                required: { value: true, message: 'This field is required' },
              }}
              render={({ field: { onChange, onBlur }, fieldState }) => (
                <Dropzone
                  noClick
                  maxSize={1000000}
                  onDropRejected={() =>
                    toast.error(
                      <Alert title='Error' subtitle='Maximum file upload size is 1MB' />,
                      {
                        duration: 6000,
                        style: {
                          borderLeft: `8px solid ${red[100]}`,
                          backgroundColor: purple[400],
                        },
                      },
                    )
                  }
                  accept={{ 'image/png': ['.png'], 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'] }}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      setAvatarImage(
                        acceptedFiles.map((upFile) =>
                          Object.assign(upFile, {
                            preview: URL.createObjectURL(upFile),
                          }),
                        ),
                      );
                      form.setValue('File', acceptedFiles as unknown as FileList, {
                        shouldValidate: true,
                      });
                    }
                  }}
                >
                  {({ getRootProps, getInputProps, open, isDragActive }) => (
                    <Box component='div' mt={1}>
                      <div
                        style={{
                          border: isDragActive ? `3px dashed ${purple[100]}` : 'none',
                          backgroundColor: isDragActive ? purple[400] : 'transparent',
                          borderRadius: '6px',
                        }}
                        {...getRootProps()}
                      >
                        <input
                          {...getInputProps({
                            id: 'spreadsheet',
                            onChange,
                            onBlur,
                          })}
                        />

                        {avatarImage.length <= 0 && (
                          <Box
                            component='div'
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            my={2}
                          >
                            {heroInfo?.photoUrl && heroInfo?.photoUrl !== null ? (
                              <Badge
                                overlap='circular'
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                  <React.Fragment>
                                    {downloadProfileAvatarPhotoHeroMutation.isLoading ? (
                                      <Chip
                                        sx={{ bgcolor: green[100] }}
                                        label={
                                          <Typography
                                            component='p'
                                            color={light[100]}
                                            variant='caption'
                                          >
                                            Downloading...
                                          </Typography>
                                        }
                                      />
                                    ) : (
                                      <Tooltip title='Download Image' arrow>
                                        <Avatar
                                          onClick={() => downloadAvatar()}
                                          sx={{
                                            width: 32,
                                            height: 32,
                                            cursor: 'pointer',
                                            bgcolor: 'white',
                                          }}
                                        >
                                          <DownloadForOfflineRoundedIcon
                                            color='info'
                                            fontSize='large'
                                          />
                                        </Avatar>
                                      </Tooltip>
                                    )}
                                  </React.Fragment>
                                }
                              >
                                <Avatar
                                  sx={{ width: 250, height: 250 }}
                                  src={`${heroInfo?.photoUrl}?${Date.now()}`}
                                  alt='avatar-image'
                                />
                              </Badge>
                            ) : (
                              <Avatar
                                sx={{
                                  height: 150,
                                  width: 150,
                                  bgcolor: heroInfo?.firstName
                                    ? useRandomColorAvatar(heroInfo?.firstName)
                                    : 'none',
                                }}
                              >
                                <Typography variant='h3' letterSpacing={-1}>
                                  {`${heroInfo?.firstName.split(' ')[0][0]}`}{' '}
                                  {heroInfo?.lastName
                                    ? `${heroInfo?.lastName.split(' ')[0][0]}`
                                    : null}
                                </Typography>
                              </Avatar>
                            )}
                          </Box>
                        )}

                        {avatarImage?.map((upFile, index: number) => (
                          <Box
                            component='div'
                            my={2}
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            flexDirection='column'
                            key={index}
                          >
                            <Avatar sx={{ height: 250, width: 250 }} src={upFile.preview} />
                          </Box>
                        ))}

                        <div>
                          {fieldState.error && <span role='alert'>{fieldState.error.message}</span>}
                        </div>
                        {removeState ? (
                          <Box
                            component='div'
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            mt={1}
                          >
                            <Typography component='h3' color={red[200]} variant='body1'>
                              Are you sure you want to remove this photo?
                            </Typography>
                          </Box>
                        ) : null}

                        <Divider sx={{ my: 2 }} />

                        <Box
                          mb={isDragActive ? 2 : 0}
                          component='div'
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                        >
                          {avatarImage.length <= 0 && (
                            <React.Fragment>
                              {heroInfo?.photoUrl && heroInfo.photoUrl !== null && (
                                <React.Fragment>
                                  {removeState ? (
                                    <Button
                                      onClick={() => setRemoveState(false)}
                                      variant='secondarybtn'
                                    >
                                      Cancel
                                    </Button>
                                  ) : (
                                    <Button
                                      variant='secondarybtn'
                                      onClick={() => setRemoveState(true)}
                                    >
                                      Remove Photo
                                    </Button>
                                  )}
                                </React.Fragment>
                              )}
                              {removeState ? (
                                <Button
                                  variant='primarybtn'
                                  sx={{ ml: 2 }}
                                  onClick={() => remove()}
                                >
                                  {removePhotoHeroMutation.isLoading ? 'Removing...' : 'Yes'}
                                </Button>
                              ) : (
                                <Button
                                  sx={{ ml: 2 }}
                                  startIcon={
                                    <Box component='img' src={CameraIcon} alt='camera-icon' />
                                  }
                                  variant='primarybtn'
                                  type='button'
                                  onClick={open}
                                >
                                  Upload Profile Photo
                                </Button>
                              )}
                            </React.Fragment>
                          )}
                          {avatarImage.length >= 1 && (
                            <React.Fragment>
                              <Button variant='secondarybtn' onClick={() => onDeleteFile()}>
                                Cancel
                              </Button>
                              <Button type='submit' variant='primarybtn' sx={{ ml: 2 }}>
                                {uploadPhotoHeroMutation.isLoading
                                  ? 'Saving Photo...'
                                  : 'Save Profile'}
                              </Button>
                            </React.Fragment>
                          )}
                        </Box>
                      </div>
                    </Box>
                  )}
                </Dropzone>
              )}
            />
          </form>
        </Box>
      </Dialog>
      <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={'stretch'}>
        <Box
          component='div'
          display='flex'
          justifyContent='flex-start'
          width='auto'
          flexDirection={{ xs: 'column', md: 'row' }}
          mt={{ xs: 1, md: 0 }}
        >
          <Box
            component='div'
            display='flex'
            justifyContent='center'
            alignItems='center'
            mr={{ xs: 0, md: 2 }}
          >
            <Badge
              overlap='circular'
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                location.pathname === `/client/${Number(clientID)}/` ? (
                  <Avatar
                    onClick={() => handleModalForUpdatePhoto()}
                    sx={{
                      width: 26,
                      height: 26,
                      cursor: 'pointer',
                      bgcolor: purple[100],
                      border: '2px solid white',
                    }}
                  >
                    <Box component='img' src={CameraIcon} alt='camera-icon' />
                  </Avatar>
                ) : null
              }
            >
              {heroInfo?.photoUrl && heroInfo?.photoUrl !== null ? (
                <Avatar
                  alt='image-profile'
                  src={`${heroInfo?.photoUrl}?${Date.now()}`}
                  sx={{
                    width: 65,
                    height: 65,
                    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.14)',
                    bgcolor: heroInfo?.firstName
                      ? useRandomColorAvatar(heroInfo?.firstName)
                      : 'none',
                  }}
                />
              ) : (
                <Avatar
                  sx={{
                    width: 65,
                    height: 65,
                    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.14)',
                    bgcolor: heroInfo?.firstName
                      ? useRandomColorAvatar(heroInfo?.firstName)
                      : 'none',
                  }}
                >
                  <Typography variant='h5' letterSpacing={-1}>
                    {`${heroInfo?.firstName.split(' ')[0][0]}`}{' '}
                    {heroInfo?.lastName ? `${heroInfo?.lastName.split(' ')[0][0]}` : null}
                  </Typography>
                </Avatar>
              )}
            </Badge>
          </Box>
          <Box width='100%' p={0.5} borderRight={{ xs: 'none', md: `1px solid ${grey[300]}` }}>
            <Box
              width='100%'
              display={'flex'}
              height={'100%'}
              justifyContent='center'
              alignItems='center'
              mr={{ xs: 0, md: 2, lg: 2 }}
            >
              <Box
                maxWidth={{ md: '130px', lg: '200px' }}
                component='div'
                display={{ xs: 'flex', md: 'block' }}
                justifyContent='center'
                alignItems='center'
                flexDirection={{ xs: 'column', md: 'row' }}
              >
                <Typography
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  component='h1'
                  color={dark[200]}
                  variant='h6'
                >
                  {heroInfo?.firstName !== null ? heroInfo?.firstName : ''}
                </Typography>
                <Typography
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  component='h3'
                  color={grey[800]}
                  variant='body1'
                  fontWeight={500}
                >
                  {`${heroInfo?.middleName !== null ? heroInfo?.middleName : ''} ${
                    heroInfo?.lastName !== null ? heroInfo?.lastName : ''
                  }`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box component='div' py={1} width={{ xs: '100%', md: '100%', lg: '100%' }}>
          <Box component='div' py={{ xs: 0, md: 1 }} px={{ xs: 0, md: 1 }} width={'100%'}>
            <Grid component='div' container>
              <Grid component='div' item xs={12} md={'auto'} mr={{ xs: 0, md: 2 }}>
                <Box component='div' display={'flex'} justifyContent='center' alignItems='center'>
                  <Typography component='p' color={dark[200]} variant='body1'>
                    ID:
                  </Typography>
                  <Typography component='p' variant='body1' color='primary' ml={0.3}>
                    {heroInfo?.id}
                  </Typography>
                </Box>
              </Grid>
              {heroInfo?.pronoun && (
                <Grid component='div' item xs={12} md='auto' mr={{ xs: 0, md: 2 }}>
                  <Box display={'flex'} justifyContent='center' alignItems='center'>
                    <Typography component='p' variant='body1' color={dark[200]}>
                      Pronoun:
                    </Typography>
                    <Typography component='p' variant='body1' color='primary' ml={0.3}>
                      {heroInfo?.pronoun}
                    </Typography>
                  </Box>
                </Grid>
              )}

              {heroInfo?.preferredName && (
                <Grid component='div' item xs={12} md='auto' mr={{ xs: 0, md: 2 }}>
                  <Box component='div' display='flex' justifyContent='center' alignItems='center'>
                    <Typography component='p' variant='body1' color={dark[200]}>
                      Preferred Name:
                    </Typography>
                    <Typography component='p' variant='body1' color='primary' ml={0.3}>
                      {heroInfo?.preferredName}
                    </Typography>
                  </Box>
                </Grid>
              )}
              {heroInfo?.facility !== null && (
                <Grid component='div' item xs={12} md='auto' mr={{ xs: 0, md: 2 }}>
                  <Box component='div' display='flex' justifyContent='center' alignItems='center'>
                    <Typography component='p' variant='body1' color={dark[200]}>
                      Facility:
                    </Typography>
                    <Typography component='p' variant='body1' color='primary' ml={0.3}>
                      {heroInfo?.facility}
                    </Typography>
                  </Box>
                </Grid>
              )}

              {heroInfo?.centerName && (
                <Grid component='div' item xs={12} md='auto' mr={{ xs: 0, md: 2 }}>
                  <Box component='div' display='flex' justifyContent='center' alignItems='center'>
                    <Typography component='p' variant='body1' color={dark[200]}>
                      Center:
                    </Typography>
                    <Typography component='p' variant='body1' color='primary' ml={0.3}>
                      {heroInfo?.centerName}
                    </Typography>
                  </Box>
                </Grid>
              )}

              {heroInfo?.duration && heroInfo?.duration > 0 ? (
                <Grid component='div' item xs={12} md={'auto'} mr={{ xs: 0, md: 2 }}>
                  <Box component='div' display={'flex'} justifyContent='center' alignItems='center'>
                    <Typography component='p' variant='body1' color={dark[200]}>
                      Duration:
                    </Typography>
                    <Typography component='p' variant='body1' color='primary' ml={0.3}>
                      {heroInfo?.duration}
                    </Typography>
                  </Box>
                </Grid>
              ) : null}

              {heroInfo?.program && (
                <Grid component='div' item xs={12} md={'auto'} mr={{ xs: 0, md: 2 }}>
                  <Box component='div' display={'flex'} justifyContent='center' alignItems='center'>
                    <Typography component='p' variant='body1' color={dark[200]}>
                      Program:
                    </Typography>
                    <Typography component='p' variant='body1' color='primary' ml={0.3}>
                      {heroInfo?.program}
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
          <Box
            component='div'
            borderTop={{ sx: 'none', md: `1px solid ${grey[300]}` }}
            py={{ xs: 0, md: 1 }}
            px={{ xs: 0, md: 1 }}
          >
            <Grid component='div' container>
              {heroInfo?.status !== null ? (
                <Grid component='div' item xs={12} md={'auto'} mr={{ xs: 0, md: 2 }}>
                  <Box component='div' display='flex' justifyContent='center' alignItems='center'>
                    <Typography component='h3' variant='body1' color={dark[200]}>
                      Status:
                    </Typography>
                    <Typography
                      component='h3'
                      fontWeight={800}
                      variant='body1'
                      color='primary'
                      ml={0.3}
                    >
                      {heroInfo?.status}
                    </Typography>
                  </Box>
                </Grid>
              ) : null}
              {heroInfo?.growthAndChangeStage && (
                <Grid component='div' item xs={12} md='auto' mr={{ xs: 0, md: 2 }}>
                  <Box component='div' display='flex' justifyContent='center' alignItems='center'>
                    <Typography component='h3' variant='body1' color={dark[200]}>
                      Growth and Change Stage:
                    </Typography>
                    <Typography
                      component='h3'
                      textAlign='center'
                      variant='body1'
                      color='primary'
                      ml={0.3}
                      fontWeight={500}
                    >
                      {heroInfo?.growthAndChangeStage}
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
      {heroInfo?.alerts && heroInfo?.alerts?.length > 0 ? (
        <Box borderTop={`1px solid ${grey[300]}`} component='div'>
          <Box
            component='div'
            display='flex'
            flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
            alignItems='center'
            mt={2}
          >
            <Box
              component='div'
              display='flex'
              justifyContent='center'
              alignItems='center'
              borderRadius='6px'
              height={{
                xs: '62px',
                sm: '62px',
                md: '62px',
                lg: '65px',
              }}
              width={{
                xs: '100%',
                sm: '100%',
                md: '70px',
                lg: '70px',
              }}
              mb={{
                xs: 1,
                sm: 1,
                md: 1,
                lg: 0,
              }}
              bgcolor={yellow[100]}
              sx={{
                cursor: 'pointer',
                ':hover': {
                  opacity: 0.9,
                },
              }}
              onClick={() => openModal()}
            >
              <Box component='img' src={notificationIcon} alt='notification-icon' />
            </Box>
            {heroQuery.isSuccess && (
              <Box
                width='100%'
                sx={{
                  /* maxWidth: 1200, */
                  display: 'grid',
                  gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                    lg: 'repeat(3, 1fr)',
                    xl: 'repeat(4, 1fr)',
                  },
                  columnGap: 2,
                  rowGap: 1,
                }}
              >
                {medical && medical.length > 0 ? (
                  <Box component='div' /* maxWidth='272px' */>
                    <Box
                      border={`1px solid ${grey[300]}`}
                      component='div'
                      p={1}
                      borderRadius='6px'
                      mx={{ xs: 0, md: 1.5 }}
                      mb={{ xs: 1, md: 0 }}
                    >
                      <Typography
                        mt={0.5}
                        mx={0.5}
                        component='h3'
                        color={dark[200]}
                        variant='body1'
                        fontWeight={500}
                      >
                        Medical:
                      </Typography>
                      <Box component='div' mx={0.5} display='flex' alignItems='center'>
                        <Box component='div' display='flex'>
                          {medical.map((element, index) => (
                            <Box component='div' key={index}>
                              {index <= 1 && (
                                <Typography
                                  sx={{
                                    width: '100px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                  component='p'
                                  mr={0.7}
                                  color='primary'
                                  variant='body2'
                                >
                                  {element.alertType}
                                </Typography>
                              )}
                            </Box>
                          ))}
                        </Box>
                        <Box component='div'>
                          {medical.length > 2 && (
                            <Typography
                              onClick={() => openModal()}
                              component='p'
                              color='primary'
                              variant='body2'
                              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                              fontWeight={600}
                            >
                              +{medical.length - 2}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : null}
                {general && general.length > 0 ? (
                  <Box component='div' /* maxWidth='272px' */>
                    <Box
                      border={`1px solid ${grey[300]}`}
                      component='div'
                      p={1}
                      borderRadius='6px'
                      mx={{ xs: 0, md: 1.5 }}
                      mb={{ xs: 1, md: 0 }}
                    >
                      <Typography
                        mt={0.5}
                        mx={0.5}
                        component='h3'
                        color={dark[200]}
                        variant='body1'
                        fontWeight={500}
                      >
                        General:
                      </Typography>
                      <Box component='div' mx={0.5} display='flex' alignItems='center'>
                        <Box component='div' display='flex'>
                          {general.map((element, index) => (
                            <Box component='div' key={index}>
                              {index <= 1 && (
                                <Typography
                                  sx={{
                                    width: '100px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                  component='p'
                                  mr={0.7}
                                  color='primary'
                                  variant='body2'
                                >
                                  {element.alertType}
                                </Typography>
                              )}
                            </Box>
                          ))}
                        </Box>
                        <Box component='div'>
                          {general.length > 2 && (
                            <Typography
                              onClick={() => openModal()}
                              component='p'
                              color='primary'
                              variant='body2'
                              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                              fontWeight={600}
                            >
                              +{general.length - 2}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : null}
                {safeAndSecurity && safeAndSecurity.length > 0 ? (
                  <Box component='div' /* maxWidth='272px' */>
                    <Box
                      border={`1px solid ${grey[300]}`}
                      component='div'
                      p={1}
                      borderRadius='6px'
                      mx={{ xs: 0, md: 1.5 }}
                      mb={{ xs: 1, md: 0 }}
                    >
                      <Typography
                        mt={0.5}
                        mx={0.5}
                        component='h3'
                        color={dark[200]}
                        variant='body1'
                        fontWeight={500}
                      >
                        Safe & Security:
                      </Typography>
                      <Box component='div' mx={0.5} display='flex' alignItems='center'>
                        <Box component='div' display='flex'>
                          {safeAndSecurity.map((element, index) => (
                            <Box component='div' key={index}>
                              {index <= 1 && (
                                <Typography
                                  sx={{
                                    width: '100px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                  component='p'
                                  mr={0.7}
                                  color='primary'
                                  variant='body2'
                                >
                                  {element.alertType}
                                </Typography>
                              )}
                            </Box>
                          ))}
                        </Box>
                        <Box component='div'>
                          {safeAndSecurity.length > 2 && (
                            <Typography
                              onClick={() => openModal()}
                              component='p'
                              color='primary'
                              variant='body2'
                              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                              fontWeight={600}
                            >
                              +{safeAndSecurity.length - 2}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : null}
                {mentalHealth && mentalHealth.length > 0 ? (
                  <Box component='div' /* maxWidth='272px' */>
                    <Box
                      border={`1px solid ${grey[300]}`}
                      component='div'
                      p={1}
                      borderRadius='6px'
                      mx={{ xs: 0, md: 1.5 }}
                      mb={{ xs: 1, md: 0 }}
                    >
                      <Typography
                        mt={0.5}
                        mx={0.5}
                        component='h3'
                        color={dark[200]}
                        variant='body1'
                        fontWeight={500}
                      >
                        Mental Health:
                      </Typography>
                      <Box component='div' mx={0.5} display='flex' alignItems='center'>
                        <Box component='div' display='flex'>
                          {mentalHealth.map((element, index) => (
                            <Box component='div' key={index}>
                              {index <= 1 && (
                                <Typography
                                  sx={{
                                    width: '100px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                  component='p'
                                  mr={0.7}
                                  color='primary'
                                  variant='body2'
                                >
                                  {element.alertType}
                                </Typography>
                              )}
                            </Box>
                          ))}
                        </Box>
                        <Box component='div'>
                          {mentalHealth.length > 2 && (
                            <Typography
                              onClick={() => openModal()}
                              component='p'
                              color='primary'
                              variant='body2'
                              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                              fontWeight={600}
                            >
                              +{mentalHealth.length - 2}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            )}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default ClientRecordHero;
