import { API_ROUTES } from 'constants/apiRoutes';
import { useFetch, UseServiceProps } from 'utils/react-query';
import { pathToUrl } from 'utils/routes';
import { z } from 'zod';

export const fields = {
  id: 'id',
  centerId: 'centerId',
  createdDate: 'createdDate',
  modifiedDate: 'modifiedDate',
  yearName: 'yearName',
  isDefault: 'isDefault',
  yearStartDate: 'yearStartDate',
  yearEndDate: 'yearEndDate',
};

export const AcademicYearSchema = z.object({
  [fields.id]: z.number(),
  [fields.centerId]: z.number().optional(),
  [fields.createdDate]: z.string(),
  [fields.modifiedDate]: z.string(),
  [fields.yearName]: z.string(),
  [fields.isDefault]: z.boolean(),
  [fields.yearStartDate]: z.string(),
  [fields.yearEndDate]: z.string(),
});

export type AcademicYearType = z.infer<typeof AcademicYearSchema>;

export const useAcademicYearsByCenter = ({
  routeParams,
  params,
  config,
}: UseServiceProps<AcademicYearType>) =>
  useFetch<AcademicYearType[]>(
    pathToUrl(API_ROUTES.ACADEMIC_YEARS_BY_CENTER, routeParams),
    params,
    config,
  );
