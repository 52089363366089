import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  customAction?: () => void;
}

export const TransitionModal = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const DialogMessage: React.FC<Props> = ({ open, onClose, customAction }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={TransitionModal}
      keepMounted
      onClose={onClose}
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle>Are you sure you want to close this section?</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>
          You will lose all information in this section. You can’t undo this action.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={customAction && customAction}
          type={customAction ? 'button' : 'submit'}
          form='form-incident-reporting'
        >
          Yes, Close section
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogMessage;
