/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Referral } from 'types/client/client-details';

export type clientTypes = {
  firstName: string;
  lastName: string;
  birthDate: Date | null;
  middleName: string | null;
  preferedName: string;
  pseudonym: string | null;
  photo: string;
  birthGender: string;
  genderIdentify: string;
  preferedPronouns: number | null;
  race: number | null;
  ethnicity: string;
  primaryLanguage: number;
  referral: Referral;
  status: number;
  transitionDate: any;
  dischargeDate: string | null;
  programService: number | null;
  socialSecurityNumber: string;
  djjClientId: any;
  djjClientProbationOfficerName: any;
  djjClientContactNumber: any;
  djjClientEmail: any;
  dcfFamilyServiceWorker: any;
  dcfServiceWorkerContactNumber: any;
  primaryFundingId: any;
  additionalProgramId: any;
  transportationAssignment: any;
  id: number;
  createdDate?: string;
  modifiedDate?: string;
  additionalProgramMCKinney: boolean | null;
  additionalProgramJimmyRyce: boolean | null;
  djjClient: boolean | null;
  dcfInvolvement: boolean | null;
};

export interface HouseholdTypes {
  id: number;
  clientId: number;
  address: Address;
  phone?: string;
  phoneType?: number;
  email?: string;
  parents: Parent[];
  members: Member[];
  emergencyFirstName?: string;
  emergencyLastName?: string;
  emergencyContactRelationship?: number;
  emergencyContactPhone?: string;
}

export interface Address {
  id: number;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface Parent {
  id: number;
  firstName: string;
  lastName: string;
  relationship: number;
  livesWithClient: boolean;
  address?: Address2;
  primaryPhone: string;
  primaryPhoneType: number;
  primaryEmail: string;
  secondaryPhone?: any;
  secondaryPhoneType: number;
  contactPreference: number;
}

export interface Address2 {
  id: number;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface Member {
  id: number;
  firstName: string;
  lastName: string;
  relationship: number;
}

export const getClient = createAsyncThunk('get-client-info', async (id: number) => {
  try {
    const response = await fetch(`https://mqi-api-qa.azure-api.net/api/client/${id}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const createClient = createAsyncThunk('create-client-info', async (body: object) => {
  try {
    const { data } = await axios.post(`https://mqi-api-qa.azure-api.net/api/client/${2}`, body);
    return data;
  } catch (error) {
    console.log(error);
  }
});
