import { Box, InputBase, Typography } from '@mui/material';
import WipChip from 'components/WipChip';
import React from 'react';
import { inputStyle, textAreaStyle } from 'theme/styles/inputs';

type InputProps = {
  caption: string;
  placeholder?: string;
  isDisabled?: boolean;
  isWip?: boolean;
  textarea?: boolean;
  color?: string;
  defaultValue: number | string;
  justifyContent?: 'space-between' | 'space-around' | 'flex-start' | 'flex-end' | 'center';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

const InputDummy: React.FC<InputProps> = (props) => {
  const {
    isWip,
    placeholder,
    isDisabled,
    textarea,
    justifyContent,
    caption,
    defaultValue,
    color,
    ...rest
  } = props;

  const sx = textarea
    ? { ...textAreaStyle, pb: '15px' }
    : color
    ? { ...inputStyle, color: color }
    : inputStyle;

  return (
    <Box display='flex' flexDirection='column' justifyContent={justifyContent}>
      <Box display='flex'>
        <Typography component='p' display='block' textAlign='left' color='darker' variant='body2'>
          {`${caption} `}
        </Typography>

        {isWip && <WipChip />}
      </Box>
      <InputBase
        fullWidth
        readOnly
        defaultValue={defaultValue}
        value={defaultValue}
        sx={sx}
        placeholder={placeholder}
        disabled={isDisabled || isWip}
        {...rest}
      />
    </Box>
  );
};

export default InputDummy;
