import { configureStore } from '@reduxjs/toolkit';
import actionArias from './slices/action-arias';
import client from './slices/clients/global/index';
import treatmentPlanning from './slices/clients/treatment-planning';
import menu from './slices/menu';
import notifications from './slices/notifications';
import onbording from './slices/onbording';
import tasks from './slices/tasks';
import tokens from './slices/tokens';

export const store = configureStore({
  reducer: {
    tokens,
    tasks,
    notifications,
    menu,
    client,
    onbording,
    treatmentPlanning,
    actionArias,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
