import { Button } from '@mui/material';
import React from 'react';
import toast from 'react-hot-toast';
import Alert from 'UI/molecules/global/alerts';

interface Props {
  title: string;
  subtitle: string;
  toastId: string;
}

const ErrorDismissToast: React.FC<Props> = ({ title, subtitle, toastId }) => {
  return (
    <span>
      <Alert title={title} subtitle={subtitle} />
      <Button onClick={() => toast.dismiss(toastId)}>Dismiss</Button>
    </span>
  );
};

export default ErrorDismissToast;
