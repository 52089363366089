import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Box, Checkbox as CB, FormControlLabel, Switch as SW } from '@mui/material';
import ErrorSpan from 'components/ErrorSpan';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { dark } from 'theme/palette';
import { checkboxStyle } from 'theme/styles/components/checkbox';
type CheckboxProps = {
  caption: string;
  fieldName: string;
  isDisabled?: boolean;
  noErrors?: boolean;
  sideErrors?: boolean;
  toggle?: boolean;
  placement?: 'top' | 'bottom' | 'end' | 'start';
  justifyContent?: 'space-between' | 'space-around' | 'flex-start' | 'flex-end' | 'center';
};

const row = (isReverse: boolean) => ({
  display: 'flex',
  flexDirection: isReverse ? 'row-reverse' : 'row',
  alignItems: 'center',
  justifyContent: isReverse ? 'flex-end' : 'flex-start',
});

const getContainerProps = (isRow: boolean, isReverse) => (isRow ? row(isReverse) : {});
const getErrosMB = (isRow: boolean, hasError: boolean) => {
  const empty = {};
  const mb = { mb: hasError ? '6px' : '26px' };

  return isRow ? empty : mb;
};

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const {
    caption,
    fieldName,
    isDisabled = false,
    noErrors = false,
    sideErrors = false,
    toggle = true,
    placement,
    justifyContent = '',
  } = props;

  const { control, formState } = useFormContext();
  const { errors } = formState;

  const hasError = !!errors[fieldName];
  const canShowErrors = !noErrors || sideErrors;

  const Comp = toggle ? SW : CB;
  const extraProps = toggle
    ? {}
    : {
        icon: <CheckBoxOutlineBlankOutlinedIcon />,
        checkedIcon: <CheckBoxOutlinedIcon />,
      };

  return (
    <Box {...getContainerProps(sideErrors, !toggle)} alignItems={justifyContent}>
      {canShowErrors && (
        <Box {...getErrosMB(sideErrors, hasError)}>
          <ErrorSpan errors={errors} name={fieldName} />
        </Box>
      )}

      <Controller
        name={fieldName}
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <FormControlLabel
            control={
              <Comp
                onChange={onChange}
                checked={value || false}
                inputRef={ref}
                disabled={isDisabled}
                sx={toggle ? {} : checkboxStyle(value, isDisabled)}
                {...extraProps}
              />
            }
            label={caption}
            labelPlacement={placement ? placement : 'start'}
            sx={{
              ml: placement && 0,
              mr: 2,
            }}
            componentsProps={{
              typography: { color: dark[200], variant: 'body2' },
            }}
          />
        )}
      />
    </Box>
  );
};

export default Checkbox;
