import axios from 'axios';
import {
  axiosRequestInterceptors,
  axiosResponseInterceptors,
  customParamsSerializer,
} from 'utils/axios';

export function environment(env: string | undefined): string | undefined {
  if (env !== 'production') {
    return `${process.env.REACT_APP_SERVICE_API_URL}`;
  }

  if (env === 'production') {
    return `${process.env.REACT_APP_SERVICE_API_URL}`;
  }

  return '';
}

export const baseUrl = environment(process.env.NODE_ENV);

export const axiosConfiguration = axios.create({
  baseURL: baseUrl,
  headers: {
    accept: '*/*',
  },
  paramsSerializer: customParamsSerializer,
});
axiosConfiguration.interceptors.request.use(...axiosRequestInterceptors);
axiosConfiguration.interceptors.response.use(...axiosResponseInterceptors);

export const axiosFDConfiguration = axios.create({
  baseURL: baseUrl,
  headers: {
    accept: '*/*',
    'Content-Type': 'multipart/form-data',
  },
  paramsSerializer: customParamsSerializer,
});
axiosFDConfiguration.interceptors.request.use(...axiosRequestInterceptors);
axiosFDConfiguration.interceptors.response.use(...axiosResponseInterceptors);

export const axiosAzure = axios.create({
  baseURL: baseUrl,
  headers: {
    accept: '*/*',
    'x-ms-blob-type': 'BlockBlob',
    responseType: 'json',
  },
});

export enum methods {
  get = 'get',
  post = 'post',
  patch = 'patch',
  put = 'put',
  delete = 'delete',
  postFD = 'postFD',
  patchFD = 'patchFD',
  putFD = 'putFD',
  deleteFD = 'deleteFD',
}
export const api = {
  [methods.get]: <T>(url: string, params?: object) =>
    axiosConfiguration.get<T>(url, {
      ...params,
    }),
  [methods.post]: <T>(url: string, data: any) => axiosConfiguration.post<T>(url, data),
  [methods.patch]: <T>(url: string, data: any) => axiosConfiguration.patch<T>(url, data),
  [methods.put]: <T>(url: string, data: any) => axiosConfiguration.put<T>(url, data),
  [methods.delete]: <T>(url: string, data: any) => axiosConfiguration.delete<T>(url, data),
  [methods.deleteFD]: <T>(url: string, data: any) => axiosConfiguration.delete<T>(url, data),
  [methods.postFD]: <T>(url: string, data: any) => axiosFDConfiguration.post<T>(url, data),
  [methods.patchFD]: <T>(url: string, data: any) => axiosFDConfiguration.patch<T>(url, data),
  [methods.putFD]: <T>(url: string, data: any) => axiosFDConfiguration.put<T>(url, data),
};
